import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { endpoints, ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import * as Sentry from '@sentry/react';

import ErrorBoundary from 'app/components/ErrorBoundary';
import ErrorPage from 'common/components/error/ErrorPage';
import { IS_PROD } from 'common/constants/common-constants';

if (IS_PROD) {
  Sentry.init({
    beforeSend: (event, hint) => {
      const error = hint.originalException;

      return isPresent(error) && error instanceof ResponseError && error.status < 500 ? null : event;
    },
    // TODO: move to CI env variables
    dsn: 'https://071a89235bd242f9a1361f85ae512494@o163897.ingest.sentry.io/4505039413379072',
    environment: endpoints.rootDomain,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
export const SentryContainer = ({ children }: { readonly children: JSX.Element }): JSX.Element =>
  IS_PROD ? (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>
  ) : (
    <ErrorBoundary>{children}</ErrorBoundary>
  );

export default SentryContainer;
