import { isOwner } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { WithMeta } from '@import-io/types';

import { selectCurrentUser } from 'features/user/auth/user-auth-query';

export const useIsOwner = (item: WithMeta | null | undefined): boolean => {
  const currentUser = selectCurrentUser();
  if (!isPresent(item?._meta)) {
    // Unable to determine ownership
    return false;
  }

  return isOwner(item, currentUser);
};
