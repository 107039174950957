import {
  FINISH_HISTORY_LOAD,
  HISTORY_LOAD_FAILED,
  LOAD_EXTRACTOR_RUNS,
  LOAD_REPORT_HISTORY,
  START_HISTORY_LOAD,
  UPDATE_RUNS_BEING_MODIFIED,
} from '../actions/reportHistory';

function getInitialState() {
  return {
    history: [],
    extractorRuns: [],
    loadFailed: false,
    loadingHistory: true,
    runsBeingModified: {},
  };
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case LOAD_REPORT_HISTORY: {
      return {
        ...state,
        history: action.history,
      };
    }
    case LOAD_EXTRACTOR_RUNS: {
      return {
        ...state,
        extractorRuns: action.extractorRuns || [],
      };
    }
    case UPDATE_RUNS_BEING_MODIFIED: {
      return {
        ...state,
        runsBeingModified: action.runsBeingModified,
      };
    }
    case START_HISTORY_LOAD: {
      return {
        ...state,
        loadFailed: false,
        loadingHistory: true,
      };
    }
    case FINISH_HISTORY_LOAD: {
      return {
        ...state,
        loadFailed: false,
        loadingHistory: false,
      };
    }
    case HISTORY_LOAD_FAILED: {
      return {
        ...state,
        loadFailed: true,
        loadingHistory: false,
      };
    }
    default:
      return state;
  }
}
