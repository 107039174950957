// TODO: split file into separate features
import { encryptApi, extractorApi, objectStoreApi, ObjectStoreQueryRequestBuilder, ResponseCallback } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type {
  CrawlRun,
  Extractor,
  InputItem,
  RuntimeConfiguration,
  RuntimeConfigurationNew,
  RuntimeConfigurationRecordNew,
} from '@import-io/types';
import { RuntimeConfigurationWrapper } from '@import-io/types';

import { convertToArray, convertToLSV } from '../extractors-utils';

export async function fetchUrlList(extractor: Extractor): Promise<string[]> {
  try {
    const urlList = extractor.urlList as string;
    if (isPresent(urlList)) {
      const response = await extractorApi.getAttachment(extractor.guid, 'urlList', urlList, undefined, ResponseCallback.TEXT);
      return isPresent(response) ? convertToArray(response) : [];
    }
  } catch (e) {
    if (e.status === 401) {
      console.error('fetchUrlList returned a 401 error', e);
    } else {
      console.error('failed to fetchUrlList', e);
    }
  }
  return [];
}

export async function fetchTrainingData(extractor: Extractor): Promise<unknown> {
  try {
    return await extractorApi.getAttachment(extractor.guid, 'training', extractor.training);
  } catch (e) {
    if (e.status === 401) {
      console.error('fetchTrainingData returned a 401 error', e);
    } else {
      console.error('failed to fetchTrainingData', e);
    }
  }
  return null;
}

export async function fetchHistory(extractor: Extractor): Promise<CrawlRun[]> {
  try {
    const query = new ObjectStoreQueryRequestBuilder()
      .setPageLimit(100)
      .setPageNumber(1)
      .setSortDesc(true)
      .addEqFilter('extractorId', extractor.guid)
      .build();
    return await objectStoreApi.crawlRun.query(query);
  } catch (e) {
    if (e.status === 401) {
      console.error('_fetchHistory returned a 401 error', e);
    } else {
      console.error('failed to _fetchHistory', e);
    }
  }
  return [];
}

export async function setRuntimeConfig(
  extractor: Extractor | null,
  runtimeConfig: RuntimeConfiguration,
): Promise<Partial<Extractor> | undefined> {
  try {
    if (!isPresent(extractor) || !isPresent(runtimeConfig)) {
      return undefined;
    }

    const wrapper = new RuntimeConfigurationWrapper(runtimeConfig);

    const newRuntimeConfigValue: RuntimeConfigurationNew = {
      ...wrapper.runtimeConfig,
    };

    //region Remove after upgrade to new web-extractor in chromium
    Object.values(newRuntimeConfigValue.extractionConfigs._runtimeConfig.fields || {}).forEach((f) => {
      f.singleValue = f.singleValue === true;
    });
    //endregion

    const newRuntimeConfig: Partial<RuntimeConfigurationRecordNew> = {
      extractorId: extractor.guid,
      config: {
        ...newRuntimeConfigValue,
      },
    };

    const response = await objectStoreApi.runtimeConfiguration.create(newRuntimeConfig);
    const latestConfigId = response.guid;
    return await extractorApi.update(extractor.guid, { latestConfigId: latestConfigId });
  } catch (e) {
    console.error('failed to setRuntimeConfig', e);
  }
}

export async function updateInputs(extractor: Extractor, inputs: InputItem[]): Promise<string | null> {
  try {
    const inputsToSave = convertToLSV(inputs.map((i) => JSON.stringify(i)));
    const attachment = await extractorApi.sendAttachment(extractor.guid, 'inputs', inputsToSave, 'text/plain');
    return attachment.guid;
  } catch (e) {
    console.error('failed to update inputs', e);
  }
  return null;
}

export async function updateUrlList(extractor: Extractor, urlList: any[]): Promise<string | null> {
  try {
    const attachment = await extractorApi.sendAttachment(extractor.guid, 'urlList', convertToLSV(urlList), 'text/plain');
    return attachment.guid;
  } catch (e) {
    console.error('failed to update url list', e);
  }
  return null;
}

export async function saveAuthenticationData(username: string, password: string): Promise<unknown> {
  if (username && password) {
    const authData = {
      username: username,
      password: password,
    };
    try {
      const privateConfig = await encryptApi.create(authData);
      const guid = privateConfig?.guid;
      if (isPresent(guid)) {
        return guid;
      }
    } catch (err) {
      console.error('Error creating encryption for authenticated extractor:', err);
    }
  }
}

export async function getUrlListLength(extractor: Extractor): Promise<number> {
  if (isPresent(extractor)) {
    const list = await fetchUrlList(extractor);
    return isPresent(list) ? list.length : 0;
  }
  return 0;
}

export function getExtractorWithChild(extractorId: string, child: string): Promise<Extractor | undefined | null> {
  return extractorApi.get(extractorId, child);
}

export async function updateExtractorCredentials(extractor: Extractor, credentials): Promise<void> {
  try {
    const privateConfig = extractor.inputs ? await encryptApi.createObject(credentials) : await encryptApi.create(credentials);
    const guid = privateConfig?.guid;
    if (isPresent(guid)) {
      await extractorApi.update(extractor.guid, { privateConfig: { credentialsGuid: guid } });
    } else {
      throw new Error('No private config created');
    }
  } catch (e) {
    throw e;
  }
}
