export enum SubscriptionModalAction {
  DOWNGRADE = 'DOWNGRADE',
  UPGRADE = 'UPGRADE',
  HIDE = 'HIDE',
}

export interface SubscriptionModalState {
  isOpen: boolean;
  isUpgrade: boolean;
}

export interface SubscriptionModalContextValue extends SubscriptionModalState {
  hideModal: () => void;
  showDowngradeModal: () => void;
  showUpgradeModal: () => void;
}
