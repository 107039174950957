import type { ThemeConfig } from 'antd/lib/config-provider';

import COLORS from 'common/constants/colors';

const FONT_FAMILY = "'Maax-Standard', 'sans-serif'";

export const theme: ThemeConfig = {
  components: {
    Button: {
      boxShadow: 'none',
      boxShadowSecondary: 'none',
      colorPrimary: COLORS.BUTTON_PRIMARY,
      colorPrimaryActive: COLORS.BUTTON_PRIMARY,
      colorPrimaryHover: COLORS.ACTIVE_CTA,
      fontFamily: FONT_FAMILY,
      fontSize: 12,
    },
    Card: {
      colorTextHeading: COLORS.TEXT,
      fontSizeLG: 18,
      fontWeightStrong: 500,
    },
    Checkbox: {
      colorPrimary: COLORS.IO_NAVY,
    },
    Empty: {},
    Input: {
      colorBgBase: COLORS.TRANSPARENT,
    },
    Layout: {
      headerBg: COLORS.BACKGROUND3,
    },
    Modal: {
      colorText: COLORS.TEXT,
      fontFamily: FONT_FAMILY,
      fontSizeHeading5: 20,
      fontWeightStrong: 600,
    },
    Spin: {
      colorPrimary: COLORS.BUTTON_PRIMARY,
    },
  },
  token: {
    colorBgBase: COLORS.WHITE,
    colorBgLayout: COLORS.LAYOUT_BACKGROUND,
    fontFamily: FONT_FAMILY,
    fontSize: 12,
  },
};
