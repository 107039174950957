import { isPresent } from '@import-io/typeguards';

import { useFetchFirstExtractor } from 'features/extractors/hooks/use-fetch-first-extractor';
import { useSubscriptionFeatureFlags } from 'features/user/subscription/subscription-hooks';

export const useCreateReportDisabled = (): boolean => {
  const { data } = useFetchFirstExtractor();
  const { canUseAnyReport } = useSubscriptionFeatureFlags();
  return !canUseAnyReport || !isPresent(data);
};
