import { isPresent } from '@import-io/typeguards';
import type { ActionData } from '@import-io/types/action-data-types';

import { EXTRACTORS_PAGE_URL } from 'common/routes/routes-constants';
import { getHistoryUrl } from 'common/routes/routes-utils';

const LSV_SPLITERATOR = '\n';

export const convertToArray = (lsv): string[] =>
  lsv
    .split(LSV_SPLITERATOR)
    .map((line) => line.trim())
    .filter(({ length }) => length !== 0);

export const convertToLSV = (arr: unknown[]): string => arr.join(LSV_SPLITERATOR);

export const getExtractorHistoryUrl = (id: string): string => getHistoryUrl(`${EXTRACTORS_PAGE_URL}/${id}`);

export const getExtractorUrl = (id: string, tab = ''): string => `${EXTRACTORS_PAGE_URL}/${id}/${tab}`;

export const getIsCodeFirstExtractor = (interactions: ActionData[] = []): boolean => {
  const first = interactions[0];
  return isPresent(first) && first.constructor === 'CodeAction';
};
