import { useCallback, useState } from 'react';

/**
 * Hook allows to store a single boolean value with toggle function.
 * @param initialValue {Boolean} initial value is false by default.
 * @returns {Array} array of two elements - boolean value, and toggle value function.
 */
export const useToggle = (initialValue: boolean = false): [boolean, () => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggleValue = useCallback(() => {
    setValue((prevValue) => !prevValue);
  }, [setValue]);

  return [value, toggleValue];
};
