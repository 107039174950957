import type { CountryCode } from 'common/common-types';

export const USA_COUNTRY_CODE: CountryCode = {
  country: 'United States of America',
  numeric: '840',
  alpha2: 'US',
  alpha3: 'USA',
};

const geoCountryCodes: CountryCode[] = [
  {
    country: 'United Kingdom',
    numeric: '826',
    alpha2: 'GB',
    alpha3: 'GBR',
  },
  {
    country: 'Thailand',
    numeric: '764',
    alpha2: 'TH',
    alpha3: 'THA',
  },
  {
    country: 'Brazil',
    numeric: '076',
    alpha2: 'BR',
    alpha3: 'BRA',
  },
  {
    country: 'Mexico',
    numeric: '484',
    alpha2: 'MX',
    alpha3: 'MEX',
  },
  {
    country: 'Italy',
    numeric: '380',
    alpha2: 'IT',
    alpha3: 'ITA',
  },
  {
    country: 'Spain',
    numeric: '724',
    alpha2: 'ES',
    alpha3: 'ESP',
  },
  {
    country: 'Japan',
    numeric: '392',
    alpha2: 'JP',
    alpha3: 'JPN',
  },
  {
    country: 'Australia',
    numeric: '036',
    alpha2: 'AU',
    alpha3: 'AUS',
  },
  {
    country: 'Poland',
    numeric: '616',
    alpha2: 'PL',
    alpha3: 'POL',
  },
  {
    country: 'Canada',
    numeric: '124',
    alpha2: 'CA',
    alpha3: 'CAN',
  },
  {
    country: 'Germany',
    numeric: '276',
    alpha2: 'DE',
    alpha3: 'DEU',
  },
  {
    country: 'France',
    numeric: '250',
    alpha2: 'FR',
    alpha3: 'FRA',
  },
  {
    country: 'India',
    numeric: '356',
    alpha2: 'IN',
    alpha3: 'IND',
  },
  {
    country: 'Russian Federation',
    numeric: '643',
    alpha2: 'RU',
    alpha3: 'RUS',
  },
  {
    country: 'Ukraine',
    numeric: '804',
    alpha2: 'UA',
    alpha3: 'UKR',
  },
  {
    country: 'Belarus',
    numeric: '112',
    alpha2: 'BY',
    alpha3: 'BLR',
  },
  {
    country: 'Argentina',
    numeric: '032',
    alpha2: 'AR',
    alpha3: 'ARG',
  },
  {
    country: 'Kazakhstan',
    numeric: '398',
    alpha2: 'KZ',
    alpha3: 'KAZ',
  },
  {
    country: 'Turkey',
    numeric: '792',
    alpha2: 'TR',
    alpha3: 'TUR',
  },
  {
    country: 'Hungary',
    numeric: '348',
    alpha2: 'HU',
    alpha3: 'HUN',
  },
  {
    country: 'Romania',
    numeric: '642',
    alpha2: 'RO',
    alpha3: 'ROU',
  },
  {
    country: 'Indonesia',
    numeric: '360',
    alpha2: 'ID',
    alpha3: 'IDN',
  },
  {
    country: 'Colombia',
    numeric: '170',
    alpha2: 'CO',
    alpha3: 'COL',
  },
  {
    country: 'Greece',
    numeric: '300',
    alpha2: 'GR',
    alpha3: 'GRC',
  },
  {
    country: 'Belgium',
    numeric: '056',
    alpha2: 'BE',
    alpha3: 'BEL',
  },
  {
    country: 'Venezuela (Bolivarian Republic)',
    numeric: '862',
    alpha2: 'VE',
    alpha3: 'VEN',
  },
  {
    country: 'Egypt',
    numeric: '818',
    alpha2: 'EG',
    alpha3: 'EGY',
  },
  {
    country: 'Portugal',
    numeric: '620',
    alpha2: 'PT',
    alpha3: 'PRT',
  },
  {
    country: 'Serbia',
    numeric: '688',
    alpha2: 'RS',
    alpha3: 'SRB',
  },
  {
    country: 'Czech Republic',
    numeric: '203',
    alpha2: 'CZ',
    alpha3: 'CZE',
  },
  {
    country: 'Bulgaria',
    numeric: '100',
    alpha2: 'BG',
    alpha3: 'BGR',
  },
  {
    country: 'Algeria',
    numeric: '012',
    alpha2: 'DZ',
    alpha3: 'DZA',
  },
  {
    country: 'Pakistan',
    numeric: '586',
    alpha2: 'PK',
    alpha3: 'PAK',
  },
  {
    country: 'Philippines',
    numeric: '608',
    alpha2: 'PH',
    alpha3: 'PHL',
  },
  {
    country: 'Malaysia',
    numeric: '458',
    alpha2: 'MY',
    alpha3: 'MYS',
  },
  {
    country: 'South Africa',
    numeric: '710',
    alpha2: 'ZA',
    alpha3: 'ZAF',
  },
  {
    country: 'Peru',
    numeric: '604',
    alpha2: 'PE',
    alpha3: 'PER',
  },
  {
    country: 'Israel',
    numeric: '376',
    alpha2: 'IL',
    alpha3: 'ISR',
  },
  {
    country: 'Chile',
    numeric: '152',
    alpha2: 'CL',
    alpha3: 'CHL',
  },
  {
    country: 'Saudi Arabia',
    numeric: '682',
    alpha2: 'SA',
    alpha3: 'SAU',
  },
  {
    country: 'Moldova',
    numeric: '498',
    alpha2: 'MD',
    alpha3: 'MDA',
  },
  {
    country: 'Netherlands',
    numeric: '528',
    alpha2: 'NL',
    alpha3: 'NLD',
  },
  {
    country: 'Taiwan, Republic of China',
    numeric: '158',
    alpha2: 'TW',
    alpha3: 'TWN',
  },
  {
    country: 'Ecuador',
    numeric: '218',
    alpha2: 'EC',
    alpha3: 'ECU',
  },
  {
    country: 'Lithuania',
    numeric: '440',
    alpha2: 'LT',
    alpha3: 'LTU',
  },
  {
    country: 'United Arab Emirates',
    numeric: '784',
    alpha2: 'AE',
    alpha3: 'ARE',
  },
  {
    country: 'Vietnam',
    numeric: '704',
    alpha2: 'VN',
    alpha3: 'VNM',
  },
  {
    country: 'Bangladesh',
    numeric: '050',
    alpha2: 'BD',
    alpha3: 'BGD',
  },
  {
    country: 'Latvia',
    numeric: '428',
    alpha2: 'LV',
    alpha3: 'LVA',
  },
  {
    country: 'Armenia',
    numeric: '051',
    alpha2: 'AM',
    alpha3: 'ARM',
  },
  {
    country: 'Sweden',
    numeric: '752',
    alpha2: 'SE',
    alpha3: 'SWE',
  },
  {
    country: 'Korea (South)',
    numeric: '410',
    alpha2: 'KR',
    alpha3: 'KOR',
  },
  {
    country: 'Switzerland',
    numeric: '756',
    alpha2: 'CH',
    alpha3: 'CHE',
  },
  {
    country: 'Austria',
    numeric: '040',
    alpha2: 'AT',
    alpha3: 'AUT',
  },
  {
    country: 'Croatia',
    numeric: '191',
    alpha2: 'HR',
    alpha3: 'HRV',
  },
  {
    country: 'Georgia',
    numeric: '268',
    alpha2: 'GE',
    alpha3: 'GEO',
  },
  {
    country: 'Azerbaijan',
    numeric: '031',
    alpha2: 'AZ',
    alpha3: 'AZE',
  },
  {
    country: 'Tunisia',
    numeric: '788',
    alpha2: 'TN',
    alpha3: 'TUN',
  },
  {
    country: 'Slovakia',
    numeric: '703',
    alpha2: 'SK',
    alpha3: 'SVK',
  },
  {
    country: 'Bosnia and Herzegovina',
    numeric: '070',
    alpha2: 'BA',
    alpha3: 'BIH',
  },
  {
    country: 'Morocco',
    numeric: '504',
    alpha2: 'MA',
    alpha3: 'MAR',
  },
  {
    country: 'Estonia',
    numeric: '233',
    alpha2: 'EE',
    alpha3: 'EST',
  },
  {
    country: 'Dominican Republic',
    numeric: '214',
    alpha2: 'DO',
    alpha3: 'DOM',
  },
  {
    country: 'Norway',
    numeric: '578',
    alpha2: 'NO',
    alpha3: 'NOR',
  },
  {
    country: 'China',
    numeric: '156',
    alpha2: 'CN',
    alpha3: 'CHN',
  },
  {
    country: 'Hong Kong, SAR China',
    numeric: '344',
    alpha2: 'HK',
    alpha3: 'HKG',
  },
  {
    country: 'Nepal',
    numeric: '524',
    alpha2: 'NP',
    alpha3: 'NPL',
  },
  {
    country: 'Iraq',
    numeric: '368',
    alpha2: 'IQ',
    alpha3: 'IRQ',
  },
  {
    country: 'Singapore',
    numeric: '702',
    alpha2: 'SG',
    alpha3: 'SGP',
  },
  {
    country: 'Uruguay',
    numeric: '858',
    alpha2: 'UY',
    alpha3: 'URY',
  },
  {
    country: 'Costa Rica',
    numeric: '188',
    alpha2: 'CR',
    alpha3: 'CRI',
  },
  {
    country: 'Albania',
    numeric: '008',
    alpha2: 'AL',
    alpha3: 'ALB',
  },
  {
    country: 'Denmark',
    numeric: '208',
    alpha2: 'DK',
    alpha3: 'DNK',
  },
  {
    country: 'New Zealand',
    numeric: '554',
    alpha2: 'NZ',
    alpha3: 'NZL',
  },
  {
    country: 'Sri Lanka',
    numeric: '144',
    alpha2: 'LK',
    alpha3: 'LKA',
  },
  {
    country: 'Jordan',
    numeric: '400',
    alpha2: 'JO',
    alpha3: 'JOR',
  },
  {
    country: 'Qatar',
    numeric: '634',
    alpha2: 'QA',
    alpha3: 'QAT',
  },
  {
    country: 'Slovenia',
    numeric: '705',
    alpha2: 'SI',
    alpha3: 'SVN',
  },
  {
    country: 'Kyrgyzstan',
    numeric: '417',
    alpha2: 'KG',
    alpha3: 'KGZ',
  },
  {
    country: 'Kenya',
    numeric: '404',
    alpha2: 'KE',
    alpha3: 'KEN',
  },
  {
    country: 'Trinidad and Tobago',
    numeric: '780',
    alpha2: 'TT',
    alpha3: 'TTO',
  },
  {
    country: 'Macedonia, Republic of',
    numeric: '807',
    alpha2: 'MK',
    alpha3: 'MKD',
  },
  {
    country: 'Uzbekistan',
    numeric: '860',
    alpha2: 'UZ',
    alpha3: 'UZB',
  },
  {
    country: 'Ireland',
    numeric: '372',
    alpha2: 'IE',
    alpha3: 'IRL',
  },
  {
    country: 'Jamaica',
    numeric: '388',
    alpha2: 'JM',
    alpha3: 'JAM',
  },
  {
    country: 'Paraguay',
    numeric: '600',
    alpha2: 'PY',
    alpha3: 'PRY',
  },
  {
    country: 'Ghana',
    numeric: '288',
    alpha2: 'GH',
    alpha3: 'GHA',
  },
  {
    country: 'Guatemala',
    numeric: '320',
    alpha2: 'GT',
    alpha3: 'GTM',
  },
  {
    country: 'Oman',
    numeric: '512',
    alpha2: 'OM',
    alpha3: 'OMN',
  },
  {
    country: 'Bolivia',
    numeric: '068',
    alpha2: 'BO',
    alpha3: 'BOL',
  },
  {
    country: 'Lebanon',
    numeric: '422',
    alpha2: 'LB',
    alpha3: 'LBN',
  },
  {
    country: 'El Salvador',
    numeric: '222',
    alpha2: 'SV',
    alpha3: 'SLV',
  },
  {
    country: 'Honduras',
    numeric: '340',
    alpha2: 'HN',
    alpha3: 'HND',
  },
  {
    country: 'Mauritius',
    numeric: '480',
    alpha2: 'MU',
    alpha3: 'MUS',
  },
  {
    country: 'Réunion',
    numeric: '638',
    alpha2: 'RE',
    alpha3: 'REU',
  },
  {
    country: 'Puerto Rico',
    numeric: '630',
    alpha2: 'PR',
    alpha3: 'PRI',
  },
  {
    country: 'Kuwait',
    numeric: '414',
    alpha2: 'KW',
    alpha3: 'KWT',
  },
  {
    country: 'Finland',
    numeric: '246',
    alpha2: 'FI',
    alpha3: 'FIN',
  },
  {
    country: 'Montenegro',
    numeric: '499',
    alpha2: 'ME',
    alpha3: 'MNE',
  },
  {
    country: 'Cambodia',
    numeric: '116',
    alpha2: 'KH',
    alpha3: 'KHM',
  },
  {
    country: 'Mongolia',
    numeric: '496',
    alpha2: 'MN',
    alpha3: 'MNG',
  },
  {
    country: 'Nigeria',
    numeric: '566',
    alpha2: 'NG',
    alpha3: 'NGA',
  },
  {
    country: 'Senegal',
    numeric: '686',
    alpha2: 'SN',
    alpha3: 'SEN',
  },
  {
    country: 'Iran, Islamic Republic of',
    numeric: '364',
    alpha2: 'IR',
    alpha3: 'IRN',
  },
  {
    country: 'Namibia',
    numeric: '516',
    alpha2: 'NA',
    alpha3: 'NAM',
  },
  {
    country: 'Brunei Darussalam',
    numeric: '096',
    alpha2: 'BN',
    alpha3: 'BRN',
  },
  {
    country: 'Cyprus',
    numeric: '196',
    alpha2: 'CY',
    alpha3: 'CYP',
  },
  {
    country: 'Myanmar',
    numeric: '104',
    alpha2: 'MM',
    alpha3: 'MMR',
  },
  {
    country: 'Panama',
    numeric: '591',
    alpha2: 'PA',
    alpha3: 'PAN',
  },
  {
    country: 'Botswana',
    numeric: '072',
    alpha2: 'BW',
    alpha3: 'BWA',
  },
  {
    country: 'Maldives',
    numeric: '462',
    alpha2: 'MV',
    alpha3: 'MDV',
  },
  {
    country: 'Angola',
    numeric: '024',
    alpha2: 'AO',
    alpha3: 'AGO',
  },
  {
    country: 'Bahamas',
    numeric: '044',
    alpha2: 'BS',
    alpha3: 'BHS',
  },
  {
    country: 'Nicaragua',
    numeric: '558',
    alpha2: 'NI',
    alpha3: 'NIC',
  },
  {
    country: 'Malta',
    numeric: '470',
    alpha2: 'MT',
    alpha3: 'MLT',
  },
  {
    country: 'Barbados',
    numeric: '052',
    alpha2: 'BB',
    alpha3: 'BRB',
  },
  {
    country: 'Cameroon',
    numeric: '120',
    alpha2: 'CM',
    alpha3: 'CMR',
  },
  {
    country: 'Sudan',
    numeric: '736',
    alpha2: 'SD',
    alpha3: 'SDN',
  },
  {
    country: 'Bahrain',
    numeric: '048',
    alpha2: 'BH',
    alpha3: 'BHR',
  },
  {
    country: 'Luxembourg',
    numeric: '442',
    alpha2: 'LU',
    alpha3: 'LUX',
  },
  {
    country: 'Guyana',
    numeric: '328',
    alpha2: 'GY',
    alpha3: 'GUY',
  },
  {
    country: 'Gabon',
    numeric: '266',
    alpha2: 'GA',
    alpha3: 'GAB',
  },
  {
    country: 'Suriname',
    numeric: '740',
    alpha2: 'SR',
    alpha3: 'SUR',
  },
  {
    country: 'French Guiana',
    numeric: '254',
    alpha2: 'GF',
    alpha3: 'GUF',
  },
  {
    country: 'Zimbabwe',
    numeric: '716',
    alpha2: 'ZW',
    alpha3: 'ZWE',
  },
  {
    country: 'Iceland',
    numeric: '352',
    alpha2: 'IS',
    alpha3: 'ISL',
  },
  {
    country: 'Saint Lucia',
    numeric: '662',
    alpha2: 'LC',
    alpha3: 'LCA',
  },
  {
    country: 'Tanzania, United Republic of',
    numeric: '834',
    alpha2: 'TZ',
    alpha3: 'TZA',
  },
  {
    country: 'Mozambique',
    numeric: '508',
    alpha2: 'MZ',
    alpha3: 'MOZ',
  },
  {
    country: 'Uganda',
    numeric: '800',
    alpha2: 'UG',
    alpha3: 'UGA',
  },
  {
    country: 'Yemen',
    numeric: '887',
    alpha2: 'YE',
    alpha3: 'YEM',
  },
  {
    country: 'Guadeloupe',
    numeric: '312',
    alpha2: 'GP',
    alpha3: 'GLP',
  },
  {
    country: 'Zambia',
    numeric: '894',
    alpha2: 'ZM',
    alpha3: 'ZMB',
  },
  {
    country: 'Lao PDR',
    numeric: '418',
    alpha2: 'LA',
    alpha3: 'LAO',
  },
  {
    country: 'Martinique',
    numeric: '474',
    alpha2: 'MQ',
    alpha3: 'MTQ',
  },
  {
    country: 'Syrian Arab Republic (Syria)',
    numeric: '760',
    alpha2: 'SY',
    alpha3: 'SYR',
  },
  {
    country: 'Andorra',
    numeric: '020',
    alpha2: 'AD',
    alpha3: 'AND',
  },
  {
    country: 'Guinea',
    numeric: '324',
    alpha2: 'GN',
    alpha3: 'GIN',
  },
  {
    country: 'Belize',
    numeric: '084',
    alpha2: 'BZ',
    alpha3: 'BLZ',
  },
  {
    country: 'Benin',
    numeric: '204',
    alpha2: 'BJ',
    alpha3: 'BEN',
  },
  {
    country: 'Cuba',
    numeric: '192',
    alpha2: 'CU',
    alpha3: 'CUB',
  },
  {
    country: 'Madagascar',
    numeric: '450',
    alpha2: 'MG',
    alpha3: 'MDG',
  },
  {
    country: 'Saint Vincent and Grenadines',
    numeric: '670',
    alpha2: 'VC',
    alpha3: 'VCT',
  },
  {
    country: 'Togo',
    numeric: '768',
    alpha2: 'TG',
    alpha3: 'TGO',
  },
  {
    country: 'Haiti',
    numeric: '332',
    alpha2: 'HT',
    alpha3: 'HTI',
  },
  {
    country: 'Saint Kitts and Nevis',
    numeric: '659',
    alpha2: 'KN',
    alpha3: 'KNA',
  },
  {
    country: 'Rwanda',
    numeric: '646',
    alpha2: 'RW',
    alpha3: 'RWA',
  },
  {
    country: 'Sierra Leone',
    numeric: '694',
    alpha2: 'SL',
    alpha3: 'SLE',
  },
  {
    country: 'Djibouti',
    numeric: '262',
    alpha2: 'DJ',
    alpha3: 'DJI',
  },
  {
    country: 'Ethiopia',
    numeric: '231',
    alpha2: 'ET',
    alpha3: 'ETH',
  },
  {
    country: 'Guam',
    numeric: '316',
    alpha2: 'GU',
    alpha3: 'GUM',
  },
  {
    country: 'French Polynesia',
    numeric: '258',
    alpha2: 'PF',
    alpha3: 'PYF',
  },
  {
    country: 'Gambia',
    numeric: '270',
    alpha2: 'GM',
    alpha3: 'GMB',
  },
  {
    country: 'Grenada',
    numeric: '308',
    alpha2: 'GD',
    alpha3: 'GRD',
  },
  {
    country: 'Virgin Islands, US',
    numeric: '850',
    alpha2: 'VI',
    alpha3: 'VIR',
  },
  {
    country: 'Aruba',
    numeric: '533',
    alpha2: 'AW',
    alpha3: 'ABW',
  },
  {
    country: 'Tajikistan',
    numeric: '762',
    alpha2: 'TJ',
    alpha3: 'TJK',
  },
  {
    country: 'Solomon Islands',
    numeric: '090',
    alpha2: 'SB',
    alpha3: 'SLB',
  },
  {
    country: 'Fiji',
    numeric: '242',
    alpha2: 'FJ',
    alpha3: 'FJI',
  },
  {
    country: 'Greenland',
    numeric: '304',
    alpha2: 'GL',
    alpha3: 'GRL',
  },
  {
    country: 'Malawi',
    numeric: '454',
    alpha2: 'MW',
    alpha3: 'MWI',
  },
];

geoCountryCodes.sort((a, b) => {
  if (a.country < b.country) return -1;
  if (a.country > b.country) return 1;
  return 0;
});

export const GEO_COUNTRY_CODES: CountryCode[] = [USA_COUNTRY_CODE, ...geoCountryCodes];
