import { combineReducers } from 'redux';
import undoable, { includeAction } from 'redux-undo';

import { CREATE_STATE, UPDATE_STATE } from '../actions/builder';

import { builderReducer } from './builder';
import { interactionsReducer } from './interactions';
import { projectReducer } from './project';
import { uiReducer } from './ui';

export const lightningReducer = combineReducers({
  ui: uiReducer,
  project: projectReducer,
  interactions: interactionsReducer,
  builder: undoable(builderReducer, {
    limit: 10,
    filter: includeAction([CREATE_STATE, UPDATE_STATE]),
  }),
});
