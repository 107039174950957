import { useEffect } from 'react';

import type { ResponseError } from '@import-io/js-sdk';
import type { Extractor } from '@import-io/types';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

import { fetchAllExtractorsApi } from 'features/extractors/api/extractor-list-api';
import { EXTRACTOR_LIST_QUERY_KEY, EXTRACTOR_NAMES_MAP_QUERY_KEY } from 'features/extractors/extractors-constants';

export const useExtractorsQuery = ({
  enabled = true,
  refetchOnMount = true, // to reduce the number of requests
}: Omit<UseQueryOptions<Extractor[], ResponseError>, 'onError' | 'queryFn' | 'queryKey'> = {}): UseQueryResult<
  Extractor[],
  ResponseError
> => {
  const queryClient = useQueryClient();
  const q = useQuery<Extractor[], ResponseError>({
    queryKey: [EXTRACTOR_LIST_QUERY_KEY],
    queryFn: async () => {
      const extractors = await fetchAllExtractorsApi();
      const namesMap = Object.fromEntries(extractors.map((e) => [e.guid, e.name]));
      queryClient.setQueryData(EXTRACTOR_NAMES_MAP_QUERY_KEY, namesMap);
      return extractors;
    },
    enabled: enabled,
    refetchOnMount: refetchOnMount,
  });

  const { error } = q;

  useEffect(() => {
    if (error) {
      console.log('Fetch extractors error:', error);
    }
  }, [error]);

  return q;
};
