import { useCallback, useState } from 'react';

export const useHandleOpenChange = (
  initialOpen = false,
): [open: boolean, handleOpenChange: (open: boolean) => void, handleClose: () => void] => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const handleOpenChange = useCallback((open: boolean) => setIsOpen(open), []);
  const handleClose = useCallback(() => setIsOpen(false), []);
  return [isOpen, handleOpenChange, handleClose];
};
