/**
 * Filters an object
 * @param {*} object
 * @param {*} callback
 */
export const filterObject = (object, callback) => {
  const filteredObject = {};

  for (const key in object) {
    // Ignore prototype inheritance
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      // Get callback result
      const result = callback(object[key], key);
      // If successful then add to the filtered object
      if (result) {
        filteredObject[key] = object[key];
      }
    }
  }

  return filteredObject;
};

export const isShallowEqual = (object1, object2) => JSON.stringify(object1) === JSON.stringify(object2);
