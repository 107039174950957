export const META_COLUMNS = [
  {
    _isMeta: true,
    id: 'SCREEN_CAPTURE',
    name: 'Screen Capture Url',
  },
  {
    _isMeta: true,
    id: 'EXTRACTION_DATE',
    name: 'Extraction Date',
  },
  {
    _isMeta: true,
    id: 'URL',
    name: 'URL',
  },
  {
    _isMeta: true,
    id: 'SOURCE_URL',
    name: 'Source Url',
  },
];
