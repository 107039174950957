/* eslint-disable redux-saga/no-unhandled-errors */
import type { RuntimeConfigurationSettings } from '@import-io/types';
import { RuntimeConfigurationWrapper } from '@import-io/types';
import type { RuntimeConfigurationRecordNew } from '@import-io/types/runtime-configuration/runtime-configuration-types';
import cloneDeep from 'lodash/cloneDeep';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import type { RootState } from 'common/common-types';

import {
  ADD_FIELD_SAGA,
  recomputeExtraction,
  RENAME_FIELD_SAGA,
  REQUEST_RECOMPUTE,
  requestRecompute,
  selectField,
  updateState,
} from '../actions/builder';
import { RUNTIME_CONFIG_RECEIVED } from '../actions/project';

const runtimeConfigRecv = (runtimeConfiguration) => ({
  type: RUNTIME_CONFIG_RECEIVED,
  runtimeConfiguration: runtimeConfiguration,
});

function* startRecomputeExtraction() {
  const state: RootState = yield select();
  if (!state.lightning.builder.present.isCreatingTraining) {
    yield put(recomputeExtraction() as any);
  }
}

function* renameFieldSaga(action) {
  const state: RootState = yield select();
  const selectedField = action.builderState.selectedField;

  let runtimeConfiguration = state.lightning?.project?.runtimeConfiguration;
  if (runtimeConfiguration) {
    runtimeConfiguration = cloneDeep(runtimeConfiguration);
    const runtimeConfig = new RuntimeConfigurationWrapper(runtimeConfiguration).config;
    if (runtimeConfig) {
      for (const rtField of runtimeConfig.fields) {
        if (rtField.id === selectedField._id) {
          rtField.name = selectedField._name;
          break;
        }
      }
      yield put(runtimeConfigRecv(runtimeConfiguration));
    }
  }

  yield put(updateState(action.builderState));
  yield put(selectField(selectedField) as any);
  yield put(requestRecompute());
}

function* addFieldSaga(action) {
  const state = yield select();
  const { newField, builderState } = action;
  let runtimeConfiguration: RuntimeConfigurationRecordNew = state.lightning?.project?.runtimeConfiguration;
  if (runtimeConfiguration) {
    runtimeConfiguration = cloneDeep(runtimeConfiguration);
    const extractionConfigs = runtimeConfiguration?.config?.extractionConfigs;
    if (extractionConfigs) {
      const runtimeConfig: RuntimeConfigurationSettings | undefined = Object.values(extractionConfigs)[0];
      if (runtimeConfig) {
        runtimeConfig.fields.push(newField);
        yield put(runtimeConfigRecv(runtimeConfiguration));
      }
    }
  }
  yield put(updateState(builderState));
  yield put(selectField(newField) as any);
}

// eslint-disable-next-line import/no-default-export
export default [
  takeLatest(REQUEST_RECOMPUTE, startRecomputeExtraction),
  // These should be slow enough on the user end that we don't need to debounce with takeLatest.
  takeEvery(RENAME_FIELD_SAGA, renameFieldSaga),
  takeEvery(ADD_FIELD_SAGA, addFieldSaga),
];
