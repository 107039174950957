/* eslint-disable @typescript-eslint/no-use-before-define */
import { Action, actions } from '@import-io/replay-browser-events';
import type { ActionData } from '@import-io/types';
import { isVariableJson } from '@import-io/types';
import { v4 as uuidv4 } from 'uuid';

import { InteractionEnum } from 'features/extractor-builder/interaction/interaction-constants-old';
import { getActionName, isPasswordField } from 'features/extractor-builder/interaction/interaction-utils-old';

export class InteractionAction<A extends Action = Action> {
  public readonly browserAction: A;

  public actionId: string;

  public recordStatus: string;

  public readonly shouldDisplay: boolean;

  public readonly name: string;

  public readonly displayValue: string;

  public readonly canDelete: boolean;

  public readonly codeActionDependent: boolean;

  public readonly antIcon: string;

  private _url: string;

  private _value: any;

  public hasVariables: boolean;

  public readonly isInitialLoad: boolean;

  public isPasswordField: boolean;

  public readonly alwaysShowDisplayValue: boolean;

  public readonly disableLoop: boolean;

  public parentActionId?: string;

  public readonly isValid: boolean;

  public isAuth: boolean;

  public isChild?: boolean;

  private _actions: InteractionAction[] = [];

  constructor(data: {
    action: A;
    actionId?: string;
    recordStatus?: string;
    isInitialLoad?: boolean;
    parentActionId?: string;
    isValid?: boolean;
    isAuth?: boolean;
  }) {
    this.browserAction = data.action;
    this.actionId = data.actionId || uuidv4();
    this.recordStatus = data.recordStatus || 'recorded';
    this.name = getActionName(data.action);

    const actionConfig = InteractionEnum[this.name] || {};

    this.shouldDisplay = !!actionConfig.shouldDisplay;

    this.displayValue = actionConfig.displayValue || this.name;
    this.canDelete = !!actionConfig.canDelete;
    this.codeActionDependent = !!actionConfig.codeActionDependent;
    this.antIcon = actionConfig.antIcon || 'GlobalOutlined';

    let url = data.action.url;
    if (url && typeof url === 'object') {
      url = url.url;
    }
    this.url = url;

    const action = data.action;

    this.hasVariables =
      (this.name === 'InputChangeAction' && isVariableJson(action?.event?.value)) ||
      (this.name === 'SelectChangeAction' && isVariableJson(action?.event?.values)) ||
      ((this.name === 'FunctionAction' || this.name === 'WaitForFunctionAction') &&
        (action?.args?.$variable$ || action?.variables?.args?.$variable$));

    this.isInitialLoad = data.isInitialLoad ?? false;
    this.isPasswordField = this.recordStatus === 'recorded' && isPasswordField(data.action);
    this.alwaysShowDisplayValue = !!actionConfig.alwaysShowDisplayValue;
    this.disableLoop = !!actionConfig.disableLoop;
    this.parentActionId = data.parentActionId;
    this.isValid = data.isValid ?? true;
    this.isAuth = data.isAuth ?? false;

    if (action.actions) {
      this.actions = action.actions.map((a) => createInteractionAction({ action: a, parentActionId: this.actionId }));
    }
  }

  get value(): any {
    return this._value;
  }

  set value(_value: any) {
    // Read-only
  }

  addAction(action: InteractionAction) {
    if (!this.browserAction.actions) return;

    const { actionId, browserAction } = action;
    this.actions = this.actions || [];
    action.parentActionId = this.actionId;

    // if this is a scroll action in the context of an infinite scroll action
    // we must remove the y coordinates for the action so that will scroll to the bottom every time
    if (this instanceof PaginationAction && this.infiniteScroll && action.name === 'ScrollAction') {
      (action as ScrollAction).y = undefined;
    }

    const existingActionIndex = this.actions.findIndex((a) => a.actionId === actionId);
    if (existingActionIndex > -1) {
      const existingAction = this.actions[existingActionIndex];
      this.actions[existingActionIndex]!.mergeProps({ ...existingAction, ...action });
    } else {
      this.browserAction.actions.push(browserAction);
      this.actions.push(action);
    }
  }

  mergeProps(newProps) {
    Object.assign(this, newProps);
  }

  set actions(actionList: InteractionAction[]) {
    this._actions = actionList.map((a) => {
      a.parentActionId = this.actionId;
      return a;
    });

    if (this.browserAction instanceof actions.FlowControlAction) {
      this.browserAction.actions = actionList.map((a) => a.browserAction);
    }
  }

  get actions(): InteractionAction[] {
    return this._actions;
  }

  get selector() {
    return this.browserAction?.event?.target?.cssSelector || this.browserAction?.target?.cssSelector;
  }

  set selector(newSelector: string) {
    const target = this.browserAction?.target || this.browserAction?.event?.target;
    if (target) {
      target.cssSelector = newSelector;
    }
  }

  get optional() {
    return this.browserAction?.optional ?? false;
  }

  set optional(optional: boolean) {
    this.browserAction.optional = optional;
  }

  get timeout() {
    return this.browserAction?.timeout;
  }

  set timeout(timeout: number) {
    (this.browserAction as any).timeout = timeout;
  }

  set url(url: string) {
    if (this.name === 'WaitLoadingAction') {
      this._url = url;
      // @ts-ignore
      (this.browserAction as actions.WaitLoadingAction).url = url;
      return;
    }

    if (this.name !== 'GotoAction') {
      return;
    }
    this._url = url;
    const variableTarget = this.browserAction?.variables?.url;
    const urlType = typeof url;

    if (variableTarget) {
      const variableType = typeof variableTarget.defaultValue;
      if (variableType === urlType) {
        variableTarget.defaultValue = url;
      } else if (variableType === 'object') {
        if (urlType === 'string') {
          (variableTarget.defaultValue! as any).url = url;
        }
      } else if (variableType === 'string') {
        if (urlType === 'object') {
          variableTarget.defaultValue = (url as any).url;
        }
      }
    }

    const browserActionUrlType = typeof this.browserAction.url;
    if (browserActionUrlType === 'object') {
      if (urlType === 'object') {
        this.browserAction.url.url = (url as any).url;
      } else if (urlType === 'string') {
        this.browserAction.url.url = url;
      }
    } else if (browserActionUrlType === 'string') {
      if (urlType === 'object') {
        (this.browserAction as any).url = (url as any).url;
      } else if (urlType === 'string') {
        (this.browserAction as any).url = url;
      }
    }
  }

  get url() {
    return this._url;
  }

  get trueEvent() {
    return this.browserAction?.trueEvent;
  }

  get variable() {
    return (
      this.browserAction?.variables?.value ||
      this.browserAction?.variables?.values ||
      this.browserAction?.variables?.num ||
      this.browserAction?.variables?.args
    );
  }

  get variables() {
    return this.browserAction?.variables;
  }

  get variableNames(): string[] {
    if (!this.variables) {
      return [];
    }
    return Object.values(this.variables)
      .map((v) => v.name)
      .filter((v) => v);
  }

  get maybeCaptcha() {
    // check for trueEvent.target here so that we don't get errors thrown in maybeCaptcha
    return this.browserAction?.maybeCaptcha && this.trueEvent?.target && this.browserAction.maybeCaptcha();
  }

  deserialize(doc: Document) {
    try {
      const action = this.fromJSON(doc, this.browserAction);
      Object.assign(this, createInteractionAction({ action: action, isInitialLoad: this.isInitialLoad }));
    } catch (e) {
      console.error(e);
      // action is already deserialized
    }
  }

  toJSON() {
    return this.browserAction.toJSON();
  }

  fromJSON(document, data): Action {
    return Action.fromJSON(document, data);
  }

  changeVariableDefaultValue(variableType: string, newDefaultValue: any) {
    if (this.variables && this.variables[variableType]) {
      this.variables[variableType]!.defaultValue = newDefaultValue;
    }
  }
}

export class PaginationAction extends InteractionAction<actions.PaginationAction> {
  public readonly infiniteScroll: boolean;

  constructor(data) {
    super(data);
    this.infiniteScroll = data.action.infiniteScroll;
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      infiniteScroll: this.infiniteScroll,
    });
  }

  fromJSON(document, data) {
    const action = Action.fromJSON(document, data);
    action.infiniteScroll = !!data.infiniteScroll;
    return action;
  }
}

export class ScrollAction extends InteractionAction<actions.ScrollAction> {
  get y() {
    return this.browserAction.y;
  }

  set y(y) {
    this.browserAction.y = y;
  }
}

export class FlowControlAction extends InteractionAction<actions.FlowControlAction> {
  fromJSON(document, data) {
    const action = Action.fromJSON(document, data);
    action.name = data.name;
    return action;
  }
}

export class CaptchaAction extends InteractionAction<actions.CaptchaAction> {
  get imageElement(): string {
    return this.browserAction?.options?.imageElement || '';
  }

  set imageElement(imageElement) {
    this.browserAction.options.imageElement = imageElement;
  }

  get inputElement() {
    return this.browserAction?.options?.inputElement || '';
  }

  set inputElement(inputElement: string) {
    this.browserAction.options.inputElement = inputElement;
  }
}

export class ViewportAction extends InteractionAction<actions.ViewportAction> {
  private initialHeight?: number;

  private initialWidth?: number;

  private userDefined?: boolean;

  get height() {
    return this.browserAction?.height || 0;
  }

  set height(height: number) {
    if (!this.initialHeight) this.initialHeight = this.height;
    this.userDefined = true;
    this.browserAction.height = height;

    // we don't support variables for height yet
    if (this.browserAction?.variables?.height?.defaultValue || 0) {
      this.browserAction.variables.height!.defaultValue = height;
    }
  }

  get width() {
    return this.browserAction?.width || 0;
  }

  set width(width: number) {
    if (!this.initialWidth) this.initialWidth = this.width;

    this.userDefined = true;
    this.browserAction.width = width;

    // we don't support variables for width yet
    if (this.browserAction?.variables?.width?.defaultValue) {
      this.browserAction.variables.width.defaultValue = width;
    }
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      userDefined: this.userDefined,
      initialHeight: this.initialHeight,
      initialWidth: this.initialWidth,
    });
  }

  fromJSON(document: Document, data: ActionData & any) {
    const action = Action.fromJSON(document, data);
    action.userDefined = !!data.userDefined;
    action.initialHeight = data.initialHeight;
    action.initialWidth = data.initialWidth;
    return action;
  }
}

export class FunctionAction extends InteractionAction<actions.FunctionAction> {
  get args() {
    return this.browserAction?.context || '';
  }

  set args(args) {
    this.browserAction.args = args;
  }

  get context() {
    return this.browserAction?.context || '';
  }

  set context(context) {
    this.browserAction.context = context;
  }

  get callback() {
    return this.browserAction?.callback || '';
  }

  set callback(callback) {
    this.browserAction.callback = callback;
  }
}

export class WaitForFunctionAction extends FunctionAction {}

export class CodeAction extends InteractionAction<actions.CodeAction> {
  get code(): string {
    return this.browserAction?.code || '';
  }

  set code(code: string) {
    this.browserAction.code = code;
  }
}

export class SelectChangeAction extends InteractionAction<actions.SelectChangeAction> {
  get values() {
    return (this.browserAction?.values || []).join(',');
  }

  set values(values: any[]) {
    this.browserAction.values = values;
  }
}

export class InputChangeAction extends InteractionAction<actions.InputChangeAction> {
  override get value(): string {
    return this.browserAction?.value || '';
  }

  set value(value: string) {
    this.browserAction.value = value;
  }
}

export class GotoAction extends InteractionAction<actions.GotoAction> {}

export const AVAILABLE_CLASSES = [
  PaginationAction,
  FlowControlAction,
  ScrollAction,
  CaptchaAction,
  FunctionAction,
  InputChangeAction,
  SelectChangeAction,
  ViewportAction,
  GotoAction,
  CodeAction,
  WaitForFunctionAction,
];

export function createInteractionAction(data: { action: Action } & any): InteractionAction {
  const actionName = getActionName(data.action);
  const actionClass =
    AVAILABLE_CLASSES.find((c) => c.constructor.name === actionName || c.name === actionName) || InteractionAction;

  return new (actionClass as typeof InteractionAction)(data);
}
