import { objectStoreApi, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { isGuid } from '@import-io/typeguards';

export const START_HISTORY_LOAD = 'START_HISTORY_LOAD';
export const FINISH_HISTORY_LOAD = 'FINISH_HISTORY_LOAD';
export const HISTORY_LOAD_FAILED = 'HISTORY_LOAD_FAILED';
export const LOAD_REPORT_HISTORY = 'LOAD_REPORT_HISTORY';
export const LOAD_EXTRACTOR_RUNS = 'LOAD_EXTRACTOR_RUNS';
export const UPDATE_RUNS_BEING_MODIFIED = 'UPDATE_RUNS_BEING_MODIFIED';

const MAX_REPORT_HISTORY = 30;
const PUBLISH_TOGGLE_SMOOTHING_TIME = 1000;

export function clearReportHistory() {
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_HISTORY,
      history: [],
    });
    dispatch({
      type: LOAD_EXTRACTOR_RUNS,
      extractorRuns: [],
    });
  };
}

export function addRunToHistory(run) {
  return async (dispatch, getState) => {
    try {
      const history = [...getState().dashboard.reports.reportHistory.history];

      if (history.find((r) => r.guid === run.guid)) return;

      history.unshift(run);

      if (history.length > MAX_REPORT_HISTORY) {
        history.pop();
      }

      dispatch({
        type: LOAD_REPORT_HISTORY,
        history: history,
      });
    } catch (ex) {
      console.error('Could not add run to history', ex);
    }
  };
}

export function loadReportHistory({ showLoader } = { showLoader: true }) {
  return async (dispatch, getState) => {
    try {
      const report = getState().dashboard.reports.reportCore.selectedReport;
      if (!isGuid(report?.guid)) return;

      if (showLoader) {
        dispatch({
          type: START_HISTORY_LOAD,
        });
      }

      const query = new ObjectStoreQueryRequestBuilder()
        .setPageLimit(MAX_REPORT_HISTORY)
        .setPageNumber(1)
        .setSortDesc(true)
        .addEqFilter('reportId', report.guid)
        .setShowArchived(true)
        .build();

      const results = await objectStoreApi.reportRun.query(query);

      const { selectedReportGuid } = getState().dashboard.reports.reportCore;

      // If we changed reports while wait for this request, then trash the result
      if (selectedReportGuid !== report.guid) {
        return;
      }

      const history = results;

      let extractorRuns = [];
      try {
        if (report.extractorId) {
          const extractorQuery = new ObjectStoreQueryRequestBuilder()
            .setPageLimit(2)
            .setPageNumber(1)
            .setSortDesc(true)
            .addEqFilter('extractorId', report.extractorId)
            .addEqFilter('state', 'FINISHED')
            .setShowArchived(true)
            .build();

          extractorRuns = await objectStoreApi.crawlRun.query(extractorQuery);
        }
      } catch (ex) {
        console.error('Could not get extractor runs', ex);
      }

      dispatch({
        type: LOAD_EXTRACTOR_RUNS,
        extractorRuns: extractorRuns,
      });

      dispatch({
        type: LOAD_REPORT_HISTORY,
        history: history,
      });

      dispatch({
        type: FINISH_HISTORY_LOAD,
      });
    } catch (ex) {
      console.error('Could not load report history', ex);
      dispatch({
        type: HISTORY_LOAD_FAILED,
      });
    }
  };
}

export function toggleRunPublish(run) {
  return async (dispatch, getState) => {
    const { selectedReportGuid } = getState().dashboard.reports.reportCore;
    const { runsBeingModified } = getState().dashboard.reports.reportHistory;

    // If we are already modifying this run, then do nothing.
    if (runsBeingModified[run.guid]) {
      return;
    }

    // Update the list of runs being modified.
    dispatch({
      type: UPDATE_RUNS_BEING_MODIFIED,
      runsBeingModified: { ...runsBeingModified, [run.guid]: true },
    });

    // Modify the ruin
    const patchData = await objectStoreApi.reportRun.update(run.guid, {
      published: !run.published,
    });
    delete patchData._meta;

    await new Promise((resolve) => setTimeout(resolve, PUBLISH_TOGGLE_SMOOTHING_TIME));

    // Get some new values after we have waited around for a bit.
    const history = [...getState().dashboard.reports.reportHistory.history];
    const finalSelectedReportGuid = getState().dashboard.reports.reportCore.selectedReportGuid;
    const finalRunsBeingModified = { ...getState().dashboard.reports.reportHistory.runsBeingModified };

    delete finalRunsBeingModified[run.guid];

    // Update the list of runs being modified.
    dispatch({
      type: UPDATE_RUNS_BEING_MODIFIED,
      runsBeingModified: finalRunsBeingModified,
    });

    // If we have not changed reports since publishing, we should update the run history
    if (finalSelectedReportGuid === selectedReportGuid) {
      const runIndex = history.findIndex((item) => item.guid === run.guid);
      if (runIndex >= 0) {
        history.splice(runIndex, 1, { ...run, ...patchData });
      }
      dispatch({
        type: LOAD_REPORT_HISTORY,
        history: history,
      });
    }
  };
}
