import dashReducer from 'app/dash-old/reducers';
import { lightningReducer } from 'app/lightning-old';
import { routerReducer } from 'app/redux-router';
import type { RootReducer } from 'common/common-types';

// TODO: fix legacy fields types
export const rootReducer: RootReducer = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dashboard: dashReducer,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  lightning: lightningReducer,
  router: routerReducer,
};
