import type { JSX, PropsWithChildren } from 'react';

import { APP_URL } from '@import-io/js-sdk/constants/host-constants';

import LogoLight from 'app/components/LogoLight';
import type { PageSidebarProps } from 'common/components/layout/layout-types';
import SubscriptionWidget from 'features/user/subscription/components/SubscriptionWidget';

import styles from './PageSidebar.module.less';

const sidebarClass = `flex flex-column full-width full-height ${styles.container}`;

const PageSidebar = ({
  children,
  showLogo = false,
  showSubscription = false,
}: PropsWithChildren<PageSidebarProps>): JSX.Element => (
  <div className={sidebarClass}>
    {showLogo ? (
      <div className={`full-width text-center ${styles.sidebarLogo}`}>
        <a href={APP_URL}>
          <LogoLight className={styles.logoLink} />
        </a>
      </div>
    ) : null}
    <div className="flex flex-column flex-grow-1">{children}</div>
    {showSubscription ? <SubscriptionWidget /> : null}
  </div>
);

export default PageSidebar;
