import { useEffect, useState } from 'react';

import { isPresent } from '@import-io/typeguards';
import type { UserSubscriptionData, PlanType } from '@import-io/types';

import { selectCurrentUser } from '../auth/user-auth-query';
import { PAID_PLANS_KEYS, VALID_PLANS_MAP } from '../subscription/subscription-constants';

export const useIdentifyPosthog = (subscription: UserSubscriptionData | undefined) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { guid, email } = selectCurrentUser() ?? {};

  useEffect(() => {
    try {
      if (!isInitialized && 'posthog' in window && isPresent(window.posthog) && isPresent(subscription)) {
        const { deactivated, planCode } = subscription;
        (window.posthog as any).identify(guid, {
          // name: "John Doe",
          email: email,
          // created_at: "1519205055"
          account: guid,
          is_paying: !deactivated && PAID_PLANS_KEYS.includes(planCode as PlanType),
          monthly_value: VALID_PLANS_MAP[planCode]?.price,
          planLevel: planCode,
        });
        setIsInitialized(true);
      }
    } catch (e: unknown) {
      if ((e as Error).message.includes('Posthog is not defined')) {
        return; // `Posthog` global variable is defined only on production
      }
      console.log('Posthog initialize error:', e);
    }
  }, [email, guid, isInitialized, subscription]);
};
