import type { ProxyType } from '@import-io/types';

export enum EventType {
  ACCOUNT_CANCELLED = 'ACCOUNT_CANCELLED',
  ACCOUNT_DOWNGRADED = 'ACCOUNT_DOWNGRADED',
  ACCOUNT_UPGRADED = 'ACCOUNT_UPGRADED',
  CRAWLRUN_CANCELLED = 'CRAWLRUN_CANCELLED',
  EXTRACTOR_BUILDING = 'EXTRACTOR_BUILDING',
  EXTRACTOR_CHANGED = 'EXTRACTOR_CHANGED',
  EXTRACTOR_CREATED = 'EXTRACTOR_CREATED',
  EXTRACTOR_DELETED = 'EXTRACTOR_DELETED',
  EXTRACTOR_BUILDER_PAGE_LOAD = 'EXTRACTOR_BUILDER_PAGE_LOAD',
}

interface UserData {
  id: string;
  email?: string;
  planCode?: string;
}

interface ExtractorData {
  id: string;
  name?: string;
  url?: string;
  domainName?: string;
}

interface CrawlRunData {
  id?: string;
  inputs?: number;
  status?: string;
  rowsExtracted?: number;
}

export interface EventData extends Record<string, unknown> {
  crawlRun?: CrawlRunData;
  extractor?: ExtractorData;
  user?: UserData;
}

export interface ExtractorBuilderPageLoadEventData {
  user?: UserData;
  builderVersion: 'v1' | 'v2';
  proxySettings: {
    type: ProxyType;
    country: string;
  };
  url: string;
  status: 'success' | 'error';
  extractorId?: string | null;
}

interface BaseTrackedEvent {
  type: EventType;
  data: ExtractorBuilderPageLoadEventData | EventData;
}

export interface ExtractorBuilderPageLoadEvent extends BaseTrackedEvent {
  type: EventType.EXTRACTOR_BUILDER_PAGE_LOAD;
  data: ExtractorBuilderPageLoadEventData;
}

// TODO: refactor further - split into more specific events
export interface LegacyEvent extends BaseTrackedEvent {
  type: Exclude<EventType, EventType.EXTRACTOR_BUILDER_PAGE_LOAD>;
  data: EventData;
}

export type TrackedEvent = ExtractorBuilderPageLoadEvent | LegacyEvent;
