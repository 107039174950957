import type { JSX, PropsWithChildren } from 'react';
import { useCallback, useMemo, useReducer } from 'react';

import { SubscriptionModalAction, SubscriptionModalContext } from 'features/user/subscription/modal';

import { initialModalState } from './subscription-modal-constants';
import { subscriptionModalReducer } from './subscription-modal-reducer';
import type { SubscriptionModalContextValue } from './subscription-modal-types';

const SubscriptionModalProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [state, dispatch] = useReducer(subscriptionModalReducer, initialModalState);
  const { isOpen, isUpgrade } = state;
  const hideModal = useCallback(() => dispatch(SubscriptionModalAction.HIDE), [dispatch]);
  const showDowngradeModal = useCallback(() => dispatch(SubscriptionModalAction.DOWNGRADE), [dispatch]);
  const showUpgradeModal = useCallback(() => dispatch(SubscriptionModalAction.UPGRADE), [dispatch]);
  const contextValue = useMemo<SubscriptionModalContextValue>(
    () => ({
      hideModal: hideModal,
      isOpen: isOpen,
      isUpgrade: isUpgrade,
      showDowngradeModal: showDowngradeModal,
      showUpgradeModal: showUpgradeModal,
    }),
    [hideModal, isOpen, isUpgrade, showDowngradeModal, showUpgradeModal],
  );
  return <SubscriptionModalContext.Provider value={contextValue}>{children}</SubscriptionModalContext.Provider>;
};

export default SubscriptionModalProvider;
