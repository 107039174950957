import type { JSX } from 'react';
import { Navigate } from 'react-router-dom';

import { isPresent } from '@import-io/typeguards';

import PageLoader from 'common/components/PageLoader';
import { EmptyReports } from 'features/reports';
import { getReportHistoryUrl } from 'features/reports/common/reports-utils';
import { useFetchFirstReport } from 'features/reports/list/report-list-hooks';

const loader = <PageLoader />;

const FirstReportRedirect = (): JSX.Element => {
  const { data, isLoading } = useFetchFirstReport();

  if (isLoading) {
    return loader;
  }

  return isPresent(data) ? <Navigate replace to={getReportHistoryUrl(data.guid)} /> : <EmptyReports />;
};

export default FirstReportRedirect;
