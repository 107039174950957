import {
  objectStoreApi,
  ObjectStoreQueryFilter,
  ObjectStoreQueryOperation,
  ObjectStoreQueryRequestBuilder,
} from '@import-io/js-sdk';

export const SET_OVERVIEW_LAST_LOGIN_CRAWL_DATA = 'SET_OVERVIEW_LAST_LOGIN_CRAWL_DATA';
export const SET_OVERVIEW_TIME_SPAN = 'SET_OVERVIEW_TIME_SPAN';
export const FETCHING_TIME_SPAN_CRAWL_DATA = 'FETCHING_TIME_SPAN_CRAWL_DATA';
export const OVERVIEW_TIME_SPAN_CRAWL_DATA_LOADED = 'OVERVIEW_TIME_SPAN_CRAWL_DATA_LOADED';

export const loadTimeSpanCrawlData = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_TIME_SPAN_CRAWL_DATA });
      const { timeSpan } = getState().dashboard.overview;
      const query = new ObjectStoreQueryRequestBuilder()
        .setPageLimit(1000)
        .setPageNumber(1)
        .setSortDesc(true)
        .setShowMine(true)
        .addFilter('created_at', new ObjectStoreQueryFilter(ObjectStoreQueryOperation.GREATER_THAN, timeSpan.value()))
        .build();
      const results = await objectStoreApi.crawlRun.query(query);
      dispatch({ type: OVERVIEW_TIME_SPAN_CRAWL_DATA_LOADED, data: results ?? [] });
    } catch (e) {
      console.log('Time span crawl data loading error', e);
      dispatch({ type: OVERVIEW_TIME_SPAN_CRAWL_DATA_LOADED, data: [] });
    }
  };
};

export const loadOverviewInformation = () => {
  return (dispatch) => {
    dispatch(loadTimeSpanCrawlData());
  };
};

export function changeTimeSpan(timeSpan) {
  return (dispatch) => {
    dispatch({ type: SET_OVERVIEW_TIME_SPAN, timeSpan: timeSpan });
    dispatch(loadTimeSpanCrawlData());
  };
}
