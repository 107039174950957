import type { AnyAction } from 'redux';

import type { ExtractorsState } from 'features/extractors/extractors-types';

import {
  CLEANUP_QUALITY_DATA,
  CRAWL_RUN_STARTED,
  CRAWL_RUN_STOPPED,
  EXTRACTOR_QUALITY_DATA_FAILURE,
  EXTRACTOR_QUALITY_DATA_REQUEST,
  EXTRACTOR_QUALITY_DATA_SUCCESS,
  EXTRACTOR_RECEIVED,
  FETCHING_RUNTIME_CONFIG_DASH,
  FETCHING_TRAINING,
  RECEIVED_TRAINING,
  RUNTIME_CONFIG_RECEIVED_DASH,
  SELECT_EXTRACTOR,
  SET_HTML_EXTRACTION,
  SET_SCREEN_CAPTURE,
  SET_SCREEN_CAPTURE_TYPE,
  STARTING_CRAWL_RUN,
  STOPPING_CRAWL_RUN,
  TOGGLE_AUTO_RUN,
  UPDATE_RUNTIME_CONFIG_DASH,
  UPDATE_SELECTED_EXTRACTOR,
  UPDATED_EXTRACTOR_SETTINGS,
  UPDATING_EXTRACTOR_SETTINGS,
} from '../actions/extractors';
import { RESET_DASH_STATE } from '../actions/ui';

function getInitialState(): Partial<ExtractorsState> {
  return {
    selectedExtractor: null,
    selectedExtractorGuid: null,
    isFetchingRuntimeConfig: false,
    runtimeConfiguration: undefined,
    isFetchingTraining: false,
    training: null,
    initialUrl: '',
    computedGridCrawlRunData: [],
    isStartingRun: false,
    doesSelectedExtractorHaveInteraction: false,
    isUpdatingSettings: false,
    isStoppingRun: false,
    runningExtractorChain: [],
    hasScreenCapture: false,
    autoRun: false,
    extractorQuality: {
      error: null,
      isFetching: false,
      averageFillRate: '0.0',
      averageRowCount: '0.0',
      lastCrawlRunFillRate: '0.0',
      lastCrawlRunRowCount: 0,
      historicalFillRates: [],
      historicalRowCounts: [],
    },
  };
}

export function extractorReducers(state = getInitialState(), action: AnyAction = { type: '' }): Partial<ExtractorsState> {
  switch (action.type) {
    case RESET_DASH_STATE:
      return {
        ...getInitialState(),
      };
    case EXTRACTOR_RECEIVED:
      return {
        ...state,
        selectedExtractor: action.selectedExtractor,
        selectedExtractorGuid: action.selectedExtractor ? action.selectedExtractor.guid : null,
        doesSelectedExtractorHaveInteraction: action.selectedExtractor ? !!action.selectedExtractor.inputs : false,
        isUpdatingSettings: false,
      };
    case SELECT_EXTRACTOR:
      return {
        ...state,
        selectedExtractor: action.selectedExtractor,
        selectedExtractorGuid: action.selectedExtractor ? action.selectedExtractor.guid : null,
        doesSelectedExtractorHaveInteraction: action.selectedExtractor ? !!action.selectedExtractor.inputs : false,
        isStartingRun: false,
      };
    case UPDATE_SELECTED_EXTRACTOR:
      return {
        ...state,
        selectedExtractor: { ...state.selectedExtractor, ...action.updates },
      };
    case FETCHING_RUNTIME_CONFIG_DASH:
      return {
        ...state,
        isFetchingRuntimeConfig: true,
      };
    case RUNTIME_CONFIG_RECEIVED_DASH:
      return {
        ...state,
        isFetchingRuntimeConfig: false,
        runtimeConfiguration: action.runtimeConfiguration,
        hasScreenCapture: action.hasScreenCapture,
        screenCaptureType: action.screenCaptureType,
        hasHtmlExtraction: action.hasHtmlExtraction,
      };
    case UPDATE_RUNTIME_CONFIG_DASH:
      return {
        ...state,
        runtimeConfiguration: {
          ...state.runtimeConfiguration!,
          config: action.newRuntimeConfig,
        },
      };
    case FETCHING_TRAINING:
      return {
        ...state,
        isFetchingTraining: true,
      };
    case RECEIVED_TRAINING:
      return {
        ...state,
        isFetchingTraining: false,
        training: action.training,
        initialUrl: action.initialUrl,
      };
    case UPDATING_EXTRACTOR_SETTINGS:
      return {
        ...state,
        isUpdatingSettings: true,
      };
    case UPDATED_EXTRACTOR_SETTINGS:
      return {
        ...state,
        isUpdatingSettings: false,
      };
    case STARTING_CRAWL_RUN:
      return {
        ...state,
        isStartingRun: true,
      };
    case CRAWL_RUN_STARTED:
      return {
        ...state,
        isStartingRun: false,
        autoRun: false,
      };
    case STOPPING_CRAWL_RUN:
      return {
        ...state,
        isStoppingRun: true,
        isStartingRun: false,
      };
    case CRAWL_RUN_STOPPED:
      return {
        ...state,
        isStoppingRun: false,
        isStartingRun: false,
        runningExtractorChain: [],
      };
    case TOGGLE_AUTO_RUN:
      return {
        ...state,
        autoRun: !state.autoRun,
      };
    case SET_SCREEN_CAPTURE:
      return {
        ...state,
        hasScreenCapture: action.hasScreenCapture,
      };
    case SET_SCREEN_CAPTURE_TYPE:
      return {
        ...state,
        screenCaptureType: action.screenCaptureType,
      };
    case SET_HTML_EXTRACTION:
      return {
        ...state,
        hasHtmlExtraction: action.hasHtmlExtraction,
      };
    case EXTRACTOR_QUALITY_DATA_REQUEST:
      return {
        ...state,
        extractorQuality: {
          ...state.extractorQuality,
          error: null,
          isFetching: true,
        },
      };
    case EXTRACTOR_QUALITY_DATA_SUCCESS:
      return {
        ...state,
        extractorQuality: {
          error: null,
          isFetching: false,
          ...action.payload,
        },
      };
    case EXTRACTOR_QUALITY_DATA_FAILURE:
      return {
        ...state,
        extractorQuality: {
          ...getInitialState().extractorQuality,
          error: action.payload,
          isFetching: false,
        },
      };
    case CLEANUP_QUALITY_DATA:
      const { extractorQuality } = getInitialState();
      return {
        ...state,
        extractorQuality: extractorQuality,
      };
    default:
      return state;
  }
}
