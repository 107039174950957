import type { Reducer } from 'react';

import type { SubscriptionModalState } from 'features/user/subscription/modal/subscription-modal-types';
import { SubscriptionModalAction } from 'features/user/subscription/modal/subscription-modal-types';

export const subscriptionModalReducer: Reducer<SubscriptionModalState, SubscriptionModalAction> = (prevState, action) => {
  switch (action) {
    case SubscriptionModalAction.DOWNGRADE: {
      return { isOpen: true, isUpgrade: false };
    }
    case SubscriptionModalAction.UPGRADE: {
      return { isOpen: true, isUpgrade: true };
    }
    default: {
      return { ...prevState, isOpen: false };
    }
  }
};
