import { combineReducers } from 'redux';

import { SELECT_REPORT, SET_REPORT_EXTRACTOR, SET_REPORT_SAVING_STATUS } from '../actions/reports';

import reportHistory from './reportHistory';
import reportSettings from './reportSettings';

function getInitialState() {
  return {
    isSaving: false,
    selectedReport: null,
    reportExtractor: null,
    selectedReportGuid: null,
  };
}

function reportCore(state = getInitialState(), action) {
  switch (action.type) {
    case SET_REPORT_EXTRACTOR: {
      return {
        ...state,
        reportExtractor: action.extractor || null,
      };
    }
    case SELECT_REPORT:
      return {
        ...state,
        selectedReport: action.selectedReport ?? null,
        selectedReportGuid: action.selectedReport?.guid ?? null,
      };
    case SET_REPORT_SAVING_STATUS:
      return {
        ...state,
        isSaving: action.isSaving,
      };
    default:
      return state;
  }
}

export default combineReducers({
  reportCore: reportCore,
  reportHistory: reportHistory,
  reportSettings: reportSettings,
});
