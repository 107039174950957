import type { CSSProperties } from 'react';
import { useCallback } from 'react';

import { history } from 'app/app-history';
import LogoLight from 'app/components/LogoLight';
import COLORS from 'common/constants/colors';
import { DASH_PAGE_URL } from 'common/routes/routes-constants';

const rootStyle: CSSProperties = { background: COLORS.BACKGROUND1 };
const logoStyle: CSSProperties = { cursor: 'pointer' };
const headerStyle: CSSProperties = { textAlign: 'center', color: 'white' };
const returnStyle: CSSProperties = { color: 'white', cursor: 'pointer' };

interface ErrorPageProps {
  readonly text?: string;
}

const defaultText = "We're sorry, something went wrong.";

const ErrorPage = ({ text = defaultText }: ErrorPageProps): JSX.Element => {
  const openApp = useCallback(() => {
    history.push(DASH_PAGE_URL);
  }, []);
  return (
    <div className="flex flex-column justify-center align-center full-height" style={rootStyle}>
      <div style={logoStyle}>
        <LogoLight />
      </div>
      <h2 style={headerStyle}>{text}</h2>
      <p onClick={openApp} style={returnStyle}>
        Click here to return to the application
      </p>
    </div>
  );
};

export default ErrorPage;
