import type { ExtractorField } from '@import-io/types';
import type { Report, ReportType } from '@import-io/types/report-types';
import type { FormInstance } from 'antd/lib/form';

import type { SelectOption } from 'common/common-types';

export enum ReportFields {
  EXTRACTOR_ID = 'extractorId',
  FIELDS = 'fields',
  NAME = 'name',
  PRIMARY_ID = 'primaryId',
  REPORT_TYPE = 'type',
}

export interface CreateReportFormValues extends Partial<Report> {
  [ReportFields.FIELDS]: ExtractorField[];
  [ReportFields.PRIMARY_ID]: string | null;
}

export type ReportTypeOption = SelectOption<ReportType>;

export interface UseReportExtractors {
  extractorOptions: SelectOption[];
  hasReceivedExtractors: boolean;
}

export interface WithReportForm {
  form: FormInstance<CreateReportFormValues>;
}

export interface UseReportForm extends WithReportForm {
  isSaving: boolean;
  onExtractorChange: (value: string, option: SelectOption | SelectOption[]) => void;
  onFinish: (values: CreateReportFormValues) => void;
  onTypeChange: (value: ReportType, option: ReportTypeOption | ReportTypeOption[]) => void;
}

export interface UsePrimary {
  id: string | null;
  options: SelectOption[];
  show: boolean;
}

export interface UseStepsDisabled {
  nextDisabled: boolean;
  submitDisabled: boolean;
}
