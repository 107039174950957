// TODO: this module is "as is" copy of commonUtils file from deprecated @import/frontend-utils.
//  Should be reviewed, rethinked and refactored.
import { endpoints } from '@import-io/js-sdk';

export function normalizeName(
  newName: string | undefined,
  oldName: string | undefined,
  existingNames: string[],
  defaultName?: string,
) {
  let newNameClean = newName?.replace(/\s+/g, ' ')?.trim() || oldName || defaultName || 'Untitled';

  const sameNameNumbers = existingNames
    .filter((n) =>
      n.match(new RegExp(`^${newNameClean.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')}\\s?\\(?(\\d+)?\\)?$`, 'gm')),
    )
    .map((n) => /\s?\(?(\d+)?\)?$/gm.exec(n)?.[1])
    .map((val) => (!val ? '0' : val))
    .map((val) => parseInt(val, 10));

  if (sameNameNumbers.length !== 0) {
    newNameClean = `${newNameClean} (${Math.max(...sameNameNumbers) + 1})`;
  }

  return newNameClean;
}

// TODO: move to js-sdk
export function getEnvironment(): string {
  switch (endpoints.rootDomain) {
    case 'demo-owl.com':
      return 'demo';
    case 'staging-owl.com':
      return 'staging';
    case 'import.io':
      return 'production';
    default:
      return 'production';
  }
}
