import type { JSX } from 'react';

import { isPresent } from '@import-io/typeguards';
import { PlanType } from '@import-io/types';
import type { UserSubscriptionData } from '@import-io/types/user-subscription-types';

import Coin from 'common/components/icons/Coin';
import SubscriptionUpdateButton from 'features/user/subscription/components/SubscriptionUpdateButton';
import { useFetchSubscription } from 'features/user/subscription/subscription-hooks';
import { canPerformQuery, getPlanTitle, getSubscriptionText } from 'features/user/subscription/subscription-utils';

import styles from './SubscriptionWidget.module.less';

const defaultSubscription: Partial<UserSubscriptionData> = {
  expiry: 0,
  maxUsage: 0,
  overage: 0,
  planCode: PlanType.UNVERIFIED,
  trial: false,
  usage: 0,
};

// TODO: deeper refactoring is required
const SubscriptionWidget = (): JSX.Element => {
  const { data: subscription = null } = useFetchSubscription();
  const isPresentSubscription = isPresent(subscription);
  const canQuery = canPerformQuery(subscription);
  const subText = isPresentSubscription ? getSubscriptionText(subscription) : '';
  const title = getPlanTitle(subscription);
  const { usage = 0, maxUsage = 0 } = isPresentSubscription ? subscription : defaultSubscription;
  const percentage = (usage / maxUsage) * 100 - 100;
  const usagePercentage = percentage > 0 ? 0 : percentage;
  const isOverage = usage > maxUsage;

  return (
    <div className={`text-center ${styles.subscriptionWidget}`}>
      <div>
        <h5 className={styles.planTitle}>{canQuery ? title : 'Plan limit reached'}</h5>
      </div>

      <div className={styles.usage}>
        <span className={styles.floatLeft}>
          QUERIES <Coin />
        </span>
        <div className={styles.floatRight}>
          <span className={isOverage ? styles.overUsageText : styles.usageText} data-test-id="sidebar-current-extractor-usage">
            {usage} /
          </span>
          <span data-test-id="sidebar-extractor-max-usage">&nbsp;{maxUsage}</span>
        </div>
      </div>
      <div className={`position-relative full-width ${styles.planProgress} ${styles.planProgressLarge}`}>
        <div className={`full-width full-height ${styles.planProgressWrapper}`} style={{ left: `${usagePercentage}%` }}>
          <div
            className={`full-width full-height ${styles.planProgressBar} ${
              isOverage ? styles.overageGradient : styles.ioGradientBluePink
            }`}
            style={{ left: `-${usagePercentage}%` }}
          >
            &nbsp;
          </div>
        </div>
      </div>

      <div>
        <p className={styles.subText}>{subText}</p>
      </div>

      <div>
        <SubscriptionUpdateButton className={`full-width ${styles.upgradeButton}`} />
      </div>
    </div>
  );
};

export default SubscriptionWidget;
