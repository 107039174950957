import { useEffect } from 'react';

import { endpoints } from '@import-io/js-sdk';

// TODO: rewrite with Redirect component
export const useLightningRedirect = () => {
  useEffect(() => {
    // Send user to app.import.io if they are trying to access lightning.import.io
    // Handles any user who is not already redirected via nginx because of caching
    if (window.location.host.split('.').indexOf('lightning') >= 0) {
      window.location.replace(`https://app.${endpoints.rootDomain}${window.parent.location.pathname}`);
    }
  }, []);
};
