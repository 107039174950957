import type { Extractor } from '@import-io/types';

import type { RootState } from 'common/common-types';
import { selectExtractorListQueryData } from 'features/extractors/common/extractor-list-query';
import type { ExtractorsState } from 'features/extractors/extractors-types';

export const selectExtractorsState = (state: RootState): ExtractorsState => state.dashboard.extractors;

/**
 * @deprecated full React Query refactoring is required
 */
export const selectMyExtractorByGuid = (guid: string): Extractor | undefined =>
  selectExtractorListQueryData().find((extractor) => extractor.guid === guid);
