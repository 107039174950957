import type { Action } from '@import-io/replay-browser-events';
import { actions } from '@import-io/replay-browser-events';
import { isString } from '@import-io/typeguards';
import type { ActionData, InputItem, VariableJson } from '@import-io/types';
import { isVariableJson } from '@import-io/types';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import { normalizeName } from 'common/common-utils';
import type { PaginationAction } from 'features/extractor-builder/interaction/interaction-action';
import { createInteractionAction, InteractionAction } from 'features/extractor-builder/interaction/interaction-action';
import type { VariableData } from 'features/extractor-builder/interaction/variable-data';
import { ExtractionType } from 'features/extractors/forms/extractors-form-types';

import * as INTERACTION_CONSTANTS from './interaction-constants-old';

/**
 * Returns action name
 */
export const getActionName = (action: InteractionAction | Action | ActionData): string => {
  if (action instanceof InteractionAction) {
    return action.name;
  }

  if (isString(action.constructor)) {
    return action.constructor;
  }

  if (isString(action.constructor.name)) {
    return action.constructor.name;
  }

  throw new Error('Could not determine action name');
};

/**
 * Creates a new set of interaction actions
 */
function mapSerializedActions(serializedActions: ActionData[], isAuth?: boolean): InteractionAction[] {
  return serializedActions.map((action, idx) =>
    createInteractionAction({
      action: action,
      isInitialLoad: INTERACTION_CONSTANTS.INITIAL_LOAD_ACTION_TYPES.includes(getActionName(action)) && idx <= 2,
      isAuth: isAuth,
    }),
  );
}

/**
 * This basically refreshes all actions in the list
 */
export function mapActionsToDoc(actionList: InteractionAction[], doc: Document) {
  actionList.forEach((action) => action.deserialize(doc));
  return actionList;
}

/**
 * Takes in a list of actions fresh from the back end and prepares them in our app
 */
export function prepareActionsForState(
  serializedActions: ActionData[],
  doc: Document = document,
  isAuth: boolean = false,
): InteractionAction[] {
  const actionList = mapSerializedActions(serializedActions, isAuth);
  return mapActionsToDoc(actionList, doc);
}

export function createVariablesForActions(actionList: InteractionAction[], defaultInput?: any): VariableData[] {
  const variables: VariableData[] = [];

  // Generate a list of variables from the action list,
  // The actionIds will help us keep track of these variables when we delete or edit actions
  actionList.forEach((action) => {
    const actionId = action.actionId;
    Object.entries(action.variables).forEach(([variableType, variable]) => {
      if (variable.$variable$ && variableType !== 'runtimeConfig') {
        variables.push({
          actionId: actionId,
          name: variable.name,
          initialName: variable.name,
          canEdit: action.name !== 'GotoAction' && action.name !== 'RenderAction',
          isArray: ['args', 'values'].includes(variableType),
          defaultValue: variable.defaultValue,
          changeType: 'NONE',
        });
      }
    });
  });

  if (defaultInput) {
    // Using the defaultInput make sure we have all variables accounted for
    // Some variables may be found here if they are code first extractors, etc
    Object.entries(defaultInput).forEach(([key, value]) => {
      if (!variables.find((v) => v.name === key)) {
        variables.push({
          name: key,
          initialName: key,
          isArray: Array.isArray(value),
          defaultValue: value,
          changeType: 'NONE',
        });
      }
    });
  }

  return variables;
}

/**
 * Takes in a list of auth actions fresh from the back end and prepares them in our app
 */
export function prepareAuthActionsForState(serializedAuthActions: ActionData[], doc: Document = document) {
  const sanitizedActions = serializedAuthActions.map((action) => {
    const possibleVariables = [action.event?.value, action.event?.values, action.args];
    const variable = possibleVariables.find(isVariableJson);

    if (variable) {
      const varName = variable.name;
      if (varName.includes('_credentials.')) {
        variable.name = varName.split('_credentials.')?.[1] ?? variable.name;
      }
      variable.defaultValue = variable.defaultValue || (action.event?.values ? [] : '');
    }

    return action;
  });

  return prepareActionsForState(sanitizedActions, doc, true);
}

/**
 * Tells if an action is a password type input
 */
export function isPasswordField(action: Action): boolean {
  return getActionName(action) === 'InputChangeAction' && action.event?.target?.type === 'password';
}

/**
 * For a given set of actions, set the default value from the input in all the right places
 */
export function subOutVariables(actionList: InteractionAction[], input: InputItem): InteractionAction[] {
  return actionList.map((action) => {
    if (action.variables) {
      Object.entries(action.variables).forEach(([key, variable]) => {
        if (variable.$variable$ && variable.name && input[variable.name]) {
          action.browserAction[key] = input[variable.name];
        }
      });
    }
    return action;
  });
}

/**
 * Initial load is basically the list of actions that are crucial for the load of a web page, it is a
 * GoToAction, ViewportAction, and WaitLoadingAction, once the first WaitLoading is marked as recorded
 * We will stop marking actions as `isInitialLoad`
 */
export function shouldRecordInitialLoad(actionList: InteractionAction[]) {
  const waitLoadings = actionList.filter((a) => a.name === 'WaitLoadingAction' && a.recordStatus === 'recorded');
  return waitLoadings.length < 1;
}

// Most often called when an action is deleted, cleans out variables and inputs that were associated with it
export function removeInputsForAction(
  actionId: string,
  variables: VariableData[],
  inputs: Record<string, any>[],
  currentInput?: Record<string, any>,
) {
  const variable = variables.find((v) => v.actionId === actionId);

  if (variable) {
    variables.splice(variables.indexOf(variable), 1);
    inputs.forEach((input) => {
      delete input[variable.name];
    });
    if (currentInput) {
      Object.keys(currentInput).forEach((input) => {
        if (input == variable.name) {
          delete currentInput[input];
        }
      });
    }
  }

  return { variables: variables, inputs: inputs, currentInput: currentInput };
}

export function cleanUpVariables(variableNames: string[], variables: VariableData[]) {
  if (!variableNames.length) {
    return variables;
  }

  let newVariables;
  // iterating over the variableNames
  // filtering out any variables that equal the variableName
  variableNames.forEach((variableName) => {
    newVariables = variables.filter((v) => v.name !== variableName);
  });

  return newVariables;
}

export function cleanUpInputs(variableNames: string[], inputs: Record<string, any>[]) {
  if (!variableNames.length) {
    return inputs;
  }

  // iterating over the variableNames and the inputs
  // deleting the input, if it has a key of variableName
  variableNames.forEach((variableName) => {
    inputs.forEach((input) => {
      delete input[variableName];
    });
  });

  return inputs;
}

export function cleanUpCurrentInput(variableNames: string[], currentInput: Record<string, any>) {
  if (!variableNames.length) {
    return currentInput;
  }

  // iterating over the variableNames
  variableNames.forEach((variableName) => {
    // setting the object.keys to the keys variable, and filtering out any key that matches the variableName
    Object.keys(currentInput).forEach((input) => {
      if (input == variableName) {
        delete currentInput[input];
      }
    });
  });

  return currentInput;
}

// helper function to run correlated functions, if the action has variableNames
export function cleanUpForAllInputs(variableNames: string[], variables: VariableData[], inputs, currentInput) {
  const newInputs = cleanUpInputs(variableNames, inputs);
  const newCurrentInput = cleanUpCurrentInput(variableNames, currentInput);
  const newVariables = cleanUpVariables(variableNames, variables);

  return { newInputs: newInputs, newCurrentInput: newCurrentInput, newVariables: newVariables };
}

export function cleanUpVariablesForAllActions(actionList: InteractionAction[], variables: VariableData[]) {
  // iterating over the variableNames
  // filtering out any variables that equal the variableName
  variables = variables.filter((v) => {
    const variableUsed = actionList.find((action) => action.actionId == v.actionId);
    if (!variableUsed) {
      v.actionId = null;
      return true;
    }

    // Filter any variables that are used in actions
    return false;
  });

  return variables;
}

// if there are multiple actions to iterate through
export function cleanUpInputsForAllActions(actionList: InteractionAction[], variables: VariableData[], inputs: InputItem[]) {
  // iterating over the variableNames
  // filtering out any variables that equal the variableName
  actionList.forEach((action) => {
    const variable = variables.find((v) => v.actionId === action.actionId);

    if (variable) {
      inputs.forEach((input) => {
        delete input[variable.name];
      });
    }
  });

  return inputs;
}

// if there are multiple actions to iterate through
export function cleanUpCurrentInputForAllActions(
  actionList: InteractionAction[],
  variables: VariableData[],
  currentInput: InputItem,
) {
  // iterating over the variableNames
  // filtering out any variables that equal the variableName
  actionList.forEach((action) => {
    const variable = variables.find((v) => v.actionId === action.actionId);

    if (variable) {
      delete currentInput[variable.name];
    }
  });

  return currentInput;
}

/**
 * Helper function to run correlated functions, if there are multiple actions to iterate through
 */
export function cleanUpForAllActions(
  actionList: InteractionAction[],
  variables: VariableData[],
  inputs: InputItem[],
  currentInput: InputItem,
) {
  let newInputs = cleanUpInputsForAllActions(actionList, variables, inputs);
  let newCurrentInput = cleanUpCurrentInputForAllActions(actionList, variables, currentInput);
  let newVariables = cleanUpVariablesForAllActions(actionList, variables);

  return { newInputs: newInputs, newCurrentInput: newCurrentInput, newVariables: newVariables };
}

// Called when removing a variable, goes through an action list and turns off variable flag
export function removeVariableFromActions(variableName: string, actionList: InteractionAction[]) {
  return actionList.map((action) => {
    if (action.variables) {
      Object.values(action.variables).forEach((variable) => {
        if (variable && variable.name === variableName) {
          variable.$variable$ = action.hasVariables = false;
        }
      });
    }

    return action;
  });
}

/**
 * This function is called when we record a new action and it needs us to set a default variable,
 * Handles the default name for the variable, as well as adding it to the variables and input lists
 */
export function configureVariableNewAction(currentAction: InteractionAction, variables: VariableData[], inputs: any[]) {
  const variable =
    currentAction.browserAction.variables.value ||
    currentAction.browserAction.variables.values ||
    currentAction.browserAction.variables.num ||
    currentAction.browserAction.variables.args; // default value

  if (!variable) {
    throw new Error("Action doesn't have a variable");
  }

  let defaultName = 'password';

  if (!currentAction.isPasswordField) {
    if (currentAction.name === 'InputChangeAction') {
      defaultName = 'Text Input';
    } else if (currentAction.name === 'SelectChangeAction') {
      defaultName = 'Select Input';
    } else if (currentAction.name === 'LoopAction') {
      defaultName = 'Loop';
    } else if (currentAction.name === 'PaginationAction') {
      defaultName = (currentAction as PaginationAction).infiniteScroll ? 'Max screens' : 'Max Pages';
    } else if (currentAction.name === 'FunctionAction' || currentAction.name === 'WaitForFunctionAction') {
      defaultName = 'Function Arguments';
    }
  }

  const variableName = normalizeName(defaultName, undefined, [...new Set(variables.map((v) => v.name))], defaultName);

  variable.$variable$ = currentAction.hasVariables = true;
  variable.name = variableName;

  const input = { _meta: {} };
  input[variableName] = variable.defaultValue;

  const isArray =
    currentAction.name === 'SelectChangeAction' ||
    currentAction.name === 'FunctionAction' ||
    currentAction.name === 'WaitForFunctionAction';

  if (inputs.length) {
    inputs.forEach((i) => {
      i[variableName] = variable.defaultValue;
    });
  }

  variables.push({
    actionId: currentAction.actionId,
    name: variableName,
    initialName: variableName,
    defaultValue: variable.defaultValue,
    changeType: 'CREATE',
    canEdit: true,
    isArray: isArray,
  });

  return { variables: variables, inputs: inputs, currentAction: currentAction };
}

/**
 * Does the same as configureVariableNewAction but for auth actions
 */
export function configureVariableAuthAction(currentAction: InteractionAction, credentialsInput: { Username?: string } = {}) {
  const variables = currentAction.variables;

  if (variables) {
    Object.values(variables).forEach((variable) => {
      const alreadyHasUsername = !!credentialsInput.Username;
      const defaultName =
        alreadyHasUsername && variable.name ? variable.name : !currentAction.isPasswordField ? 'username' : 'password';
      const variableName = normalizeName(defaultName, undefined, [...new Set(Object.keys(credentialsInput))], defaultName);

      variable.$variable$ = true;
      currentAction.hasVariables = true;
      variable.name = variableName;

      credentialsInput[variableName] = variable.defaultValue;
    });
  }

  return { credentialsInput: credentialsInput, currentAction: currentAction };
}

/**
 * For the given action if it uses the old variable name applies the new variable name
 */
export function renameVariableForAction(action: InteractionAction, newVariableName: string, oldVariableName: string) {
  if (action.variables) {
    Object.values(action.variables).forEach((variable) => {
      if (variable.name === oldVariableName) {
        variable.name = newVariableName;
      }
    });
  }
}

/**
 * Takes in an action list and applies the new variable to anything with the old variable name
 */
export function renameVariableForActions(actionList: InteractionAction[], newVariableName: string, oldVariableName: string) {
  actionList.forEach((a) => renameVariableForAction(a, newVariableName, oldVariableName));
}

/**
 * Creates a credentials input object for the given set of auth actions
 */
export function getAuthVariables(authInteractions: ActionData[] | Action[] = []) {
  const credentialsInput = {};

  authInteractions.forEach((action) => {
    const possibleVariables = [action.event?.value, action.args];

    const variable: VariableJson | undefined = possibleVariables.find((v) => isVariableJson(v));
    const variableName = variable?.name;

    if (variableName) {
      const varName = variableName.split('_credentials.')[1];
      if (varName && !credentialsInput.hasOwnProperty(varName)) {
        credentialsInput[varName] = '';
      }
    }

    // If it's Actions
    const actionVariables = (action as unknown as Action).variables;
    if (actionVariables) {
      Object.values(actionVariables).forEach((value) => {
        if (value && value.$variable$ && value.name) {
          const varName = value.name.split('_credentials.')[1];
          if (varName && !credentialsInput.hasOwnProperty(varName)) credentialsInput[varName] = '';
        }
      });
    }
  });

  return credentialsInput;
}

/**
 * Prepares the auth interactions to be saved, cleans out default values so that we are not storing
 * sensitive data and sets the variable name to be prepended with _credentials
 */
export function prepAuthActionsForSave(serializedAuthActions: ActionData[]): ActionData[] {
  if (serializedAuthActions.length <= 0) {
    return [];
  }

  // map the var names to _credentials.{varName} so they can be replayed after decrypted on server
  const setCreds = (variable: any) => {
    if (!isVariableJson(variable)) {
      return;
    }

    let varName = variable.name;
    if (!varName.includes('_credentials.')) {
      varName = `_credentials.${varName}`;
      variable.name = varName;
    }

    variable.defaultValue = '';
  };

  return serializedAuthActions.map((action) => {
    setCreds(action.event?.value);
    setCreds(action.event?.values);
    setCreds(action.args);
    return action;
  });
}

/**
 * Changes an action's default value by variableName
 */
export function changeVariableDefaultValueByName(action: InteractionAction, newInputValue: any, variableName: string) {
  if (action.variables) {
    Object.entries(action.variables).forEach(([key, variable]) => {
      if (variable.$variable$ && variable.name == variableName) {
        action.variables[key]!.defaultValue = newInputValue;
      }
    });
  }
}

/**
 * Finds all actions and changes the default value for them
 */
export function findActionsAndChangeDefaultValue(actionList: InteractionAction[], newInputValue: any, variableName: string) {
  actionList.forEach((action) => {
    changeVariableDefaultValueByName(action, newInputValue, variableName);
  });
}

/**
 * Determines if we need to record a default input for a new action, if returns true then we call configureVariableNewAction
 */
export function needToRecordDefaultInput(action: InteractionAction) {
  return (
    INTERACTION_CONSTANTS.RECORD_DEFAULT_INPUTS_FOR_TYPES.includes(action.name) &&
    (action.browserAction.variables.value ||
      action.browserAction.variables.values ||
      action.browserAction.variables.args ||
      action.browserAction.variables.num)
  );
}

export function makeExtractDataAction(name: string = '_runtimeConfig') {
  const action = new actions.ExtractDataAction(null);

  action.variables.runtimeConfig!.variable = true;
  action.variables.runtimeConfig!.name = name;

  return action;
}

export function makeRenderAction(url: string): actions.RenderAction {
  const action = new actions.RenderAction(null, { url: url });
  action.variables.options!.variable = true;
  action.variables.options!.name = '_url';

  return action;
}

/**
 * Flattens actions so none are nested under a parent
 */
export function flattenActions(actionList: InteractionAction[]) {
  let flattenedActions: InteractionAction[] = [];

  actionList.forEach((action) => {
    if (action.actions && action.actions.length) {
      flattenedActions = [...flattenedActions, ...action.actions];
    } else {
      flattenedActions.push(action);
    }
  });

  return flattenedActions;
}

/**
 * Determines if actions are siblings of one another
 */
export const areActionsSiblings = (actionList: Action[]) => {
  if (!actionList.every((a) => a.parentActionId)) {
    return false;
  }

  // Verify that all actions have same parent
  const allParentActionIds = new Set(actionList.map((a) => a.parentActionId));
  return allParentActionIds.size === 1;
};

export const serializeActions = (actionList: InteractionAction[]) => {
  return actionList.filter((action) => action.recordStatus === 'recorded').map((a) => a.toJSON());
};

export function areActionsDirty(list: {
  authInteractions: InteractionAction[];
  actionList: InteractionAction[];
  savedActions: InteractionAction[];
  savedAuthActions: InteractionAction[];
}): boolean {
  const cleanActions = (actionList: InteractionAction[]) =>
    actionList.map((a) => omit(a, ['browserAction.document', 'browserAction.trueEvent']));

  return !(
    isEqual(cleanActions(list.authInteractions), cleanActions(list.savedAuthActions)) &&
    isEqual(cleanActions(list.actionList), cleanActions(list.savedActions))
  );
}

export function getActionDefaultValues(actionList: Action[]): Record<string, any> {
  const input: Record<string, any> = {};

  actionList.forEach((action) => {
    if (action.variables) {
      Object.values(action.variables).forEach((variable) => {
        if (variable.$variable$ && variable.name) {
          input[variable.name] = variable.defaultValue || '';
        }
      });
    }
  });

  return input;
}

export function getInputFromActions(actionList: InteractionAction[]): Record<string, any> {
  const input: Record<string, any> = {};

  actionList.forEach((action) => {
    if (action.variables) {
      Object.entries(action.variables).forEach(([variableName, variable]) => {
        if (variable.$variable$ && variable.name) {
          const actionVariable = action.browserAction[variableName] || '';
          input[variable.name] = actionVariable;
        }
      });
    }
  });

  return input;
}

export function getPageSetInputs(pageSet) {
  return ((pageSet && pageSet.pages) || [])
    .map((page) => {
      let input = page.input;
      if (!input) return input;
      input = { ...input };
      input._meta = input._meta || {};
      input._meta.pageId = page.id;
      return input;
    })
    .filter((i) => i);
}

export function separateTrainingInputs(inputs: any[]) {
  const inputsAttachment: any[] = [];
  let trainingInputs: any[] = [];

  inputs.forEach((input) => {
    if ((input._meta || {}).hasOwnProperty('pageId')) {
      // this will get all inputs that have the property pageId, even if it is an empty string
      trainingInputs.push(input);
    } else {
      inputsAttachment.push(input);
    }
  });

  // if the property was set to '' then filter them out
  trainingInputs = trainingInputs.filter((i) => i._meta.pageId);
  return { inputsAttachment: inputsAttachment, trainingInputs: trainingInputs };
}

export function inferExtractionType({
  authInteractions = [],
  interactions = [],
}: {
  authInteractions?: InteractionAction[];
  interactions?: InteractionAction[];
}): ExtractionType {
  const isBasic = flattenActions(interactions)
    .filter((a) => a.name !== 'ExtractDataAction')
    .every((a) => a.name === 'RenderAction');

  const hasAuth = Array.isArray(authInteractions) && authInteractions.length > 0;

  if (isBasic) {
    return hasAuth ? ExtractionType.AUTH : ExtractionType.BASIC;
  }

  return ExtractionType.INTERACTIVE;
}

export function isCodeExtractor(actionList: Action[] | InteractionAction[] | ActionData[]) {
  return actionList.some((action) => getActionName(action) === 'CodeAction');
}

/**
 * Redirects user to the training page if user tries to navigate to the interactions page.
 */
export function restrictInteractionsAccess(canRecordInteraction: boolean) {
  if (canRecordInteraction) {
    return;
  }

  const url = new URL(location.href);
  const search = url.searchParams;
  const type = search.get('type');
  const extractorGuid = search.get('extractorGuid');
  const path = url.pathname;

  if (!extractorGuid) {
    // For new extractor
    if (type === 'interactive') {
      search.set('type', 'basic');
      // We need to delete the dataUrl parameter to prevent staying on the extractor login page
      // when the extrator's type initially is auth to correctly redirect the user to the basic extractor
      // training page. Otherwise (if the parameter exists in the URL) extractor is still considered as auth.
      search.delete('dataUrl');
      location.replace(url.toString());
    }
  } else {
    // For existing extractor with training data.

    // If this is an existing extractor
    // and URL contains '/browse' in the path (that means the interactions page)
    // but user doesn't have the PAGE_INTERACTION feature flag ...
    if (path === '/browse') {
      // ... then redirect user to the training page.
      url.pathname = '/results';
      location.replace(url.toString());
    }
  }
}
