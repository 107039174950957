import type { RootState } from 'common/common-types';
import { areActionsDirty } from 'features/extractor-builder/interaction/interaction-utils-old';

/**
 * @deprecated use lightning-selectors.ts
 */
export const getLightning = (state: RootState) => state.lightning;
/**
 * @deprecated use lightning-selectors.ts
 */
export const getInteraction = (state: RootState) => state.lightning.interactions;
/**
 * @deprecated use lightning-selectors.ts
 */
export const getBuilder = (state: RootState) => state.lightning.builder.present;
/**
 * @deprecated use lightning-selectors.ts
 */
export const getRawBuilder = (state: RootState) => state.lightning.builder;
/**
 * @deprecated use lightning-selectors.ts
 */
export const getSelectedField = (state: RootState) => getBuilder(state).selectedField;
/**
 * @deprecated use lightning-selectors.ts
 */
export const getProject = (state: RootState) => state.lightning.project;
// TODO: move to lightning-selectors.ts
export const getShouldPreventLeave = (state: RootState) => {
  const builder = getBuilder(state);
  return (
    (!builder.hasSaved && !getProject(state).isSaving && builder.showSaveModal && !(!builder.data || builder.data.length <= 0)) ||
    areActionsDirty(getInteraction(state))
  );
};
