import { useCallback } from 'react';

import type { Meta, WithMeta } from '@import-io/types';

import type { SortByProperty, SortByValue } from 'common/common-types';

interface SortByStore<TModel extends WithMeta> {
  value: SortByValue<TModel>;
  setValue: (newValue: SortByValue<TModel>) => void;
}

type SortByChangeHandler<TModel> = (newProp: SortByProperty<TModel>, desc: boolean) => void;

type SortByComparator<TModel> = (record1: TModel, record2: TModel) => number;

/**
 * A hook that provides sort by change handler.
 * @param sortByStore {Object} The value store instance that supports value updates {@link SortByStore}.
 * @returns {Function} sort by value change handler {@link SortByChangeHandler}.
 */
export const useHandleSortByChange = <TModel extends WithMeta>(sortByStore: SortByStore<TModel>): SortByChangeHandler<TModel> => {
  const { setValue: setSortBy } = sortByStore;
  return useCallback<SortByChangeHandler<TModel>>(
    (newProp, desc) => {
      setSortBy({
        isDescending: desc,
        property: newProp,
      });
    },
    [setSortBy],
  );
};

/**
 * A hook that provides sort by property comparator function.
 * @param sortByStore {Object} The value store instance that supports value updates {@link SortByStore}.
 * @returns {Function} sort by order and property comparator function {@link SortByComparator}.
 */
export const useSortByComparator = <TModel extends WithMeta>(sortByStore: SortByStore<TModel>): SortByComparator<TModel> => {
  const { value } = sortByStore;
  const { isDescending, property } = value;
  return useCallback<SortByComparator<TModel>>(
    // TODO: copied from DisplayItemsList requires refactoring
    (record1, record2) => {
      const less = isDescending ? -1 : 1;
      const greater = isDescending ? 1 : -1;
      const meta1 = record1._meta ?? ({} as Meta);
      const meta2 = record2._meta ?? ({} as Meta);
      const property1 = record1[property as keyof TModel] ?? (meta1[property as keyof Meta] as any);
      const property2 = record2[property as keyof TModel] ?? (meta2[property as keyof Meta] as any);

      if (property1 < property2) {
        return less;
      }
      if (property2 < property1) {
        return greater;
      }
      return 0;
    },
    [isDescending, property],
  );
};

/**
 * A hook that provides sort by property and descending/ascending order handlers.
 * @param sortByStore {Object} The value store instance that supports value updates {@link SortByStore}.
 * @returns {Array} 3 element array: [
 *   the current sort by value object,
 *   sort by value change handler {@link SortByChangeHandler}, {@link useHandleSortByChange},
 *   sort by order and property comparator function {@link SortByComparator}, {@link useSortByComparator},
 * ]
 */
export const useSortBy = <TModel extends WithMeta>(
  sortByStore: SortByStore<TModel>,
): [SortByValue<TModel>, SortByChangeHandler<TModel>, SortByComparator<TModel>] => {
  const { value } = sortByStore;
  const handleSortByChange = useHandleSortByChange<TModel>(sortByStore);
  const sortComparator = useSortByComparator<TModel>(sortByStore);
  return [value, handleSortByChange, sortComparator];
};
