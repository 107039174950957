import type { JSX } from 'react';
import { lazy, Suspense } from 'react';

import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';

import { useCreateReportModalStore } from 'features/reports/forms/create/create-report-form-hooks';

export const CreateReportForm = lazy(() => import('features/reports/forms/create/CreateReportForm'));

const CreateReportModal = (): JSX.Element => {
  const { value, toggleValue } = useCreateReportModalStore();
  return (
    <Modal
      centered
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={toggleValue}
      open={value}
      title="Create a new Report"
    >
      <Suspense fallback={<Spin />}>
        <CreateReportForm />
      </Suspense>
    </Modal>
  );
};

export default CreateReportModal;
