import type { CSSProperties, JSX } from 'react';

import Spin from 'antd/lib/spin';

const style: CSSProperties = { height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' };

const PageLoader = (): JSX.Element => (
  <div style={style}>
    <Spin size="large" />
  </div>
);

export default PageLoader;
