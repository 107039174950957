import { extractorApi, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import type { Extractor } from '@import-io/types';

export const fetchAllExtractorsApi = (): Promise<Extractor[]> => extractorApi.getAllExtractorsForCurrentUser();

export const fetchFirstExtractorApi = async (): Promise<Extractor | null> => {
  const query = new ObjectStoreQueryRequestBuilder()
    .setPageNumber(1)
    .setPageLimit(1)
    .setShowArchived(false)
    .setShowMine(true)
    .build();
  const result = await extractorApi.query(query);
  return result?.[0] ?? null;
};
