import { extractorApi, objectStoreApi, ObjectStoreQueryRequestBuilder, runReportApi } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import { ReportType } from '@import-io/types/report-types';
import message from 'antd/lib/message';
import { push } from 'redux-first-history';

import { history } from 'app/app-history';
import { getReportHistoryUrl } from 'features/reports/common/reports-utils';

import { REPORT_FEATURES } from '../lib/dashConstants';

import { checkAndStartRun } from './extractors';
import { addRunToHistory } from './reportHistory';
import { loadReportConfig, loadReportSettings } from './reportSettings';

export const SELECT_REPORT = 'SELECT_REPORT';
export const SET_REPORT_EXTRACTOR = 'SET_REPORT_EXTRACTOR';
export const SET_REPORT_SAVING_STATUS = 'SET_REPORT_SAVING_STATUS';

const sectionRE = /\/dash\/(.*)\/(.*)\/(.*)/;

export const selectReport = (report) => ({ type: SELECT_REPORT, selectedReport: report });

export const setReportExtractor = (extractor) => ({
  type: SET_REPORT_EXTRACTOR,
  extractor: isPresent(extractor) ? extractor : null,
});

export const updateSelectReport = (patchData) => {
  return (dispatch, getState) => {
    const { selectedReport } = getState().dashboard.reports.reportCore;
    const updatedReport = {
      ...selectedReport,
      ...patchData,
    };
    dispatch(selectReport(updatedReport));
    return updatedReport;
  };
};

//-------------------------------------------------------------------------------------
// REPORT RUN
//-------------------------------------------------------------------------------------
export const runSelectedReport = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedReport } = getState().dashboard.reports.reportCore;

      // Check this flag to disable the run button on the UI
      dispatch(updateSelectReport({ startingRun: true }));
      message.info('Running.');

      // Kick off the run
      const runInfo = await runReportApi.run(selectedReport.guid);
      // Add the run to the report history.
      const run = await objectStoreApi.reportRun.get(runInfo.reportRunId);
      dispatch(addRunToHistory(run));

      dispatch(updateSelectReport({ startingRun: false }));
    } catch (ex) {
      message.error('Failed.');
      dispatch(updateSelectReport({ startingRun: false }));
      console.error('Could not start run', ex);
    }
  };
};

export const navigateToReportExtractor = (extractorId = '') => {
  return async (dispatch) => {
    dispatch(push(`/dash/extractors/${extractorId}`));
  };
};

export const runSelectedReportExtractor = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState().dashboard.reports;
      const report = state.reportCore.selectedReport;
      const extractorId = report.extractorId;

      // Got to extractor history page
      dispatch(navigateToReportExtractor(extractorId));

      if (state.reportCore.reportExtractor) {
        // If we already fetched the extractor then nice, just use that.
        dispatch(checkAndStartRun(state.reportCore.reportExtractor));
      } else {
        // Otherwise get the extractor and then run it.
        const extractor = await extractorApi.get(extractorId);
        dispatch(checkAndStartRun(extractor));
      }
    } catch (ex) {
      console.error('Could not run extractor for report', ex);
    }
  };
};

export const toggleAutoPublish = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedReport } = getState().dashboard.reports.reportCore;
      dispatch(updateSelectReport({ togglingAutoPublish: true }));
      const nextToggleValue = !selectedReport.autoPublish;
      await objectStoreApi.report.update(selectedReport.guid, {
        autoPublish: nextToggleValue,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch(
        updateSelectReport({
          togglingAutoPublish: false,
          autoPublish: nextToggleValue,
        }),
      );
    } catch (ex) {
      console.error('Could not toggle auto publish status', ex);
      dispatch(
        updateSelectReport({
          togglingAutoPublish: false,
        }),
      );
    }
  };
};

export const changeReportName = (name) => {
  return async (dispatch, getState) => {
    try {
      const { selectedReport } = getState().dashboard.reports.reportCore;
      dispatch(updateSelectReport({ updating: true }));
      await objectStoreApi.report.update(selectedReport.guid, {
        name: name,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch(updateSelectReport({ updating: false, name: name }));
    } catch (ex) {
      console.error('Could not delete report', ex);
      dispatch(updateSelectReport({ updating: false }));
    }
  };
};

export const saveDiffConfig = (config) => {
  return async (dispatch, getState) => {
    try {
      const report = getState().dashboard.reports.reportCore.selectedReport;
      const { availableRows } = getState().dashboard.reports.reportSettings;

      dispatch(startReportSave());

      // Save report and config
      const shouldRun = false;
      await dispatch(
        saveReportWithConfig(
          report,
          {
            config: config,
            extractorId: availableRows[0].guid,
          },
          shouldRun,
        ),
      );

      // Reload the config locally
      dispatch(loadReportConfig(config));

      dispatch(endReportSave());
    } catch (ex) {
      console.error('Could not save diff config', ex);
      dispatch(endReportSave());
    }
  };
};

export const saveReportWithConfig = (report, config, shouldRun = true) => {
  return async (dispatch) => {
    const reportId = report.guid;
    try {
      report.type = report.type || ReportType.CRAWL_DIFF;

      // Remove the guid property from config if it exists
      delete config.config.guid;

      // Save the config
      config.reportId = reportId;
      config.config.type = report.type;
      const savedConfig = await objectStoreApi.reportConfiguration.create(config);

      // Update the report with the latest config id
      const savedReport = await objectStoreApi.report.update(report.guid, {
        name: report.name,
        type: report.type,
        latestConfigId: savedConfig.guid,
        extractorId: config.extractorId || config.config.extractorId,
      });

      // Updated the saved config
      if (savedConfig.config.type === ReportType.CRAWL_DIFF && savedConfig.extractorId) {
        savedConfig.config.extractorId = savedConfig.extractorId;
      }
      savedConfig.config.guid = savedConfig.guid;

      // Update meta data so report list sorting works properly.
      if (report._meta) {
        savedReport._meta = { ...report._meta, ...savedReport._meta };
      }

      // Reload the report config (this removes any resolved conflict warnings)
      dispatch(loadReportSettings(savedReport));

      try {
        if (shouldRun) {
          const query = new ObjectStoreQueryRequestBuilder()
            .setPageLimit(2)
            .setPageNumber(1)
            .setSortDesc(true)
            .addEqFilter('state', 'FINISHED')
            .addEqFilter('extractorId', savedReport.extractorId)
            .setShowArchived(true)
            .build();

          // Only attempt to run if we have a enough crawl runs.
          const extractorRuns = await objectStoreApi.crawlRun.query(query);

          if (extractorRuns && extractorRuns.length >= (REPORT_FEATURES[savedReport.type].minExtractorRuns || 0)) {
            // Run the report
            await dispatch(runSelectedReport());
          }
        }
      } catch (ex) {
        console.error('Could not run report', ex);
      }

      // Navigate to history
      history.push(getReportHistoryUrl(reportId));

      // Return the saved info
      return { savedReport: savedReport, savedConfig: savedConfig };
    } catch (ex) {
      console.error('Could not save report configuration.', ex);
      return {
        savedConfig: null,
        savedReport: null,
      };
    }
  };
};

//-------------------------------------------------------------------------------------
// NETWORK STATUS INDICATORS
// Toggles the state of blocking loading modals in the MyReports component.
//-------------------------------------------------------------------------------------
export function startReportSave() {
  return (dispatch) => dispatch({ type: SET_REPORT_SAVING_STATUS, isSaving: true });
}

export function endReportSave() {
  return (dispatch) => dispatch({ type: SET_REPORT_SAVING_STATUS, isSaving: false });
}
