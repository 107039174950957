import message from 'antd/lib/message';
import type { ProgressProps } from 'antd/lib/progress';
import Progress from 'antd/lib/progress';

import { ONE_HUNDRED } from 'common/constants/common-constants';

import { DEFAULT_MESSAGE_DURATION } from './messages-constants';

const key = 'progressMessage';

export const showProgressMessage = ({ percent = 0, ...restProps }: ProgressProps = {}) => {
  const isInProgress = percent < ONE_HUNDRED;

  void message.open({
    key: key,
    type: isInProgress ? 'info' : 'success',
    content: <Progress {...restProps} percent={percent} />,
    duration: isInProgress ? 0 : DEFAULT_MESSAGE_DURATION,
  });
};
