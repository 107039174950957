import { Role } from '@import-io/types';
import notification from 'antd/lib/notification';
import findLastIndex from 'lodash/findLastIndex';
import { replace } from 'redux-first-history';

import { TEN_SECONDS_DURATION } from 'common/messages/messages-constants';
import { isShallowEqual } from 'common/utils/object-utils';
import { generatePath, getSearchParams, sanitizeUrl, validateUrl } from 'common/utils/url-utils';
import { selectCurrentUser } from 'features/user/auth/user-auth-query';

import { getSelectedField } from '../reducers/selectors';

import { clearPages, loadDefaultConfig } from './builder';
import {
  clearAllActions,
  setExtractionType,
  setInitialCurrentInput,
  setRecordingAtIndex,
  setReplayMessage,
  startRecording,
  updateInteractionState,
} from './interactions';
import {
  buildTraining,
  getCreateDataReportSettings,
  getDataForUrl,
  getSchedulingSettings,
  getScreenCaptureSettings,
  lightningLoaded,
} from './project';

export const SET_DATA = 'SET_DATA';
export const WRONG_URL = 'WRONG_URL';
export const CLEANUP_STATE = 'CLEANUP_STATE';
export const SELECT_VIEW_TAB = 'SELECT_VIEW_TAB';
export const SET_CURRENT_URL = 'SET_CURRENT_URL';
export const SET_INITIAL_URL = 'SET_INITIAL_URL';
export const INCOMPATIBLE_URL = 'INCOMPATIBLE_URL';
export const SHOW_XPATH_PANEL = 'SHOW_XPATH_PANEL';
export const HIDE_XPATH_PANEL = 'HIDE_XPATH_PANEL';
export const SHOW_CSS_SELECTOR_PANEL = 'SHOW_CSS_SELECTOR_PANEL';
export const HIDE_CSS_SELECTOR_PANEL = 'HIDE_CSS_SELECTOR_PANEL';
export const START_NAVIGATION = 'START_NAVIGATION';
export const SET_AUTH_OPTIONS = 'SET_AUTH_OPTIONS';
export const SHOW_DATA_EXTRACTED = 'SHOW_DATA_EXTRACTED';
export const NAVIGATION_COMPLETE = 'NAVIGATION_COMPLETE';
export const SHOW_INTERACTION_COPY_MODAL = 'SHOW_INTERACTION_COPY_MODAL';
export const HIDE_INTERACTION_COPY_MODAL = 'HIDE_INTERACTION_COPY_MODAL';
export const SHOW_MANAGE_URLS_MODAL = 'SHOW_MANAGE_URLS_MODAL';
export const HIDE_MANAGE_URLS_MODAL = 'HIDE_MANAGE_URLS_MODAL';
export const TOGGLE_REMOVE_CAPTURE_LINK_MODAL = 'TOGGLE_REMOVE_CAPTURE_LINK_MODAL';
export const TOGGLE_DELETE_MONITORED_COLUMN_MODAL = 'TOGGLE_DELETE_MONITORED_COLUMN_MODAL';
export const RESOURCE_RESPONSE_OBTAINED = 'RESOURCE_RESPONSE_OBTAINED';
export const SHOW_LIST_PRODUCER_LOADING = 'SHOW_LIST_PRODUCER_LOADING';
export const CHANGE_INPUTS_MODAL_STATUS = 'CHANGE_INPUTS_MODAL_STATUS';
export const SET_OPT_TAG = 'SET_OPT_TAG';
export const SET_HIDDEN_FRAME_DOC = 'SET_HIDDEN_FRAME_DOC';
export const START_LOADING = 'START_LOADING';
export const TOGGLE_INTERACTION_SIDEBAR = 'TOGGLE_INTERACTION_SIDEBAR';
export const CLEAR_BAD_URL_STATUS = 'CLEAR_BAD_URL_STATUS';
export const STOP_LOADING = 'STOP_LOADING';
export const TOGGLE_TRAINING_CTA_TOOLTIP = 'TOGGLE_TRAINING_CTA_TOOLTIP';
export const TOGGLE_SELECTOR_PANEL = 'TOGGLE_SELECTOR_PANEL';
export const CHECK_FOR_LIST_OR_DETAILS_PAGE = 'CHECK_FOR_LIST_OR_DETAILS_PAGE';
export const CHECKED_FOR_LIST_OR_DETAILS_PAGE = 'CHECKED_FOR_LIST_OR_DETAILS_PAGE';
export const TOGGLE_MANAGE_PAGES_MODAL = 'TOGGLE_MANAGE_PAGES_MODAL';

let TIMEOUT_IDS = [];
const WRONG_URLS = 'WRONG_URLS';
const URL_GREYLIST = 'URL_GREYLIST';
const INCOMPATIBLE_URLS = 'INCOMPATIBLE_URLS';

export const clearBadUrlStatus = () => ({ type: CLEAR_BAD_URL_STATUS });
export const showInteractionCopyModal = () => ({ type: SHOW_INTERACTION_COPY_MODAL });
export const hideInteractionCopyModal = () => ({ type: HIDE_INTERACTION_COPY_MODAL });
export const startLoading = () => ({ type: START_LOADING });
export const stopLoading = () => ({ type: STOP_LOADING });
export const showXPathPanel = () => ({ type: SHOW_XPATH_PANEL });
export const showCssSelectorPanel = () => ({ type: SHOW_CSS_SELECTOR_PANEL });
export const startNavigation = () => ({ type: START_NAVIGATION });
export const toggleSidebarOpen = () => ({ type: TOGGLE_INTERACTION_SIDEBAR });
export const showListProducerLoading = () => ({ type: SHOW_LIST_PRODUCER_LOADING });
export const showDataExtracted = () => ({ type: SHOW_DATA_EXTRACTED });
export const resourceResponseObtained = (result) => ({ type: RESOURCE_RESPONSE_OBTAINED, result: result });
export const changeInputsModalStatus = (inputsModal) => ({ type: CHANGE_INPUTS_MODAL_STATUS, inputsModal: inputsModal });
export const setOptTag = (optTag) => ({ type: SET_OPT_TAG, optTag: optTag });
export const setHiddenFrameDoc = (hiddenFrameDoc) => ({ type: SET_HIDDEN_FRAME_DOC, hiddenFrameDoc: hiddenFrameDoc });
export const toggleRemoveCaptureLinkModal = () => ({ type: TOGGLE_REMOVE_CAPTURE_LINK_MODAL });
export const toggleDeleteMonitoredColumnModal = () => ({ type: TOGGLE_DELETE_MONITORED_COLUMN_MODAL });
export const toggleManagePagesModal = (isManagePagesModal) => ({
  type: TOGGLE_MANAGE_PAGES_MODAL,
  isManagePagesModal: isManagePagesModal,
});

// Sagas
export const checkForListOrDetailsPage = () => ({ type: CHECK_FOR_LIST_OR_DETAILS_PAGE });
export const checkedForListOrDetailsPage = () => ({ type: CHECKED_FOR_LIST_OR_DETAILS_PAGE });

export function gotoUrl(url) {
  return (dispatch, getState) => {
    const state = getState();
    const interactionState = state.lightning.interactions;
    const sanitizedUrl = sanitizeUrl(url);

    // If we had to make the url nicer, replace the url query param
    if (sanitizedUrl !== url) {
      const queryString = Object.entries(getSearchParams())
        .map(([key, value]) => `${key}=${key === 'url' ? encodeURIComponent(sanitizedUrl) : value}`)
        .join('&');
      dispatch(replace(`${state.router.location.pathname}?${queryString}`));
    }

    const { roles } = selectCurrentUser();
    if (!roles.includes(Role.SUPPORT)) {
      if (validateUrl(sanitizedUrl, INCOMPATIBLE_URLS)) {
        dispatch(stopLoading());
        return dispatch({
          type: INCOMPATIBLE_URL,
        });
      }
      const greyListMatch = validateUrl(sanitizedUrl, URL_GREYLIST);
      if (greyListMatch) {
        dispatch(stopLoading());
        return dispatch({
          type: INCOMPATIBLE_URL,
          incompatibleUrlMessage: greyListMatch.message,
          incompatibleUrlTitle: greyListMatch.title,
        });
      }
    }
    if (validateUrl(sanitizedUrl, WRONG_URLS)) {
      dispatch(stopLoading());
      return dispatch({
        type: WRONG_URL,
      });
    }

    dispatch(getSchedulingSettings());
    dispatch(getScreenCaptureSettings());
    dispatch(getCreateDataReportSettings());

    // If this is an interaction project then we setInitialUrl which will make Browse.js respond to the change in initial url
    // and navigate. Otherwise we use UrlDataFrame to render the first page
    switch (interactionState.extractionType) {
      case 'interactive':
        return dispatch(setInitialUrl(sanitizedUrl));
      case 'basic':
        return dispatch(getDataForUrl(sanitizedUrl));
      case 'auth':
        if (interactionState.authStatus === 'authenticated' || interactionState.authStatus === 'setting-data-url') {
          dispatch(setExtractionType('basic'));
          return dispatch(getDataForUrl(sanitizedUrl));
        } else {
          return dispatch(setInitialUrl(sanitizedUrl));
        }
      default:
        return;
    }
  };
}

/**
 * Dispatches to the store the status of resources request in case of error, or,
 * in case of success, process to request the HTML.
 * @param  {object}  result       Object that shows the status of success or error from webcache
 * @param  {Boolean} shouldNotify Flag indicating if additional actions should be dispatched,
 *                                after loading this website.
 */
export function resourcesObtained(result) {
  return (dispatch) => {
    const validResponse =
      result !== undefined && typeof result === 'object' && result.statusCode >= 200 && result.statusCode < 300;
    if (validResponse) {
      dispatch(beginAutoExtract(result));
      dispatch(resourceResponseObtained(result));
    }
  };
}

export function beginAutoExtract() {
  return async (dispatch) => {
    try {
      await dispatch(loadDefaultConfig());
      dispatch(buildTraining());
    } catch (e) {
      console.log('beginAutoExtract error', e);
    }
  };
}

export function finishLoad() {
  return (dispatch, getState) => {
    const state = getState().lightning;
    if (state.interactions.replayMessage) dispatch(setReplayMessage(''));
    if (!state.ui.isDataExtracted || state.ui.isListProducerLoading) {
      dispatch(showDataExtracted());
    }

    // Necessary to remove loading state from extract data -> keep my current training
    if (state.ui.isLoading) dispatch(stopLoading());
    if (!state.ui.hasData) {
      notification.info({
        message: 'Page content ready',
        description: 'Please point and click on the desired data to train your Extractor.',
        placement: 'top',
        duration: TEN_SECONDS_DURATION,
      });
    }
    dispatch(selectViewTab('data-view', true));
  };
}

export function cleanupState() {
  return (dispatch) => {
    TIMEOUT_IDS.forEach((id) => clearTimeout(id));
    TIMEOUT_IDS = [];

    dispatch({
      type: CLEANUP_STATE,
    });
  };
}

export function selectViewTab(key, navigate) {
  return (dispatch, getState) => {
    const state = getState();
    const { isSidebarOpen } = state.lightning.ui;
    const { isNewProject, guid } = state.lightning.project;
    const { isRecording, actionList, initialCurrentInput, currentInput } = state.lightning.interactions;
    const path = state.router.location.pathname;

    if (key === 'record-view') {
      // will always default to open the 'record-view' with the record button on
      if (!isRecording) {
        dispatch(startRecording());

        // we grab the index of the action that equals 'ExtractDataAction', using lodash's findLastIndex method
        // and set the index to the const lastExtractData
        // https://lodash.com/docs/4.17.11#findLastIndex
        // if lastExtractData is greater than 0, we dispatch setRecordingAtIndex(lastExtractData);
        const lastExtractData = findLastIndex(actionList, (a) => a.name === 'ExtractDataAction');
        if (lastExtractData > 0) {
          dispatch(setRecordingAtIndex(lastExtractData));
        }
      }

      if (!path.includes('/browse')) {
        if (!isSidebarOpen) dispatch(toggleSidebarOpen());
        if (!isNewProject && guid) {
          dispatch(replace(`/browse?extractorGuid=${guid}`));
        } else {
          const queryString = Object.entries(getSearchParams())
            .map(
              ([key, value]) =>
                `${key}=${
                  key === 'url'
                    ? encodeURIComponent(sanitizeUrl(state.lightning.ui.initialUrl || state.lightning.interactions.initialUrl))
                    : value
                }`,
            )
            .join('&');
          dispatch(replace(`/browse?${queryString}`));
        }
        if (!isShallowEqual(initialCurrentInput, currentInput)) {
          dispatch(setInitialCurrentInput(currentInput));
        }
      }
    } else {
      if (!path.includes('/results') && navigate) {
        if (isSidebarOpen) dispatch(toggleSidebarOpen());
        if (!isNewProject && guid) {
          dispatch(replace(`/results?extractorGuid=${guid}`));
        } else {
          const queryString = Object.entries(getSearchParams())
            .map(
              ([key, value]) =>
                `${key}=${
                  key === 'url'
                    ? encodeURIComponent(sanitizeUrl(state.lightning.ui.initialUrl || state.lightning.interactions.initialUrl))
                    : value
                }`,
            )
            .join('&');
          dispatch(replace(`/results?${queryString}`));
        }
      }
    }

    dispatch({
      type: SELECT_VIEW_TAB,
      key: key,
    });
  };
}

export function showManageUrlsModal() {
  return (dispatch) => {
    dispatch(toggleSelectorPanel(false));
    dispatch({
      type: SHOW_MANAGE_URLS_MODAL,
    });
  };
}

export function hideManageUrlsModal() {
  return (dispatch) => {
    dispatch({
      type: HIDE_MANAGE_URLS_MODAL,
    });
  };
}

export function setCurrentUrl(url) {
  return (dispatch) => dispatch({ type: SET_CURRENT_URL, url: url });
}

export function setInitialUrl(url) {
  const initialUrl = sanitizeUrl(url);
  return (dispatch) => {
    dispatch({ type: SET_INITIAL_URL, initialUrl: initialUrl });
    dispatch(startNavigation());
  };
}

export function setAuthOptions(authUrl, loginSessionId) {
  return (dispatch) => {
    dispatch({
      type: SET_AUTH_OPTIONS,
      authUrl: authUrl,
      loginSessionId: loginSessionId,
    });
  };
}

export function setData(hasData) {
  return (dispatch) => {
    dispatch({
      type: SET_DATA,
      hasData: hasData,
    });
  };
}

// Toggles the opening and closing of the selector panel
export function toggleSelectorPanel(isSelectorPanelVisible = false) {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_SELECTOR_PANEL, isSelectorPanelVisible: isSelectorPanelVisible });
    if (!isSelectorPanelVisible) {
      return;
    }

    const selectedField = getSelectedField(getState());
    // It's not exactly clear how this can happen, still it sometimes happens (refer to EN-786)
    if (!selectedField) {
      return;
    }

    if (selectedField.manualXpath || (!selectedField.manualXpath && !selectedField.manualSelector)) {
      dispatch(showXPathPanel());
    } else if (selectedField.manualSelector) {
      dispatch(showCssSelectorPanel());
    }
  };
}

export function switchToInteractiveMode() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.lightning.interactions && state.lightning.interactions.extractionType === 'basic') {
      dispatch(clearPages());
      dispatch(setExtractionType('interactive'));
      dispatch(clearAllActions());

      const { inputs } = state.lightning.interactions;
      const url = state.lightning.ui.initialUrl || state.lightning.interactions.initialUrl;
      const currentInput = { _url: url };
      if (!inputs.length) {
        inputs.push(currentInput);
      }

      dispatch(updateInteractionState({ inputs: inputs, currentInput: currentInput }));

      const path = generatePath({
        isBrowseMode: true,
        type: 'interactive',
        url: url,
      });

      dispatch(replace(path));
      dispatch(lightningLoaded());
      dispatch(selectViewTab('record-view', true));
    }
  };
}
