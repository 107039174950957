// Add to the file only lazy loaded components or true common stuff like context, providers and redirects
import { lazy } from 'react';

export { default as CreateReportModal } from './CreateReportModal';
export { default as FirstReportRedirect } from 'features/reports/selected/FirstReportRedirect';
export { default as ReportGuidRedirect } from 'features/reports/selected/ReportGuidRedirect';

export const EmptyReports = lazy(() => import('features/reports/EmptyReports'));

export const ReportsMain = lazy(() => import('features/reports/ReportsMain'));

export const SelectedReport = lazy(() => import('features/reports/selected/SelectedReport'));

export const ReportHistory = lazy(() => import('features/reports/history/ReportHistory'));
export const ReportSettings = lazy(() => import('features/reports/settings/ReportSettings'));
