import type { SyntheticEvent } from 'react';

export const IS_PROD = import.meta.env.PROD;

export const emptyFn = () => {
  // empty
};

export const EMPTY_ARRAY = Object.freeze([]);

export const EMPTY_OBJECT = Object.freeze({});

export const disabledFn = (e: SyntheticEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

export const COLUMN_LINK_PROPERTY = 'href';

export const ONE_HUNDRED = 100;
