export const InteractionEnum = {
  GotoAction: {
    displayValue: 'Go to',
    shouldDisplay: true,
    codeActionDependent: true,
  },
  RenderAction: {
    displayValue: 'Render Url',
    shouldDisplay: true,
  },
  ViewportAction: {
    displayValue: 'Set viewport',
    shouldDisplay: true,
    codeActionDependent: true,
  },
  WaitLoadingAction: {
    shouldDisplay: true,
    codeActionDependent: true,
  },
  ClickAction: {
    displayValue: 'Mouse Click',
    shouldDisplay: true,
    canDelete: true,
    antIcon: 'SelectOutlined',
  },
  ScrollAction: {
    displayValue: 'Scroll',
    shouldDisplay: true,
    canDelete: true,
    antIcon: 'ArrowsAltOutlined',
  },
  InputChangeAction: {
    displayValue: 'Entered input field',
    shouldDisplay: true,
    canDelete: true,
    antIcon: 'SnippetsOutlined',
  },
  SelectChangeAction: {
    displayValue: 'Dropdown',
    shouldDisplay: true,
    canDelete: true,
    antIcon: 'CaretDownOutlined',
  },
  WaitMutationAction: {
    shouldDisplay: true,
    displayValue: 'Wait for changes',
    canDelete: true,
  },
  WaitRegionMutationAction: {
    shouldDisplay: true,
    canDelete: true,
    displayValue: 'Wait for element to change',
  },
  WaitForSelectorAction: {
    shouldDisplay: true,
    canDelete: true,
    displayValue: 'Wait for element',
  },
  WaitForFunctionAction: {
    shouldDisplay: true,
    canDelete: true,
    displayValue: 'Wait for function',
  },
  ExtractDataAction: {
    displayValue: 'Extract Data',
    shouldDisplay: true,
    canDelete: true,
    antIcon: 'UploadOutlined',
    canDeleteIndividually: true,
    disableLoop: true,
  },
  HoverAction: {
    displayValue: 'Hover',
    shouldDisplay: true,
    canDelete: true,
    antIcon: 'VerticalAlignTopOutlined',
  },
  WaitAction: {
    displayValue: 'Wait',
    shouldDisplay: true,
    antIcon: 'ClockCircleOutlined',
    canDelete: true,
    canDeleteIndividually: true,
  },
  FlowControlAction: {
    displayValue: 'Group',
    shouldDisplay: true,
    antIcon: 'UnorderedListOutlined',
    canDelete: true,
  },
  LoopAction: {
    displayValue: 'Loop',
    shouldDisplay: true,
    antIcon: 'RetweetOutlined',
    canDelete: true,
    alwaysShowDisplayValue: true,
    disableLoop: true,
  },
  FunctionAction: {
    displayValue: 'JavaScript Action',
    shouldDisplay: true,
    antIcon: 'CodeOutlined',
    canDelete: true,
    alwaysShowDisplayValue: true,
    canDeleteIndividually: true,
  },
  CodeAction: {
    displayValue: 'Code Action',
    shouldDisplay: true,
    antIcon: 'CodeOutlined',
    canDelete: true,
    alwaysShowDisplayValue: true,
    canDeleteIndividually: true,
  },
  PaginationAction: {
    antIcon: 'CopyOutlined',
    displayValue: 'Paginate',
    shouldDisplay: true,
    canDelete: true,
    variablePath: '',
    alwaysShowDisplayValue: true,
    disableLoop: true,
  },
  ScreenCaptureAction: {
    antIcon: 'DesktopOutlined',
    displayValue: 'Screenshot',
    shouldDisplay: true,
    canDelete: true,
    disableLoop: true,
  },
  CaptchaAction: {
    displayValue: 'Solve Captcha',
    shouldDisplay: true,
    antIcon: 'SettingFilled',
    canDelete: true,
    disableLoop: true,
  },
  KeyAction: {
    displayValue: 'Key',
    canDelete: true,
    shouldDisplay: true,
  },
  KeyboardAction: {
    displayValue: 'Keyboard press',
    canDelete: true,
    shouldDisplay: true,
  },
};

export const INITIAL_LOAD_ACTION_TYPES = ['GotoAction', 'ViewportAction', 'WaitLoadingAction'];
export const REPLAY_TO_LAST_ACTION_MESSAGE = 'One moment... we are getting you back to where you were';
export const REPLAY_FOR_INPUT_MESSAGE = 'Replaying your sequence for the given inputs...';
export const DEFAULT_REPLAY_MESSAGE = 'Replaying your sequence...';
export const NUMBER_VARIABLE_ACTIONS = ['PaginationAction', 'LoopAction'];
export const EXPANDABLE_ACTION_TYPES = ['FunctionAction', 'CodeAction', 'WaitForSelectorAction', 'WaitForFunctionAction'];
export const NON_EXPANDABLE_ACTION_TYPES = ['ExtractDataAction', 'FlowControlAction'];
export const WS_DEFAULT_SETTINGS = { images_enabled: true, css_enabled: true, load_all_resources: true };
export const RECORD_DEFAULT_INPUTS_FOR_TYPES = ['InputChangeAction', 'SelectChangeAction', 'PaginationAction', 'LoopAction'];
