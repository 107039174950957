// TODO: fix ESLint errors
/* eslint-disable @typescript-eslint/no-use-before-define */
import { extractorApi, objectStoreApi, ObjectStoreQueryRequestBuilder, userDataApi } from '@import-io/js-sdk';
import type { ServerBrowserResponse } from '@import-io/page-interaction-sdk/server-browser-api';
import { isNotEmptyString, isPresent } from '@import-io/typeguards';
import type { Extractor, InputItem, RuntimeConfigurationRecordNew } from '@import-io/types';
import { getUrlFromInput } from '@import-io/types';
import type { PageSetOptions } from '@import-io/web-extractor';
import { PageSet } from '@import-io/web-extractor';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { ActionCreators } from 'redux-undo';
import store from 'store';
import { v4 as uuidv4 } from 'uuid';

import { selectLightningProjectState } from 'app/lightning-old/lightning-selectors';
import type { RootState } from 'common/common-types';
import { getUrlFromTraining } from 'common/utils/training-utils';
import { canReplaceAssetsForUrl, getFormattedName, getLoginUrl, getSearchParams } from 'common/utils/url-utils';
import { useExtractorAuthModalStore } from 'features/extractor-auth/extractor-auth-hooks';
import { createInteractionAction } from 'features/extractor-builder/interaction/interaction-action';
import {
  getAuthVariables,
  getPageSetInputs,
  makeRenderAction,
  restrictInteractionsAccess,
} from 'features/extractor-builder/interaction/interaction-utils-old';
import { selectExtractorListQueryData } from 'features/extractors/common/extractor-list-query';
import { updateExtractorQueryData } from 'features/extractors/common/extractor-query';
import type { ProxySettingsData } from 'features/extractors/proxy/proxy-types';
import { selectCurrentUser } from 'features/user/auth/user-auth-query';
import { selectSubscriptionFeatureFlags } from 'features/user/subscription/subscription-query';

import {
  addPage,
  createTraining,
  initializeBuilder,
  setActiveExtractorConfig,
  setCssRendering,
  updateStateNoUndo,
} from './builder';
import {
  openUrlDataFrame,
  prepareInteractionState,
  setAuthUrl,
  setCredentials,
  startLockInteractions,
  updateInteractionState,
} from './interactions';
import { selectViewTab, setCurrentUrl, setInitialUrl, toggleSidebarOpen } from './ui';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const RUNTIME_CONFIG_UPDATE = 'RUNTIME_CONFIG_UPDATE';
export const SAVE_EXTRACTOR_STARTED = 'SAVE_EXTRACTOR_STARTED';
export const SAVE_EXTRACTOR_SUCCESS = 'SAVE_EXTRACTOR_SUCCESS';
export const SAVE_EXTRACTOR_FAILURE = 'SAVE_EXTRACTOR_FAILURE';
export const EXTRACTOR_LOADED = 'EXTRACTOR_LOADED';
export const EXTRACTOR_LOAD_FAILURE = 'EXTRACTOR_LOAD_FAILURE';
export const NEW_URL_LOADING = 'NEW_URL_LOADING';
export const NEW_URL_SUCCESS = 'NEW_URL_SUCCESS';
export const NEW_URL_NO_DATA = 'NEW_URL_NO_DATA';
export const NEW_URL_ERROR = 'NEW_URL_ERROR';
export const RESET_PROJECT = 'RESET_PROJECT';
export const RESET_MANAGE_URLS_STATE = 'RESET_MANAGE_URLS_STATE';
export const SET_NEW_PROJECT = 'SET_NEW_PROJECT';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const SET_CREDENTIALS_GUID = 'SET_CREDENTIALS_GUID';
export const SAVE_AND_RUN = 'SAVE_AND_RUN';
export const SET_SCREEN_CAPTURE = 'SET_SCREEN_CAPTURE';
export const SET_NOTIFY_ME = 'SET_NOTIFY_ME';
export const SET_INTERACTION_PROJECT = 'SET_INTERACTION_PROJECT';
export const SET_SCHEDULING_SETTINGS = 'SET_SCHEDULING_SETTINGS';
export const FETCHING_RUNTIME_CONFIG = 'FETCHING_RUNTIME_CONFIG';
export const RUNTIME_CONFIG_RECEIVED = 'RUNTIME_CONFIG_RECEIVED';
export const SET_FINAL_PAGES_LIST = 'SET_FINAL_PAGES_LIST';
export const TOGGLE_CREATE_DATA_REPORT = 'TOGGLE_CREATE_DATA_REPORT';
export const SET_CREATE_DATA_REPORT = 'SET_CREATE_DATA_REPORT';
export const SET_REPORT = 'SET_REPORT';
export const NAVIGATING_TO_URL = 'NAVIGATING_TO_URL';
export const START_REFRESHING_HTML = 'START_REFRESHING_HTML';
export const REFRESHING_HTML_COMPLETE = 'REFRESHING_HTML_COMPLETE';
export const SET_PROXY_SETTINGS = 'SET_PROXY_SETTINGS';
export const SET_EXISTING_PROJECT = 'SET_EXISTING_PROJECT';
export const TOGGLE_REPLACE_ASSETS = 'TOGGLE_REPLACE_ASSETS';
export const LIGHTNING_LOADED = 'LIGHTNING_LOADED';
export const BUILD_TRAINING = 'BUILD_TRAINING';
export const SET_HTML_EXTRACTION = 'SET_HTML_EXTRACTION';

const SCHEDULING_SETTINGS_KEY = 'SCHEDULING_SETTINGS_KEY';
const SCREEN_CAPTURE_SETTINGS_KEY = 'SCREEN_CAPTURE_SETTINGS_KEY';
const CREATE_DATA_REPORT_SETTINGS_KEY = 'CREATE_DATA_REPORT_SETTINGS_KEY';

// ====== Sagas ======== \\
export const buildTraining = () => ({ type: BUILD_TRAINING });
export const lightningLoaded = () => ({ type: LIGHTNING_LOADED });
export const saveExtractor = () => ({ type: SAVE_EXTRACTOR_STARTED });
export const saveAndRunExtractor = () => ({ type: SAVE_AND_RUN });

// ======== Simple actions ============\\
export const resetManageUrlsState = () => ({ type: RESET_MANAGE_URLS_STATE });
export const setNotifyMe = (notifyMe: boolean | any) => ({ type: SET_NOTIFY_ME, notifyMe: !!notifyMe });
export const setScreenCapture = (screenCapture: boolean | any) => ({
  type: SET_SCREEN_CAPTURE,
  screenCapture: !!screenCapture,
});
export const setInteractionProject = (hasInteraction) => ({
  type: SET_INTERACTION_PROJECT,
  hasInteraction: hasInteraction,
});
export const setExistingProject = (guid) => ({ type: SET_EXISTING_PROJECT, guid: guid });
export const setProxySettings = (proxySettings: Partial<ProxySettingsData>) => ({
  type: SET_PROXY_SETTINGS,
  proxySettings: proxySettings,
});
export const resetProject = () => ({ type: RESET_PROJECT });
export const setCreateDataReport = (createDataReport) => ({
  type: SET_CREATE_DATA_REPORT,
  createDataReport: createDataReport,
});
export const setReport = (report) => ({ type: SET_REPORT, report: report });
export const setNewProject = () => {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_PROJECT,
    });
  };
};

// ======== Thunks ==========\\
export function createProject(
  {
    name,
    schemaId,
    guid,
    _meta,
    existingProject = true,
    activeExtractorConfig = '_runtimeConfig',
  }: {
    name?: string;
    schemaId?: string;
    guid?: string;
    _meta?: Object;
    existingProject?: boolean;
    activeExtractorConfig?: string;
  } = {},
  pageSet: PageSet = new PageSet(),
) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const { url } = getSearchParams();

    const project = state.lightning.project;

    const existingGuid: string = project.guid || uuidv4();
    const existingMeta: any = project._meta;

    dispatch(initializeBuilder(pageSet));

    if (!name) {
      if (state.lightning.project.name) {
        name = state.lightning.project.name;
      } else {
        name = getFormattedName(String(url) || 'New Project');
      }
    }

    dispatch({
      type: CREATE_PROJECT,
      guid: guid || existingGuid,
      name: name,
      schemaId: schemaId,
      _meta: _meta || existingMeta,
      existingProject: existingProject,
      activeExtractorConfig: activeExtractorConfig,
    });
  };
}

function dedupeName(newProjectName: string, extractors: Extractor[]) {
  newProjectName = newProjectName.replace(/\s+/g, ' ').trim();

  const sameExtractor = extractors
    .filter((e) =>
      e.name.match(new RegExp(`^${newProjectName.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')}\\s?\\(?(\\d+)?\\)?$`, 'gm')),
    )
    .map((e) => /\s?\(?(\d+)?\)?$/gm.exec(e.name)?.[1])
    .map((val) => (!val ? '0' : val))
    .map((val) => parseInt(val, 10));

  if (sameExtractor.length !== 0) {
    newProjectName = `${newProjectName} (${Math.max(...sameExtractor) + 1})`;
  }

  return newProjectName;
}

export function refreshCurrentPage() {
  return (dispatch, getState) => {
    const selectedPage = (getState() as RootState).lightning.builder.present.selectedPage;
    if (selectedPage) {
      dispatch({ type: START_REFRESHING_HTML, refreshingPageId: selectedPage.id });
      dispatch(addUrl(selectedPage.url || ''));
    }
  };
}

// Add url from "add url" modal
export function addUrl(url: string) {
  return (dispatch, getState) => {
    dispatch({
      type: NEW_URL_LOADING,
    });

    const { authUrl, loginSessionId } = (getState() as RootState).lightning.ui;
    if (!authUrl && !loginSessionId) {
      return dispatch(getDataForUrl(url));
    }
    dispatch(urlObtained({ url: url }));
  };
}

export function addUrlResource(resource) {
  return (dispatch) => {
    dispatch(urlObtained(resource));
    dispatch(setCurrentUrl(resource.url));
  };
}

function urlObtained(resource: Partial<ServerBrowserResponse>) {
  return (dispatch) => {
    if (isPresent(resource.html) && resource.html.length > 0) {
      dispatch(addPage(resource));

      setImmediate(() => {
        dispatch(checkDataStatus());
      });
    } else {
      dispatch({
        type: NEW_URL_ERROR,
      });
    }
  };
}

export function checkDataStatus() {
  return (dispatch, getState) => {
    const state = (getState() as RootState).lightning;
    if (state.builder.present.data.length === 0) {
      dispatch({
        type: NEW_URL_NO_DATA,
      });
    } else {
      dispatch({
        type: NEW_URL_SUCCESS,
      });
    }
  };
}

export function loadExtractor() {
  return async (dispatch) => {
    // Load extractor from query param
    const { extractorGuid } = getSearchParams();
    const { canRecordInteraction } = selectSubscriptionFeatureFlags();

    // Stretch the LoadingOverlay to full window width
    // in order to lock interactions sidebar.
    if (!canRecordInteraction) {
      dispatch(startLockInteractions());
    }

    dispatch(setExistingProject(extractorGuid));
    // Fetch extractor from object store
    const extractor = await extractorApi.get(extractorGuid as string);

    if (!extractor) {
      throw new Error(`Extractor ${extractorGuid} not found`);
    }
    updateExtractorQueryData(extractorGuid!, extractor);

    const { iso3Country, proxyType } = extractor;
    // Set proxy settings and respect robots flags if they exist
    if (isNotEmptyString(proxyType) || isNotEmptyString(iso3Country)) {
      dispatch(setProxySettings({ proxyType: proxyType, country: iso3Country }));
    }

    // Fetch the runtimeconfig
    await dispatch(loadRuntimeConfig(extractor));
    //Do not show diff modal if we are editing an extractor
    //Load the diff in case we need to change it because the user might delete monitored columns
    dispatch(loadExtractorDiffReports(extractor));
    // Get the extractor training
    if (extractor.training) {
      await dispatch(loadExtractorTraining(extractor));
    } else {
      dispatch(createTraining(extractor));
    }

    dispatch(setNotifyMe(extractor.notifyMe));
    dispatch(setCreateDataReport(extractor.createDataReport));
    dispatch(updateStateNoUndo({ showSaveModal: false }));
    const cssDisabled = store.get(`extractor-${extractor.guid}-cssDisabled`);
    dispatch(setCssRendering(!!cssDisabled));

    dispatch({
      type: EXTRACTOR_LOADED,
      extractor: extractor,
    });
  };
}

function loadRuntimeConfig(extractor: Extractor) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const { credentialsInput } = state.lightning.interactions;
    try {
      dispatch({ type: FETCHING_RUNTIME_CONFIG });
      const runtimeConfiguration = await objectStoreApi.runtimeConfiguration.get(extractor.latestConfigId);

      if (!runtimeConfiguration) {
        throw new Error(`No runtime configuration found for extractor ${extractor.guid}}`);
      }

      if (!(extractor.inputs && runtimeConfiguration.config && Array.isArray(runtimeConfiguration.config.interactions))) {
        // Something is missing
        return;
      }

      // Means it is an interactive extractor
      dispatch(setInteractionProject(true));

      const extractionConfigName =
        (runtimeConfiguration.config?.extractionConfigs && Object.keys(runtimeConfiguration.config.extractionConfigs)[0]) ||
        '_runtimeConfig';

      dispatch(setActiveExtractorConfig(extractionConfigName));

      // It is an auth extractor
      if (extractor.authUrl) {
        dispatch(setAuthUrl(extractor.authUrl));
        if (!credentialsInput && runtimeConfiguration.config.authInteractions) {
          // Interactive authentication
          const authCredentials = getAuthVariables(runtimeConfiguration.config.authInteractions);
          // Prompt user for credentials
          dispatch(setCredentials(authCredentials));
          useExtractorAuthModalStore.setState({ value: true });
        }
      }

      // Get the default input for playback
      const defaultInput: InputItem = (await extractorApi.getDefaultInput(extractor.guid)) as InputItem;
      dispatch(
        prepareInteractionState({
          serializedActions: runtimeConfiguration.config.interactions,
          serializedAuthActions: runtimeConfiguration.config.authInteractions || [],
          currentInput: defaultInput,
        }),
      );

      // Open the sidebar
      const newState = getState();
      if (newState.router.location.pathname === '/browse' && newState.lightning.interactions.extractionType === 'interaction') {
        dispatch(toggleSidebarOpen());
      }
      await dispatch({ type: RUNTIME_CONFIG_RECEIVED, runtimeConfiguration: runtimeConfiguration });
    } catch (err) {
      console.error('Error loading extractor runtimeConfig:', err);
    }
  };
}

function loadExtractorTraining(extractor: Extractor) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const { router } = state;
    const { currentInput, extractionType } = state.lightning.interactions;
    const { canRecordInteraction } = selectSubscriptionFeatureFlags();

    let { activeExtractorConfig } = state.lightning.builder.present;

    try {
      let training = await extractorApi.getAttachment(extractor.guid, 'training', extractor.training);

      // tldr; PageSet training is saved as a string to overcome some weird nested class issues
      if (typeof training === 'string') {
        training = JSON.parse(training);
      }

      if (isEmpty(training.pageSets) && !training.fields) {
        return dispatch(createTraining(extractor));
      }

      restrictInteractionsAccess(canRecordInteraction || extractionType === 'auth');

      // Set the current url, this is used to display the url at the top of screen
      if (state.lightning.project.hasInteraction && currentInput && getUrlFromInput(currentInput)) {
        dispatch(setCurrentUrl(getUrlFromInput(currentInput)));
      } else {
        const currentUrl = getUrlFromTraining(training);
        if (currentUrl) {
          dispatch(setCurrentUrl(currentUrl));
        }
      }

      const project = (getState() as RootState).lightning.project;
      const { hasInteraction, screenCapture, screenCaptureType, htmlExtraction } = project;

      let pageSet: PageSet;
      if (training.pageSets) {
        pageSet = new PageSet(Object.values(cloneDeep(training.pageSets))[0] as PageSetOptions);
      } else {
        pageSet = new PageSet(training);
      }

      // sync the training with current runtime configuration
      pageSet.screenCapture = screenCapture;
      pageSet.screenCaptureType = screenCaptureType;
      pageSet.htmlExtraction = htmlExtraction;

      dispatch(createProject({ ...extractor, activeExtractorConfig: activeExtractorConfig }, pageSet));
      if (hasInteraction) {
        let inputs: any[] = [];
        if (pageSet?.pages.length) {
          inputs = [...inputs, ...getPageSetInputs(pageSet)];
        }
        // Set our initial inputs list
        dispatch(updateInteractionState({ inputs: inputs }));
      }

      dispatch(selectViewTab(router.location?.pathname === '/browse' ? 'record-view' : 'data-view'));

      dispatch(ActionCreators.clearHistory());
    } catch (err) {
      if (err.status === 401) {
        const returnPath = encodeURIComponent(location.href);
        window.location.href = `${getLoginUrl()}/?redirect=${returnPath}`;
      } else {
        console.error('Error occurred while loading extractor: ', err);
        dispatch({ type: EXTRACTOR_LOAD_FAILURE });
      }
    }
  };
}

export function setInitialProjectName() {
  return (dispatch) => {
    const { url } = getSearchParams();
    if (!url) {
      return;
    }

    const name = getFormattedName(String(url));
    dispatch(setProjectName(name));
  };
}

export function getExtractorsAndSetProjectName() {
  return (dispatch) => {
    dispatch(setInitialProjectName());
  };
}

export function setProjectName(name: string) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const currentUser = selectCurrentUser();
    const extractors = selectExtractorListQueryData();
    const { guid } = selectLightningProjectState(state);

    if (!isPresent(guid) && isPresent(currentUser) && extractors.length > 0) {
      name = dedupeName(name, extractors);
    }

    dispatch({
      type: SET_PROJECT_NAME,
      name: name,
    });
  };
}

/**
 * General function to get a setting from the user data api
 */
function getSetting(keyName: string, type: string) {
  return async (dispatch) => {
    try {
      const { guid } = selectCurrentUser();
      const resp = await userDataApi.get(guid, keyName);
      dispatch({
        type: type,
        ...(resp! as any).value,
      });
    } catch (e) {
      console.log(`Error getting '${keyName}' setting`, e);
    }
  };
}

export function getSchedulingSettings() {
  return getSetting(SCHEDULING_SETTINGS_KEY, SET_SCHEDULING_SETTINGS);
}

export function getScreenCaptureSettings() {
  return getSetting(SCREEN_CAPTURE_SETTINGS_KEY, SET_SCREEN_CAPTURE);
}

export function getCreateDataReportSettings() {
  return getSetting(CREATE_DATA_REPORT_SETTINGS_KEY, SET_CREATE_DATA_REPORT);
}

export function getDataForUrl(gettingDataForUrl) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    dispatch(openUrlDataFrame());
    dispatch(setInitialUrl(gettingDataForUrl));
    const { inputs, variables, actionList } = state.lightning.interactions;
    let updateTheState = false;
    if (!inputs.length) {
      updateTheState = true;
      inputs.push({ _url: gettingDataForUrl });
    }
    if (!actionList.length) {
      updateTheState = true;
      actionList.push(createInteractionAction({ action: makeRenderAction(gettingDataForUrl) }));
    }
    if (!variables.length) {
      updateTheState = true;
      const renderAction = actionList.find((a) => a.name === 'RenderAction');
      variables.push({
        actionId: renderAction?.actionId,
        name: '_url',
        canEdit: false,
      });
    }

    if (updateTheState) {
      dispatch(
        updateInteractionState({
          inputs: inputs,
          actionList: actionList,
          variables: variables,
        }),
      );
    }
    const query = getSearchParams();
    dispatch({
      type: NAVIGATING_TO_URL,
      gettingDataForUrl: gettingDataForUrl,
      canReplaceAssetsForUrl: query.replaceAssets ? query.replaceAssets === 'true' : canReplaceAssetsForUrl(gettingDataForUrl),
    });
  };
}

/**
 * @deprecated TODO: remove this empty action. Shouldn't we use initialLoadComplete from actions/interactions?
 */
export function projectInitialLoadComplete() {
  return () => {};
}

export function loadExtractorDiffReports(extractor: Extractor) {
  return async (dispatch) => {
    const { canCreateDiffReport } = selectSubscriptionFeatureFlags();
    if (canCreateDiffReport) {
      //Find the report associated with the extractor

      const query = new ObjectStoreQueryRequestBuilder()
        .setPageLimit(100)
        .setPageNumber(1)
        .setSortDesc(true)
        .setShowMine(true)
        .addEqFilter('type', 'CRAWL_DIFF')
        .addEqFilter('extractorId', extractor.guid)
        .setShowArchived(false)
        .build();

      try {
        const reports = await objectStoreApi.report.query(query);

        if (reports.length > 0) {
          dispatch(setReport(reports[0]));
        }
      } catch (err) {
        console.error('Error loading crawl run diff config:', err);
      }
    }
  };
}

export function toggleReplaceAssets(canReplaceAssetsForUrlVar: boolean | any) {
  return (dispatch, getState) =>
    dispatch({
      type: TOGGLE_REPLACE_ASSETS,
      canReplaceAssetsForUrl:
        typeof canReplaceAssetsForUrlVar === 'boolean'
          ? canReplaceAssetsForUrlVar
          : !(getState() as RootState).lightning.project.canReplaceAssetsForUrl,
    });
}

export function setDefaultSettings() {
  return (dispatch) => {
    dispatch(getScreenCaptureSettings());
    dispatch(getCreateDataReportSettings());
  };
}

export function updateRuntimeConfigurationRaw(runtimeConfiguration: Pick<RuntimeConfigurationRecordNew, 'config'>) {
  return (dispatch) => {
    dispatch({
      type: RUNTIME_CONFIG_UPDATE,
      runtimeConfiguration: runtimeConfiguration,
    });
  };
}
