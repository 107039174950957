import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import type { Action } from '@import-io/replay-browser-events';
import type { ActionData } from '@import-io/types';

import { setCredentialsAndEdit } from 'app/actions-old/app-ui';
import type { AppDispatch } from 'app/app-types';
import { toggleValueStoreCreator } from 'common/utils/store-utils';
import type { ExtractorAuthFormValues, ExtractorAuthSubmitHandler } from 'features/extractor-auth/extractor-auth-types';
import { DEFAULT_AUTH_FORM_VALUES } from 'features/extractor-auth/extractors-auth-constants';
import { getAuthVariables } from 'features/extractor-builder/interaction/interaction-utils-old';

/**
 * Create extractor authentication from values from auth interaction actions data.
 * @param authInteractions {Array} auth interaction actions data.
 * @returns {Object} authentication from values.
 */
export const useExtractorAuthFormValues = (authInteractions: ActionData[] | Action[] = []): ExtractorAuthFormValues =>
  useMemo(() => {
    const values = getAuthVariables(authInteractions);
    return Object.keys(values).length > 0 ? values : DEFAULT_AUTH_FORM_VALUES;
  }, [authInteractions]);

export const useExtractorAuthModalStore = toggleValueStoreCreator('extractor-auth-modal-store');

export const useHandleSubmitExtractorAuth = (extractorId: string): ExtractorAuthSubmitHandler => {
  const dispatch = useDispatch<AppDispatch>();
  const { toggleValue } = useExtractorAuthModalStore();
  return useCallback<ExtractorAuthSubmitHandler>(
    (values) => {
      dispatch(setCredentialsAndEdit(values, extractorId));
      toggleValue();
    },
    [dispatch, extractorId, toggleValue],
  );
};
