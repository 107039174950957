import type { CSSProperties, JSX } from 'react';
import { NavLink } from 'react-router-dom';

import Tooltip from 'antd/lib/tooltip';

import { PAGE_NAVIGATION_ITEM_HEIGHT } from 'common/components/layout/layout-constants';
import type { PageNavigationItemProps } from 'common/components/layout/layout-types';

import styles from './PageNavigation.module.less';

const itemStyle: CSSProperties = { height: PAGE_NAVIGATION_ITEM_HEIGHT };

const PageNavigationItem = ({ icon, label, url }: PageNavigationItemProps): JSX.Element => (
  <div style={itemStyle}>
    <Tooltip mouseEnterDelay={0.5} placement="top" title={label}>
      <NavLink className={`flex align-center full-height full-width justify-between ${styles.link}`} to={url}>
        <span className="ellipsis">{label}</span>
        {icon ? <span className={styles.icon}>{icon}</span> : null}
      </NavLink>
    </Tooltip>
  </div>
);

export default PageNavigationItem;
