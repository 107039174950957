import { Role } from '@import-io/types';

import { useHasRole } from 'common/hooks/use-has-role';
import { useSubscriptionFeatureFlags } from 'features/user/subscription/subscription-hooks';

export const useShowUserManagement = (): boolean => {
  const isOrgAdmin = useHasRole(Role.ORG_ADMIN);
  const { orgManagement } = useSubscriptionFeatureFlags();
  return orgManagement && isOrgAdmin;
};
