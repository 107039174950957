import { all } from 'redux-saga/effects';

import { lightningSagas } from 'app/lightning-old';

export function* rootSaga(): Generator {
  try {
    yield all([lightningSagas()]);
  } catch (e) {
    console.error('Unhandled saga error', e);
  }
}
