import type { Extractor } from '@import-io/types';
import type { Updater } from '@tanstack/react-query';

import { queryClient } from 'common/query/query-constants';
import { EXTRACTOR_LIST_QUERY_KEY, GET_EXTRACTOR_QUERY_KEY } from 'features/extractors/extractors-constants';

export const selectExtractorListQueryData = (): Extractor[] =>
  queryClient.getQueryData<Extractor[]>([EXTRACTOR_LIST_QUERY_KEY]) ?? [];

export const updateExtractorListQueryData = (updater: Updater<Extractor[] | undefined, Extractor[] | undefined>): Extractor[] =>
  queryClient.setQueryData<Extractor[]>([EXTRACTOR_LIST_QUERY_KEY], updater) ?? [];

export const deleteExtractorListQueryData = (extractorId: string): Extractor[] => {
  queryClient.removeQueries({ queryKey: [GET_EXTRACTOR_QUERY_KEY, extractorId] });
  return updateExtractorListQueryData((extractors = []) => extractors.filter(({ guid }) => guid !== extractorId));
};

export const invalidateExtractorListQuery = (): Promise<void> =>
  queryClient.invalidateQueries({ queryKey: [EXTRACTOR_LIST_QUERY_KEY] });
