import { runApi } from '@import-io/js-sdk';
import type { StatusResponse } from '@import-io/js-sdk/types/http-request-details';
import { isPresent } from '@import-io/typeguards';
import type { CrawlRun, Extractor, User, UserSubscriptionData } from '@import-io/types';
import message from 'antd/lib/message';

import { sendEvent } from 'common/events/events-api';
import { EventType } from 'common/events/events-types';
import { invalidateExtractorHistory } from 'features/extractors/common/extractor-history-query';
import { updateExtractorQueryData } from 'features/extractors/common/extractor-query';

interface CancelRunParams {
  crawlRun?: CrawlRun;
  extractor: Extractor;
  subscription: UserSubscriptionData | null;
  user: User;
}

export const startRunApi = async (extractor: Extractor): Promise<{ crawlRunId: string }> => {
  try {
    const { guid } = extractor;
    const result = await runApi.start(guid);
    const { crawlRunId } = result;
    void invalidateExtractorHistory(guid);
    updateExtractorQueryData(guid, (data = extractor) => ({ ...data, nextCrawlRunId: crawlRunId }));
    return result;
  } catch (e) {
    void message.error('Run extractor error');
    return Promise.reject(e);
  }
};

export const cancelRunApi = ({ crawlRun, extractor, subscription, user }: CancelRunParams): Promise<StatusResponse> => {
  const { guid: extractorId, name } = extractor;
  if (isPresent(crawlRun)) {
    void sendEvent({
      type: EventType.CRAWLRUN_CANCELLED,
      data: {
        user: {
          id: user.guid,
          email: user.email,
          planCode: subscription?.basePlanCode,
        },
        extractor: {
          id: extractorId,
          name: name,
        },
        crawlRun: {
          id: crawlRun.guid,
          inputs: crawlRun.totalUrlCount ?? 0,
          status: 'CANCELLED',
          rowsExtracted: crawlRun.rowCount,
        },
      },
    });
  }
  return runApi.cancel(extractorId, crawlRun?.guid);
};
