import type { ForwardedRef, JSX } from 'react';
import { forwardRef } from 'react';

import Button from 'antd/lib/button';

import type { IconButtonProps } from 'common/components/buttons/buttons-types';
import TooltipWrapper from 'common/components/TooltipWrapper';

const IconButton = forwardRef(
  (
    {
      disabled = false,
      disabledTooltip,
      ghost = false,
      icon,
      shape = 'circle',
      tooltip,
      type = 'text',
      ...restProps
    }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ): JSX.Element => (
    <TooltipWrapper disabled={disabled} disabledTitle={disabledTooltip} {...tooltip}>
      <Button disabled={disabled} ghost={ghost} icon={icon} ref={ref} shape={shape} type={type} {...restProps} />
    </TooltipWrapper>
  ),
);

IconButton.displayName = 'IconButton';

export default IconButton;
