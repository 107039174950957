import type { SelectOption } from 'common/common-types';
import { dayjs } from 'common/date';

export const DEFAULT_TIME_SPAN: SelectOption<() => void> = {
  label: 'Past 3 Days',
  value: () => dayjs().subtract(3, 'days').startOf('day').valueOf(),
};

export const OVERVIEW_TIME_SPANS: readonly SelectOption<() => void>[] = [
  {
    label: 'Today',
    value: () => dayjs().startOf('day').valueOf(),
  },
  DEFAULT_TIME_SPAN,
  {
    label: 'Past Week',
    value: () => dayjs().subtract(7, 'days').startOf('day').valueOf(),
  },
  {
    label: 'Past Month',
    value: () => dayjs().subtract(30, 'days').valueOf(),
  },
];
