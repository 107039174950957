import {
  CLEAR_REPORT_SETTINGS,
  LOAD_CONFLICT_NOTIFY,
  LOAD_REPORT_CONFIG,
  SET_AVAILABLE_EXTRACTORS,
  SET_COLUMNS,
  SET_EDIT_CELL,
  SET_EDIT_COLUMN,
  SET_MATRIX_MODE,
  SET_ROWS,
  SET_VALIDATION,
  SOFT_RESET,
  TRACK_MASTER_ROW,
} from '../actions/reportSettings';

export const getInitialState = () => {
  return {
    rowMap: {},
    masterId: null,
    editColumn: null,
    matrixMode: null,
    loadConflicts: {
      rows: [],
      fields: [],
      columns: [],
    },
    editingCell: null,
    _configBack: null,
    availableRows: [],
    matrixFeatures: {},
    matrixConfig: null,
    matrixParser: {
      columnName: 'reportFieldName',
    },
    validationState: {
      error: 0,
      warning: 0,
    },
  };
};

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case SET_AVAILABLE_EXTRACTORS:
      return {
        ...state,
        rowMap: action.rowMap,
        availableRows: action.extractors,
      };
    case LOAD_REPORT_CONFIG:
      return {
        ...state,
        matrixConfig: action.config,
        _configBack: action._configBack,
        matrixFeatures: action.features || {},
      };
    case SET_ROWS:
      return {
        ...state,
        matrixConfig: {
          ...state.matrixConfig,
          rows: action.rows,
        },
      };
    case SET_COLUMNS:
      return {
        ...state,
        matrixConfig: {
          ...state.matrixConfig,
          columns: action.columns,
        },
      };
    case SET_EDIT_CELL:
      return {
        ...state,
        editingCell: action.cell,
      };
    case SET_EDIT_COLUMN:
      return {
        ...state,
        editColumn: action.column,
      };
    case SET_VALIDATION:
      return {
        ...state,
        validationState: action.validationState,
      };
    case SOFT_RESET:
      return {
        ...state,
        editColumn: null,
        editingCell: null,
        validationState: {
          warning: 0,
          error: 0,
        },
        matrixConfig: {
          ...state.matrixConfig,
          rows: [],
          columns: [],
        },
      };
    case TRACK_MASTER_ROW:
      return {
        ...state,
        masterId: action.masterId,
      };
    case SET_MATRIX_MODE:
      return {
        ...state,
        matrixMode: action.mode,
      };
    case LOAD_CONFLICT_NOTIFY:
      return {
        ...state,
        loadConflicts: action.conflicts,
      };
    case CLEAR_REPORT_SETTINGS:
      return getInitialState();
    default:
      return state;
  }
}
