import { objectStoreApi, portalApi } from '@import-io/js-sdk';
import { isEqual } from 'lodash/lang';
import { escapeRegExp } from 'lodash/string';

// TODO: refactor and move to features/portal/portal-slice and features/portal/portal-sagas
export const LOAD_RUNS = 'LOAD_RUNS';
export const UPDATE_RUNS = 'UPDATE_RUNS';
export const END_LOADING = 'END_LOADING';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const START_LOADING = 'START_LOADING';
export const SET_PORTAL_GUID = 'SET_PORTAL_GUID';
export const SET_SEARCH_QUEUE = 'SET_SEARCH_QUEUE';
export const CHANGE_NAME_FILTER = 'CHANGE_NAME_FILTER';
export const CHANGE_TYPE_FILTER = 'CHANGE_TYPE_FILTER';
export const CHANGE_DATE_FILTER = 'CHANGE_DATE_FILTER';
export const SET_AVAILABLE_REPORTS = 'SET_AVAILABLE_REPORTS';

export const TYPE_DEBOUNCE_TIME = 0;
export const CLEAR_DEBOUNCE_TIME = 0;
export const DATE_DEBOUNCE_TIME = 250;
export const NAME_DEBOUNCE_TIME = 300;

// Create an inclusive date range from two dates (start 00:00 -- end 23:59:59) and convert to unix time
function inclusiveUTCDateRange(start, end = null) {
  if (start) {
    start = start.toDate();
  }

  // Set the time of the first date to 00:00 in the user's timezone
  start.setHours(0, 0, 0, 0);

  const endOfFirstDay = new Date(start);
  endOfFirstDay.setHours(23, 59, 59, 999);

  const startDate = start.getTime();

  let endDate = end;
  if (endDate == null) {
    endDate = endOfFirstDay.getTime();
  } else {
    endDate = endDate.toDate();
    // Inclusive end
    endDate.setHours(23, 59, 59, 999);
    endDate = endDate.getTime();
  }

  return [startDate, endDate];
}

const buildQuery = (filters) => {
  const filtersObject = {
    types: [],
  };

  Object.keys(filters.types).forEach((type) => {
    if (filters.types[type]) {
      filtersObject.types.push(type);
    }
  });

  if (filters.dateRange[0] !== null || filters.dateRange[1] !== null) {
    const [startDate, endDate] = inclusiveUTCDateRange(filters.dateRange[0], filters.dateRange[1]);

    filtersObject.dateFrom = startDate;
    filtersObject.dateTo = endDate;
  }

  if (filters.name) {
    const cleanedBits = escapeRegExp(filters.name).trim();
    filtersObject.name = cleanedBits;
  }

  return filtersObject;
};

export function fetchRuns(filters) {
  return async (dispatch, getState) => {
    try {
      const now = Date.now(); // Timestamp of when we start the search
      const { portalGuid } = getState().dashboard.portal;

      const runs = await portalApi.queryPublicRuns(portalGuid, buildQuery(filters));

      let { hasInitialData } = getState().dashboard.portal;
      const { loadTimestamp } = getState().dashboard.portal;

      // If this request is old then ignore it.
      if (now < loadTimestamp) {
        return;
      }

      if (loadTimestamp === 0) {
        hasInitialData = runs.length > 0;
      } else if (getState().dashboard.portal.runs.length === 0 && runs.length > 0) {
        hasInitialData = true;
      }

      dispatch({
        type: LOAD_RUNS,
        runs: runs, // The runs, duh.
        timestamp: now, // The timestamp of this search.
        hasInitialData: loadTimestamp === 0 ? runs.length > 0 : hasInitialData, // If the timestamp is zero this is the first search, so set initial data flags here.
      });
    } catch (ex) {
      console.error('could not run fetch', ex);
    }
  };
}

export function queueSearch(debounce = 0) {
  return async (dispatch, getState) => {
    const { filters } = getState().dashboard.portal;
    let { searchQueued } = getState().dashboard.portal;

    // If we have a pending search, clear it.
    if (searchQueued) {
      clearTimeout(searchQueued);
    }

    // Create a new search callback
    const search = async () => {
      // Get search info
      dispatch(startLoading());
      await dispatch(fetchRuns(filters));
      dispatch(endLoading());

      // If another search hasn't been queued since this one, then clear the search queue indicator.
      const currentQueuedSearch = getState().dashboard.portal.searchQueued;
      if (searchQueued === currentQueuedSearch) {
        dispatch({
          type: SET_SEARCH_QUEUE,
          searchQueued: false,
        });
      }
    };

    // Debounce the search
    searchQueued = setTimeout(search, debounce);

    // Queue new search.
    dispatch({
      type: SET_SEARCH_QUEUE,
      searchQueued: searchQueued,
    });
  };
}

export function removeRun(run) {
  return (dispatch, getState) => {
    const runs = [...getState().dashboard.portal.runs];
    const runIndex = runs.findIndex((r) => r.guid === run.guid);

    if (runIndex >= 0) {
      runs.splice(runIndex, 1);
      dispatch({
        type: UPDATE_RUNS,
        runs: runs,
      });
    }
  };
}

export function shareRun(run) {
  return async (dispatch, getState) => {
    const runs = [...getState().dashboard.portal.runs];
    const newRun = { ...run, working: true };
    const runIndex = runs.indexOf(run);

    if (runIndex >= 0) {
      runs.splice(runIndex, 1, newRun);

      dispatch({
        type: UPDATE_RUNS,
        runs: runs,
      });

      await objectStoreApi.reportRun.update(run.guid, {
        published: false,
      });

      dispatch(removeRun(newRun));
    }
  };
}

export function clearFilters() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FILTERS,
    });
    dispatch(queueSearch(CLEAR_DEBOUNCE_TIME));
  };
}

export function changeTypeFilter(which, value) {
  return (dispatch, getState) => {
    const initialFilters = getState().dashboard.portal.filters;
    dispatch({
      type: CHANGE_TYPE_FILTER,
      which: which,
      value: value,
    });
    const finalFilters = getState().dashboard.portal.filters;
    if (!isEqual(initialFilters, finalFilters)) {
      dispatch(queueSearch(TYPE_DEBOUNCE_TIME));
    }
  };
}

export function changeDateFilter(value) {
  return (dispatch, getState) => {
    const initialFilters = getState().dashboard.portal.filters;
    dispatch({
      type: CHANGE_DATE_FILTER,
      value: value,
    });
    const finalFilters = getState().dashboard.portal.filters;
    if (!isEqual(initialFilters, finalFilters)) {
      dispatch(queueSearch(DATE_DEBOUNCE_TIME));
    }
  };
}

export function changeNameFilter(value) {
  return (dispatch, getState) => {
    const initialFilters = getState().dashboard.portal.filters;
    dispatch({
      type: CHANGE_NAME_FILTER,
      value: value,
    });
    const finalFilters = getState().dashboard.portal.filters;
    if (!isEqual(initialFilters, finalFilters)) {
      dispatch(queueSearch(NAME_DEBOUNCE_TIME));
    }
  };
}

export function startLoading() {
  return (dispatch) => {
    dispatch({
      type: START_LOADING,
    });
  };
}

export function endLoading() {
  return (dispatch) => {
    dispatch({
      type: END_LOADING,
    });
  };
}

export function setPortalGuid(portalGuid) {
  return (dispatch) => {
    dispatch({
      portalGuid: portalGuid,
      type: SET_PORTAL_GUID,
    });
  };
}

export function setAvailableReportTypes(availableReports) {
  return (dispatch) => {
    dispatch({
      availableReports: availableReports,
      type: SET_AVAILABLE_REPORTS,
    });
  };
}
