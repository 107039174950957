import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

/**
 * Create a store for a single boolean value with toggle function.
 * The implementation is similar to {@link useToggle} hook.
 * @param storeName {String} zustand store name.
 * @param initialValue {Boolean} initial toggle value state.
 */
export const toggleValueStoreCreator = (storeName: string, initialValue: boolean = false) =>
  create<{
    value: boolean;
    toggleValue: () => void;
  }>()(
    devtools(
      (set) => ({
        value: initialValue,
        toggleValue: () => set(({ value }) => ({ value: !value })),
      }),
      {
        name: storeName,
      },
    ),
  );

/**
 * Create a store for a single value with setter function.
 * The implementation is similar to {@link useState} React hook.
 * @param storeName {String} zustand store name.
 * @param initialValue {String} initial value.
 */
export const singleValueStoreCreator = <TValue = unknown>(storeName: string, initialValue: TValue) =>
  create<{
    value: TValue;
    setValue: (newValue: TValue) => void;
  }>()(
    devtools(
      (set) => ({
        value: initialValue,
        setValue: (newValue: TValue) => set(() => ({ value: newValue })),
      }),
      {
        name: storeName,
      },
    ),
  );
