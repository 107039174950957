import { PageSet } from '@import-io/web-extractor';
import cloneDeep from 'lodash/cloneDeep';
import type { AnyAction } from 'redux';

import type { RootState } from 'common/common-types';

import {
  ADD_NEW_EXTRACTOR_CONFIG,
  CREATE_STATE,
  CREATING_TRAINING,
  CREATING_TRAINING_FINISHED,
  FOCUS_COLUMN_NAME,
  LOAD_DEFAULT_CONFIG,
  RESET_STATE,
  SET_ACTIVE_EXTRACTOR_CONFIG,
  SET_HAS_SAVED,
  SET_PAGE_SET,
  TOGGLE_DEFAULT_VALUE_PANEL,
  TOGGLE_SCREEN_CAPTURE,
  UNFOCUS_COLUMN_NAME,
  UPDATE_STATE,
  UPDATE_STATE_NO_UNDO,
} from '../actions/builder';
import { BUILD_TRAINING } from '../actions/project';

/**
 * Returns the object used as the initial state.
 * This is the state object used everytime user enters results page.
 * @return {Object} Initial state object.
 */
function getInitialState(): Partial<RootState['lightning']['builder']['present']> {
  return {
    doc: null,
    counterExampleElements: [],
    exampleElements: [],
    pageSet: new PageSet(),
    selectedPage: null,
    selectedField: null,
    data: [],
    cssDisabled: false,
    overlaysEnabled: true,
    activeExtractorConfig: '_runtimeConfig',
    hasSaved: false,
    defaultConfigs: {},
    isDefaultValuePanelVisible: false,
    renamingField: null,
    isCreatingTraining: false,
    isColumnNameFocused: false,
  };
}

export function builderReducer(
  state = getInitialState(),
  action: AnyAction = { type: '' },
): Partial<RootState['lightning']['builder']['present']> {
  const { type, ...newState } = action;
  switch (type) {
    case RESET_STATE:
      return { ...getInitialState(), hasSaved: true };
    case CREATE_STATE:
      return {
        ...getInitialState(),
        doc: state.doc,
        ...newState,
        showSaveModal: true,
        hasSaved: true,
      };
    case UPDATE_STATE:
      return {
        ...state,
        ...newState,
        showSaveModal: true,
        hasSaved: false,
      };
    case UPDATE_STATE_NO_UNDO:
      return { ...state, ...newState, hasSaved: false };
    case SET_ACTIVE_EXTRACTOR_CONFIG:
      return {
        ...state,
        activeExtractorConfig: action.activeExtractorConfig,
      };
    case TOGGLE_SCREEN_CAPTURE:
      const pageSet = cloneDeep(state.pageSet);
      if (pageSet) {
        const newScreenCapture = !pageSet.screenCapture;
        pageSet.screenCapture = newScreenCapture;
        pageSet.screenCaptureType = 'png';
      }
      return {
        ...state,
        pageSet: pageSet,
      };
    case ADD_NEW_EXTRACTOR_CONFIG:
      return { ...state, pageSet: action.pageSet, activeExtractorConfig: action.newConfigName };
    case SET_HAS_SAVED:
      return { ...state, hasSaved: action.hasSaved };
    case LOAD_DEFAULT_CONFIG:
      const defaultConfigs = state.defaultConfigs || {};
      defaultConfigs[state.activeExtractorConfig!] = action.config;
      return {
        ...state,
        defaultConfigs: defaultConfigs,
      };
    case TOGGLE_DEFAULT_VALUE_PANEL:
      return {
        ...state,
        isDefaultValuePanelVisible: action.isDefaultValuePanelVisible,
        selectedField: action.selectedField,
      };
    case BUILD_TRAINING:
      return { ...state, renamingField: null };
    case CREATING_TRAINING:
      return {
        ...state,
        isCreatingTraining: true,
      };
    case CREATING_TRAINING_FINISHED:
      return {
        ...state,
        isCreatingTraining: false,
      };
    case SET_PAGE_SET:
      return {
        ...state,
        pageSet: action.pageSet,
      };
    case FOCUS_COLUMN_NAME:
      return {
        ...state,
        isColumnNameFocused: true,
      };
    case UNFOCUS_COLUMN_NAME:
      return {
        ...state,
        isColumnNameFocused: false,
      };

    default:
      return state;
  }
}
