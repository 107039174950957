import { endpoints } from '@import-io/js-sdk';
import { isPresent, isUrl } from '@import-io/typeguards';
import type { ProxyType } from '@import-io/types';
import isRegExp from 'lodash/isRegExp';

import { TROUBLESOME_DOMAINS } from 'app/lightning-old/lib/lightningConstants';
import * as CONSTANTS from 'features/extractors/extractors-constants';
import type { ExtractionType } from 'features/extractors/forms/extractors-form-types';
import { getIso3Country } from 'features/extractors/forms/new-extractor-form/new-extractor-form-utils';

import { DEFAULT_EXTRACTOR_COUNTRY_CODE, DEFAULT_EXTRACTOR_PROXY_TYPE } from '../constants/site-constants';

const optDelimiter = '#[!opt!]';

function stripSessionIds(href: string): string {
  return href
    .replace(/(?:(\?)|[&;])(_?(l|j|bv_)?(phpsessid|sessionid)=.*?)([?#&;]|$)/i, '$1$5')
    .replace('?&', '?')
    .replace('?#', '#')
    .replace(/\?$/, '');
}

export const getProtocol = (url: string = ''): string => (url.search(/^https\:\/\//) !== -1 ? 'https://' : 'http://');

export function sanitizeUrl(url: string = ''): string | undefined {
  try {
    const protocol = getProtocol(url);
    let noProtocolUrl = url.replace(/.*?:\/\//, '');
    const splitBy = noProtocolUrl.indexOf(optDelimiter);
    let optQuery = '';
    if (splitBy >= 0) {
      optQuery = noProtocolUrl.substring(splitBy);
      noProtocolUrl = noProtocolUrl.substring(0, splitBy);
    }
    const noSpacesUrl = noProtocolUrl.replace(/\s+/g, '');
    const encodedUrl = new URL(protocol + noSpacesUrl);
    const sanitizedUrl = stripSessionIds(encodedUrl.href) + optQuery;
    return isUrl(sanitizedUrl) ? sanitizedUrl : undefined;
  } catch (e) {
    console.error(`Error parsing URL: ${e.message}`);
  }
}

export function getUrlsList(listName: string): any[] {
  if (CONSTANTS[listName] && Array.isArray(CONSTANTS[listName])) {
    return CONSTANTS[listName];
  }

  return [];
}

export function validateUrl(url: string, listName: string) {
  const urlPatternsList = getUrlsList(listName);

  return urlPatternsList.find((urlPattern) => {
    if (isRegExp(urlPattern)) {
      return urlPattern.test(url);
    } else if (isRegExp(urlPattern.regex)) {
      return urlPattern.regex.test(url);
    }
  });
}

export function getFormattedName(url: string): string {
  const { hostname } = new URL(url);
  const formattedName = (hostname || 'new extractor').replace(/^www./, '');
  return formattedName;
}

export function getCapitalizedString(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFormattedNameCapitalized(url: string): string {
  const { hostname } = new URL(url);
  let formattedName = (hostname || 'new extractor').replace(/^www./, '');
  formattedName = getCapitalizedString(formattedName);
  return formattedName;
}

export function getUrlDomainName(url: string): string {
  try {
    const { hostname } = new URL(url);
    const domainName = hostname.replace(/^www./, '');
    return domainName;
  } catch (e) {
    return '';
  }
}

export function canReplaceAssetsForUrl(url: string): boolean {
  return !TROUBLESOME_DOMAINS.includes(getUrlDomainName(url));
}

export const generateGeoQueryString = (proxyType: ProxyType, iso3Country: string): string => {
  return `&proxyType=${proxyType}&country=${iso3Country}`;
};

export const generatePath = (input: {
  url: string;
  queryString?: string;
  selectedViewTab?: string;
  isBrowseMode?: boolean;
  type?: string;
  builderVersion?: 'new' | 'legacy';
}): string => {
  const { url, queryString, selectedViewTab, isBrowseMode, type, builderVersion } = input;

  let path = '';
  if (url) {
    if (builderVersion === 'new') {
      path = `/v2/browse/new?url=${encodeURIComponent(sanitizeUrl(url)!)}`;
    } else {
      if (selectedViewTab === 'record-view' || isBrowseMode) {
        path = `/browse?type=${type}&url=${encodeURIComponent(sanitizeUrl(url)!)}`;
      } else {
        path = `/results?url=${encodeURIComponent(url)}`;
      }
    }
    if (queryString) path += queryString;
  }
  return path;
};

export const getExtractorBuilderUrl = (input: {
  extractionUrl: string;
  extractionType: ExtractionType;
  authUrl?: string;
  proxyCountry?: string;
  proxyType?: ProxyType;
  builderVersion?: 'new' | 'legacy';
  availableCountryIso2Codes?: string[];
}): string => {
  const {
    availableCountryIso2Codes,
    extractionUrl,
    extractionType,
    authUrl,
    proxyCountry = DEFAULT_EXTRACTOR_COUNTRY_CODE,
    proxyType = DEFAULT_EXTRACTOR_PROXY_TYPE,
    builderVersion,
  } = input;
  const sanitizedExtractionUrl = sanitizeUrl(extractionUrl) ?? '';
  const sanitizedAuthUrl = isPresent(authUrl) ? sanitizeUrl(authUrl) ?? '' : '';
  // for auth extractor we navigate to login page first
  const initialUrl = sanitizedAuthUrl || sanitizedExtractionUrl;
  const iso3Country = getIso3Country(initialUrl, proxyCountry, availableCountryIso2Codes);
  const queryString = generateGeoQueryString(proxyType, iso3Country);

  return generatePath({
    isBrowseMode: true,
    queryString: queryString,
    type: extractionType,
    url: initialUrl,
    builderVersion: builderVersion,
  });
};

export const getLoginUrl = (): string => `${window.location.protocol}//enter.${endpoints.rootDomain}`;

export const getSearchParams = <TParams extends Record<string, string | undefined> = Record<string, string | undefined>>(
  search = window.location.search,
): TParams => Object.fromEntries(new URLSearchParams(search)) as unknown as TParams;

export const isURLSecure = (url: string): boolean => {
  if (!isPresent(url)) {
    return false;
  }

  try {
    const parsedURL = new URL(url);
    return parsedURL.protocol === 'https:';
  } catch (e) {
    return false;
  }
};
