import type { JSX } from 'react';
import { useContext } from 'react';

import { PlanType } from '@import-io/types/user-subscription-types';
import type { ButtonProps } from 'antd/lib/button/button';

import CustomButton from 'common/components/buttons/CustomButton';
import type { SubscriptionModalContextValue } from 'features/user/subscription/modal';
import { SubscriptionModalContext } from 'features/user/subscription/modal';
import { VALID_PLANS_KEYS } from 'features/user/subscription/subscription-constants';
import { useFetchSubscription } from 'features/user/subscription/subscription-hooks';
import { canUpgrade } from 'features/user/subscription/subscription-utils';

const SubscriptionUpdateButton = ({
  title = 'Upgrade',
  type = 'primary',
  ...restProps
}: Omit<ButtonProps, 'onClick'>): JSX.Element | null => {
  const { showUpgradeModal } = useContext<SubscriptionModalContextValue>(SubscriptionModalContext);
  const { data: subscription } = useFetchSubscription();
  const { maxUsage = 0, planCode = PlanType.UNVERIFIED, usage = 0 } = subscription || {};
  // modal can be used only with valid plan codes from PlanType enum
  const isVisible = VALID_PLANS_KEYS.includes(planCode as PlanType) && (maxUsage - usage <= 0 || canUpgrade(planCode));
  return isVisible ? (
    <CustomButton onClick={showUpgradeModal} type={type} {...restProps}>
      {title}
    </CustomButton>
  ) : null;
};

export default SubscriptionUpdateButton;
