import { useCallback, useMemo } from 'react';

import type { ResponseError } from '@import-io/js-sdk';
import { isGuid, isNotEmptyString, isPresent } from '@import-io/typeguards';
import type { ExtractorField } from '@import-io/types';
import type { Report } from '@import-io/types/report-types';
import { ReportType } from '@import-io/types/report-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FormInstance } from 'antd/lib/form';
import Form from 'antd/lib/form';
import message from 'antd/lib/message';

import { history } from 'app/app-history';
import { REPORT_TYPES } from 'app/dash-old/lib/constants/reports';
import type { SelectOption } from 'common/common-types';
import { toggleValueStoreCreator } from 'common/utils/store-utils';
import { useExtractorsQuery } from 'features/extractors/hooks/use-extractors-query';
import { createReportApi } from 'features/reports/common/api/create-report-api';
import { GET_REPORT_QUERY_KEY, REPORT_NAMES } from 'features/reports/common/reports-constants';
import { getReportSettingsUrl } from 'features/reports/common/reports-utils';
import type {
  CreateReportFormValues,
  ReportTypeOption,
  UsePrimary,
  UseReportExtractors,
  UseReportForm,
  UseStepsDisabled,
} from 'features/reports/forms/create/create-report-form-types';
import { ReportFields } from 'features/reports/forms/create/create-report-form-types';
import { REPORT_LIST_QUERY_KEY } from 'features/reports/list/report-list-constants';
import { selectSubscriptionQueryData } from 'features/user/subscription/subscription-query';

export const useCreateReportModalStore = toggleValueStoreCreator('create-report-modal-store');

export const useReportTypeOptions = (): ReportTypeOption[] =>
  useMemo<ReportTypeOption[]>(() => {
    const subscription = selectSubscriptionQueryData();
    const subscriptionFeatures = subscription?.features ?? [];

    return REPORT_TYPES.filter(({ features }) => subscriptionFeatures.includes(features.permission)).map(
      ({ features, slug }) => ({
        label: features.name,
        value: slug,
      }),
    );
  }, []);

export const useReportExtractors = (): UseReportExtractors => {
  const { data: extractors = [], isFetched } = useExtractorsQuery({ enabled: true });

  const extractorOptions = useMemo(() => {
    return extractors.map(({ guid, name }) => ({
      label: name,
      value: guid,
    }));
  }, [extractors]);

  return {
    extractorOptions: extractorOptions,
    hasReceivedExtractors: isFetched,
  };
};

export const useReportForm = (): UseReportForm => {
  const { toggleValue } = useCreateReportModalStore();
  const [form] = Form.useForm<CreateReportFormValues>();
  const { getFieldValue, setFieldValue } = form;

  const onTypeChange = useCallback(
    (value: ReportType | ReportType[]) => {
      if (value === ReportType.CRAWL_REPORT) {
        const primaryId = getFieldValue(ReportFields.PRIMARY_ID);
        if (isPresent(primaryId)) {
          setFieldValue(ReportFields.PRIMARY_ID, null);
        }
      }
      setFieldValue(ReportFields.REPORT_TYPE, value);
    },
    [getFieldValue, setFieldValue],
  );

  const onExtractorChange = useCallback(
    (value: string, selectOption: SelectOption | SelectOption[]) => {
      const label = Array.isArray(selectOption) ? selectOption[0]?.label : selectOption?.label;
      setFieldValue(ReportFields.EXTRACTOR_ID, value);
      const name = getFieldValue(ReportFields.NAME);
      const reportType = getFieldValue(ReportFields.REPORT_TYPE);
      if (!isPresent(name)) {
        const newName = `${label} ${REPORT_NAMES[reportType]}`;
        setFieldValue(ReportFields.NAME, newName);
      }
    },
    [getFieldValue, setFieldValue],
  );

  const queryClient = useQueryClient();
  const { isPending, mutate: onFinish } = useMutation<Report, ResponseError, CreateReportFormValues>({
    mutationFn: createReportApi,
    onSuccess: (report) => {
      void message.success('Report successfully created');
      toggleValue();
      const reports = queryClient.getQueryData<Report[]>(REPORT_LIST_QUERY_KEY) || [];
      const newReports = [report, ...reports];
      queryClient.setQueryData(REPORT_LIST_QUERY_KEY, newReports);
      queryClient.setQueryData([GET_REPORT_QUERY_KEY, report.guid], report);
      history.push(getReportSettingsUrl(report.guid));
    },
    onError: (e) => {
      void message.error('Create report error');
      console.log('Create report error:', e);
    },
  });

  return {
    form: form,
    isSaving: isPending,
    onExtractorChange: onExtractorChange,
    onFinish: onFinish,
    onTypeChange: onTypeChange,
  };
};

export const useFieldsValues = (form: FormInstance<CreateReportFormValues>): ExtractorField[] =>
  Form.useWatch<ExtractorField[]>(ReportFields.FIELDS, form) ?? [];

export const usePrimary = (form: FormInstance<CreateReportFormValues>): UsePrimary => {
  const reportType = Form.useWatch<string>(ReportFields.REPORT_TYPE, form);
  const primaryId = Form.useWatch<string>(ReportFields.PRIMARY_ID, form);
  const show = reportType === ReportType.CRAWL_DIFF && isPresent(primaryId);
  const fieldsValues = useFieldsValues(form);

  const options = useMemo<SelectOption[]>(
    () => fieldsValues.map((field) => ({ label: field.name, value: field.id })),
    [fieldsValues],
  );

  return {
    id: primaryId,
    options: options,
    show: show,
  };
};

export const useStepsDisabled = (form: FormInstance<CreateReportFormValues>): UseStepsDisabled => {
  const extractorId = Form.useWatch(ReportFields.EXTRACTOR_ID, form);
  const name = Form.useWatch(ReportFields.NAME, form);
  const fieldsValues = useFieldsValues(form);
  const nextDisabled = !isGuid(extractorId) || !isNotEmptyString(name);
  const submitDisabled = nextDisabled || fieldsValues.length === 0 || fieldsValues.some((field) => !Object.keys(field).length);

  return {
    nextDisabled: nextDisabled,
    submitDisabled: submitDisabled,
  };
};
