import type { AnyAction } from 'redux';

import {
  FETCHING_TIME_SPAN_CRAWL_DATA,
  OVERVIEW_TIME_SPAN_CRAWL_DATA_LOADED,
  SET_OVERVIEW_TIME_SPAN,
} from 'features/overview/overview-actions';
import { DEFAULT_TIME_SPAN } from 'features/overview/overview-constants';
import type { OverviewState } from 'features/overview/overview-types';

const initialState: OverviewState = {
  hasReceivedTimeSpanCrawlData: false,
  isFetchingTimeSpanCrawlData: false,
  timeSpan: DEFAULT_TIME_SPAN,
  timeSpanCrawlData: [],
};

export const overviewReducers = (state = initialState, action: AnyAction = { type: '' }): OverviewState => {
  switch (action.type) {
    case OVERVIEW_TIME_SPAN_CRAWL_DATA_LOADED:
      return {
        ...state,
        timeSpanCrawlData: action.data,
        hasReceivedTimeSpanCrawlData: true,
        isFetchingTimeSpanCrawlData: false,
      };
    case FETCHING_TIME_SPAN_CRAWL_DATA:
      return {
        ...state,
        isFetchingTimeSpanCrawlData: true,
      };
    case SET_OVERVIEW_TIME_SPAN:
      return {
        ...state,
        timeSpan: action.timeSpan,
      };
    default:
      return state;
  }
};
