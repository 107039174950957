import { history } from 'app/app-history';
import { revalidatingAuth, setAuthStatus, setCredentials, setVirtualBrowserId } from 'app/lightning-old/actions/interactions';

// TODO: this action requires further refactoring  virtualBrowserId seems always empty
export function setCredentialsAndEdit(credentialsInput: any, extractorGuid: string, virtualBrowserId: string = '') {
  return (dispatch) => {
    if (virtualBrowserId) {
      dispatch(setAuthStatus('authenticated'));
      dispatch(setCredentials(credentialsInput));
      dispatch(setVirtualBrowserId({ virtualBrowserId: virtualBrowserId }));
    } else {
      dispatch(revalidatingAuth(credentialsInput));
    }
    history.push(`/${virtualBrowserId ? 'results' : 'browse'}?extractorGuid=${extractorGuid}`);
  };
}
