import type { ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { Extractor } from '@import-io/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

import { fetchFirstExtractorApi } from 'features/extractors/api/extractor-list-api';
import { GET_EXTRACTOR_QUERY_KEY } from 'features/extractors/extractors-constants';

export const useFetchFirstExtractor = (): UseQueryResult<Extractor | null | undefined, ResponseError> => {
  const queryClient = useQueryClient();
  return useQuery<Extractor | null | undefined, ResponseError>({
    queryKey: [GET_EXTRACTOR_QUERY_KEY],
    queryFn: async () => {
      try {
        const data = await fetchFirstExtractorApi();
        if (isPresent(data)) {
          queryClient.setQueryData([GET_EXTRACTOR_QUERY_KEY, data.guid], data);
        }
        return data;
      } catch (error) {
        console.log('Fetch extractor error:', error);
      }
    },
    refetchOnMount: true,
  });
};
