import type { ExtractionOutput } from '@import-io/types';

import type {
  BuilderState,
  InteractionsState,
  LightningState,
  PresentBuilderState,
  ProjectState,
  UiState,
} from 'app/lightning-old/lightning-types';
import type { RootState } from 'common/common-types';

export const selectLightningState = (state: RootState): LightningState => state.lightning;

export const selectLightningInteractionsState = (state: RootState): InteractionsState => selectLightningState(state).interactions;

export const selectLightningUiState = (state: RootState): UiState => selectLightningState(state).ui;

export const selectLightningProjectState = (state: RootState): ProjectState => selectLightningState(state).project;

export const selectLightningBuilderState = (state: RootState): BuilderState => selectLightningState(state).builder;

export const selectLightningBuilderPresentState = (state: RootState): PresentBuilderState =>
  selectLightningBuilderState(state).present;

export const selectLightningBuilderPastState = (state: RootState): PresentBuilderState[] =>
  selectLightningBuilderState(state).past;

export const selectLightningBuilderFutureState = (state: RootState): PresentBuilderState[] =>
  selectLightningBuilderState(state).future;

export const selectShowProcessingPageError = (state: RootState): boolean =>
  selectLightningInteractionsState(state).showProcessingPageError;

export const selectExtractionType = (state: RootState): string => selectLightningInteractionsState(state).extractionType;

export const selectActionList = (state: RootState) => selectLightningInteractionsState(state).actionList;

export const selectCurrentInput = (state: RootState) => selectLightningInteractionsState(state).currentInput;

export const selectIsCopyingActions = (state: RootState) => selectLightningInteractionsState(state).isCopyingActions;

export const selectIsLoadingCopyActions = (state: RootState) => selectLightningInteractionsState(state).isLoadingCopyActions;

export const selectAuthCopyData = (state: RootState) => selectLightningInteractionsState(state).authCopyData;

export const selectIsInteractionCopyModalOpen = (state: RootState) => selectLightningUiState(state).isInteractionCopyModalOpen;

export const selectIncompatibleUrl = (state: RootState): boolean => selectLightningUiState(state).incompatibleUrl;

export const selectWrongUrl = (state: RootState): boolean => selectLightningUiState(state).wrongUrl;

export const selectIsLoading = (state: RootState): boolean => selectLightningUiState(state).isLoading;

export const selectLoadingDidFail = (state: RootState): boolean => selectLightningUiState(state).loadingDidFail;

export const selectIsManageUrlsModalVisible = (state: RootState): boolean =>
  selectLightningUiState(state).isManageUrlsModalVisible;

export const selectIsManagePagesModal = (state: RootState): boolean => selectLightningUiState(state).isManagePagesModal;

export const selectSelectedViewTab = (state: RootState): string => selectLightningUiState(state).selectedViewTab;

export const selectNewUrlSuccessful = (state: RootState): boolean => selectLightningProjectState(state).newUrlSuccessful;

export const selectNewUrlNoData = (state: RootState): boolean => selectLightningProjectState(state).newUrlNoData;

export const selectNewUrlLoading = (state: RootState): boolean => selectLightningProjectState(state).newUrlLoading;

export const selectNewUrlError = (state: RootState): boolean => selectLightningProjectState(state).newUrlError;

export const selectHasInteraction = (state: RootState): boolean => selectLightningProjectState(state).hasInteraction;

export const selectIsParentTriggered = (state: RootState) => selectLightningProjectState(state).isParentTriggered;

export const selectScreenCapture = (state: RootState) => selectLightningProjectState(state).screenCapture;

export const selectRefreshingPageId = (state: RootState): string | undefined =>
  selectLightningProjectState(state).refreshingPageId;

export const selectIsNewProject = (state: RootState): boolean => selectLightningProjectState(state).isNewProject;

export const selectNotifyMe = (state: RootState) => selectLightningProjectState(state).notifyMe;

export const selectSelectedPage = (state: RootState) => selectLightningBuilderPresentState(state).selectedPage;

export const selectSelectedField = (state: RootState) => selectLightningBuilderPresentState(state).selectedField;

export const selectData = (state: RootState): ExtractionOutput => selectLightningBuilderPresentState(state).data;

export const selectActiveExtractorConfig = (state: RootState): string =>
  selectLightningBuilderPresentState(state).activeExtractorConfig;
