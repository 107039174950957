/* eslint-disable redux-saga/no-unhandled-errors */
import { put, takeLeading } from 'redux-saga/effects';

import { CHECK_FOR_LIST_OR_DETAILS_PAGE, checkedForListOrDetailsPage } from '../actions/ui';

function* checkForListOrDetailsPage() {
  yield put(checkedForListOrDetailsPage());
}

// eslint-disable-next-line import/no-default-export
export default [takeLeading(CHECK_FOR_LIST_OR_DETAILS_PAGE, checkForListOrDetailsPage)];
