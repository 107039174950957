import type { FormEvent, RefObject } from 'react';

import type { SubscriptionPlan } from '@import-io/types';
import type { PlanType } from '@import-io/types/user-subscription-types';
import type { CardElementChangeEvent } from '@recurly/react-recurly';
import type { FormInstance } from 'antd/lib/form';

// TODO: move to frontend mono repository
export interface Plan extends Partial<SubscriptionPlan> {
  description: string[];
  disabled?: boolean;
  label: string;
  price: string;
  queries: number;
  subtitle?: string;
  topFeatures: string[];
  value: PlanType;
}

export type ValidPlanType = PlanType.TRIAL | PlanType.STARTER | PlanType.STANDARD | PlanType.ADVANCED | PlanType.DEACTIVATED;

interface RecurlyCardField {
  empty: boolean;
  focus: boolean;
  valid: boolean;
}

interface RecurlyCard extends RecurlyCardField {
  brand: string;
  cvv: RecurlyCardField;
  expiry: RecurlyCardField;
  firstSix: string;
  lastFour: string;
  number: RecurlyCardField;
}

export interface SubscriptionUpgradeForm {
  card: RecurlyCard;
  firstName: string;
  lastName: string;
  planCode: PlanType;
}

export interface UseSubmitForm {
  form: FormInstance<SubscriptionUpgradeForm>;
  formRef: RefObject<HTMLFormElement>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export type PlanChangeHandler = (value: PlanType) => void;

export interface UseFinish {
  isLoading: boolean;
  onFinish: (values: SubscriptionUpgradeForm) => void;
}

export interface WithSetField<TValue> {
  setFieldValue: (name: string, value: TValue) => void;
}

export interface UsePlanTypeProps extends WithSetField<PlanType> {
  initialValue: PlanType;
  upgrade: boolean;
}

export interface UsePlanType {
  onChange: PlanChangeHandler;
  planType: PlanType;
  selectableTypes: PlanType[];
}

export interface CardInputProps {
  className: string;
  onChange: (value: CardElementChangeEvent) => void;
  onValidChange: (isValid: boolean) => void;
}

export interface UpdateSubscriptionParams {
  planCode: PlanType;
  recurlyToken?: string;
}

export enum RecurlyErrorCategory {
  SOFT = 'SOFT',
}

export enum RecurlyErrorCode {
  DECLINED = 'DECLINED',
}

export enum RecurlyErrorStatus {
  ERROR = 'ERROR',
}

export interface SubscriptionErrorBody {
  error?: string;
  message?: string;
  recurlyError?: {
    customerMessage: string;
    errorCategory: RecurlyErrorCategory;
    errorCode: RecurlyErrorCode;
    status: RecurlyErrorStatus;
  };
}

export enum SubscriptionMethod {
  CANCEL = 'cancel',
  GET = 'get',
  UPDATE = 'update',
}

export interface SubscriptionFeatureFlags {
  canAdvancedExtract: boolean;
  canAuthenticateExtractors: boolean;
  canCreateDataReport: boolean;
  canCreateDiffReport: boolean;
  canDownloadContent: boolean;
  canDownloadData: boolean;
  canPublishToPortal: boolean;
  canRecordInteraction: boolean;
  canScheduleRuns: boolean;
  canSolveCaptchas: boolean;
  canTagExtractors: boolean;
  canUseAnyReport: boolean;
  canUseApis: boolean;
  canUseCodeActions: boolean;
  canUseExtractorWebhooks: boolean;
  canUseGeo: boolean;
  canUseHtmlExtraction: boolean;
  canUseScreenCapture: boolean;
  hasAdvancedFeatures: boolean;
  orgManagement: boolean;
}
