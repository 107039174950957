import { objectStoreApi } from '@import-io/js-sdk';
import type { ExtractorField } from '@import-io/types/extractor-types';
import type {
  BaseReportConfig,
  DataReportColumn,
  DataReportConfig,
  DiffReportColumn,
  DiffReportConfig,
  Report,
} from '@import-io/types/report-types';
import { ReportType } from '@import-io/types/report-types';

import type { CreateReportFormValues } from 'features/reports/forms/create/create-report-form-types';

const getBaseConfig = (extractorId: string, type: ReportType) => ({
  extractorId: extractorId,
  type: type,
});

const primaryFieldAdapter = ({ captureLink, id, name, type }: ExtractorField): DiffReportColumn => {
  const property = type.toLowerCase();
  return {
    id: id,
    captureLink: captureLink,
    key: `${id}_${property}`,
    name: name,
    displayName: name,
    property: property,
  };
};

const diffFieldAdapter = (field: ExtractorField): DiffReportColumn => ({
  ...primaryFieldAdapter(field),
  diffed: true,
});

const getPrimary = (fields: ExtractorField[], primaryId: string): DiffReportColumn => {
  const primary = fields.find(({ id }) => id === primaryId) as ExtractorField;
  return primaryFieldAdapter(primary);
};

const getDiffColumns = (fields: ExtractorField[], primaryId: string): DiffReportColumn[] =>
  fields.filter(({ id }) => id !== primaryId).map((field) => diffFieldAdapter(field));

const getDiffConfig = (baseConfig: BaseReportConfig, fields: ExtractorField[], primaryId: string): DiffReportConfig => ({
  ...baseConfig,
  columns: getDiffColumns(fields, primaryId),
  primaryKey: getPrimary(fields, primaryId),
});

const getDataColumns = (fields: ExtractorField[], extractorId: string): DataReportColumn[] =>
  fields.map(({ id, name, type }, index) => ({
    fields: [
      {
        columnId: id,
        extractorId: extractorId,
        name: name,
        property: type.toLowerCase(),
      },
    ],
    primaryKey: index === 0,
    reportFieldName: name,
  }));

const getDataConfig = ({ email, extractorId, type }: BaseReportConfig, fields: ExtractorField[]): DataReportConfig => ({
  columns: getDataColumns(fields, extractorId),
  email: email,
  extractorId: extractorId,
  extractors: [
    {
      id: extractorId,
      master: true,
    },
  ],
  match: {
    type: 'EXACT',
  },
  type: type,
});

export const createReportApi = async ({
  extractorId,
  fields,
  name,
  primaryId,
  type,
}: CreateReportFormValues): Promise<Report> => {
  const isDiffReport = type === ReportType.CRAWL_DIFF;
  const baseConfig = getBaseConfig(extractorId as string, type as ReportType);
  const config = await objectStoreApi.reportConfiguration.create({
    config: isDiffReport ? getDiffConfig(baseConfig, fields, primaryId as string) : getDataConfig(baseConfig, fields),
    extractorId: extractorId,
  });
  const report = await objectStoreApi.report.create({
    extractorId: extractorId,
    latestConfigId: config.guid,
    name: name,
    type: type,
  });

  await objectStoreApi.reportConfiguration.update(config.guid, { reportId: report.guid });

  return report;
};
