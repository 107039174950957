import type { AnyAction } from 'redux';

import type { RootState } from 'common/common-types';

import { EXTRACTOR_LOAD_FAILURE, EXTRACTOR_LOADED } from '../actions/project';
import {
  CHANGE_INPUTS_MODAL_STATUS,
  CHECK_FOR_LIST_OR_DETAILS_PAGE,
  CHECKED_FOR_LIST_OR_DETAILS_PAGE,
  CLEANUP_STATE,
  CLEAR_BAD_URL_STATUS,
  HIDE_CSS_SELECTOR_PANEL,
  HIDE_INTERACTION_COPY_MODAL,
  HIDE_MANAGE_URLS_MODAL,
  HIDE_XPATH_PANEL,
  INCOMPATIBLE_URL,
  NAVIGATION_COMPLETE,
  RESOURCE_RESPONSE_OBTAINED,
  SELECT_VIEW_TAB,
  SET_AUTH_OPTIONS,
  SET_CURRENT_URL,
  SET_DATA,
  SET_HIDDEN_FRAME_DOC,
  SET_INITIAL_URL,
  SET_OPT_TAG,
  SHOW_CSS_SELECTOR_PANEL,
  SHOW_DATA_EXTRACTED,
  SHOW_INTERACTION_COPY_MODAL,
  SHOW_LIST_PRODUCER_LOADING,
  SHOW_MANAGE_URLS_MODAL,
  SHOW_XPATH_PANEL,
  START_LOADING,
  START_NAVIGATION,
  STOP_LOADING,
  TOGGLE_DELETE_MONITORED_COLUMN_MODAL,
  TOGGLE_INTERACTION_SIDEBAR,
  TOGGLE_MANAGE_PAGES_MODAL,
  TOGGLE_REMOVE_CAPTURE_LINK_MODAL,
  TOGGLE_SELECTOR_PANEL,
  WRONG_URL,
} from '../actions/ui';

/**
 * Returns the object used as the initial state.
 * This is the state object used everytime user enters results page.
 * @return {Object} Initial state object.
 */
function getInitialState(): Partial<RootState['lightning']['ui']> {
  return {
    isLoading: true,
    isLoadingResource: true,
    loadingDidFail: false,
    selectedViewTab: 'record-view',
    selectedField: null,
    isXPathPanelVisible: false,
    isCssSelectorPanelVisible: false,
    userSelectedTab: false,
    tabs: [],
    entities: {},
    incompatibleUrl: false,
    incompatibleUrlMessage: '',
    incompatibleUrlTitle: '',
    wrongUrl: false,
    resourceResponse: null,
    isManageUrlsModalVisible: false,
    isInteractionCopyModalOpen: false,
    isListProducerLoading: false,
    isDataExtracted: false,
    currentUrl: '',
    initialUrl: '',
    isNavigating: false,
    hasData: false,
    isRemoveCaptureLinkModalVisible: false,
    isDeleteMonitoredColumnModalVisible: false,
    inputsModal: null,
    optTag: null,
    hiddenFrameDoc: null,
    regionXPath: null,
    isUrlDataFrameOpen: false,
    isSidebarOpen: false,
    hasCheckedForListOrDetailsPage: false,
    isSelectorPanelVisible: false,
    isManagePagesModal: false,
  };
}

/**
 * Main state reducer.
 * @param  {Object} state  State at the moment of receiving an action.
 * @param  {Object} action Action object, containing details of an action.
 * @return {Object}        New state with the action handled.
 */
export function uiReducer(state = getInitialState(), action: AnyAction = { type: '' }) {
  switch (action.type) {
    // Interaction Copy
    case SHOW_INTERACTION_COPY_MODAL:
      return {
        ...state,
        isInteractionCopyModalOpen: true,
      };

    case HIDE_INTERACTION_COPY_MODAL:
      return {
        ...state,
        isInteractionCopyModalOpen: false,
      };

    // User requested to revert the results state to the initial contidion.
    case CLEANUP_STATE:
      return {
        ...getInitialState(),
        currentUrl: state.currentUrl,
        initialUrl: state.initialUrl,
      };

    // User requested a change of view tab.
    case SELECT_VIEW_TAB:
      return {
        ...state,
        isXPathPanelVisible: false,
        isCssSelectorPanelVisible: false,
        selectedViewTab: action.key,
      };

    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case SHOW_MANAGE_URLS_MODAL:
      return {
        ...state,
        isManageUrlsModalVisible: true,
      };

    case HIDE_MANAGE_URLS_MODAL:
      return {
        ...state,
        isManageUrlsModalVisible: false,
      };

    case INCOMPATIBLE_URL:
      return {
        ...state,
        incompatibleUrl: true,
        incompatibleUrlMessage: action.incompatibleUrlMessage,
        incompatibleUrlTitle: action.incompatibleUrlTitle,
      };

    case WRONG_URL:
      return {
        ...state,
        wrongUrl: true,
      };

    case CLEAR_BAD_URL_STATUS:
      return {
        ...state,
        wrongUrl: false,
        incompatibleUrl: false,
        incompatibleUrlMessage: '',
        incompatibleUrlTitle: '',
        isLoading: false,
      };

    case RESOURCE_RESPONSE_OBTAINED:
      return {
        ...state,
        isLoadingResource: false,
        resourceResponse: action.result,
        html: action.result.html,
      };

    case EXTRACTOR_LOADED:
      return {
        ...state,
        isLoadingResource: false,
      };

    case EXTRACTOR_LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingResource: false,
        loadingDidFail: true,
      };

    case SHOW_XPATH_PANEL:
      return {
        ...state,
        isXPathPanelVisible: true,
      };

    case HIDE_XPATH_PANEL:
      return {
        ...state,
        isXPathPanelVisible: false,
      };

    case SHOW_CSS_SELECTOR_PANEL:
      return {
        ...state,
        isCssSelectorPanelVisible: true,
        isFloatingColumnRenameActive: false,
      };

    case HIDE_CSS_SELECTOR_PANEL:
      return {
        ...state,
        isCssSelectorPanelVisible: false,
      };

    case SHOW_LIST_PRODUCER_LOADING:
      return {
        ...state,
        isListProducerLoading: true,
      };

    case SHOW_DATA_EXTRACTED:
      return {
        ...state,
        isListProducerLoading: false,
        isDataExtracted: true,
      };

    case SET_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.url,
      };

    case SET_INITIAL_URL:
      return {
        ...state,
        initialUrl: action.initialUrl,
        currentUrl: action.initialUrl,
      };

    case START_NAVIGATION:
      return {
        ...state,
        isNavigating: true,
      };
    case NAVIGATION_COMPLETE:
      return {
        ...state,
        isNavigating: false,
      };

    case SET_AUTH_OPTIONS:
      return {
        ...state,
        authUrl: action.authUrl,
        loginSessionId: action.loginSessionId,
      };

    case SET_DATA:
      return {
        ...state,
        hasData: action.hasData,
      };

    case TOGGLE_REMOVE_CAPTURE_LINK_MODAL:
      return {
        ...state,
        isRemoveCaptureLinkModalVisible: !state.isRemoveCaptureLinkModalVisible,
      };

    case TOGGLE_DELETE_MONITORED_COLUMN_MODAL:
      return {
        ...state,
        isDeleteMonitoredColumnModalVisible: !state.isDeleteMonitoredColumnModalVisible,
      };

    case CHANGE_INPUTS_MODAL_STATUS:
      return {
        ...state,
        inputsModal: { ...state.inputsModal, ...action.inputsModal },
      };

    case SET_OPT_TAG:
      return {
        ...state,
        optTag: action.optTag,
      };

    case SET_HIDDEN_FRAME_DOC:
      return {
        ...state,
        hiddenFrameDoc: action.hiddenFrameDoc,
      };

    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoadingResource: true,
      };

    case TOGGLE_INTERACTION_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };

    case TOGGLE_SELECTOR_PANEL:
      return {
        ...state,
        isSelectorPanelVisible: action.isSelectorPanelVisible,
        isCssSelectorPanelVisible: action.isSelectorPanelVisible ? state.isCssSelectorPanelVisible : false,
        isXPathPanelVisible: action.isSelectorPanelVisible ? state.isXPathPanelVisible : false,
      };
    case CHECK_FOR_LIST_OR_DETAILS_PAGE:
      return {
        ...state,
        hasCheckedForListOrDetailsPage: true,
      };
    case CHECKED_FOR_LIST_OR_DETAILS_PAGE:
      return {
        ...state,
        hasCheckedForListOrDetailsPage: false,
      };
    case TOGGLE_MANAGE_PAGES_MODAL:
      return {
        ...state,
        isManagePagesModal: action.isManagePagesModal,
      };
    // Unhandled action happened.
    default:
      return state;
  }
}
