/* eslint-disable redux-saga/no-unhandled-errors,@typescript-eslint/no-use-before-define */
import { encryptApi, endpoints, extractorApi, objectStoreApi } from '@import-io/js-sdk';
import { isNotEmptyString, isPresent } from '@import-io/typeguards';
import type { Extractor, RuntimeConfigurationRecordNew } from '@import-io/types';
import { inputToObject, ProxyType, Role, RuntimeConfigurationWrapper } from '@import-io/types';
import type { PageSet } from '@import-io/web-extractor';
import { guessLocaleInformation, Page, selectNodeElements, trainField } from '@import-io/web-extractor';
import message from 'antd/lib/message';
import cloneDeep from 'lodash/cloneDeep';
import { push } from 'redux-first-history';
import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { ActionCreators } from 'redux-undo';
import store from 'store';
import { v4 as uuidv4 } from 'uuid';

import { toggleAutoRun } from 'app/dash-old/actions/extractors';
import type { LightningState } from 'app/lightning-old/lightning-types';
import type { RootState } from 'common/common-types';
import { ONE_HUNDRED } from 'common/constants/common-constants';
import { DEFAULT_EXTRACTOR_COUNTRY_CODE, DEFAULT_EXTRACTOR_PROXY_TYPE } from 'common/constants/site-constants';
import { sendEvent } from 'common/events/events-api';
import { EventType } from 'common/events/events-types';
import { showProgressMessage } from 'common/messages/progress-message';
import { getSearchParams, getUrlDomainName } from 'common/utils/url-utils';
import {
  getPageSetInputs,
  makeExtractDataAction,
  makeRenderAction,
  prepAuthActionsForSave,
  restrictInteractionsAccess,
  separateTrainingInputs,
} from 'features/extractor-builder/interaction/interaction-utils-old';
import { invalidateExtractorListQuery } from 'features/extractors/common/extractor-list-query';
import { getExtractorHistoryUrl } from 'features/extractors/extractors-utils';
import { selectCurrentUser } from 'features/user/auth/user-auth-query';
import { selectSubscriptionFeatureFlags, selectSubscriptionQueryData } from 'features/user/subscription/subscription-query';
import { isActiveSubscription, isVerifiedSubscription } from 'features/user/subscription/subscription-utils';

import {
  addField,
  CREATING_TRAINING_FINISHED,
  resetState as resetBuilderState,
  selectPage,
  setHasSaved,
} from '../actions/builder';
import { saveActions, setExtractionType, setPostAuthUrl, stopExtraction, updateInteractionState } from '../actions/interactions';
import * as projectActions from '../actions/project';
import { CHECKED_FOR_LIST_OR_DETAILS_PAGE, cleanupState, finishLoad, gotoUrl, setOptTag, startLoading } from '../actions/ui';
import { getInteraction, getLightning } from '../reducers/selectors';
import { getFields } from '../selectors/builder';

function* setUpProject() {
  const currentUser = selectCurrentUser();
  const query = getSearchParams();
  const subscription = selectSubscriptionQueryData();

  if (!isPresent(currentUser) || !isPresent(subscription)) {
    return;
  }
  // Check if the subscription is valid. If not we do not need to make an unneccessary request.
  if (!isActiveSubscription(subscription) || !isVerifiedSubscription(subscription)) {
    return;
  }

  if (query.url) {
    // is new extractor
    yield call(setUpNewProject);
  } else if (query.extractorGuid) {
    // is existing extractor
    yield put(startLoading());
    yield put(projectActions.loadExtractor());
  }
}

function* setUpNewProject() {
  const state: RootState = yield select();
  const initialExtractionType = state.lightning.interactions.extractionType;
  const { canUseGeo, canAdvancedExtract, canAuthenticateExtractors, canRecordInteraction } = selectSubscriptionFeatureFlags();
  const { country, dataUrl, proxyType, type, url } = getSearchParams();
  if (!isPresent(url)) {
    return;
  }

  const extractionType = type || initialExtractionType || 'basic';
  const optTagStart = url.indexOf('#[!opt!]');
  const optTagEnd = url.indexOf('[/!opt!]');
  const { roles } = selectCurrentUser();

  restrictInteractionsAccess(canRecordInteraction || extractionType === 'auth');

  yield put(projectActions.setNewProject());
  yield put(projectActions.getExtractorsAndSetProjectName());
  yield put(projectActions.setDefaultSettings());

  // Make sure the proper extraction type is set
  if (extractionType !== initialExtractionType) yield put(setExtractionType(extractionType));
  if (canAuthenticateExtractors && dataUrl && state.lightning.interactions.postAuthUrl !== dataUrl) {
    if (extractionType !== 'auth') {
      yield put(setExtractionType('auth'));
    }
    yield put(setPostAuthUrl(dataUrl as string));
  }

  // set proxy settings
  if (proxyType || country) {
    let setProxy: ProxyType = proxyType as ProxyType;
    const isSupport = roles.includes(Role.SUPPORT);

    // TODO: 'GEO' is some UI hack - there is no such proxy type on BE.
    if ((proxyType === 'GEO' || proxyType === ProxyType.DATA_CENTER) && (canUseGeo || canAdvancedExtract || isSupport)) {
      setProxy = proxyType === 'GEO' ? ProxyType.RESIDENTIAL : ProxyType.DATA_CENTER;
      yield put(projectActions.setProxySettings({ proxyType: setProxy, country: country }));
    } else {
      //Set proxy settings to empty which is the same as default
      yield put(projectActions.setProxySettings({}));
    }
  }

  // if there is an opt tag in the url
  if (optTagStart >= 0 && optTagEnd >= 0) {
    const optTag = url.substr(optTagStart, optTagEnd);
    yield put(setOptTag(optTag));
  }

  yield put(gotoUrl(url));
  const { email, guid } = selectCurrentUser();
  const subscription = selectSubscriptionQueryData();
  const basePlanCode = subscription?.basePlanCode ?? null;
  if (isPresent(basePlanCode)) {
    yield call(sendEvent, {
      type: EventType.EXTRACTOR_BUILDING,
      data: {
        user: {
          id: guid,
          email: email,
          planCode: basePlanCode,
        },
        extractor: {
          id: '',
          url: url,
          domainName: getUrlDomainName(url),
        },
      },
    });
  }
  // Ultimately once this url is validated we'll begin initial navigation.
}

function* buildTraining() {
  const state: LightningState = yield select(getLightning);
  const { resourceResponse } = state.ui;
  const {
    interactionPages,
    currentInput,
    inputs,
    isExtracting,
    suggestDataDuringExtraction,
    isSuggestingData,
    replayingForInput,
  } = state.interactions;
  const { existingProject } = state.project;
  const { defaultConfigs, isCreatingTraining } = state.builder.present;
  const input = inputToObject(cloneDeep(currentInput));
  const url = state.interactions.currentUrl || resourceResponse?.url;
  const html = state.interactions.html || resourceResponse?.html;
  const timestamp = state.interactions.html ? state.interactions.htmlTimestamp : resourceResponse?.timestamp;

  let pageSet: PageSet = state.builder.present.pageSet;

  try {
    // Create the pageset for the project,
    // if we are suggesting data or there is a defaultConfigs object we will use the default config that was
    // generated during suggest
    const useDefaultConfig = (isSuggestingData || suggestDataDuringExtraction) && Object.keys(defaultConfigs).length;

    // Add all pages that were captured during the playback. These are captured when each ExtractDataAction is played
    // If interactionPages is empty it means we must use the default page (which is the "html" variable)
    if (isExtracting) {
      interactionPages.forEach((page, idx) => {
        // This is a bit weird but because we passed interactions.html into the auto suggest
        // If useDefaultConfig is true then the first page on interactionPages already exists on the pageSet
        if (!useDefaultConfig || (useDefaultConfig && (idx > 0 || !pageSet.pages.length))) {
          pageSet.addPage(page);
        }
      });
    }

    if (!pageSet.pages.length) {
      pageSet.addPage(
        new Page({
          url: url,
          html: html,
          timestamp: timestamp,
          ...(input ? { input: input } : {}),
        }),
      );
    }

    // Select the default page, if we are replayingForInput it means we are refreshing the html so take last page
    const selectedPage = replayingForInput ? pageSet.pages[pageSet.pages.length - 1]! : pageSet.pages[0]!;
    // Add default HTML if it is not on the selected page.
    // This can happen if we are suggesting and using the defaultConfig,
    // Since the default config could come from somewhere like britannica as a saved config, we must attach the html
    if (!selectedPage.html && html) {
      selectedPage.html = html;
    }

    // Guess the locale information
    if (!pageSet.locale || !pageSet.tz) {
      pageSet.locale = guessLocaleInformation(selectedPage.dom!, url || '');
      // pageSet.tz = tz;
    }

    // Update the inputs in state, can't remember why this is here.
    // Note that the 'getPageSetInputs' call implicitly assigns page ids to the items in 'inputs' array.
    const pageSetInputs = getPageSetInputs(pageSet);
    const newInputs = [...pageSetInputs, ...cloneDeep(inputs.filter((i: any) => !i?._meta?.pageId))];
    yield put(updateInteractionState({ inputs: newInputs }));

    // Create the project with the given page set, first pass in the existing PageSet

    if (isCreatingTraining) {
      // Need to reverse engineer the training,
      // we've replayed back the sequence now we manually grab example elements and train the fields
      const defaultPage = pageSet.pages.find((page) => !!page.html);
      if (defaultPage) {
        pageSet.fields.forEach((field) => {
          const examples = selectNodeElements(field, defaultPage.dom);
          examples.forEach((example) => trainField(pageSet, defaultPage, field, example));
        });
        yield put({ type: CREATING_TRAINING_FINISHED });
      }
    }

    yield put(projectActions.createProject({ existingProject: existingProject }, pageSet));
    // After project has been created, build the selectedPage, then select the page
    yield put(selectPage(selectedPage));
    if (!pageSet.fields.length) yield put(addField({})); // If there are no fields, add a default field
    // Clear the redo/undo history
    yield put(ActionCreators.clearHistory());
    // Update the raw runtime configuration, for advanced view editor
    yield put(
      projectActions.updateRuntimeConfigurationRaw({
        config: {
          interactions: [],
          extractionConfigs: {
            _runtimeConfig: pageSet.config,
          },
        },
      }),
    );
  } catch (e) {
    console.error('Error building training', e);
  } finally {
    if (isExtracting || isSuggestingData) yield put(stopExtraction());

    yield put(finishLoad());
  }
}

function* resetState() {
  yield put(resetBuilderState());
  yield put(cleanupState());
}

function* saveExtractorSaga() {
  const state: RootState = yield select();
  yield call(showProgressMessage);
  yield call(showProgressMessage, { percent: 20 });

  const builder = cloneDeep(state.lightning.builder.present);
  const fields = getFields(state).map((f) => {
    return {
      id: f.id,
      type: f.type,
      name: f.name,
      captureLink: !!f.captureLink,
    };
  });

  // Variables everywhere
  const { resourceResponse } = state.lightning.ui;
  const { canUseScreenCapture, canUseHtmlExtraction } = selectSubscriptionFeatureFlags();
  const { cssDisabled, pageSet } = builder;
  const { credentialsInput, authUrl, actionList, authInteractions, extractionType, initialUrl } = state.lightning.interactions;
  const {
    guid,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _meta,
    notifyMe,
    screenCapture,
    htmlExtraction,
    hasInteraction,
    finalPagesList,
    createDataReport,
    proxySettings,
  } = state.lightning.project;
  let { credentialsGuid, name, addInteractiveInputsToOutput } = state.lightning.project;
  let runtimeConfigWrapper = new RuntimeConfigurationWrapper({});
  let isPiAuthenticated = false;
  let inputs = state.lightning.interactions.inputs;

  // Add interactive inputs to output should be defaulted to true unless explicitly set to false by user
  addInteractiveInputsToOutput = addInteractiveInputsToOutput === false ? addInteractiveInputsToOutput : true;

  // Set PageSet globals, options that apply to all extraction configs
  pageSet.screenCapture = canUseScreenCapture && screenCapture;
  pageSet.htmlExtraction = htmlExtraction === false ? htmlExtraction : !!canUseHtmlExtraction; // Default to true if user has HTML Extraction enabled

  // Make the Runtime config
  // Page interaction extractors
  if (hasInteraction) {
    yield put(saveActions({ actionList: actionList, authInteractions: authInteractions }));

    const interactionState = yield select(getInteraction);
    const { serializedAuthActions } = interactionState;
    let { serializedActions: interactions } = interactionState;

    // separate training inputs from inputs attachment
    const { trainingInputs, inputsAttachment } = separateTrainingInputs(inputs);
    // reset training inputs on the pages
    pageSet.pages.forEach((page) => {
      const input = trainingInputs.find((i) => i._meta.pageId === page.id);
      if (input) page.input = input;
    });

    if (extractionType === 'basic' || extractionType === 'auth') {
      const renderAction = makeRenderAction(initialUrl);
      interactions = [renderAction.toJSON(), makeExtractDataAction().toJSON()];
    }
    runtimeConfigWrapper.interactions = interactions;
    runtimeConfigWrapper.config = pageSet.getOptimizedRuntimeConfig();

    inputs = inputsAttachment;
    isPiAuthenticated = !!serializedAuthActions.length;

    if (isPiAuthenticated) {
      runtimeConfigWrapper.authInteractions = prepAuthActionsForSave(serializedAuthActions);
      const privateConfig = yield call([encryptApi, 'createObject'], credentialsInput);
      if (privateConfig && privateConfig.guid) {
        credentialsGuid = privateConfig.guid;
      }
    }
  } else {
    // Legacy extractors
    runtimeConfigWrapper.config = pageSet.getOptimizedRuntimeConfig();
  }

  // Make the extractor
  const isNew = !guid || !_meta;
  if (!name) {
    yield put(projectActions.setInitialProjectName());
    const updatedState = yield select(getLightning);
    name = updatedState.project.name;
  }
  const extractor: Partial<Extractor> = {
    guid: guid || uuidv4(),
    name: name,
    fields: fields,
    notifyMe: notifyMe,
    createDataReport: createDataReport,
    addInteractiveInputsToOutput: addInteractiveInputsToOutput,
  };

  // Set authUrl if it is an auth extractor
  if (resourceResponse && resourceResponse.authUrl) {
    extractor.authUrl = resourceResponse.authUrl;
  } else if (isPiAuthenticated) {
    extractor.authUrl = authUrl;
  }

  // Proxy settings (in the future this will probably be moved to the runtime config level)
  const { country, proxyType } = proxySettings;
  if (isNotEmptyString(country) || isNotEmptyString(proxyType)) {
    extractor.iso3Country = country ?? DEFAULT_EXTRACTOR_COUNTRY_CODE;
    extractor.proxyType = proxyType ?? DEFAULT_EXTRACTOR_PROXY_TYPE;
  }
  yield call(showProgressMessage, { percent: 40 });
  // Save the bitch
  yield call(performSave, {
    runtimeConfig: runtimeConfigWrapper.record,
    extractor: extractor,
    pageSet: pageSet,
    credentialsGuid: credentialsGuid,
    finalPagesList: finalPagesList,
    inputs: inputs,
    hasInteraction: hasInteraction,
    cssDisabled: cssDisabled,
    isNew: isNew,
    initialUrl: initialUrl,
  });
}

function* saveAndRunExtractorSaga() {
  yield put(toggleAutoRun());
  yield call(saveExtractorSaga);
}

function* performSave({
  runtimeConfig,
  extractor,
  pageSet,
  credentialsGuid,
  finalPagesList,
  inputs,
  hasInteraction,
  cssDisabled,
  isNew,
  initialUrl,
}: {
  runtimeConfig: Partial<RuntimeConfigurationRecordNew>;
  extractor: Partial<Extractor>;
  pageSet: PageSet;
  credentialsGuid?: string;
  finalPagesList: any;
  inputs: any[];
  hasInteraction: boolean;
  cssDisabled: boolean;
  isNew: boolean;
  initialUrl: string;
}) {
  try {
    const state: RootState = yield select();
    const { variables } = state.lightning.interactions;
    const { data } = state.lightning.builder.present;
    const subscription = selectSubscriptionQueryData();

    // Save Extractor
    let extractorGuid: string;
    if (isNew) {
      extractorGuid = (yield extractorApi.create(extractor)).guid;
      extractor.guid = extractorGuid;
    } else {
      extractorGuid = extractor!.guid!;
    }

    yield call(showProgressMessage, { percent: 10 });

    // Save RuntimeConfig
    const rtcWrapper = new RuntimeConfigurationWrapper(runtimeConfig as RuntimeConfigurationRecordNew);
    const runtimeConfigResponse = yield objectStoreApi.runtimeConfiguration.create({
      config: rtcWrapper.runtimeConfig,
      extractorId: extractorGuid,
    });

    yield call(showProgressMessage, { percent: 20 });

    // Update latest runtimeConfig & credentialsGuid if any
    yield extractorApi.update(extractorGuid, {
      latestConfigId: runtimeConfigResponse.guid,
      ...(credentialsGuid
        ? {
            privateConfig: {
              credentialsGuid: credentialsGuid,
            },
          }
        : {}),
    });

    yield call(showProgressMessage, { percent: 40 });

    // Save training
    yield extractorApi.sendAttachment(extractorGuid, 'training', pageSet);

    yield call(showProgressMessage, { percent: 50 });

    // Save inputs
    if (isNew) {
      if (hasInteraction && inputs && inputs.length) {
        yield extractorApi.sendAttachment(extractorGuid, 'inputs', inputs.map((i) => JSON.stringify(i)).join('\n'), 'text/plain');
      } else {
        // For new legacy extractors create the url list, this can probably be removed
        let urls = pageSet.pages.map((p) => p.url).join('\n');
        if (finalPagesList) {
          urls = finalPagesList;
        }
        yield extractorApi.sendAttachment(extractorGuid, 'urlList', urls, 'text/plain');
      }
    } else if (hasInteraction) {
      yield call(showProgressMessage, { percent: 75 });
      // Update input variables
      yield extractorApi.updateVariables(extractorGuid, variables);
    }

    yield call(showProgressMessage, { percent: 80 });

    const currentUser = selectCurrentUser();
    trackSaveResults(data);

    // TODO this needs to be moved to training
    store.set(`extractor-${extractorGuid}-cssDisabled`, cssDisabled);

    yield put({ type: projectActions.SAVE_EXTRACTOR_SUCCESS });
    yield put(setHasSaved({ hasSaved: true }));
    yield call(showProgressMessage, { percent: ONE_HUNDRED });
    yield call(invalidateExtractorListQuery);
    const { email, guid } = currentUser;
    const basePlanCode = subscription?.basePlanCode;
    const { name } = extractor;
    yield call(sendEvent, {
      type: isNew ? EventType.EXTRACTOR_CREATED : EventType.EXTRACTOR_CHANGED,
      data: {
        user: {
          id: guid,
          email: email,
          planCode: basePlanCode,
        },
        extractor: {
          id: extractorGuid,
          name: name,
          url: initialUrl,
        },
      },
    });
    yield put(projectActions.setExistingProject(extractorGuid));
    if (isNew) {
      const savedExtractor = yield call([extractorApi, 'get'], extractorGuid);
      yield put({ type: projectActions.CREATE_PROJECT, ...savedExtractor });
    }
    yield put(push(getExtractorHistoryUrl(extractorGuid)));
  } catch (err) {
    if (err.status === 401) {
      window.location.href = `https://app.${endpoints.rootDomain}`;
    } else {
      console.error('Error occurred while saving extractor: ', err);
      yield call(message.error, 'There was an error saving your Extractor');
      yield put({ type: projectActions.SAVE_EXTRACTOR_FAILURE });
    }
  }
}

function trackSaveResults(data) {
  if (data && data[0]) {
    const columnKeys = {};
    data[0].group.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (!columnKeys[key]) {
          columnKeys[key] = true;
        }
      });
    });
  }
}

export function* projectSagas(): Generator {
  yield takeEvery(projectActions.LIGHTNING_LOADED, setUpProject);
  yield takeLeading(projectActions.BUILD_TRAINING, buildTraining);
  yield takeLeading(CHECKED_FOR_LIST_OR_DETAILS_PAGE, buildTraining);
  yield takeEvery(projectActions.RESET_PROJECT, resetState);
  yield takeLatest(projectActions.SAVE_EXTRACTOR_STARTED, saveExtractorSaga);
  yield takeLatest(projectActions.SAVE_AND_RUN, saveAndRunExtractorSaga);
}
