import { createContext } from 'react';

import { emptyFn } from 'common/constants/common-constants';
import type {
  SubscriptionModalContextValue,
  SubscriptionModalState,
} from 'features/user/subscription/modal/subscription-modal-types';

export const initialModalState: SubscriptionModalState = {
  isOpen: false,
  isUpgrade: false,
};

export const SubscriptionModalContext = createContext<SubscriptionModalContextValue>({
  hideModal: emptyFn,
  isOpen: false,
  isUpgrade: false,
  showDowngradeModal: emptyFn,
  showUpgradeModal: emptyFn,
});
