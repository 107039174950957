import type { JSX } from 'react';

import { isPresent } from '@import-io/typeguards';
import App from 'antd/lib/app';

import { useLightningRedirect } from 'app/app-hooks';
import styles from 'app/components/AppContainer.module.less';
import AppSidebar from 'app/components/AppSidebar';
import PageLoader from 'common/components/PageLoader';
import AppRoutes from 'common/routes/components/AppRoutes';
import { useIdentifyPosthog } from 'features/user/analytics/use-identify-posthog';
import { useInitializeUserPilot } from 'features/user/analytics/userpilot-hooks';
import { useFetchCurrentUser, useGetApiKey } from 'features/user/auth/user-auth-hooks';
import { SubscriptionModal, SubscriptionModalProvider } from 'features/user/subscription/modal';
import { useFetchSubscription, useSubscriptionFeatureFlags } from 'features/user/subscription/subscription-hooks';

const AppContainer = (): JSX.Element => {
  const { data: user, isLoading: isUserLoading } = useFetchCurrentUser({
    enabled: true,
    refetchOnMount: true,
  });
  const { data: subscription, isLoading: isSubscriptionLoading } = useFetchSubscription({
    enabled: isPresent(user),
    refetchOnMount: true,
  });
  useSubscriptionFeatureFlags({ enabled: true });
  useInitializeUserPilot(subscription);
  useIdentifyPosthog(subscription);
  useGetApiKey();
  useLightningRedirect();
  // app content makes sense only after current user and subscription are loaded
  return isUserLoading || isSubscriptionLoading ? (
    <PageLoader />
  ) : (
    <App className={`flex full-width full-height ${styles.container}`}>
      <AppSidebar />
      <SubscriptionModalProvider>
        <AppRoutes />
        <SubscriptionModal />
      </SubscriptionModalProvider>
    </App>
  );
};

export default AppContainer;
