import { isPresent } from '@import-io/typeguards';
import type { Extractor } from '@import-io/types';
import type { Updater } from '@tanstack/react-query';

import { queryClient } from 'common/query/query-constants';
import { updateExtractorListQueryData } from 'features/extractors/common/extractor-list-query';
import { GET_EXTRACTOR_QUERY_KEY } from 'features/extractors/extractors-constants';

export const selectExtractorQueryData = (extractorId: string): Extractor | null =>
  queryClient.getQueryData<Extractor | null>([GET_EXTRACTOR_QUERY_KEY, extractorId]) ?? null;

export const updateExtractorQueryData = <TResult = Extractor | undefined>(
  extractorId: string,
  updater: Updater<TResult, TResult>,
): TResult => {
  const newExtractor = queryClient.setQueryData<TResult>([GET_EXTRACTOR_QUERY_KEY, extractorId], updater);
  if (isPresent(newExtractor)) {
    updateExtractorListQueryData((extractors = []) =>
      extractors.map((extractor) => (extractor.guid === extractorId ? { ...extractor, ...newExtractor } : extractor)),
    );
  }
  return newExtractor as TResult;
};
