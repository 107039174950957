import {
  LOAD_RUNS,
  UPDATE_RUNS,
  END_LOADING,
  START_LOADING,
  CLEAR_FILTERS,
  SET_PORTAL_GUID,
  SET_SEARCH_QUEUE,
  CHANGE_DATE_FILTER,
  CHANGE_TYPE_FILTER,
  CHANGE_NAME_FILTER,
  SET_AVAILABLE_REPORTS,
} from '../actions/portal';
import { PORTAL_DATE_FILTER, PORTAL_NAME_FILTER, PORTAL_TYPE_FILTER } from '../lib/dashConstants';

const initialFilters = () => {
  return {
    types: {},
    name: '',
    dateRange: [null, null],
  };
};

const getInitialState = () => {
  return {
    runs: [],
    isLoading: false,
    lastFilter: null,
    portalGuid: null,
    loadTimestamp: 0,
    searchQueued: false,
    hasInitialData: false,
    filters: initialFilters(),
    availableReports: {},
  };
};

export default function portal(state = getInitialState(), action) {
  switch (action.type) {
    case LOAD_RUNS:
      return {
        ...state,
        runs: action.runs || [],
        loadTimestamp: action.timestamp,
        hasInitialData: action.hasInitialData,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        lastFilter: null,
        filters: { ...initialFilters(), types: state.availableReports },
      };
    case CHANGE_DATE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          dateRange: action.value,
        },
        lastFilter: PORTAL_DATE_FILTER,
      };
    }
    case CHANGE_TYPE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          types: {
            ...state.filters.types,
            [action.which]: action.value,
          },
        },
        lastFilter: PORTAL_TYPE_FILTER,
      };
    case CHANGE_NAME_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          name: action.value,
        },
        lastFilter: PORTAL_NAME_FILTER,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case END_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case SET_PORTAL_GUID:
      return {
        ...state,
        portalGuid: action.portalGuid || null,
      };
    case SET_SEARCH_QUEUE:
      return {
        ...state,
        searchQueued: action.searchQueued,
      };
    case UPDATE_RUNS:
      return {
        ...state,
        runs: action.runs,
      };
    case SET_AVAILABLE_REPORTS:
      return {
        ...state,
        availableReports: action.availableReports,
        filters: {
          ...state.filters,
          types: action.availableReports,
        },
      };
    default:
      return state;
  }
}
