import type { ChangeEventHandler, JSX } from 'react';
import { useCallback, useMemo, useState } from 'react';

import Input from 'antd/lib/input';
import throttle from 'lodash/throttle';

import type { PageSidebarInputProps } from './layout-types';
import styles from './PageSidebar.module.less';

const className = `full-width ${styles.input}`;

const defaultDelay = 500;

const PageSidebarInput = ({
  initialValue = '',
  inputDelay = defaultDelay,
  onChange,
  placeholder,
  prefix,
  suffix,
}: PageSidebarInputProps): JSX.Element => {
  const [value, setValue] = useState(initialValue);
  const onChangeDelayed = useMemo(() => throttle(onChange, inputDelay), [inputDelay, onChange]);

  const onChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      const newValue = target.value;
      setValue(newValue);
      onChangeDelayed(newValue);
    },
    [onChangeDelayed],
  );

  return (
    <Input
      className={className}
      onChange={onChangeHandler}
      placeholder={placeholder}
      prefix={prefix}
      suffix={suffix}
      value={value}
    />
  );
};

export default PageSidebarInput;
