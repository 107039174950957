import CustomButton from 'common/components/buttons/CustomButton';

import type { PageSidebarButtonProps } from './layout-types';
import styles from './PageSidebar.module.less';

const className = `full-width ${styles.button}`;

const PageSidebarButton = ({ dark = true, title, type = 'primary', ...restProps }: PageSidebarButtonProps): JSX.Element => (
  <CustomButton className={className} dark={dark} type={type} {...restProps}>
    {title}
  </CustomButton>
);

export default PageSidebarButton;
