import type { PageSet } from '@import-io/web-extractor';
import { createSelector } from 'reselect';

import type { RootState } from 'common/common-types';

export function getPageSet(state: RootState): PageSet {
  return state.lightning.builder.present.pageSet;
}

export function getRowRecord(state: RootState) {
  return state.lightning.builder.present.pageSet.singleRecord;
}

export function getSelectedField(state: RootState) {
  return state.lightning.builder.present.selectedField;
}

export const getPages = createSelector([getPageSet], (pageSet) => pageSet.pages);

export const getFields = createSelector([getPageSet], (pageSet) => pageSet.fields ?? []);

export const getRecordXPath = createSelector([getPageSet], (pageSet) => pageSet.recordXPath);

export const getSingleRecord = createSelector([getPageSet], (pageSet) => pageSet.singleRecord);

export const getScreenCapture = createSelector([getPageSet], (pageSet) => pageSet.screenCapture);

export const findSelectedField = createSelector([getSelectedField, getFields], (selectedField, fields) => {
  if (!selectedField) {
    return null;
  } else {
    return fields.find((f) => f.id === selectedField.id);
  }
});
