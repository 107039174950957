import type { JSX } from 'react';

import { isPresent } from '@import-io/typeguards';
import type { TooltipPropsWithOverlay } from 'antd/lib/tooltip';
import Tooltip from 'antd/lib/tooltip';

export type BaseTooltipWrapperProps = Omit<TooltipPropsWithOverlay, 'children'>;

export interface WithTooltip {
  disabledTooltip?: string;
  tooltip?: BaseTooltipWrapperProps;
}

export interface TooltipWrapperProps extends BaseTooltipWrapperProps {
  readonly children: JSX.Element;
  readonly contentClass?: string;
  readonly disabled?: boolean;
  readonly disabledTitle?: string;
}

export const wrapperTestId = 'tooltip-wrapper';
export const contentTestId = 'tooltip-content';

const TooltipWrapper = ({
  children,
  contentClass,
  disabled = false,
  title,
  disabledTitle,
  ...restProps
}: TooltipWrapperProps): JSX.Element => {
  const tooltip = disabled ? disabledTitle : title;
  return isPresent(tooltip) ? (
    <Tooltip data-testid={wrapperTestId} title={tooltip} {...restProps}>
      <div className={contentClass} data-testid={contentTestId}>
        {children}
      </div>
    </Tooltip>
  ) : (
    children
  );
};

export default TooltipWrapper;
