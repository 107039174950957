import type { CSSProperties } from 'react';

import { getExpiryText } from 'features/user/subscription/subscription-utils';

const expiryStyle: CSSProperties = { whiteSpace: 'nowrap' };

interface SubscriptionExpiryProps {
  readonly expiry: number;
  readonly prefix?: string;
  readonly suffix?: string;
}

const SubscriptionExpiry = ({ expiry, prefix = '', suffix = '' }: SubscriptionExpiryProps): JSX.Element => {
  const expiryText = getExpiryText(expiry);
  return (
    <span>
      {prefix}
      <span style={expiryStyle}>{expiryText}</span>
      {suffix}
    </span>
  );
};

export default SubscriptionExpiry;
