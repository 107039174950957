import { objectStoreApi } from '@import-io/js-sdk';
import type { Report, ReportType } from '@import-io/types/report-types';

import { createReportsQuery } from 'features/reports/common/reports-utils';

export async function fetchReportsApi(typesFilter: ReportType[] | ReportType | null = null) {
  const pageLimit = 4500;
  let pageNumber = 1;
  let reportsList: Report[] = [];

  while (pageNumber <= 15)
    try {
      const query = createReportsQuery({
        limit: pageLimit,
        page: pageNumber++,
        types: typesFilter,
      });

      const results = await objectStoreApi.report.query(query);
      reportsList.push(...results);

      if (results.length < pageLimit) break;
    } catch (err) {
      break;
    }

  return reportsList;
}

export async function fetchFirstReportApi(typesFilter: ReportType[] | ReportType | null = null) {
  const query = createReportsQuery({
    limit: 1,
    types: typesFilter,
  });
  const result = await objectStoreApi.report.query(query);
  return result?.[0] ?? null;
}
