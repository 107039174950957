import type { JSX } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { isGuid } from '@import-io/typeguards';

import PageNotFound from 'common/components/error/PageNotFound';
import * as routesConstants from 'common/routes/routes-constants';

const ShortExtractorUrlRedirect = (): JSX.Element | null => {
  const { extractorGuid } = useParams();
  return isGuid(extractorGuid) ? (
    <Navigate replace to={`${routesConstants.EXTRACTORS_PAGE_URL}/${extractorGuid}/history`} />
  ) : (
    <PageNotFound />
  );
};

export default ShortExtractorUrlRedirect;
