import { Provider } from 'react-redux';

import { QueryClientProvider } from '@tanstack/react-query';
import ConfigProvider from 'antd/lib/config-provider';
import message from 'antd/lib/message';
import { createRoot } from 'react-dom/client';
import { HistoryRouter } from 'redux-first-history/rr6';

import AppContainer from 'app/components/AppContainer';
import SentryContainer from 'app/components/SentryContainer';
import { DEFAULT_MESSAGE_DURATION } from 'common/messages/messages-constants';
import { queryClient } from 'common/query/query-constants';

import { theme } from './app-theme';
import { reduxHistory, store } from './store';
import '../style/main.less';

message.config({ duration: DEFAULT_MESSAGE_DURATION });

const root = createRoot(document.getElementById('lightning'));

root.render(
  <Provider store={store}>
    <SentryContainer>
      <ConfigProvider theme={theme}>
        <HistoryRouter history={reduxHistory}>
          <QueryClientProvider client={queryClient}>
            <AppContainer />
          </QueryClientProvider>
        </HistoryRouter>
      </ConfigProvider>
    </SentryContainer>
  </Provider>,
);
