/* eslint-disable @typescript-eslint/no-use-before-define */
import { objectStoreApi } from '@import-io/js-sdk';
import { actions } from '@import-io/replay-browser-events';
import { isObject } from '@import-io/typeguards';
import type { ActionData, InputItem, ObjectInputItem } from '@import-io/types';
import { getUrlFromInput, inputToObject } from '@import-io/types';
import { Page } from '@import-io/web-extractor';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { push, replace } from 'redux-first-history';
import { v4 as uuidv4 } from 'uuid';

import type { InteractionsState } from 'app/lightning-old/lightning-types';
import type { RootState } from 'common/common-types';
import { normalizeName } from 'common/common-utils';
import { canReplaceAssetsForUrl, getSearchParams, sanitizeUrl } from 'common/utils/url-utils';
import type { FunctionAction } from 'features/extractor-builder/interaction/interaction-action';
import { createInteractionAction, InteractionAction } from 'features/extractor-builder/interaction/interaction-action';
import * as INTERACTION_CONSTANTS from 'features/extractor-builder/interaction/interaction-constants-old';
import * as InteractionUtils from 'features/extractor-builder/interaction/interaction-utils-old';

import { removePageById } from './builder';
import { lightningLoaded } from './project';
import { changeInputsModalStatus, HIDE_INTERACTION_COPY_MODAL, selectViewTab, startLoading, toggleSidebarOpen } from './ui';

export const NEW_ACTION_RECORDED = 'NEW_ACTION_RECORDED';
export const NEW_ACTION_FAILED = 'NEW_ACTION_FAILED';
export const DELETE_ACTION = 'DELETE_ACTION';
export const CLEAR_ALL_ACTIONS = 'CLEAR_ALL_ACTIONS';
export const RECEIVED_HTML = 'RECEIVED_HTML';
export const UPDATE_CURRENT_URL = 'UPDATE_CURRENT_URL';
export const UPDATE_BROWSER_STATUS = 'UPDATE_BROWSER_STATUS';
export const SET_CURRENT_ACTION = 'SET_CURRENT_ACTION';
export const REPLAY_ACTIONS = 'REPLAY_ACTIONS';
export const STOP_REPLAY = 'STOP_REPLAY';
export const EXTRACT_DATA_INITIATED = 'EXTRACT_DATA_INITIATED';
export const EXTRACT_DATA_FINISHED = 'EXTRACT_DATA_FINISHED';
export const SUGGESTING_DATA = 'SUGGESTING_DATA';
export const SAVING_ACTION_LIST = 'SAVING_ACTION_LIST';
export const START_RECORDING = 'START_RECORDING';
export const STOP_RECORDING = 'STOP_RECORDING';
export const REFRESH_START = 'REFRESH_START';
export const REFRESH_STOP = 'REFRESH_STOP';
export const NAVIGATION_START = 'NAVIGATION_START';
export const NAVIGATION_COMPLETE = 'NAVIGATION_COMPLETE';
export const DISMISS_WARNING = 'DISMISS_WARNING';
export const PREVENT_RECORDING = 'PREVENT_RECORDING';
export const ENABLE_RECORDING = 'ENABLE_RECORDING';
export const UPDATE_INTERACTION_STATE = 'UPDATE_INTERACTION_STATE';
export const NAVIGATION_ALTERED = 'NAVIGATION_ALTERED';
export const REPLAY_AND_SUGGEST_FOR_INPUT = 'REPLAY_AND_SUGGEST_FOR_INPUT';
export const SELECT_INPUT = 'SELECT_INPUT';
export const STOP_REPLAY_FOR_INPUT = 'STOP_REPLAY_FOR_INPUT';
export const BEGIN_INITIAL_LOAD = 'BEGIN_INITIAL_LOAD';
export const INITIAL_LOAD_COMPLETE = 'INITIAL_LOAD_COMPLETE';
export const MOCKING_ACTION = 'MOCKING_ACTION';
export const RESET_INTERACTION_STATE = 'RESET_INTERACTION_STATE';
export const RECEIVED_VIRTUAL_BROWSER_ID = 'RECEIVED_VIRTUAL_BROWSER_ID';
export const TOGGLE_PRIVATE_SESSION = 'TOGGLE_PRIVATE_SESSION';
export const VALIDATE_AUTHENTICATION = 'VALIDATE_AUTHENTICATION';
export const EDITING_AUTH_STEPS_START = 'EDITING_AUTH_STEPS_START';
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const EDITING_AUTH_STEPS_FINISH = 'EDITING_AUTH_STEPS_FINISH';
export const AUTH_DETECTED = 'AUTH_DETECTED';
export const CLEAR_AUTH_INTERACTIONS = 'CLEAR_AUTH_INTERACTIONS';
export const SET_AUTH_URL = 'SET_AUTH_URL';
export const DELETE_AUTH_ACTION = 'DELETE_AUTH_ACTION';
export const SET_CREDENTIALS_INPUT = 'SET_CREDENTIALS_INPUT';
export const REVALIDATING_AUTH = 'REVALIDATING_AUTH';
export const AUTH_REVALIDATED = 'AUTH_REVALIDATED';
export const SET_AUTH_URL_AND_NAVIGATE = 'SET_AUTH_URL_AND_NAVIGATE';
export const SET_INTERACTION_PAGES = 'SET_INTERACTION_PAGES';
export const SET_RECORDING_INTO_ACTION_ID = 'SET_RECORDING_INTO_ACTION_ID';
export const TOGGLE_LOADING_ACTIONS = 'TOGGLE_LOADING_ACTIONS';
export const TOGGLE_HOVER_MODE = 'TOGGLE_HOVER_MODE';
export const SET_RECORDING_AT_INDEX = 'SET_RECORDING_AT_INDEX';
export const START_COPY_ACTIONS = 'START_COPY_ACTIONS';
export const END_COPY_ACTIONS = 'END_COPY_ACTIONS';
export const START_AUTH_COPY_LOAD = 'START_AUTH_COPY_LOAD';
export const END_AUTH_COPY_LOAD = 'END_AUTH_COPY_LOAD';
export const UPDATE_AUTH_COPY_CREDENTIALS = 'UPDATE_AUTH_COPY_CREDENTIALS';
export const INITIAL_LOAD_FAILED = 'INITIAL_LOAD_FAILED';
export const SET_REPLAY_MESSAGE = 'SET_REPLAY_MESSAGE';
export const TOGGLE_CODE_ACTION_EDITOR = 'TOGGLE_CODE_ACTION_EDITOR';
export const SELECT_CONFIG_FOR_EXTRACT_INTERACTION_SAGA = 'SELECT_CONFIG_FOR_EXTRACT_INTERACTION_SAGA';
export const SET_POST_AUTH_URL = 'SET_POST_AUTH_URL';
export const SET_EXTRACTION_TYPE = 'SET_EXTRACTION_TYPE';
export const OPEN_URL_DATA_FRAME = 'OPEN_URL_DATA_FRAME';
export const CLOSE_URL_DATA_FRAME = 'CLOSE_URL_DATA_FRAME';
export const SHOW_PROCESSING_PAGE_ERROR = 'SHOW_PROCESSING_PAGE_ERROR';
export const CONFIRM_INTERACTIVE_URL_CHANGE = 'CONFIRM_INTERACTIVE_URL_CHANGE';
export const CANCEL_INTERACTIVE_URL_CHANGE = 'CANCEL_INTERACTIVE_URL_CHANGE';
export const SET_INITIAL_CURRENT_INPUT = 'SET_INITIAL_CURRENT_INPUT';
export const SET_SHOW_POPOVER_FOR_ACTION = 'SET_SHOW_POPOVER_FOR_ACTION';
export const START_LOCK_INTERACTIONS = 'START_LOCK_INTERACTIONS';

// ========= Sagas ======= \\
export const receivedHtml = (html, timestamp) => ({ type: RECEIVED_HTML, html: html, timestamp: timestamp });
// ======== Simple actions =========== \\
export const setExtractionType = (extractionType) => ({ type: SET_EXTRACTION_TYPE, extractionType: extractionType });
export const stopExtraction = () => ({ type: EXTRACT_DATA_FINISHED });
export const suggestingData = () => ({ type: SUGGESTING_DATA });
export const stopRecording = () => ({ type: STOP_RECORDING });
export const interactionInitialLoadComplete = () => ({ type: INITIAL_LOAD_COMPLETE });
export const startRecording = () => ({ type: START_RECORDING });
export const toggleHoverMode = () => ({ type: TOGGLE_HOVER_MODE });
export const setNavigationComplete = () => ({ type: NAVIGATION_COMPLETE });
export const preventRecording = () => ({ type: PREVENT_RECORDING });
export const enableRecording = () => ({ type: ENABLE_RECORDING });
export const selectInput = (currentInput) => ({ type: SELECT_INPUT, currentInput: currentInput });
export const setInitialCurrentInput = (initialCurrentInput) => ({
  type: SET_INITIAL_CURRENT_INPUT,
  initialCurrentInput: initialCurrentInput,
});
export const stopRefresh = () => ({ type: REFRESH_STOP });
export const toggleBrowserStatus = (isBrowserOpen) => ({ type: UPDATE_BROWSER_STATUS, isBrowserOpen: isBrowserOpen });
export const setAuthStatus = (authStatus) => ({ type: SET_AUTH_STATUS, authStatus: authStatus });
export const editingAuthentication = () => ({ type: EDITING_AUTH_STEPS_START });
export const stopEditingAuthentication = () => ({ type: EDITING_AUTH_STEPS_FINISH });
export const clearAuthInteractions = () => ({ type: CLEAR_AUTH_INTERACTIONS });
export const setAuthUrl = (authUrl) => ({ type: SET_AUTH_URL, authUrl: authUrl });
export const setAuthUrlAndNavigate = (authUrl) => ({ type: SET_AUTH_URL_AND_NAVIGATE, authUrl: authUrl });
export const setCredentials = (credentialsInput) => ({
  type: SET_CREDENTIALS_INPUT,
  credentialsInput: credentialsInput,
});
export const revalidatingAuth = (credentialsInput) => ({ type: REVALIDATING_AUTH, credentialsInput: credentialsInput });
export const authRevalidated = () => ({ type: AUTH_REVALIDATED });
export const toggleLoadingActions = (isLoadingActions) => ({
  type: TOGGLE_LOADING_ACTIONS,
  isLoadingActions: isLoadingActions,
});
export const togglePrivateSession = (privateSession = true) => ({
  type: TOGGLE_PRIVATE_SESSION,
  privateSession: privateSession,
});
export const setRecordingAtIndex = (index, recordingIntoAuth = false) => ({
  type: SET_RECORDING_AT_INDEX,
  recordingAtIndex: Number.isInteger(index) && index >= 0 ? index : 0,
  recordingIntoAuth: recordingIntoAuth,
});
export const initialLoadFailed = () => ({ type: INITIAL_LOAD_FAILED });
export const setReplayMessage = (replayMessage) => ({ type: SET_REPLAY_MESSAGE, replayMessage: replayMessage });
export const toggleCodeActionEditor = (editingCodeAction) => ({
  type: TOGGLE_CODE_ACTION_EDITOR,
  editingCodeAction: editingCodeAction,
});
export const openUrlDataFrame = () => ({ type: OPEN_URL_DATA_FRAME });
export const selectConfigForExtractInteractionSaga = (actionId, extractorConfig) => ({
  type: SELECT_CONFIG_FOR_EXTRACT_INTERACTION_SAGA,
  actionId: actionId,
  extractorConfig: extractorConfig,
});
export const setShowPopoverForAction = (showPopoverOnActionId) => ({
  type: SET_SHOW_POPOVER_FOR_ACTION,
  showPopoverOnActionId: showPopoverOnActionId,
});
export const startLockInteractions = () => ({ type: START_LOCK_INTERACTIONS });

export function saveActions({
  actionList,
  authInteractions,
  serializedActions,
  serializedAuthActions,
}: {
  actionList?: InteractionAction[];
  authInteractions?: InteractionAction[];
  serializedActions?: ActionData[];
  serializedAuthActions?: ActionData[];
}) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const interactionState = rootState.lightning.interactions;
    actionList = actionList || interactionState.actionList;
    authInteractions = authInteractions || interactionState.authInteractions;
    dispatch({
      type: SAVING_ACTION_LIST,
      actionList: actionList,
      authInteractions: authInteractions,
      serializedActions: serializedActions || InteractionUtils.serializeActions(actionList),
      serializedAuthActions: serializedAuthActions || InteractionUtils.serializeActions(authInteractions),
    });
  };
}

/**
 *
 * @param {any|undefined} [variables]
 * @param inputs
 * @param {any|undefined} [actionList]
 * @param {any|undefined} [currentInput]
 * @param {any|undefined} [authInteractions]
 * @param {any|undefined} [credentialsInput]
 */
export function updateInteractionState({
  variables,
  inputs,
  actionList,
  currentInput,
  authInteractions,
  credentialsInput,
}: Partial<InteractionsState>) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = rootState.lightning.interactions;
    dispatch({
      type: UPDATE_INTERACTION_STATE,
      variables: variables || state.variables,
      inputs: inputs || state.inputs,
      actionList: actionList || state.actionList,
      currentInput: currentInput || state.currentInput,
      serializedActions: (actionList || state.actionList).map((a) => a.toJSON()),
      authInteractions: authInteractions || state.authInteractions,
      credentialsInput: credentialsInput || state.credentialsInput,
    });
  };
}

// ====================================== \\

export const updateAuthCopyCredentials = (key, value) => {
  return (dispatch) => {
    dispatch({
      key: key,
      value: value,
      type: UPDATE_AUTH_COPY_CREDENTIALS,
    });
  };
};

export const copyExtractorSelected = (extractor) => {
  return async (dispatch) => {
    dispatch({
      type: START_AUTH_COPY_LOAD,
    });

    // Get the config
    const result = await objectStoreApi.runtimeConfiguration.get(extractor.latestConfigId);
    if (!result || !result.config || !result.config.authInteractions) {
      dispatch({
        type: END_AUTH_COPY_LOAD,
      });
      return null;
    }

    // Do the casting
    const credentials = InteractionUtils.getAuthVariables(result.config.authInteractions);
    const authInteractions = InteractionUtils.prepareAuthActionsForState(result.config.authInteractions);

    // Store the extractor config
    dispatch({
      type: END_AUTH_COPY_LOAD,
      authInteractions: authInteractions,
      credentials: credentials,
    });
  };
};

export const copyAuthActionsFromExtractor = () => {
  return (dispatch, getState) => {
    dispatch({
      type: START_COPY_ACTIONS,
    });

    // Get the current state
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning.interactions);
    let { authUrl } = state;
    const { actionList, initialUrl } = state;

    // Get the authUrl
    authUrl = initialUrl;
    const gotoAction = state.authCopyData.authInteractions.find((a) => a.name === 'GotoAction');
    if (gotoAction && gotoAction.url) {
      authUrl = gotoAction.url;
    }

    // Set private session
    dispatch(togglePrivateSession());

    // Update the things
    dispatch({
      type: VALIDATE_AUTHENTICATION,
      authUrl: authUrl,
      actionList: actionList,
      credentialsInput: state.authCopyData.credentials,
      authInteractions: state.authCopyData.authInteractions,
    });

    // Apply Credentials
    dispatch(setCredentialsAndReplay(state.authCopyData.credentials));

    // Finished
    dispatch({
      type: END_COPY_ACTIONS,
    });

    // Close modal
    dispatch({
      type: HIDE_INTERACTION_COPY_MODAL,
    });
  };
};

export function newActionRecorded(action, actionId = uuidv4(), recordStatus = 'recorded', isValid = true) {
  return (dispatch, getState) => {
    try {
      const rootState: RootState = getState();
      const interactionState = cloneDeep(rootState.lightning.interactions);

      if (interactionState.isRefreshing) {
        // stop a refresh if that be happening
        dispatch(stopRefresh());
      }

      if (interactionState.recordingIntoActionId) {
        return dispatch(recordActionForAction(action, actionId, recordStatus));
      }

      const actionName = InteractionUtils.getActionName(action);
      // flag to help determine if we should record this event even though user has not specified to start recording
      const isInitialLoad =
        InteractionUtils.shouldRecordInitialLoad(interactionState.actionList) &&
        INTERACTION_CONSTANTS.INITIAL_LOAD_ACTION_TYPES.includes(actionName);

      if (
        isInitialLoad ||
        (!interactionState.isReplaying &&
          !interactionState.isRefreshing &&
          !interactionState.isSuggestingData &&
          !interactionState.isExtracting)
      ) {
        let { currentVisibleAction, invalidActions, currentInput, recordingAtIndex } = interactionState;
        const {
          actionList,
          currentUrl,
          privateSession,
          authInteractions,
          isEditingAuthActions,
          authStatus,
          variables,
          inputs,
          credentialsInput,
          recordingIntoAuth,
        } = interactionState;
        if (!interactionState.isReplaying) {
          const actionListWeAreUsing =
            isEditingAuthActions || (recordingAtIndex && recordingIntoAuth) ? authInteractions : actionList;

          const addToActionList = (newAction) => {
            //Before we add the action check if the current action is a key action and set the key actions to optional
            //This fixes a bug where interaction fails due to unnecessary KeyActions that are marked as required
            if (newAction.name === 'KeyAction') {
              newAction.optional = true;
              newAction.actions.forEach((a) => {
                a.optional = true;
              });
            }

            if (recordingAtIndex) {
              actionListWeAreUsing.splice(recordingAtIndex, 0, newAction);
              recordingAtIndex++;
            } else {
              actionListWeAreUsing.push(newAction);
            }
          };

          let currentAction = createInteractionAction({
            action: action,
            actionId: actionId,
            recordStatus: recordStatus,
            isInitialLoad: isInitialLoad,
            isValid: isValid,
            isAuth: isEditingAuthActions,
          });
          // if this is the first WaitLoadingAction
          if (actionName === 'WaitLoadingAction' && recordStatus === 'recorded') {
            currentAction.url = currentUrl;
          }

          if (isValid) {
            const existingAction = actionListWeAreUsing.find((a) => a.actionId === actionId);
            const browserAction = action;

            if (actionName === 'GotoAction') {
              //we need to record our first input, which is needed to save an interaction extractor.
              // We'll also go ahead and set it as the current input;
              const { url } = action.url;

              currentInput = inputs.find((i) => !!getUrlFromInput(i));
              if (!currentInput) {
                inputs.push((currentInput = { _url: url }));
              } else {
                (currentInput as ObjectInputItem)._url = url;
              }

              if (!variables.some((v) => v.name === '_url')) {
                variables.push({
                  actionId: actionId,
                  name: '_url',
                  canEdit: false,
                });
              }

              dispatch(updateInteractionState({ inputs: inputs, currentInput: currentInput, variables: variables }));
              browserAction.variables.url.$variable$ = true;
              browserAction.variables.url.name = '_url';
            }

            // see if we need to add it to the list or update an existing action
            if (existingAction || recordStatus === 'recorded') {
              // record logic
              if (existingAction && existingAction.url && existingAction.name === 'WaitLoadingAction') {
                // this is actually needed as it re adds the url to the wait loading action if it doesn't have it
                browserAction.url = existingAction.url;
              }

              // For input types ======================================\\
              if (InteractionUtils.needToRecordDefaultInput(currentAction)) {
                const isActionPasswordField = InteractionUtils.isPasswordField(action);
                if (isActionPasswordField) {
                  currentAction.isPasswordField = true;

                  // if we have previously not detected password type inputs and this action is one set flag as detected
                  if (
                    (actionListWeAreUsing.filter((a) => a.isPasswordField).length === 0 && // is first password type
                      !privateSession &&
                      authInteractions.length === 0 &&
                      authStatus === 'undetected') || // first time detected
                    (authStatus === 'validation-required' && isEditingAuthActions)
                  ) {
                    // or we need validation and we're currently editing auth list
                    dispatch(setAuthStatus('detected'));
                  }
                }

                if (!isEditingAuthActions) {
                  const variableConfig = InteractionUtils.configureVariableNewAction(currentAction, variables, inputs);
                  currentAction = variableConfig.currentAction;

                  // this is some defensive code that will get executed in the event
                  // that currentInput doesn't reference
                  // something on the input list
                  if (currentAction.variable && currentAction.variable.name && !currentInput![currentAction.variable.name]) {
                    currentInput![currentAction.variable.name] = currentAction.variable.defaultValue;
                  }

                  dispatch(
                    updateInteractionState({
                      variables: variableConfig.variables,
                      inputs: variableConfig.inputs,
                      currentInput: currentInput,
                    }),
                  );
                } else {
                  const { currentAction: newCurrentAction, credentialsInput: newCredentials } =
                    InteractionUtils.configureVariableAuthAction(currentAction, credentialsInput || {});
                  currentAction = newCurrentAction;
                  dispatch(setCredentials(newCredentials));
                }
              }
              // ==================================\\

              if (recordStatus === 'recorded' && !existingAction) {
                addToActionList(currentAction);
              } else {
                // mapping for editing existing action properties with new stuff
                actionListWeAreUsing.forEach((a, idx) => {
                  if (a.actionId === actionId) {
                    const { isInitialLoad: aIsInitialLoad, ...rest } = a;
                    actionListWeAreUsing[idx]!.mergeProps({ ...rest, ...currentAction, isInitialLoad: aIsInitialLoad });
                  }
                });
              }
            } else {
              // or add the new action
              addToActionList(currentAction);
            }
          } else {
            // invalid actions (user is interacting without record mode)
            const actionExists = !!invalidActions.find((a) => a.actionId === actionId);
            // see if we need to add it to the list or update an existing action
            if (actionExists) {
              invalidActions = invalidActions.map((a) => {
                if (a.actionId === actionId) {
                  a.recordStatus = recordStatus;
                }
                return a;
              });
            } else {
              invalidActions.push(currentAction);
              currentAction.recordStatus = 'saving';
            }
          }

          currentVisibleAction =
            currentAction.shouldDisplay &&
            isValid &&
            actionList.findIndex((a) => a.actionId === currentAction.actionId) >=
              actionList.findIndex((a) => a.actionId === (currentVisibleAction || {}).actionId)
              ? currentAction
              : currentVisibleAction;

          // add to state the id of the newly created action that shall show the popover
          const showPopoverOnActionId =
            (currentAction.name === 'PaginationAction' || currentAction.name === 'CaptchaAction') &&
            actionList.filter((a) => a.displayValue === currentAction.displayValue).length === 1
              ? currentAction.actionId
              : null;
          if (showPopoverOnActionId || interactionState.showPopoverOnActionId !== showPopoverOnActionId) {
            dispatch({
              type: SET_SHOW_POPOVER_FOR_ACTION,
              showPopoverOnActionId: showPopoverOnActionId,
            });
          }

          // we're updating a lot here just to record one action. but it is needed to maintain object references
          // to avoid
          // redundant updating throughout the app
          dispatch({
            type: NEW_ACTION_RECORDED,
            actionList: actionList,
            currentVisibleAction: currentVisibleAction,
            currentAction: currentAction.isValid ? currentAction : interactionState.currentAction,
            invalidActions: invalidActions,
            authInteractions: authInteractions,
            recordingAtIndex: recordingAtIndex,
          });

          // if we've detected a login we'll show prompt after 2 recorded actions
          if (
            interactionState.authStatus === 'detected' &&
            recordStatus === 'recorded' &&
            actionListWeAreUsing.findIndex((a) => a.isPasswordField) <= actionListWeAreUsing.length - 3 &&
            !authInteractions.length
          ) {
            dispatch(togglePrivateSession());
            dispatch(setAuthStatus('started'));
          }

          if (currentAction.name === 'CodeAction') {
            dispatch(toggleCodeActionEditor(currentAction));
          }
        }
      }
      // some logic to figure out if this is part the loading of the first page
      if (isInitialLoad) {
        if (!interactionState.isRecordingInitialLoad) {
          dispatch({ type: BEGIN_INITIAL_LOAD });
        } else if (
          actionName === 'WaitLoadingAction' &&
          recordStatus === 'recorded' &&
          interactionState.authStatus === 'setting-data-url'
        ) {
          dispatch(setAuthStatus('authenticated'));
        }
      }
    } catch (e) {
      // PI sdk swallows most exceptions so we catch it here
      console.error('Failed to record action', e);
    }
  };
}

function recordActionForAction(action, actionId, recordStatus) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList, recordingIntoActionId, authInteractions, isEditingAuthActions, variables, inputs } = cloneDeep(
      rootState.lightning.interactions,
    );
    const actionListWeAreUsing = isEditingAuthActions ? authInteractions : actionList;

    const recordingIntoAction = actionListWeAreUsing.find((a) => a.actionId === recordingIntoActionId);
    if (!recordingIntoAction) return;

    let newAction = createInteractionAction({ action: action, actionId: actionId, recordStatus: recordStatus });
    if (recordStatus === 'recorded' && InteractionUtils.needToRecordDefaultInput(newAction)) {
      const variableConfig = InteractionUtils.configureVariableNewAction(newAction, variables, inputs);
      newAction = variableConfig.currentAction;
      dispatch(
        updateInteractionState({
          variables: variableConfig.variables,
          inputs: variableConfig.inputs,
          currentInput: variableConfig.inputs[0],
        }),
      );
    }

    recordingIntoAction.addAction(newAction);
    dispatch(updateInteractionState({ actionList: actionList, authInteractions: authInteractions }));
  };
}

export function newActionFailed(action, actionId, isValid = true) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    console.error('Failed to record action', action, actionId);
    let { actionList, invalidActions } = cloneDeep(rootState.lightning.interactions);
    if (isValid) {
      actionList = actionList.map((a) => {
        if (a.actionId === actionId) {
          a.recordStatus = 'failed';
        }
        return a;
      });
    } else {
      invalidActions = invalidActions.map((a) => {
        if (a.actionId === actionId) {
          a.recordStatus = 'failed';
        }
        return a;
      });
    }

    dispatch({
      type: NEW_ACTION_FAILED,
      actionList: actionList,
      invalidActions: invalidActions,
    });
  };
}

export function playCurrentAction(currentAction) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { currentVisibleAction, playedActions } = rootState.lightning.interactions;
    playedActions.push(currentAction);
    dispatch({
      type: SET_CURRENT_ACTION,
      currentAction: currentAction,
      currentVisibleAction: currentAction.shouldDisplay ? currentAction : currentVisibleAction,
      playedActions: playedActions,
    });
  };
}

export function deleteAction({ action, isAuthAction, parentActionId }) {
  if (!action) return;
  return (dispatch, getState) => {
    if (isAuthAction) {
      dispatch(deleteAuthAction(action, true));
    } else {
      const rootState: RootState = getState();
      const { actionList, variables, inputs, recordingIntoActionId, currentInput } = cloneDeep(rootState.lightning.interactions);
      let actionListCopy: InteractionAction[] = [];
      if (parentActionId) {
        const parentAction = actionList.find((a) => parentActionId === a.actionId);
        if (!parentAction) return;
        const newLength = parentAction.actions.findIndex((a) => action.actionId === a.actionId);
        actionListCopy = parentAction.actions.slice(newLength - 1, parentAction.actions.length + 1);
        parentAction.actions.length = newLength;
      } else {
        const newLength = actionList.findIndex((a) => action.actionId === a.actionId);
        actionListCopy = actionList.slice(newLength - 1, actionList.length + 1);
        actionList.length = newLength;
      }

      if (recordingIntoActionId && !!actionListCopy.find((a) => a.actionId === recordingIntoActionId)) {
        dispatch(setRecordingIntoActionId(null));
      }

      let results;
      if (action.variableNames && action.variableNames.length) {
        // if an action has variablesNames, run this clean up function functions
        results = InteractionUtils.cleanUpForAllInputs(action.variableNames, variables, inputs, currentInput);
      } else {
        // if you are dealing with an action without a variable (or multiple actions), run this function
        // you need to do the inputs and currentInput first, so you will still have access to the variable
        results = InteractionUtils.cleanUpForAllActions(actionListCopy, variables, inputs, currentInput!);
      }

      dispatch({
        type: DELETE_ACTION,
        actionList: actionList,
        variables: results.newVariables,
        inputs: results.newInputs,
        currentInput: results.newCurrentInput,
      });
    }

    dispatch(setRecordingStatus());
  };
}

export function deleteCodeActionDependentActions({ action }: { action: InteractionAction }) {
  if (!action) return;

  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList, variables, inputs, currentInput } = cloneDeep(rootState.lightning.interactions);
    const indexesToRemove: number[] = [];

    for (let i = 0; i < actionList.length; i++) {
      if (actionList[i]!.codeActionDependent) {
        if (i > 2) {
          break;
        }

        indexesToRemove.splice(0, 0, i);
      }
    }

    for (let i = 0; i < indexesToRemove.length; i++) {
      actionList.splice(indexesToRemove[i]!, 1);
    }

    const results = InteractionUtils.cleanUpForAllActions(actionList, variables, inputs, currentInput || {});

    dispatch({
      type: DELETE_ACTION,
      actionList: actionList,
      variables: results.newVariables,
      inputs: results.newInputs,
      currentInput: results.newCurrentInput,
    });

    dispatch(setRecordingStatus());
  };
}

export function setRecordingStatus() {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    return rootState.lightning.interactions.isRecording ? dispatch(enableRecording()) : dispatch(preventRecording());
  };
}

export function deleteAuthAction(action: InteractionAction, deleteSubsequent: boolean) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { authInteractions, credentialsInput } = cloneDeep(rootState.lightning.interactions);

    if (action.hasVariables && action.browserAction) {
      let varName = '';
      if (action.browserAction.variables && action.browserAction.variables.value) {
        varName = action.browserAction.variables.value.name;
      } else if (action.browserAction.variables && action.browserAction.variables.values) {
        varName = action.browserAction.variables.values.name;
      }
      delete credentialsInput[varName];
    }
    if (deleteSubsequent) {
      const newLength = authInteractions.findIndex((a) => action.actionId === a.actionId);
      const actionListCopy = authInteractions.slice(newLength, authInteractions.length + 1);
      actionListCopy.forEach((a) => {
        let varName = '';
        if (a.browserAction.variables?.value) {
          varName = a.browserAction.variables.value.name;
        } else if (action.browserAction.variables?.values) {
          varName = a.browserAction.variables.values!.name;
        }
        delete credentialsInput[varName];
      });
      authInteractions.length = newLength;
    } else {
      const actionIndex = authInteractions.findIndex((a) => a.actionId === action.actionId);
      authInteractions.splice(actionIndex, 1);
    }

    dispatch({
      type: DELETE_AUTH_ACTION,
      authInteractions: authInteractions,
      credentialsInput: credentialsInput,
    });
  };
}

export function clearAllActions() {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    if (rootState.lightning.interactions.recordingAtIndex) {
      dispatch(setRecordingAtIndex(0));
    }
    dispatch({
      type: CLEAR_ALL_ACTIONS,
      actionList: [],
      inputs: [],
      variables: [],
      currentInput: [],
    });
    dispatch(setRecordingStatus());
  };
}

export function updateCurrentUrl(currentUrl: string, newHistoryIndex: number) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const appState = rootState;
    const state = cloneDeep(appState.lightning.interactions);
    const initialUrl = state.initialUrl || currentUrl;

    let currentHistoryIndex = Number.isInteger(newHistoryIndex) ? newHistoryIndex : state.currentHistoryIndex;
    const interactionState = state;
    const { browsingHistory, actionList, preventRecording: noRecording, currentAction, isReplaying } = interactionState;

    let { hasAlteredNavigation, currentVisibleAction } = interactionState;
    if (browsingHistory[currentHistoryIndex] !== currentUrl) {
      // used for creating new branch after user has hit back button then goes to a new page
      if (hasAlteredNavigation) {
        browsingHistory.pop();
        hasAlteredNavigation = false;
      } else if (!state.isReplaying || !state.isExtracting) {
        browsingHistory.push(currentUrl);
        currentHistoryIndex = browsingHistory.length === 1 ? 0 : currentHistoryIndex + 1;
      }
    }

    if (!noRecording && !isReplaying && currentAction) {
      const lastAction: InteractionAction | undefined = last(actionList);
      const isLastWaitLoading = lastAction?.name === 'WaitLoadingAction';

      // We're adding a wait loading action here so that we have a trail of what url each action is associated with
      if (currentAction.name !== 'GotoAction' && !isLastWaitLoading) {
        const newWaitAction = createInteractionAction({ action: new actions.WaitLoadingAction(null) });
        newWaitAction.url = currentUrl;
        const newWaitLoadingIndex = actionList.findIndex((a) => a.actionId === currentAction.actionId) + 1;
        actionList.splice(newWaitLoadingIndex, 0, newWaitAction);
        // if it is the last action we'll make it the current visible action
        if (newWaitLoadingIndex === actionList.length - 1) currentVisibleAction = newWaitAction;
      } else if (isLastWaitLoading && lastAction.url !== currentUrl) {
        // this updates the last WaitLoadingAction with the currentUrl for better displaying in the ui.
        lastAction.url = currentUrl;
      }
    }
    const { replaceAssets } = getSearchParams();
    dispatch({
      type: UPDATE_CURRENT_URL,
      currentUrl: currentUrl,
      initialUrl: initialUrl,
      actionList: actionList,
      browsingHistory: browsingHistory,
      currentHistoryIndex: currentHistoryIndex,
      hasAlteredNavigation: hasAlteredNavigation,
      currentVisibleAction: currentVisibleAction,
      canReplaceAssetsForPage: replaceAssets ? replaceAssets === 'true' : canReplaceAssetsForUrl(currentUrl),
    });
  };
}

export function rewindAndReplay(replayMessage?: string, playFromAction?: InteractionAction, playToAction?: InteractionAction) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { privateSession, authStatus } = rootState.lightning.interactions;
    if (!privateSession || (privateSession && authStatus === 'authenticated')) {
      dispatch(replayActionList(replayMessage, playFromAction, playToAction));
    } else {
      dispatch(replayAuthentication(replayMessage, playFromAction, playToAction));
    }
  };
}

export function replayAuthentication(
  replayMessage = 'Creating private session...',
  playFromAction?: InteractionAction,
  playToAction?: InteractionAction,
) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    dispatch(setAuthStatus('replaying-auth'));
    const { authInteractions } = cloneDeep(rootState.lightning).interactions;

    dispatch(replay(authInteractions, replayMessage, playFromAction, playToAction));
  };
}

export function replayActionList(
  replayMessage = INTERACTION_CONSTANTS.DEFAULT_REPLAY_MESSAGE,
  playFromAction?: InteractionAction,
  playToAction?: InteractionAction,
) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList, authInteractions } = cloneDeep(rootState.lightning).interactions;
    const playFromActionInAuth =
      authInteractions.length && playFromAction && authInteractions.find((a) => a.actionId === playFromAction.actionId);
    const playToActionInAuth =
      authInteractions.length && playToAction && authInteractions.find((a) => a.actionId === playToAction.actionId);

    if (playFromActionInAuth || playToActionInAuth) {
      dispatch(replayAuthentication(replayMessage, playFromAction, playToAction));
    } else {
      // ensure that we're not trying to play to/from something that doesn't exist
      dispatch(replay(actionList, replayMessage, playFromAction, playToAction));
    }
  };
}

/**
 * Will only pick actions in between of playFrom & playTo (including both)
 */
function sliceActions(
  actionList: InteractionAction[],
  playFromAction?: InteractionAction,
  playToAction?: InteractionAction,
): InteractionAction[] {
  if (!playFromAction && !playToAction) {
    return actionList;
  }

  function putInBounds(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }

  const startFromIndex = playFromAction ? actionList.findIndex((a) => a.actionId === playFromAction.actionId) : 0;
  const endIndex = playToAction ? actionList.findIndex((a) => a.actionId === playToAction.actionId) + 1 : actionList.length;

  return actionList.slice(putInBounds(startFromIndex, 0, actionList.length - 1), putInBounds(endIndex, 0, actionList.length));
}

export function replay(
  actionList: InteractionAction[],
  replayMessage,
  playFromAction?: InteractionAction,
  playToAction?: InteractionAction,
) {
  return (dispatch) => {
    let playList = Array.isArray(actionList) ? actionList : [actionList];
    if (!playList.length) {
      return;
    }

    playList = sliceActions(playList, playFromAction, playToAction);
    playList.slice().forEach((action) => {
      if (action.actions && action.actions.length) {
        // find the index of this action group because it might have changed during this process
        const newIndex = playList.findIndex((a) => a.actionId === action.actionId);
        let flattenedActions: InteractionAction[] = [];
        if (action.name === 'PaginationAction' || action.name === 'LoopAction') {
          let pages = parseInt(action.browserAction.num, 10) || 2;
          if (pages > 2 && action.name === 'PaginationAction') pages = 2;
          for (let i = 1; i <= pages; i++) {
            flattenedActions = [...flattenedActions, ...action.actions];
          }
        } else {
          flattenedActions = [...action.actions];
        }
        playList.splice(newIndex, 1);
        flattenedActions.forEach((a, index) => {
          const flattenedAction = a;
          flattenedAction.actionId = action.actionId;
          flattenedAction.isChild = true;
          playList.splice(newIndex + index, 0, flattenedAction);
        });
      }
    });
    dispatch({
      type: REPLAY_ACTIONS,
      replayMessage: replayMessage,
      playList: playList,
      playFromAction: playFromAction,
      playToAction: playToAction,
    });
  };
}

export function redoAuth() {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { authUrl } = rootState.lightning.interactions; // get authUrl before we clear it out
    dispatch(clearAuthInteractions());
    dispatch(editingAuthentication());
    dispatch(setAuthUrlAndNavigate(authUrl));
    dispatch(setAuthStatus('validation-required'));
  };
}

export function stopReplay(playbackError?: Error) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning);
    const {
      actionList,
      currentVisibleAction,
      authStatus,
      isReValidating,
      browsingHistory,
      playToAction,
      authInteractions,
      postAuthUrl,
    } = state.interactions;
    const { guid } = state.project;
    const { isCreatingTraining } = state.builder.present;
    const erroredOnAction = playbackError ? currentVisibleAction : null;

    const isPlayToActionAuth =
      playToAction && authInteractions.length && authInteractions.find((a) => a.actionId === playToAction.actionId);
    dispatch({
      type: STOP_REPLAY,
      playbackError: playbackError,
      erroredOnAction: erroredOnAction,
    });

    if (authStatus !== 'replaying-auth') return;

    if (!erroredOnAction) {
      if (actionList.length >= 2) {
        // means an initial load has been recorded
        // this is for if user has replayed auth sequence before reg sequence
        if (isPlayToActionAuth) {
          // if they selected a playto action that lives in the auth list
          // that must mean current action is on auth list so any actions recorded here will have to enter the auth list
          dispatch(editingAuthentication());
          dispatch(setAuthStatus('validation-required'));
        } else {
          dispatch(setAuthStatus('authenticated'));
          dispatch(authRevalidated());
          if (isReValidating) {
            if (isCreatingTraining) {
              // User saved an interactive auth extractor without saving it
              dispatch(toggleSidebarOpen());
              return dispatch(rewindAndReplay(undefined, undefined, playToAction));
            }
            dispatch(selectViewTab('data-view'));
            dispatch(push(`/results?extractorGuid=${guid}`));
          } else if (!isPlayToActionAuth) {
            dispatch(rewindAndReplay(undefined, undefined, playToAction));
          }
        }
      } else {
        // this sets the default data url after initial validation of auth
        dispatch(setAuthStatus('setting-data-url'));
        dispatch(stopEditingAuthentication());
        dispatch(replace(`/browse?url=${encodeURIComponent(sanitizeUrl(postAuthUrl || browsingHistory.pop()!)!)}`));
        dispatch(lightningLoaded());
      }
    } else {
      // user needs to restart their auth yo
      dispatch(setAuthStatus('started'));
      dispatch(editingAuthentication());
    }
  };
}

export function beginExtraction(suggestDataDuringExtraction = false) {
  return (dispatch) => {
    dispatch(startLoading());
    dispatch({
      type: EXTRACT_DATA_INITIATED,
      suggestDataDuringExtraction: suggestDataDuringExtraction,
    });
  };
}

export function prepareInteractionState({
  serializedActions,
  serializedAuthActions,
  doc = document,
  currentInput,
}: {
  serializedActions: ActionData[];
  serializedAuthActions: ActionData[];
  doc?: Document;
  currentInput?: InputItem;
}) {
  return (dispatch) => {
    const actionList = InteractionUtils.prepareActionsForState(serializedActions, doc);
    const authInteractions = InteractionUtils.prepareAuthActionsForState(serializedAuthActions, doc);
    const variables = InteractionUtils.createVariablesForActions(actionList, currentInput);
    const extractionType = InteractionUtils.inferExtractionType({
      interactions: actionList,
      authInteractions: authInteractions,
    });

    dispatch(
      saveActions({
        serializedActions: serializedActions,
        serializedAuthActions: serializedAuthActions,
        authInteractions: authInteractions,
        actionList: actionList,
      }),
    );

    dispatch(updateInteractionState({ variables: variables, currentInput: currentInput }));
    dispatch(setExtractionType(extractionType));
  };
}

export function beginInitialReplay(doc: Document = document) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { serializedActions, serializedAuthActions, authInteractions, credentialsInput, isReValidating } =
      rootState.lightning.interactions;

    if (authInteractions.length)
      dispatch(
        updateInteractionState({ authInteractions: InteractionUtils.subOutVariables(authInteractions, credentialsInput) }),
      );
    let replayCB = rewindAndReplay;
    if (isReValidating) {
      replayCB = replayAuthentication;
    } else {
      const { actionList } = rootState.lightning.interactions;
      if (actionList.length === 0) {
        // if for some reason we haven't initialized the interaction state (this should never happen)
        dispatch(
          prepareInteractionState({
            serializedActions: serializedActions,
            serializedAuthActions: serializedAuthActions,
            doc: doc,
          }),
        );
      }
    }
    dispatch(replayCB());
  };
}

export function removeSingleAction({ actionId, parentActionId }) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning.interactions);
    const { recordingIntoActionId } = state;

    let { actionList, inputs, variables, currentInput } = state;
    let action;
    actionList = actionList.slice();
    if (!parentActionId) {
      if (actionId === recordingIntoActionId) dispatch(setRecordingIntoActionId(null));
      action = actionList.find((a) => a.actionId === actionId);
      if (action.name === 'ExtractDataAction') {
        dispatch(enableRecording());
      }
      const actionIndex = actionList.findIndex((a) => a.actionId === action.actionId);
      actionList.splice(actionIndex, 1);
    } else {
      const parentAction = actionList.find((a) => a.actionId === parentActionId);
      if (!parentAction) return;

      const newActions = parentAction.actions.slice();

      action = newActions.find((a) => a.actionId === actionId);
      if (!action) return;

      newActions.splice(newActions.indexOf(action), 1);
      parentAction.actions = newActions;
    }

    if (action.hasVariables) {
      const newState = InteractionUtils.cleanUpForAllInputs(action.variableNames, variables, inputs, currentInput);

      inputs = newState.newInputs;
      variables = newState.newVariables;
      currentInput = newState.newCurrentInput;
    }

    dispatch({
      type: DELETE_ACTION,
      actionList: actionList,
      inputs: inputs,
      variables: variables,
      currentInput: currentInput,
    });
  };
}

export function dismissWarning(record) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList, currentAction, authInteractions, isEditingAuthActions } = cloneDeep(rootState.lightning).interactions;
    const actionListWeAreUsing = isEditingAuthActions ? authInteractions : actionList;

    if (record && currentAction) {
      // Check that current action is on our list
      const currentActionOnList = actionListWeAreUsing.find((a) => a.actionId === currentAction.actionId);
      if (currentActionOnList) {
        const newLength = actionListWeAreUsing.findIndex((a) => a.actionId === currentAction.actionId) + 1;
        const deleteAtAction = actionListWeAreUsing[newLength];
        if (deleteAtAction)
          dispatch(
            deleteAction({
              action: deleteAtAction,
              isAuthAction: isEditingAuthActions,
              parentActionId: deleteAtAction.parentActionId,
            }),
          );
      }
    }
    dispatch({ type: DISMISS_WARNING, preventRecording: !record, isRecording: record });
  };
}

export function renameVariable(
  newVariableName: string,
  oldVariableName: string,
  { isAuthAction }: { isAuthAction?: boolean } = {},
) {
  return (dispatch, getState) => {
    if (newVariableName === oldVariableName) return;

    const rootState: RootState = getState();
    const interactionState = rootState.lightning.interactions;
    const { inputs, variables, credentialsInput, actionList, authInteractions, currentInput } = interactionState;
    if (!isAuthAction) {
      const newName = normalizeName(newVariableName, oldVariableName, [...new Set(variables.map((v) => v.name))]);

      variables.forEach((variable) => {
        if (variable.name === oldVariableName) {
          variable.name = newName;
          // For existing extractors so we can update inputs attachment when we save
          if (variable.changeType !== 'CREATE') {
            variable.changeType = 'UPDATE';
          }
        }
      });

      // Update all the training inputs
      inputs.forEach((input) => {
        if (input.hasOwnProperty(oldVariableName)) {
          input[newVariableName] = input[oldVariableName];
          delete input[oldVariableName];
        }
      });
      InteractionUtils.renameVariableForActions(actionList, newName, oldVariableName);
    } else {
      const newName = normalizeName(newVariableName, oldVariableName, [...new Set(Object.keys(credentialsInput))]);
      credentialsInput[newName] = credentialsInput[oldVariableName];
      delete credentialsInput[oldVariableName];
      InteractionUtils.renameVariableForActions(authInteractions, newName, oldVariableName);
    }

    // Update the current input being played
    if (currentInput && currentInput.hasOwnProperty(oldVariableName)) {
      currentInput[newVariableName] = currentInput[oldVariableName];
      delete currentInput[oldVariableName];
    }

    dispatch(
      updateInteractionState({
        variables: variables,
        inputs: inputs,
        actionList: actionList,
        authInteractions: authInteractions,
        credentialsInput: credentialsInput,
        currentInput: currentInput,
      }),
    );
  };
}

export function changeInputValue(newInputValue: string, variableName: string, input?: InteractionsState['currentInput']) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = rootState.lightning.interactions;
    const { inputs, actionList, authInteractions, credentialsInput, currentInput } = state;
    let { variables } = state;

    // Default to current input if no input is passed
    if (!input) input = currentInput || {};

    const inputIndex = inputs.indexOf(input!);

    let variable = variables.find((v) => v.name === variableName);
    if (!variable && credentialsInput && credentialsInput[variableName]) {
      variable = {
        name: variableName,
        isArray: Array.isArray(credentialsInput[variableName]),
      };
    }
    const newVal = variable && variable.isArray ? newInputValue.split(',').map((n) => n.trim()) : newInputValue;

    let isAuthVariable = false;
    let isNormalVariable = false;

    if (credentialsInput && credentialsInput[variableName]) {
      isAuthVariable = true;
      credentialsInput[variableName] = newVal;
      InteractionUtils.findActionsAndChangeDefaultValue(authInteractions, newVal, variableName);
    }

    const changeInput = () => {
      input![variableName] = newVal;
      inputs.splice(inputIndex, 1, input!);
      if (inputIndex === 0) {
        // we only need to update the default value if this is the first input
        InteractionUtils.findActionsAndChangeDefaultValue(actionList, newVal, variableName);
        variables = variables.map((v) => {
          if (v.name === variableName) {
            v.defaultValue = newVal;
          }
          return v;
        });
      }
    };

    if (input[variableName]) {
      isNormalVariable = true;
      changeInput();
    }

    if (!isAuthVariable && !isNormalVariable) {
      // this only happens if key doesn't exist on inputs somehow
      changeInput();
    }

    dispatch(
      updateInteractionState({
        variables: variables,
        inputs: inputs,
        actionList: actionList,
        authInteractions: authInteractions,
        credentialsInput: credentialsInput,
      }),
    );
  };
}

export function addInputRow(newRow: ObjectInputItem) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { inputs } = cloneDeep(rootState.lightning.interactions);
    inputs.push(newRow);
    dispatch(updateInteractionState({ inputs: inputs }));
  };
}

export function addInputRows(newRows: ObjectInputItem[]) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning).interactions;
    const { actionList } = state;
    let { inputs } = state;
    const urls = [...new Set(inputs.map((i) => (i as ObjectInputItem)._url))];
    const newUrls = [...new Set(newRows.map((i) => i._url))];

    const isNewUrl = !newUrls.every((url) => urls.includes(url));
    const firstAction = actionList[0];
    const needToUpdateGoto = firstAction && firstAction.name === 'GotoAction' && !firstAction.hasVariables;
    if (isNewUrl && needToUpdateGoto) {
      firstAction.hasVariables = true;
      firstAction.browserAction.variables.url!.$variable$ = true;
      firstAction.browserAction.variables.url!.name = '_url';
    }
    inputs = [...inputs, ...newRows];
    dispatch(updateInteractionState({ inputs: inputs, actionList: actionList }));
  };
}

export function deleteInputRow(input: ObjectInputItem & any) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { inputs } = rootState.lightning.interactions;
    if (input._meta && input._meta.pageId) {
      dispatch(removePageById(input._meta.pageId));
    }
    const index = inputs.indexOf(input);
    const newInputs = inputs.slice();
    newInputs.splice(index, 1);
    dispatch(updateInteractionState({ inputs: newInputs }));
  };
}

export function renameInputUrl(newUrl: string, oldUrl: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    let inputs = cloneDeep(rootState.lightning.interactions).inputs as ObjectInputItem[];
    newUrl = normalizeName(newUrl, oldUrl, [...new Set(inputs.map((i) => i._url!))], 'New url');
    inputs = inputs.map((i) => {
      if (i._url === oldUrl) {
        i._url = newUrl;
      }
      return i;
    });
    dispatch(updateInteractionState({ inputs: inputs }));
  };
}

export function playSequenceWithInput(input: ObjectInputItem, addToTraining: boolean) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = rootState.lightning;
    const { selectedViewTab } = state.ui;
    const { actionList } = state.interactions;
    dispatch(
      updateInteractionState({
        actionList: InteractionUtils.subOutVariables(actionList, input),
        currentInput: input,
      }),
    );
    dispatch(changeInputsModalStatus({ isVisible: false }));
    if (!addToTraining) {
      dispatch(rewindAndReplay(INTERACTION_CONSTANTS.REPLAY_FOR_INPUT_MESSAGE));
    } else {
      if (selectedViewTab !== 'record-view') dispatch(selectViewTab('record-view'));
      dispatch({ type: REPLAY_AND_SUGGEST_FOR_INPUT });
    }
  };
}

export function deleteInputUrl(url: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { inputs } = cloneDeep(rootState.lightning.interactions);
    const newInputs = inputs.filter((input) => getUrlFromInput(input) !== url);
    dispatch(updateInteractionState({ inputs: newInputs }));
  };
}

export function removePageFromInputs(pageId: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { builder, interactions } = rootState.lightning;
    const { inputs } = interactions;
    inputs.forEach((input) => {
      if (isObject(input) && isObject(input._meta) && (input._meta as any).pageId === pageId) {
        delete (input._meta as any).pageId;
      }
    });
    const currentInput = inputs.find((i) => isObject(i) && (i._meta as any)?.pageId === builder.present.selectedPage?.id);
    dispatch(updateInteractionState({ inputs: inputs, currentInput: currentInput }));
  };
}

export function deleteVariableForAction(action: InteractionAction, variableType: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning).interactions;
    const variable = action.variables[variableType];
    if (!variable) return;

    let actionList = state.actionList;
    if (variable.name) {
      if (action.parentActionId) {
        const parentAction = actionList.find((a) => a.actionId === action.parentActionId);
        if (!parentAction) return;
        parentAction.actions = InteractionUtils.removeVariableFromActions(variable.name, parentAction.actions);
      } else {
        actionList = InteractionUtils.removeVariableFromActions(variable.name, state.actionList);
      }
      const variables = state.variables.filter((v) => v.name !== variable.name);
      const inputs = state.inputs.map((input) => {
        if (input[variable.name]) {
          delete input[variable.name];
        }
        return input;
      });
      dispatch(updateInteractionState({ variables: variables, actionList: actionList, inputs: inputs }));
    }
  };
}

export function makeVariableForAction(action: InteractionAction, variableType: string) {
  return (dispatch) => {
    if (action.parentActionId) {
      return dispatch(makeVariableForChildAction(action, variableType));
    } else {
      return dispatch(makeVariableForStandardAction(action, variableType));
    }
  };
}

function makeVariableForChildAction(action: InteractionAction, variableType: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning).interactions;
    const { actionList, variables, inputs } = state;
    const parentAction = actionList.find((a) => a.actionId === action.parentActionId);
    let { currentInput } = state;
    if (!parentAction) return;

    action = parentAction.actions.find((a) => a.actionId === action.actionId)!;
    if (!action) return;

    const variable = action.variables[variableType];
    const defaultName = action.variable?.name || 'New Input';
    const variableName = normalizeName(defaultName, undefined, [...new Set(variables.map((v) => v.name))], defaultName);
    const isArray =
      action.name === 'SelectChangeAction' || action.name === 'FunctionAction' || action.name === 'WaitForFunctionAction';

    if (!variable) return;

    variable.$variable$ = action.hasVariables = true;
    variable.name = variableName;
    currentInput = inputs.find((i) => isEqual(i, currentInput)) || inputs[0];
    inputs.forEach((i) => {
      i[variable.name] = variable.defaultValue;
    });

    // const actionIndex = parentAction.actions.findIndex(a => a.actionId === action.actionId);
    const parentActionIndex = actionList.findIndex((a) => a.actionId === parentAction.actionId);
    const newActions = parentAction.actions.slice();
    parentAction.actions = newActions;
    let indexToSlice = actionList.length;
    variables.forEach((v, idx) => {
      const indexOnActionList = actionList.findIndex((a) => a.actionId === v.actionId);
      if (indexOnActionList && indexOnActionList < parentActionIndex) {
        indexToSlice = idx;
      }
    });
    variables.splice(indexToSlice, 0, {
      actionId: action.actionId,
      name: variable.name,
      canEdit: true,
      isArray: isArray,
    });
    dispatch(
      updateInteractionState({
        variables: variables,
        actionList: actionList,
        inputs: inputs,
        currentInput: currentInput,
      }),
    );
  };
}

function makeVariableForStandardAction(action: InteractionAction, variableType: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning).interactions;
    const { actionList, variables, inputs } = state;
    const variable = action.variables[variableType];
    const defaultName = variable?.name || 'New Input';
    const variableName = normalizeName(defaultName, undefined, [...new Set(variables.map((v) => v.name))], defaultName);
    const isArray =
      action.name === 'SelectChangeAction' || action.name === 'FunctionAction' || action.name === 'WaitForFunctionAction';
    let { currentInput } = state;
    if (!variable) return;

    variable.$variable$ = action.hasVariables = true;
    variable.name = variableName;
    currentInput = inputs.find((i) => isEqual(i, currentInput)) || inputs[0];

    // if is a url type, default value could be an object so watch out
    const varDefaultValue =
      variableType === 'url' ? ((variable.defaultValue as any) || {}).url || variable.defaultValue : variable.defaultValue;
    inputs.forEach((i) => {
      i[variable.name] = varDefaultValue;
    });

    const actionIndex = actionList.findIndex((a) => a.actionId === action.actionId);
    actionList.splice(actionIndex, 1, action);

    let indexToSlice = actionList.length;
    variables.forEach((v, idx) => {
      const indexOnActionList = actionList.findIndex((a) => a.actionId === v.actionId);
      if (indexOnActionList && indexOnActionList < actionIndex) {
        indexToSlice = idx;
      }
    });
    variables.splice(indexToSlice, 0, {
      actionId: action.actionId,
      name: variable.name,
      canEdit: action.name !== 'GotoAction',
      isArray: isArray,
    });
    dispatch(
      updateInteractionState({
        variables: variables,
        actionList: actionList,
        inputs: inputs,
        currentInput: currentInput,
      }),
    );
  };
}

export function changeWaitForAction(actionId: string, newWaitTime: number, isAuthAction: boolean = false) {
  return (dispatch, getState) => {
    if (newWaitTime < 1) {
      return;
    }

    const rootState: RootState = getState();
    const { actionList, authInteractions } = cloneDeep(rootState.lightning).interactions;
    const actionListWeAreUsing = isAuthAction ? authInteractions : actionList;
    actionListWeAreUsing.forEach((a) => {
      if (a.actionId === actionId) {
        a.browserAction.timeout = newWaitTime;
      } else if (a.actions && Array.isArray(a.actions)) {
        a.actions.forEach((ac) => {
          if (ac.actionId === actionId) {
            ac.browserAction.timeout = newWaitTime;
          }
        });
      }
    });
    dispatch(updateInteractionState({ actionList: actionList, authInteractions: authInteractions }));
  };
}

export function clearActionList() {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList, variables, inputs, recordingIntoActionId, recordingAtIndex, currentInput } = cloneDeep(
      rootState.lightning,
    ).interactions;
    const newActions: InteractionAction[] = [];
    const deletingActions: InteractionAction[] = [];
    actionList.forEach((a) => {
      if (a.isInitialLoad) {
        newActions.push(a);
      } else {
        deletingActions.push(a);
      }
    });
    const results = InteractionUtils.cleanUpForAllActions(deletingActions, variables, inputs, currentInput!);
    dispatch(
      updateInteractionState({
        actionList: newActions,
        variables: results.newVariables,
        inputs: results.newInputs,
        currentInput: results.newCurrentInput,
      }),
    );
    if (recordingIntoActionId) {
      dispatch(setRecordingIntoActionId(null));
    }
    if (recordingAtIndex) {
      dispatch(setRecordingAtIndex(0));
    }
  };
}

export function createActionGroup(draggingAction, draggingToAction, doc) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    let groupName = '';
    let action;
    let { variables, inputs, currentInput } = cloneDeep(rootState.lightning).interactions;
    const { actionList, recordingIntoActionId, recordingAtIndex } = cloneDeep(rootState.lightning).interactions;
    const indexOfDraggingToAction = actionList.findIndex((a) => a.actionId === draggingToAction.actionId);
    const indexOfDraggingAction = actionList.findIndex((a) => a.actionId === draggingAction.actionId);
    const startIndex = indexOfDraggingToAction < indexOfDraggingAction ? indexOfDraggingToAction : indexOfDraggingAction;
    const endIndex = indexOfDraggingToAction < indexOfDraggingAction ? indexOfDraggingAction : indexOfDraggingToAction;
    const numberToRemove = endIndex - startIndex + 1;
    const doesDraggingActionHaveNum = INTERACTION_CONSTANTS.NUMBER_VARIABLE_ACTIONS.includes(draggingAction.name);
    const doesDraggingToHaveNum = INTERACTION_CONSTANTS.NUMBER_VARIABLE_ACTIONS.includes(draggingToAction.name);
    const recordingIntoOneOfThese =
      recordingIntoActionId === draggingAction.actionId || recordingIntoActionId === draggingToAction.actionId;

    // Gather the actions for the group, flatten actions that are already groups
    const actionGroup: actions.Action[] = actionList
      .slice(startIndex, endIndex + 1)
      .map((a) => a.browserAction)
      .reduce((a: actions.Action[], b) => {
        // @ts-ignore
        const actionName = b.displayName || b.constructor.displayName;
        if (b.actions) {
          if (actionName === 'FlowControlAction') {
            groupName = b.name;
          }
          return a.concat(b.actions.slice());
        } else {
          return a.concat(b);
        }
      }, []);

    // If either of these actions have a num variable, we'll be sure to keep at least one
    if (doesDraggingActionHaveNum || doesDraggingToHaveNum) {
      const bothHaveNum = doesDraggingActionHaveNum && doesDraggingToHaveNum;
      let removeVariableForActionId = '';
      if (bothHaveNum || !doesDraggingActionHaveNum) {
        action = createInteractionAction({
          action: draggingToAction.browserAction,
          actionId: draggingToAction.actionId,
        });
        removeVariableForActionId = doesDraggingActionHaveNum && draggingAction.actionId;
      } else {
        action = createInteractionAction({ action: draggingAction.browserAction, actionId: draggingAction.actionId });
        removeVariableForActionId = doesDraggingToHaveNum && draggingToAction.actionId;
      }

      const actionActions = actionGroup.map((a) => createInteractionAction({ action: a }));
      action.actions = actionActions;

      if (removeVariableForActionId) {
        const results = InteractionUtils.cleanUpForAllInputs(action.variableNames, variables, inputs, currentInput);

        variables = results.newVariables;
        inputs = results.newInputs;
        currentInput = results.newCurrentInput;

        dispatch(
          updateInteractionState({
            variables: variables,
            inputs: inputs,
            currentInput: currentInput,
          }),
        );
      }
    } else {
      if (!groupName)
        groupName = normalizeName(
          'Group',
          undefined,
          [...new Set(actionList.filter((a) => a.name === 'FlowControlAction').map((a) => a.browserAction.name))],
          'Group',
        );
      action = new InteractionAction({ action: new actions.FlowControlAction(doc, actionGroup, groupName) });
    }

    actionList.splice(startIndex, numberToRemove, action);
    // reset recording at index
    if (recordingAtIndex) {
      dispatch(setRecordingAtIndex(recordingAtIndex));
    }
    if (recordingIntoOneOfThese) {
      dispatch(setRecordingIntoActionId(action.actionId));
    }

    dispatch(updateInteractionState({ actionList: actionList }));
  };
}

export function createLoopAction(action, doc, waitAction) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const interactionState = cloneDeep(rootState.lightning.interactions);
    const { actionList, currentInput, inputs, variables } = interactionState;
    const removeIndex = actionList.findIndex((a) => a.actionId === action.actionId);
    const waitLoadingAction = new actions.WaitLoadingAction(null);

    if (action.name === 'ScrollAction') {
      // remove these values in context of a loop action so that it scrolls to the bottom
      action.browserAction.x = undefined;
      action.browserAction.y = undefined;
    }

    const childActions = action.name === 'FlowControlAction' ? [...action.browserAction.actions] : [action.browserAction];
    if (waitAction) {
      childActions.push(waitAction);
    }
    childActions.push(waitLoadingAction);

    const loopAction = new InteractionAction({
      action: new actions.LoopAction(doc, childActions, 1),
    });

    actionList.splice(removeIndex, 1, loopAction);

    const variableConfig = InteractionUtils.configureVariableNewAction(loopAction, variables, inputs);
    dispatch(
      updateInteractionState({
        variables: variableConfig.variables,
        inputs: variableConfig.inputs,
        currentInput: currentInput,
        actionList: actionList,
      }),
    );
  };
}

export function setVirtualBrowserId({ virtualBrowserId }: { virtualBrowserId?: string }) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = rootState.lightning;
    const { guid } = state.project;
    if (virtualBrowserId) {
      const { credentialsInput } = state.interactions;
      if (guid && credentialsInput)
        sessionStorage.setItem(
          `extractor-${guid}-pi`,
          btoa(JSON.stringify({ virtualBrowserId: virtualBrowserId, credentialsInput: credentialsInput })),
        );
    } else {
      if (state.interactions.authStatus === 'authenticated') {
        dispatch(setAuthStatus('validation-required'));
      }
      if (guid) sessionStorage.removeItem(`extractor-${guid}-pi`);
    }

    dispatch({ type: RECEIVED_VIRTUAL_BROWSER_ID, virtualBrowserId: virtualBrowserId });
  };
}

export function validateAuthentication() {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const state = cloneDeep(rootState.lightning.interactions);
    let { authInteractions, actionList, authUrl, variables, inputs } = state;
    const { initialUrl, isAuthNoPI } = state;
    const credentialsInput: {
      username?: string;
    } = {};
    if (!authInteractions.length) {
      authUrl = initialUrl;
      authInteractions = actionList.map((a) => {
        if (a.name === 'GotoAction') {
          authUrl = a.url; // set the authUrl, to be used in UI and also saved on runtimeConfig
          a.variables.url!.$variable$ = false; // turn off variable for url
        }
        a.isAuth = true;
        return a;
      });
      actionList = [];

      if (isAuthNoPI) {
        //Set variables to empty if we are recording an auth only extractor
        variables = [];
      } else {
        // prep auth state by deleting all inputs other than initialUrl
        variables = variables.filter((v) => !v.canEdit);
      }

      inputs.forEach((i, idx) => {
        Object.entries(i).forEach(([key, value]) => {
          if (key !== '_url' && key !== '_meta') {
            if (idx === 0) {
              let newKey = key;
              if (!key.toLowerCase().includes('password') && !credentialsInput.username) {
                // all non password variables will be given the "username" property
                newKey = normalizeName('username', key, [...new Set(Object.keys(credentialsInput))], 'username');
                InteractionUtils.renameVariableForActions(authInteractions, newKey, key);
              }
              credentialsInput[newKey] = value;
            }
            delete i[key];
          }
        });
      });

      if (isAuthNoPI) {
        //Set inputs to empty if we are recording an auth only extractor
        inputs = [];
      }
    }

    dispatch({
      type: VALIDATE_AUTHENTICATION,
      authInteractions: authInteractions,
      actionList: actionList,
      authUrl: authUrl,
      credentialsInput: credentialsInput,
    });

    dispatch(updateInteractionState({ variables: variables, inputs: inputs, currentInput: inputs[0] }));
    dispatch(replayAuthentication());
  };
}

export function setCredentialsAndReplay(credentialsInput) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { authInteractions } = rootState.lightning.interactions;
    dispatch(setCredentials(credentialsInput));
    dispatch(setVirtualBrowserId({ virtualBrowserId: '' }));
    dispatch(updateInteractionState({ authInteractions: InteractionUtils.subOutVariables(authInteractions, credentialsInput) }));
    dispatch(replayAuthentication());
  };
}

export function onSaveFunctionAction({
  action,
  newArg,
  newCallback,
  newVariableName,
  isAuthAction,
}: {
  action: FunctionAction;
  newArg: string;
  newCallback: string;
  newVariableName: string;
  isAuthAction: boolean;
}) {
  return (dispatch) => {
    const variable: any = action.variable || {};
    const oldVariableName = variable.name;
    action.callback = newCallback;
    variable.defaultValue = (newArg || '').split(',');
    if (!oldVariableName) variable.name = newVariableName;
    if ((newArg.length || newVariableName) && !action.hasVariables) {
      action.hasVariables = true;
      variable.$variable$ = true;
    }
    if (!newArg && !newVariableName && action.parentActionId) {
      action.hasVariables = false;
      variable.$variable$ = false;
      dispatch(deleteVariableForAction(action, action.parentActionId));
    }
    dispatch(updateAction(action));
    if (action.hasVariables) {
      if (oldVariableName) {
        dispatch(changeInputValue(newArg, oldVariableName!));
        dispatch(renameVariable(newVariableName, oldVariableName, { isAuthAction: isAuthAction }));
      } else dispatch(makeVariableForAction(action, 'args'));
    }
  };
}

export function addExtractionPage(url: string, html: string, timestamp: number) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { interactionPages, canReplaceAssetsForPage, currentInput } = rootState.lightning.interactions;
    const input = cloneDeep(currentInput);

    interactionPages.push(
      new Page({
        url: url,
        html: html,
        timestamp: timestamp,
        input: inputToObject(input) || undefined,
      }),
    );

    dispatch(setInteractionPages(interactionPages));
  };
}

export function setInteractionPages(interactionPages) {
  return (dispatch) => dispatch({ type: SET_INTERACTION_PAGES, interactionPages: interactionPages });
}

export function setRecordingIntoActionId(recordingIntoActionId: string | null) {
  return (dispatch) => dispatch({ type: SET_RECORDING_INTO_ACTION_ID, recordingIntoActionId: recordingIntoActionId });
}

export function breakActionGroupAtIndex(parentActionId: string, index: number) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList } = cloneDeep(rootState.lightning.interactions);
    const parentActionIndex = actionList.findIndex((a) => a.actionId === parentActionId);
    const parentAction = actionList[parentActionIndex];

    if (!parentAction || !parentAction.actions) return;

    const parentActionListCopy = parentAction.actions.slice();
    const spreadActions = parentActionListCopy.slice(index, parentActionListCopy.length);
    spreadActions.forEach((a) => (a.parentActionId = undefined)); // remove parentId reference
    actionList.splice(parentActionIndex + 1, 0, ...spreadActions);

    parentActionListCopy.length = index;

    if (parentActionListCopy.length) parentAction.actions = parentActionListCopy;
    else actionList.splice(parentActionIndex, 1);

    dispatch(updateInteractionState({ actionList: actionList }));
  };
}

export function changeActionGroupName(actionId: string, newName: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList } = cloneDeep(rootState.lightning.interactions);
    const action = actionList.find((a) => a.actionId === actionId);
    if (!action) return;
    action.browserAction.name = normalizeName(
      newName,
      action.browserAction.name,
      [...new Set(actionList.filter((a) => a.name === 'FlowControlAction').map((a) => a.browserAction.name))],
      'Group',
    );
    dispatch(updateInteractionState({ actionList: actionList }));
  };
}

export function updateAction(action: InteractionAction, isAuth: boolean = false) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { actionList, authInteractions } = cloneDeep(rootState.lightning.interactions);
    const actionListWeAreUsing = isAuth || action.isAuth ? authInteractions : actionList;
    actionListWeAreUsing.forEach((a, idx) => {
      if (action.parentActionId && a.actions && a.actionId === action.parentActionId) {
        const childActionIndex = a.actions.findIndex((childAction) => childAction.actionId === action.actionId);
        let childAction = a.actions[childActionIndex];
        if (childAction) {
          childAction = Object.assign(action, {});
          a.actions.splice(childActionIndex, 1, childAction);
        }
        a.actions = a.actions.slice(); // resets browser actions
      } else if (a.actionId === action.actionId) {
        actionListWeAreUsing[idx] = Object.assign(action, {});
      }
    });
    dispatch(updateInteractionState({ actionList: actionList, authInteractions: authInteractions }));
  };
}

export function convertToCaptchaAction(action, isAuth) {
  return (dispatch) => {
    if (!action.browserAction || !action.browserAction.toCaptchaAction) return;

    const captchaAction = action.browserAction.toCaptchaAction();
    if (!captchaAction) return;

    const newAction = createInteractionAction({
      ...action,
      action: captchaAction,
    });
    dispatch(updateAction(newAction, isAuth));
    dispatch(replay([newAction], 'Solving Captcha...'));
  };
}

export function updateCurrentInput(key: string, value: any, isAuth: boolean) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    if (!isAuth) {
      const currentInput = Object.assign({}, rootState.lightning.interactions.currentInput);
      currentInput[key] = value;
      dispatch(updateInteractionState({ currentInput: currentInput }));
    } else {
      const credentialsInput = { ...rootState.lightning.interactions.credentialsInput };
      credentialsInput[key] = value;
      dispatch(setCredentials(credentialsInput));
    }
  };
}

export function setActionVarDefaultValue(action, variableType, newDefaultValue) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    action.changeVariableDefaultValue(variableType, newDefaultValue);
    dispatch(updateAction(action));
    const { variables: oldVariables } = cloneDeep(rootState.lightning.interactions);
    let updateVariables = false;
    const variables = oldVariables.map((v) => {
      if (v.actionId === action.actionId && v.defaultValue !== newDefaultValue) {
        v.defaultValue = newDefaultValue;
        updateVariables = true;
      }

      return v;
    });

    if (updateVariables) {
      dispatch(updateInteractionState({ variables: variables }));
    }
  };
}

export function setPostAuthUrl(postAuthUrl: string) {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const { pathname } = rootState.router.location!;
    const query = Object.entries(getSearchParams())
      .map(([key, value]) => {
        return `${key}=${encodeURIComponent(value!.toString())}`;
      })
      .join('&');
    const newUrl = `${pathname}?${query}&dataUrl=${encodeURIComponent(sanitizeUrl(postAuthUrl)!)}`;
    dispatch(replace(newUrl));
    dispatch({ type: SET_POST_AUTH_URL, postAuthUrl: postAuthUrl });
  };
}

export function closeUrlDataFrame() {
  return (dispatch, getState) => {
    dispatch({ type: CLOSE_URL_DATA_FRAME });
    const rootState: RootState = getState();
    const uiState = rootState.lightning.ui;
    if (uiState.selectedViewTab !== 'data-view') dispatch(selectViewTab('data-view'));
    if (uiState.isLoading) dispatch(setReplayMessage('Looking for data...'));
  };
}

export function problemProcessingPage(showProcessingPageError) {
  return (dispatch) => {
    dispatch({
      type: SHOW_PROCESSING_PAGE_ERROR,
      showProcessingPageError: showProcessingPageError,
      isRecordingInitialLoad: false,
    });
  };
}

export function onConfirmInteractiveUrlChange() {
  return (dispatch) =>
    dispatch({
      type: CONFIRM_INTERACTIVE_URL_CHANGE,
      interactiveUrlChange: true,
    });
}

export function onCancelInteractiveUrlChange() {
  return (dispatch) =>
    dispatch({
      type: CANCEL_INTERACTIVE_URL_CHANGE,
      interactiveUrlChange: false,
    });
}
