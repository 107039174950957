export function getUrlFromTraining(training) {
  let initialUrl;
  if (training) {
    let trainingPages = (Array.isArray(training.pages) && training.pages) || [];
    if (training.pageSets) {
      trainingPages = Object.values(training.pageSets)
        .map((val) => val.pages)
        .flat();
    }
    if (trainingPages.length > 0) initialUrl = trainingPages[0].url;
  }
  return initialUrl;
}
