import type { ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { UserSubscriptionData } from '@import-io/types/user-subscription-types';
import { PlanType } from '@import-io/types/user-subscription-types';

import { dayjs } from 'common/date';
import SubscriptionExpiry from 'features/user/subscription/components/SubscriptionExpiry';
import { PLAN_TITLES } from 'features/user/subscription/subscription-constants';
import type { SubscriptionErrorBody } from 'features/user/subscription/subscription-types';
import { RecurlyErrorCode, RecurlyErrorStatus, SubscriptionMethod } from 'features/user/subscription/subscription-types';

export const isActiveSubscription = (subscription: UserSubscriptionData | null): boolean =>
  isPresent(subscription) && !subscription.deactivated;

export const isTrialSubscription = (subscription: UserSubscriptionData | null): boolean =>
  isPresent(subscription) && subscription.trial;

export const isVerifiedSubscription = (subscription: UserSubscriptionData | null): boolean =>
  isPresent(subscription) && subscription.planCode !== PlanType.UNVERIFIED;

export const canPerformQuery = (subscription: UserSubscriptionData | null): boolean =>
  isPresent(subscription) && subscription.overageDisabled ? subscription.usage < subscription.maxUsage : true;

export const canUpgrade = (planCode: string): boolean => planCode !== PlanType.ADVANCED;

export const isUnverifiedSubscription = (subscription: UserSubscriptionData | null): boolean =>
  !isPresent(subscription) || subscription.planCode === PlanType.UNVERIFIED;

export const getExpiryText = (expiry: number): string => dayjs(expiry).format('MMMM DD, YYYY');

export const getSubscriptionText = ({
  autoRenew,
  custom,
  deactivated,
  expiry,
  planCode,
  trial,
}: UserSubscriptionData): JSX.Element | string => {
  if (!custom && !deactivated && planCode !== PlanType.UNVERIFIED) {
    if (trial) {
      return <SubscriptionExpiry expiry={expiry} prefix="Your trial ends on " />;
    }
    if (autoRenew) {
      return <SubscriptionExpiry expiry={expiry} prefix="Your subscription renews on " />;
    }
    return <SubscriptionExpiry expiry={expiry} prefix="Your plan will end on " />;
  }
  return '';
};

export const getPlanTitle = (subscription: UserSubscriptionData | null): string => {
  if (!isPresent(subscription)) {
    return '';
  }
  const { additionalFields, planCode } = subscription;
  const displayName = isPresent(additionalFields) ? additionalFields.displayName : undefined;
  if (isPresent(displayName)) {
    return displayName;
  }
  return PLAN_TITLES[planCode] ?? '';
};

const ERROR_METHODS_MAP: Record<SubscriptionMethod, string> = {
  [SubscriptionMethod.CANCEL]: 'canceling',
  [SubscriptionMethod.GET]: 'getting',
  [SubscriptionMethod.UPDATE]: 'updating',
};

export const getErrorMessage = (e: unknown, method: SubscriptionMethod): string => {
  const { message, body } = e as ResponseError<SubscriptionErrorBody>;
  const {
    error,
    message: bodyMessage = message,
    recurlyError,
  }: SubscriptionErrorBody = isPresent(body) ? body : { error: message };
  console.error(`Couldn't ${method} user subscription.`, e);
  if (isPresent(recurlyError)) {
    const { customerMessage, errorCode, status } = recurlyError;
    return errorCode === RecurlyErrorCode.DECLINED || status === RecurlyErrorStatus.ERROR ? customerMessage : bodyMessage;
  }

  return `Error ${ERROR_METHODS_MAP[method]} subscription: ${isPresent(error) ? error : bodyMessage}`;
};
