/* eslint-disable @typescript-eslint/no-use-before-define */
import { isUrl } from '@import-io/typeguards';
import type {
  Extractor,
  RuntimeConfigurationField,
  RuntimeConfigurationFieldType,
  RuntimeConfigurationSettings,
} from '@import-io/types';
import { getUrlFromInput, inputToObject, RuntimeConfigurationWrapper } from '@import-io/types';
import type { PageSetOptions } from '@import-io/web-extractor';
import { Page, PageSet, selectByFullXPath, trainField } from '@import-io/web-extractor';
import message from 'antd/lib/message';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { v4 as uuidv4 } from 'uuid';

import type { PresentBuilderState } from 'app/lightning-old/lightning-types';
import type { RootState } from 'common/common-types';
import { fetchUrlList } from 'features/extractors/api/extractors-api';

import { getFields, getPages, getPageSet } from '../selectors/builder';

import { beginExtraction, removePageFromInputs } from './interactions';
import { createProject, getDataForUrl, REFRESHING_HTML_COMPLETE } from './project';
import { selectViewTab, setCurrentUrl, setData, stopLoading, toggleSelectorPanel } from './ui';

export const CREATE_STATE = 'CREATE_STATE';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_STATE_NO_UNDO = 'UPDATE_STATE_NO_UNDO';
export const RESET_STATE = 'RESET_STATE';
export const REQUEST_RECOMPUTE = 'REQUEST_RECOMPUTE';
export const SET_ACTIVE_EXTRACTOR_CONFIG = 'SET_ACTIVE_EXTRACTOR_CONFIG';
export const TOGGLE_SCREEN_CAPTURE = 'TOGGLE_SCREEN_CAPTURE';
export const ADD_NEW_EXTRACTOR_CONFIG = 'ADD_NEW_EXTRACTOR_CONFIG';
export const SET_HAS_SAVED = 'SET_HAS_SAVED';
export const LOAD_DEFAULT_CONFIG = 'LOAD_DEFAULT_CONFIG';
export const TOGGLE_DEFAULT_VALUE_PANEL = 'TOGGLE_DEFAULT_VALUE_PANEL';
export const CREATING_TRAINING = 'CREATING_TRAINING';
export const CREATING_TRAINING_FINISHED = 'CREATING_TRAINING_FINISHED';
export const SET_PAGE_SET = 'SET_PAGE_SET';
export const FOCUS_COLUMN_NAME = 'FOCUS_COLUMN_NAME';
export const UNFOCUS_COLUMN_NAME = 'UNFOCUS_COLUMN_NAME';

// ======== Sagas ========= \\
export const ADD_FIELD_SAGA = 'ADD_FIELD_SAGA';
export const RENAME_FIELD_SAGA = 'RENAME_FIELD_SAGA';
export const createState = (newState) => ({ type: CREATE_STATE, ...newState });
export const requestRecompute = () => ({ type: REQUEST_RECOMPUTE });
export const addFieldSaga = (builderState, newField) => ({
  type: ADD_FIELD_SAGA,
  builderState: builderState,
  newField: newField,
});
export const renameFieldSaga = (builderState) => ({ type: RENAME_FIELD_SAGA, builderState: builderState });
export const updateState = (newState) => ({ type: UPDATE_STATE, ...newState });
export const updateStateNoUndo = (newState) => ({ type: UPDATE_STATE_NO_UNDO, ...newState });
export const resetState = () => ({ type: RESET_STATE });
export const setActiveExtractorConfig = (activeExtractorConfig) => ({
  type: SET_ACTIVE_EXTRACTOR_CONFIG,
  activeExtractorConfig: activeExtractorConfig,
});
export const toggleScreenCapture = (extractorConfig) => ({ type: TOGGLE_SCREEN_CAPTURE, extractorConfig: extractorConfig });
export const setHasSaved = ({ hasSaved }) => ({ type: SET_HAS_SAVED, hasSaved: hasSaved });
export const setPageSet = (pageSet: PageSet) => ({ type: SET_PAGE_SET, pageSet: pageSet });

// ========================= \\

export function toggleOverlays() {
  return (dispatch, getState) => {
    const state = cloneDeep((getState() as RootState).lightning.builder.present);

    state.overlaysEnabled = !state.overlaysEnabled;
    dispatch(createState(state));
  };
}

function getRequiredStates(getState: () => any): {
  activeExtractorConfig: string;
  builderState: PresentBuilderState;
  pageSet: PageSet;
  state: RootState;
} {
  const state: RootState = getState();
  const pageSet = getPageSet(state);
  const builderState = state.lightning.builder.present;
  const { activeExtractorConfig } = builderState;
  return cloneDeep({
    state: state,
    pageSet: pageSet,
    builderState: builderState,
    activeExtractorConfig: activeExtractorConfig,
  });
}

/**
 * Initializes the new session of Builder.
 * @param  {PageSetOptions} pageSetOptions PageSet object used to initialize the new session.
 */
export function initializeBuilder(pageSetOptions: PageSetOptions) {
  return (dispatch, getState) => {
    const state = cloneDeep((getState() as RootState).lightning.builder.present);
    state.pageSet = new PageSet(pageSetOptions);
    state.selectedPage = state.pageSet?.pages?.[0];
    dispatch(createState(state));
    dispatch(requestRecompute());
  };
}

/**
 * Normalize the field name for whitespace & update such that there isn't a clash
 */
export function normalizeName(
  newFieldName: string | undefined,
  oldFieldName: string | undefined,
  fields: RuntimeConfigurationField[],
) {
  // normalize spaces
  let newFieldName2 = newFieldName?.replace(/\s+/g, ' ')?.trim() || oldFieldName || 'New column';

  const sameFieldNumbers = fields
    .filter((f) =>
      f.name.match(new RegExp(`^${newFieldName2.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')}\\s?\\(?(\\d+)?\\)?$`, 'gm')),
    )
    .map((f) => /\s?\(?(\d+)?\)?$/gm.exec(f.name)?.[1])
    .map((val) => (!val ? '0' : val))
    .map((val) => parseInt(val, 10));

  if (sameFieldNumbers.length !== 0) {
    newFieldName2 = `${newFieldName2} (${Math.max(...sameFieldNumbers) + 1})`;
  }

  return newFieldName2;
}

/**
 * Adds a field, creating a new Field object, and making it the selected field
 */
export function addField(fieldData: Partial<RuntimeConfigurationField>, position: number = 0) {
  return (dispatch, getState): RuntimeConfigurationField => {
    const field: RuntimeConfigurationField = {
      id: uuidv4(),
      name: fieldData.name ?? 'New column',
      type: fieldData.type ?? 'AUTO',
      singleValue: false,
      ...fieldData,
    };
    const [newState, newField] = addFieldToState(field, position, getState);
    dispatch(addFieldSaga(newState, newField));
    dispatch(selectRenamingField(newField));
    return newField!;
  };
}

export function cloneField(fieldId: string) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const fields = getFields(state);
    const field = fields.find((f) => f.id === fieldId);
    const position = fields.findIndex((f) => f.id === field?.id) + 1;
    if (field) {
      const clonedField: Partial<RuntimeConfigurationField> = clone(field);
      delete clonedField.id;
      dispatch(addField(clonedField, position));
      dispatch(requestRecompute());
    }
  };
}

export function selectRenamingField(renamingField: RuntimeConfigurationField) {
  return (dispatch) => {
    dispatch(updateStateNoUndo({ renamingField: renamingField }));
  };
}

export function selectNextField() {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const fields = getFields(state);
    const builderState = cloneDeep(state.lightning.builder.present);
    const selectedField = getSelectedField(builderState);
    const { renamingField } = builderState;

    const newFieldIndex = fields.findIndex((f) => f.id === selectedField?.id) + 1;
    const isLastField = newFieldIndex > fields.length - 1;
    const newField = isLastField ? fields[0] : fields[newFieldIndex];
    builderState.selectedField = newField;
    if (renamingField) {
      builderState.renamingField = newField ?? null;
    }
    dispatch(updateStateNoUndo(builderState));
    dispatch(selectField(newField));
  };
}

export function selectPreviousField() {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const fields = getFields(state);
    const builderState = cloneDeep(state.lightning.builder.present);
    const selectedField = getSelectedField(builderState);
    const { renamingField } = builderState;

    const newFieldIndex = fields.findIndex((f) => f.id === selectedField?.id) - 1;
    const isFirstField = newFieldIndex < 0;
    const newField = isFirstField ? fields[fields.length - 1] : fields[newFieldIndex];
    builderState.selectedField = newField;
    if (renamingField) {
      builderState.renamingField = newField ?? null;
    }
    dispatch(updateStateNoUndo(builderState));
    dispatch(selectField(newField));
  };
}

/**
 * Performs check to see if we need to update fields in state. Checks to see if a new field has been added or reordered.
 * @param {Array} fields current set of fields that has been updated.
 */
export function updateFields(fields: RuntimeConfigurationField[]) {
  return (dispatch, getState) => {
    const { builderState: newBuilderState, pageSet: newPageSet } = getRequiredStates(getState);

    newPageSet.fields = fields;
    newBuilderState.pageSet = newPageSet;
    dispatch(updateStateNoUndo(newBuilderState));
  };
}

/**
 * Adds a new page to the existing pageSet.
 * @param {object} resource Page resource as received from `ServerBrowserApi.getDataForPage`.
 */
export function addPage(resource) {
  return (dispatch, getState) => {
    const { state, builderState: newBuilderState } = getRequiredStates(getState);
    const lightningState = state.lightning;

    const page = new Page({
      url: resource.url,
      html: resource.html,
      timestamp: resource.timestamp,
    });

    const input = lightningState.interactions.currentInput;
    if (input) {
      // @ts-ignore
      page.input = {
        ...inputToObject(input),
        _meta: { pageId: page.id },
      };
    }
    newBuilderState.pageSet.addPage(page);
    newBuilderState.selectedPage = page;

    dispatch(updateState(newBuilderState));
    dispatch(requestRecompute());
    const refreshingPageId = state.lightning.project.refreshingPageId;
    if (refreshingPageId) {
      const oldPage = getPages(state).find((p) => p.id === refreshingPageId);
      if (oldPage && !getFields(state).find((f) => f.xpath) && Object.keys(oldPage.fieldExamples).length === 0) {
        dispatch(removePageById(refreshingPageId));
      }
      dispatch({ type: REFRESHING_HTML_COMPLETE });
    }
  };
}

export function removePageById(pageId: string) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const page = getPages(state).find((p) => p.id === pageId);
    if (page) {
      dispatch(removePage(page));
    }
  };
}

/**
 * Changes currently selected page.
 * @param  {Page} page Page object to be selected.
 */
export const selectPage = (page: Page) => (dispatch) => {
  if (page && page.url) {
    // So here we need to set selected field to null
    // This is because we can only select the field and show the highlighted items AFTER the iframe is ready
    // BuilderSiteRenderer.js will take care of selecting this field again using lastSelectedField
    dispatch(
      updateStateNoUndo({
        selectedPage: page,
        selectedField: null,
      }),
    );
    dispatch(requestRecompute());
    dispatch(toggleSelectorPanel(false));
    dispatch(setCurrentUrl(page.url));
  }
};

/**
 * Changes currently selected field
 * @param  {Field | undefined} field Field object to be selected.
 */
export function selectField(field?: RuntimeConfigurationField) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const fields = getFields(state);
    const builderState = cloneDeep(state.lightning.builder.present);

    // initialize the examples the user has made on the page
    const counterExampleElements: Node[] = [];
    const exampleElements: Node[] = [];

    if (field && builderState.selectedPage && builderState.doc) {
      builderState.selectedPage.fieldExamplesFor(field).forEach((example) => {
        const el = selectByFullXPath(example.xpath || '', builderState.doc!);
        if (!el) {
          // If we remove the example it may have been available in the script/noscript version
          // ex.splice(ex.indexOf(example), 1);
          console.warn(`Path not found for ${field.name} ${example.xpath}`);
          return;
        }
        // @ts-ignore
        el.__example = example._id;
        if (example.isCounterExample) {
          counterExampleElements.push(el);
        } else {
          exampleElements.push(el);
        }
      });
    }

    const selectedField = field ? fields.find((f) => f.id === field.id) : null;
    const selectedFieldIndex = field ? fields.findIndex((f) => f.id === selectedField?.id) : -1;

    dispatch(
      updateStateNoUndo({
        selectedField: selectedField,
        selectedFieldIndex: selectedFieldIndex,
        exampleElements: exampleElements,
        counterExampleElements: counterExampleElements,
        lastSelectedField: selectedField || builderState.selectedField,
      }),
    );
  };
}

/**
 * Clear the current examples for the selected field.
 * Will clear the selector and the current overlays.
 */
export function clearSelectedFieldExamples() {
  return (dispatch, getState) => {
    const state = cloneDeep(getState());
    const builderState = state.lightning.builder.present;
    const selectedField = getSelectedField(builderState);
    if (!selectedField) return;
    selectedField.type = 'AUTO';
    getPages(state).forEach((page) => {
      page.fieldExamplesFor(selectedField).length = 0;
      if (builderState.selectedPage.id === page.id) {
        builderState.selectedPage = page;
      }
    });
    selectedField.xpath = undefined;
    selectedField.manualSelector = undefined;
    selectedField.microPath = undefined;
    selectedField.selector = undefined;
    builderState.selectedField = selectedField;
    dispatch(updateState(builderState));
    dispatch(selectField(builderState.selectedField));
    dispatch(requestRecompute());
  };
}

/**
 * Renames currently selected field.
 * @param  {String} newName New name that will be given to currently selected field.
 */
export function renameSelectedField(newName: string) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const builderState = cloneDeep(state.lightning.builder.present);
    const selectedField = getSelectedField(builderState);
    if (!selectedField) return;

    const oldName = selectedField.name;
    const otherFields = getFields(state).filter((f) => f.id !== selectedField.id);
    selectedField.name = normalizeName(newName, oldName, otherFields);
    builderState.selectedField = selectedField;

    dispatch(renameFieldSaga(builderState));
  };
}

export function changeSelectedDownloadContent() {
  return (dispatch, getState) => {
    const state = cloneDeep((getState() as RootState).lightning.builder.present);
    const selectedField = getSelectedField(state);
    if (!selectedField) return;

    if (selectedField.downloadContent) {
      selectedField.downloadContent = null;
    } else {
      const data = state.data;

      const srcValues: any[] = [];
      const hrefValues: any[] = [];
      const textValues: any[] = [];

      // result = [ { group: [ { col: [ { linkToDownload } ] } ] } ]
      for (const result of Array.isArray(data) ? data : []) {
        // for data in [ { group: [ ] } ]
        for (const row of Array.isArray(result.group) ? result.group : []) {
          // for row in [ { col: [] } ]
          const columns = row[selectedField.name] || [];
          // for columnName in { col: [ { src, linkToDownload } ] }

          for (const columnObj of columns) {
            if (columnObj?.src) {
              srcValues.push(columnObj.src);
            }

            if (columnObj?.href) {
              hrefValues.push(columnObj.href);
            }

            if (columnObj?.text) {
              textValues.push(columnObj.text);
            }
          }
        }
      }

      const areThereDistinctSrcValues = srcValues.length > 1 && uniq(srcValues).length > 1;
      const areValuesValidLinks = {
        text: textValues.length > 0 && textValues.every((q) => isUrl(q)),
        href: hrefValues.length > 0 && hrefValues.every((q) => isUrl(q)),
        src: srcValues.length > 0 && srcValues.every((q) => isUrl(q)),
      };

      // If these are distinct images, just use `src` property.
      if (areThereDistinctSrcValues) {
        selectedField.downloadContent = 'src';

        // Check if there are some `href` values, and use those instead.
      } else if (hrefValues.length) {
        selectedField.downloadContent = 'href';

        // If we don't have `href` values, maybe text values are links?
      } else if (areValuesValidLinks.text) {
        selectedField.downloadContent = 'text';

        // Ok, text values are also not useful, can't be smarter than that.
        // Let's use `src` values if there are any, even if they are the same.
        // Maybe user really wants to get same image multiple times...
        // For an IMAGE column, always fallback to `src` property.
      } else if (srcValues.length || selectedField.type === 'IMAGE') {
        selectedField.downloadContent = 'src';

        // If there are no `href` or `src` values, just use the text value, and hope it is ok...
      } else {
        selectedField.downloadContent = 'text';
      }
    }

    state.selectedField = selectedField;

    dispatch(updateState(state));
    dispatch(selectField(selectedField));
    dispatch(requestRecompute());
  };
}

/**
 * Changes the selected field xpath selector.
 * @param  {String} xpath New xpath value.
 */
export function changeSelectedFieldXPath(xpath) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);
    const selectedField = getSelectedField(builderState);
    if (!selectedField) return;
    selectedField.xpath = xpath ?? null;
    // clearing the data for a css selector if the xpath panel is chosen
    selectedField.manualSelector = undefined;
    builderState.selectedField = selectedField;

    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    dispatch(selectField(selectedField));
    dispatch(requestRecompute());
  };
}

/**
 * Changes the selected field css selector.
 * @param  {String} css New css selector value.
 */
export function changeSelectedFieldCssSelector(cssSelector: string) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);
    const selectedField = getSelectedField(builderState);
    if (!selectedField) return;
    selectedField.manualSelector = cssSelector ? cssSelector : undefined;
    // clearing the data for xpath if the css selector panel is chosen
    selectedField.xpath = undefined;
    builderState.selectedField = selectedField;

    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    dispatch(selectField(selectedField));
    dispatch(requestRecompute());
  };
}

/**
 * Changes the row xpath selector.
 * @param  {String} xpath New xpath value.
 */
export function changeRecordXPath(xpath: string) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);
    pageSet.recordXPath = xpath ? xpath : undefined;
    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

export function toggleSingleRecord(singleRecord: boolean) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);

    builderState.pageSet.singleRecord = singleRecord;

    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

/**
 * Changes the selected field type.
 * @param  {String} type New type value.
 */
export function changeSelectedFieldType(type: RuntimeConfigurationFieldType) {
  return (dispatch, getState) => {
    const state = cloneDeep((getState() as RootState).lightning.builder.present);
    const selectedField = getSelectedField(state);
    if (!selectedField) return;

    selectedField.type = type;
    state.selectedField = selectedField;
    dispatch(updateState(state));
    dispatch(selectField(selectedField));
    dispatch(requestRecompute());
  };
}

/**
 * Deletes field from pageSet.
 * @param  {Field} field Field to be deleted.
 */
export function deleteField(field: RuntimeConfigurationField) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);
    const idx = (pageSet.fields ?? []).findIndex((f) => f.id === field.id);

    if (idx < 0) return;

    pageSet.fields!.splice(idx, 1);

    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    const newFields = pageSet.fields!;
    if (newFields.length === 0) {
      dispatch(addField({}, 0));
    } else {
      dispatch(selectField(newFields[idx > newFields.length - 1 ? idx - 1 : idx]));
      dispatch(requestRecompute());
    }

    dispatch(toggleSelectorPanel(false));
  };
}

export function clearData() {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);
    pageSet.fields = [];
    pageSet.recordXPath = undefined;
    pageSet.recordSelector = undefined;

    builderState.pageSet = pageSet;
    dispatch(updateStateNoUndo(builderState));
    dispatch(requestRecompute());
    dispatch(toggleSelectorPanel(false));
  };
}

/**
 * Sets whether a field is required.
 * @param  {Field} field Field to be updated.
 * @param  {boolean} isRequired Whether or not required.
 */
export function setFieldRequired(field: RuntimeConfigurationField, isRequired: boolean) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);
    const idx = pageSet.fields.findIndex((f) => f.id === field.id);

    if (idx < 0) return;

    pageSet.fields[idx]!.required = isRequired;
    builderState.pageSet = pageSet;

    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

/**
 * Sets whether a field should output HTML, rather than text content.
 * @param  {Field} field Field to be updated.
 * @param  {boolean} isHTML Whether or not output should be HTML.
 */
export function setHTMLField(field: RuntimeConfigurationField, isHTML: boolean) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);

    const idx = pageSet.fields.findIndex((f) => f.id === field.id);
    if (idx < 0) return;

    pageSet.fields[idx]!.html = isHTML;
    builderState.pageSet = pageSet;

    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

/**
 * Sets whether a field should capture link.
 * @param  {Field} field Field to be updated.
 * @param  {boolean} capture Whether or not output should capture link.
 */
export function setCaptureLinkField(field: RuntimeConfigurationField, capture: boolean) {
  return (dispatch, getState) => {
    const { state, builderState, pageSet } = getRequiredStates(getState);

    const idx = getFields(state).findIndex((f) => f.id === field.id);
    if (idx < 0) return;

    pageSet.fields[idx]!.captureLink = capture;
    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

/**
 * @param  {Field} field Field to be updated.
 * @param  {string} type
 */
export function setFieldType(field: RuntimeConfigurationField, type: RuntimeConfigurationFieldType) {
  return (dispatch, getState) => {
    const { state, builderState, pageSet } = getRequiredStates(getState);

    const idx = getFields(state).findIndex((f) => f.id === field.id);
    if (idx < 0) return;

    // updated this line from type to convertTo, in order to allow extractors to save with a type
    pageSet.fields[idx]!.type = 'TEXT';
    pageSet.fields[idx]!.convertTo = type;
    builderState.pageSet = pageSet;
    builderState.selectedField = pageSet.fields[idx];
    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

/**
 * @param  {Field} field Field to be updated.
 * @param  {boolean} singleValue
 */
export function setFieldSingleValue(field: RuntimeConfigurationField, singleValue: boolean) {
  return (dispatch, getState) => {
    const { state, builderState, pageSet } = getRequiredStates(getState);

    const idx = getFields(state).findIndex((f) => f.id === field.id);
    if (idx < 0) return;
    pageSet.fields[idx]!.singleValue = singleValue;
    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

/**
 * Sets the value to be used for given field, if the row does not have its own value for that field.
 * @param  {Field} field Field to be tested.
 * @param  {string|number} value Default value.
 */
export function setDefaultValue(field: RuntimeConfigurationField, value: string) {
  return (dispatch, getState) => {
    const { state, builderState, pageSet } = getRequiredStates(getState);

    const idx = getFields(state).findIndex((f) => f.id === field.id);
    if (idx < 0) return;

    pageSet.fields[idx]!.defaultValue = value;
    builderState.pageSet = pageSet;
    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

/**
 * Sets the regex match and replace values to be applied to the given field.
 * @param  {Field} field Field to which the regex will be applied.
 * @param  {string} match The expression to be matched.
 * @param  {string} replace The replacement string.
 * @param commit
 */
export function setRegex(field, match, replace, commit: boolean = false) {
  return (dispatch, getState) => {
    const { state, builderState, pageSet } = getRequiredStates(getState);

    const idx = getFields(state).findIndex((f) => f.id === field.id);
    if (idx < 0) return;

    pageSet.fields[idx]!.regExp = match;
    pageSet.fields[idx]!.regExpReplace = replace;

    builderState.pageSet = pageSet;

    if (commit) {
      dispatch(updateState(builderState));
    } else {
      dispatch(updateStateNoUndo(builderState));
    }

    dispatch(requestRecompute());
  };
}

/**
 * Sets the locale and time zone fields.
 * @param  {string} locale The locale.
 * @param  {string} timeZone The timezone.
 */
export function setLocaleAndTimeZone(locale: string | undefined, timeZone: string | undefined) {
  return (dispatch, getState) => {
    const { builderState } = getRequiredStates(getState);

    const { pageSet } = builderState;
    pageSet.locale = locale;
    pageSet.tz = timeZone;

    dispatch(updateState(builderState));
    dispatch(requestRecompute());
  };
}

export function removePage(page: Page) {
  return (dispatch, getState) => {
    const { state, builderState, pageSet } = getRequiredStates(getState);
    const lightningState = state.lightning;
    const { inputs } = lightningState.interactions;

    const idx = getPages(state).findIndex((p) => p.id === page.id);
    if (idx < 0) return;

    pageSet.pages.splice(idx, 1);

    builderState.pageSet = pageSet;

    dispatch(updateState(builderState));
    dispatch(requestRecompute());
    dispatch(selectPage(pageSet.pages[idx > pageSet.pages.length - 1 ? idx - 1 : idx]!));
    if (inputs.length) {
      dispatch(removePageFromInputs(page.id));
    }
  };
}

/**
 * Handler for onClick event, when user clicks on an element on the page.
 * @param  {HTMLElement}  element     HTML element on which user clicked.
 * @param  {Boolean} isCounterExample Information if given element was selected as example or counter example.
 */
export function elementClicked(element, isCounterExample = false) {
  if (element.nodeType !== 1) {
    element = element.parentNode;
  }

  return (dispatch, getState) => {
    const { builderState, pageSet: oldPageSet } = getRequiredStates(getState);

    let pageSet = oldPageSet;
    let selectedField = getSelectedField(builderState);

    try {
      if (selectedField) {
        builderState.selectedPage = pageSet.pages.find((p) => p.id === builderState.selectedPage?.id) || pageSet.pages[0]!;
        const example = trainField(pageSet, builderState.selectedPage, selectedField, element, isCounterExample);
        element.__example = example.id;

        // TODO: need to initialize these on load
        if (isCounterExample) {
          builderState.counterExampleElements = [...builderState.counterExampleElements, element];
        } else {
          builderState.exampleElements = [...builderState.exampleElements, element];
        }
        builderState.selectedField = selectedField;

        dispatch(updateState(builderState));
        dispatch(selectField(selectedField));
        dispatch(requestRecompute());
      }
    } catch (e) {
      console.error('Error selecting element:', e);
      void message.error('Unable to extract data from the selected element.');
    }
  };
}

/**
 * Handler for onClick event, when user clicks to remove an element from examples list.
 * @param  {HTMLElement} elt      Element that user clicked on.
 * @param  {Array} elements List of examples or counter examples, depending on which one element belonged.
 */
export function removeExample(elt, elements) {
  return (dispatch, getState) => {
    const { builderState, pageSet } = getRequiredStates(getState);
    const selectedField = getSelectedField(builderState);
    if (!selectedField) return;
    const examples = builderState.selectedPage?.fieldExamplesFor(selectedField) || [];
    examples.splice(
      examples.findIndex((e) => e.id === elt.__example),
      1,
    );
    elements.splice(elements.indexOf(elt), 1);
    builderState.exampleElements.splice(elements.indexOf(elt), 1);
    builderState.selectedField = selectedField;
    // calculateAndSetFieldSelector(pageSet, selectedField);
    // calculateAndSetRegionsSelector(pageSet);

    builderState.pageSet = pageSet;

    dispatch(updateState(builderState));
    dispatch(selectField(selectedField));
    dispatch(requestRecompute());
  };
}

/**
 * Recomputes extracted data.
 */
export function recomputeExtraction() {
  return (dispatch, getState) => {
    const { pageSet, builderState } = getRequiredStates(getState);
    if (!pageSet) return;
    let data: any[] = [];
    const runtimeConfig = pageSet.config;
    if (builderState.selectedPage) {
      if (pageSet && pageSet.singleRecord) {
        pageSet.pages.forEach((page) => {
          const pageData = page.extract(runtimeConfig);
          if (!data) {
            data = pageData;
          } else {
            data.push(...pageData);
          }
        });
      } else {
        const page = builderState.selectedPage;
        data = page.extract(runtimeConfig);
      }
    }
    //Set flag in UI for whether we have empty data or not
    dispatch(setData(!!(data && data.length > 0)));

    dispatch(updateStateNoUndo({ data: data }));
  };
}

export function toggleCssRendering() {
  return (dispatch, getState) => {
    const state = cloneDeep((getState() as RootState).lightning.builder.present);
    dispatch(updateState({ cssDisabled: !state.cssDisabled }));
  };
}

/**
 * Adds a new field to the state object.
 */
export function addFieldToState(
  field: RuntimeConfigurationField,
  position: number,
  getState: () => RootState,
): [PresentBuilderState, RuntimeConfigurationField] {
  const state: RootState = getState();

  const pageSet: PageSet = cloneDeep(getPageSet(state));
  field.name = normalizeName(field.name, undefined, pageSet?.fields || []);
  pageSet.addField(field, position);

  const newState: PresentBuilderState = {
    ...state.lightning.builder.present,
    pageSet: pageSet,
  };
  return [newState, field];
}

function getSelectedField(builder): RuntimeConfigurationField | null {
  return builder.selectedField ? builder.pageSet.fields.find((f) => f.id === builder.selectedField.id) : null;
}

export function setRegexField(field: RuntimeConfigurationField) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const builderState = cloneDeep(state.lightning.builder.present);
    const regexEditField = field ? getFields(state).find((f) => f.id === field.id) : null;
    builderState.regexEditField = regexEditField ?? undefined;
    dispatch(updateStateNoUndo(builderState));
  };
}

export function setCssRendering(cssDisabled: boolean) {
  return (dispatch) => {
    dispatch(updateStateNoUndo({ cssDisabled: cssDisabled }));
  };
}

export function loadDefaultConfig(config: PageSet = new PageSet()) {
  return (dispatch, getState) => {
    const { currentInput } = (getState() as RootState).lightning.interactions;
    // Ensure it has the basics, sometimes we'll get fields without pages or vice versa
    config.fields = config.fields || [];
    config.pages = config.pages || [];
    config.pages.forEach((p) => {
      p.input = { ...inputToObject(currentInput) }; // add current input on pages
    });

    dispatch({
      type: LOAD_DEFAULT_CONFIG,
      config: config,
    });
  };
}

// toggles default modal on data table.js
// sends in the selectedField
export function toggleDefaultValuePanel(isDefaultValuePanelVisible: boolean) {
  return (dispatch, getState) => {
    const { selectedField } = (getState() as RootState).lightning.builder.present;

    dispatch({
      type: TOGGLE_DEFAULT_VALUE_PANEL,
      isDefaultValuePanelVisible: isDefaultValuePanelVisible,
      selectedField: selectedField,
    });
  };
}

export const focusColumnName = () => (dispatch) =>
  dispatch({
    type: FOCUS_COLUMN_NAME,
  });

export const unfocusColumnName = () => (dispatch) =>
  dispatch({
    type: UNFOCUS_COLUMN_NAME,
  });

export function createTraining(extractor: Extractor) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: CREATING_TRAINING });

      const state: RootState = getState();
      const lightningState = cloneDeep(state.lightning);
      const rtc: any = lightningState.project.runtimeConfiguration;
      const hasInteraction: boolean = lightningState.project.hasInteraction;
      const activeExtractorConfig: string = lightningState.builder.present.activeExtractorConfig;

      if (hasInteraction && (!rtc || isEmpty(rtc?.extractionConfigs))) {
        // If we get to this it means the user saved without training, so just take them back to where they were
        dispatch(createProject({ ...extractor, activeExtractorConfig: activeExtractorConfig }));
        dispatch(selectViewTab('record-view', true));
        dispatch(stopLoading());
        return;
      }

      const pageSets: Record<string, RuntimeConfigurationSettings> = {};

      const rtcWrapper = new RuntimeConfigurationWrapper(rtc);
      const runtimeConfiguration = rtcWrapper.config;
      runtimeConfiguration.fields = runtimeConfiguration.fields || [];

      let pageSetName: string = lightningState.builder.present.activeExtractorConfig;
      if (rtc && rtc?.extractionConfigs) {
        pageSetName = Object.keys(rtc?.extractionConfigs)[0] ?? pageSetName;
      }
      pageSets[pageSetName] = runtimeConfiguration;

      const pageSet = new PageSet(Object.values(pageSets)[0]);

      dispatch(setPageSet(pageSet));

      dispatch(createProject({ ...extractor, activeExtractorConfig: activeExtractorConfig }, pageSet));

      if (hasInteraction) {
        // Extractor hasInteraction
        const { currentInput, inputs, extractionType } = lightningState.interactions;
        let trainingInput = currentInput;
        if (!trainingInput || isEmpty(trainingInput)) {
          trainingInput = inputs[0] || {};
        }
        if (extractionType === 'basic') {
          dispatch(getDataForUrl(getUrlFromInput(trainingInput)));
        } else if (extractionType === 'interactive') {
          dispatch(beginExtraction());
          dispatch(selectViewTab('record-view'));
        }
      } else if (extractor.urlList) {
        const urls = await fetchUrlList(extractor);
        if (!urls || !urls.length) return;
        dispatch(getDataForUrl(urls[0]));
      }
    } catch (e) {
      console.error('Error creating training', e);
    }
  };
}

export function clearPages() {
  return (dispatch, getState) => {
    const rootState: RootState = getState();
    const pageSet: PageSet = cloneDeep(rootState.lightning.builder.present.pageSet);
    pageSet.pages = [];

    dispatch(updateStateNoUndo({ pageSet: pageSet }));
  };
}
