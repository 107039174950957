// TODO: split constants into separate files by usage purpose

export const LOADER_MAX_WAIT_TIME = 30;

export const INCOMPATIBLE_URLS = [
  /^https?:\/\/([^\/]+\.)?facebook\.com/,
  /^https?:\/\/([^\/]+\.)?linkedin\.com/,
  /^https?:\/\/([^\/]+\.)?angieslist\.com/,
];

export const WRONG_URLS = [/^https?:\/\/www\.google\.[^\/]+\/?$/];

export const URL_GREYLIST = [
  {
    regex: /^https?:\/\/([^\/]+\.)?rickyabell\.me/,
    message: 'Custom reason here...',
    title: 'Custom title here...',
  },
];

export const SUGGESTED_REGEX_FORMULA = [
  {
    name: 'Number',
    description:
      'Find a number. Accepts whole numbers, decimals, scientific notation, and financial notation. Examples: 1956, -16, +12.5, 9.99, 1.67e-12, 1,234,567.89. If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '[+-]?\\d+(,\\d{3})*(\\.\\d+)?(e[+-]?\\d+)?',
    replace: '',
  },
  {
    name: 'Number with currency',
    description:
      'Find a number preceeded by a currency symbol. If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '[+-]?[$\\u00A3\\u20AC\\u00A5]{1}\\d+(,\\d{3})*(\\.\\d+)?(e[+-]?\\d+)?',
    replace: '',
  },
  {
    name: 'Percentage',
    description:
      'Find a number with percentage. Examples: 3.5%, 12%, -7.99%, 1,234%.  If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '[+-]?\\d+(,\\d{3})*(\\.\\d+)?(e[+-]?\\d+)?%',
    replace: '',
  },
  {
    name: 'URL',
    description: 'Find a URL. If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '\\b(ftp|https?):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?\\b',
    replace: '',
  },
  {
    name: 'Email Address',
    description: 'Find an email address. If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*',
    replace: '',
  },
  {
    name: 'Phone Number',
    description:
      'Find a Phone Number. Very forgiving, so it should accept all sorts of international numbers, but might also match invalid phone numbers. If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '[+#*\\(\\)\\[\\]]*([0-9][ ext+-pw#*\\(\\)\\[\\]]*){6,45}',
    replace: '',
  },
  {
    name: 'US Phone Number',
    description: 'Find a US Phone Number. If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '(\\+?(\\d{1,3}))?([-. (\\/]*(\\d{3})[-. )\\/]*)?((\\d{3})[-. \\/]*(\\d{2,4})([-.ext(ension)* ]*(\\d+))?)\\s*',
    replace: '',
  },
  {
    name: 'IP Address',
    description: 'Find an IP Address. If multiple instances are found, they are saved as a semicolon-separated list.',
    match: '\\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\b',
    replace: '',
  },
];

export const GET_EXTRACTOR_QUERY_KEY = 'GET_EXTRACTOR_QUERY_KEY';

export const GET_EXTRACTOR_TRAINING_QUERY_KEY = 'GET_EXTRACTOR_TRAINING_QUERY_KEY';

export const EXTRACTOR_LIST_QUERY_KEY = 'EXTRACTOR_LIST_QUERY_KEY';
export const EXTRACTOR_NAMES_MAP_QUERY_KEY = ['EXTRACTOR_NAMES_MAP_QUERY_KEY'];

export const GET_RUNTIME_CONFIG_QUERY_KEY = 'GET_RUNTIME_CONFIG_QUERY_KEY';

export const GET_EXTRACTOR_INPUTS_QUERY_KEY = 'GET_EXTRACTOR_INPUTS_QUERY_KEY';

export const GET_EXTRACTOR_QUALITY_DATA_QUERY_KEY = 'GET_EXTRACTOR_QUALITY_DATA_QUERY_KEY';
