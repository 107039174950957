import cloneDeep from 'lodash/cloneDeep';
import { put, takeEvery, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { RECEIVED_HTML, SELECT_CONFIG_FOR_EXTRACT_INTERACTION_SAGA, updateInteractionState } from '../actions/interactions';
import { resourceResponseObtained } from '../actions/ui';

function* createResource() {
  const state = yield select();
  const { html, htmlTimestamp, initialUrl } = state.lightning.interactions;
  if (!html) {
    return;
  }

  const resource = {
    statusCode: 200,
    html: html,
    content: html,
    timestamp: htmlTimestamp,
    url: initialUrl,
    resourceId: uuidv4(),
  };

  yield put(resourceResponseObtained(resource));
}

function* selectConfigForExtractInteraction(action) {
  const state = yield select();
  const { actionId, extractorConfig } = action;
  const { actionList } = state.lightning.interactions;
  const newExtractActionIndex = actionList.findIndex((interaction) => {
    return interaction.actionId === actionId;
  });
  const newExtractAction = cloneDeep(actionList[newExtractActionIndex]);
  newExtractAction.browserAction.runtimeConfig = extractorConfig;
  newExtractAction.browserAction.variables.runtimeConfig.name = extractorConfig;
  actionList[newExtractActionIndex] = newExtractAction;
  yield put(updateInteractionState({ actionList: actionList }));
}

export default [
  takeEvery(RECEIVED_HTML, createResource),
  takeEvery(SELECT_CONFIG_FOR_EXTRACT_INTERACTION_SAGA, selectConfigForExtractInteraction),
];
