import type { CSSProperties, JSX } from 'react';
import { useCallback, useMemo, useState } from 'react';

import ArrowsAltOutlined from '@ant-design/icons/ArrowsAltOutlined';
import DiffFilled from '@ant-design/icons/DiffFilled';
import RedoOutlined from '@ant-design/icons/RedoOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { isPresent } from '@import-io/typeguards';
import type { Report } from '@import-io/types/report-types';
import { ReportType } from '@import-io/types/report-types';
import Space from 'antd/lib/space';
import Spin from 'antd/lib/spin';
import Tooltip from 'antd/lib/tooltip';

import SidebarDropdown from 'app/dash-old/components/common/SidebarDropdown';
import { REPORT_FEATURES, REPORT_TYPES } from 'app/dash-old/lib/constants/reports';
import type { SortByValue } from 'common/common-types';
import IconButton from 'common/components/buttons/IconButton';
import type { PageNavigationItemProps } from 'common/components/layout/layout-types';
import PageNavigation from 'common/components/layout/PageNavigation';
import PageSidebar from 'common/components/layout/PageSidebar';
import PageSidebarButton from 'common/components/layout/PageSidebarButton';
import PageSidebarInput from 'common/components/layout/PageSidebarInput';
import COLORS from 'common/constants/colors';
import { DEFAULT_SORT_BY_VALUE } from 'common/constants/sort-constants';
import { useSearch } from 'common/hooks/use-search';
import { useSortBy } from 'common/hooks/use-sort-by';
import { singleValueStoreCreator } from 'common/utils/store-utils';
import { useCreateReportDisabled } from 'features/reports/common/hooks/use-create-report-disabled';
import type { ExtendedReport, ReportTypeFilter } from 'features/reports/common/reports-types';
import { getReportUrl } from 'features/reports/common/reports-utils';
import { useCreateReportModalStore } from 'features/reports/forms/create/create-report-form-hooks';
import { useReports } from 'features/reports/list/report-list-hooks';
import { selectSubscriptionQueryData } from 'features/user/subscription/subscription-query';

const btnContainerStyle: CSSProperties = {
  padding: '10px',
};

const refreshIconStyle: CSSProperties = {
  color: COLORS.WHITE,
};

const searchPrefix = <SearchOutlined style={{ color: COLORS.WHITE }} />;

const initialTypeFilter: ReportTypeFilter = {
  [ReportType.CRAWL_DIFF]: false,
  [ReportType.CRAWL_REPORT]: false,
};

const getIcon = (icon: string, name: string, updating: boolean): JSX.Element | null => {
  if (updating) {
    return <Spin />;
  }
  if (icon === 'ArrowsAltOutlined') {
    return (
      <Tooltip title={name}>
        <ArrowsAltOutlined />
      </Tooltip>
    );
  }
  if (icon === 'DiffFilled') {
    return (
      <Tooltip title={name}>
        <DiffFilled />
      </Tooltip>
    );
  }
  return null;
};

const itemRenderCallback = ({ guid, name, type, updating = false }: ExtendedReport) => {
  const feature = REPORT_FEATURES[type];
  return {
    icon: isPresent(feature) ? getIcon(feature.icon, feature.name, updating) : null,
    key: guid,
    label: name,
    url: getReportUrl(guid),
  };
};

const useSortStore = singleValueStoreCreator<SortByValue<Report>>('reports-sort', DEFAULT_SORT_BY_VALUE);

const useReportsSearchStore = singleValueStoreCreator<string>('reports-search', '');

// TODO: most of the code was copied from DisplayItemsList and requires refactoring
const ReportsSidebar = (): JSX.Element => {
  const { isFetching, data: reports = [], refetch } = useReports();
  const reFetchReports = useCallback(() => refetch(), [refetch]);
  const { toggleValue } = useCreateReportModalStore();

  const sortStore = useSortStore();
  const [sortBy, handleSortByChange, sortCallback] = useSortBy<Report>(sortStore);
  const { isDescending, property } = sortBy;

  const searchStore = useReportsSearchStore();
  const [search, handleSearch, searchFilterCallback] = useSearch<Report>(searchStore);

  const [typeFilter, setTypeFilter] = useState<ReportTypeFilter>(initialTypeFilter);
  const toggleTypeFilter = useCallback(
    (checked: boolean, type: ReportType) => {
      setTypeFilter((prev) => ({ ...prev, [type]: !checked }));
    },
    [setTypeFilter],
  );
  const typeFilterCallback = useCallback(({ type }) => !(typeFilter[type] as boolean), [typeFilter]);
  const items = useMemo<PageNavigationItemProps[]>(
    () =>
      reports.length === 0
        ? []
        : reports.filter(typeFilterCallback).filter(searchFilterCallback).sort(sortCallback).map(itemRenderCallback),
    [reports, searchFilterCallback, sortCallback, typeFilterCallback],
  );

  const subscription = selectSubscriptionQueryData();
  const features = subscription?.features;
  const availableReportTypes = useMemo(
    () => (isPresent(features) ? REPORT_TYPES.filter((report) => features.includes(report.features.permission)) : []),
    [features],
  );

  const createReportDisabled = useCreateReportDisabled();

  return (
    <PageSidebar showLogo showSubscription>
      <div className="full-width" style={btnContainerStyle}>
        <Space className="full-width" direction="vertical">
          <PageSidebarButton
            disabled={createReportDisabled}
            disabledTooltip="At least one Extractor is required to create a report"
            onClick={toggleValue}
            title="New Report"
          />
          <div className="flex full-width">
            <PageSidebarInput
              initialValue={search}
              onChange={handleSearch}
              placeholder="Search..."
              prefix={searchPrefix}
              suffix={
                <SidebarDropdown
                  availableReports={availableReportTypes}
                  hideDisplayItemTypes={typeFilter}
                  isDescending={isDescending}
                  isReports
                  sortBy={handleSortByChange}
                  sortingByProperty={property}
                  toggleTypeFilter={toggleTypeFilter}
                />
              }
            />
            <IconButton
              color="white"
              disabled={isFetching}
              icon={<RedoOutlined />}
              onClick={reFetchReports}
              style={refreshIconStyle}
            />
          </div>
        </Space>
      </div>
      <PageNavigation isLoading={isFetching} items={items} />
    </PageSidebar>
  );
};

export default ReportsSidebar;
