import type { JSX } from 'react';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import PageLoader from 'common/components/PageLoader';
import { appRoutes } from 'common/routes/app-routes';

const loadingPage = <PageLoader />;

const AppRoutes = (): JSX.Element => {
  const routes = useRoutes(appRoutes);
  return <Suspense fallback={loadingPage}>{routes}</Suspense>;
};

export default AppRoutes;
