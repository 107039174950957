import type { JSX, MouseEvent } from 'react';
import { useCallback, useState } from 'react';

import CheckOutlined from '@ant-design/icons/CheckOutlined';
import { isPresent } from '@import-io/typeguards';
import Space from 'antd/lib/space';

import COLORS from 'common/constants/colors';

import type { MenuItemProps } from './menu-item-types';
import styles from './MenuItem.module.less';

const MenuItem = ({
  width,
  text,
  onClick,
  className,
  antIcon,
  iconHoverColor,
  icon,
  checked = false,
  selected = false,
  disabled = false,
  active = false,
}: MenuItemProps): JSX.Element => {
  const [hovering, setHovering] = useState(false);
  const outlinedIcon = <CheckOutlined className={styles.magenta} />;
  const fill = isPresent(iconHoverColor) ? (hovering ? iconHoverColor : COLORS.TEXT_DARK) : undefined;

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!active && !disabled && isPresent(onClick)) {
        onClick(e);
      }
    },
    [active, disabled, onClick],
  );
  const onMouseEnter = useCallback(() => setHovering(true), [setHovering]);
  const onMouseLeave = useCallback(() => setHovering(false), [setHovering]);

  return (
    <div
      className={`flex align-center menu-item ${className} ${styles.menuItem} ${disabled ? styles.disabled : ''}`}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Space>
        {active || isPresent(antIcon) || isPresent(icon) ? (
          <span style={{ width: width }}>
            {isPresent(active) && active ? outlinedIcon : null}
            {isPresent(antIcon) && antIcon}
            {isPresent(icon) && <icon.type active={hovering} fill={fill} />}
          </span>
        ) : null}
        <span>{text}</span>
        {checked || selected ? <span>{outlinedIcon}</span> : null}
      </Space>
    </div>
  );
};

export default MenuItem;
