import type { ThunkMiddleware } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { EXTRACTOR_RECEIVED } from 'app/dash-old/actions/extractors';
import { rootReducer } from 'app/reducers';
import { createReduxHistory, routerMiddleware } from 'app/redux-router';
import type { RootState } from 'common/common-types';
import { IS_PROD } from 'common/constants/common-constants';

import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

// TODO: check if we really need that
const hiddenActionTypes = [EXTRACTOR_RECEIVED];

const getMiddleware = () => {
  const defaultMiddlewares = [thunk, routerMiddleware, sagaMiddleware];

  if (IS_PROD) {
    return defaultMiddlewares;
  }

  const logger = createLogger({
    predicate: (_getState, action) => !hiddenActionTypes.includes(action.type),
    collapsed: true,
  });

  return [...defaultMiddlewares, logger];
};

export const store = configureStore<RootState>({
  devTools: !IS_PROD,
  middleware: getMiddleware() as [ThunkMiddleware<RootState>],
  reducer: rootReducer,
});

export const reduxHistory = createReduxHistory(store);
sagaMiddleware.run(rootSaga);
