import { useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import List from 'antd/lib/list';
import Spin from 'antd/lib/spin';
import VirtualList from 'rc-virtual-list';

import { PAGE_NAVIGATION_ITEM_HEIGHT } from 'common/components/layout/layout-constants';
import type { PageNavigationItemProps, PageNavigationProps } from 'common/components/layout/layout-types';
import PageNavigationItem from 'common/components/layout/PageNavigationItem';

import styles from './PageNavigation.module.less';

const PageNavigation = ({ isLoading = false, items = [] }: PageNavigationProps): JSX.Element => {
  const renderList = useCallback(
    ({ height }) => (
      <List>
        <VirtualList<PageNavigationItemProps> data={items} height={height} itemHeight={PAGE_NAVIGATION_ITEM_HEIGHT} itemKey="key">
          {PageNavigationItem}
        </VirtualList>
      </List>
    ),
    [items],
  );

  return (
    <div className={`flex flex-column full-height ${styles.container}`}>
      {isLoading ? (
        <div className="flex flex-column align-center justify-center full-height full-width">
          <Spin size="large" />
        </div>
      ) : items.length > 0 ? (
        <AutoSizer disableWidth>{renderList}</AutoSizer>
      ) : (
        <div className={`text-center ${styles.empty}`}>There are no items to show</div>
      )}
    </div>
  );
};

export default PageNavigation;
