import { useCallback } from 'react';

import { isNotEmptyString } from '@import-io/typeguards';

interface SearchableModel {
  email?: string;
  name?: string;
  tags?: string[] | undefined;
}

type SearchHandler = (value: string) => void;

interface SearchStore {
  value: string;
  setValue: SearchHandler;
}

type SearchComparator<TModel> = (item: TModel) => boolean | TModel;

const defaultSearchThreshold = 2;

const matchSting = (search: string, value: string = ''): boolean =>
  isNotEmptyString(value) && value.toLowerCase().includes(search);

const matchTags = (search: string, tags?: string[]): boolean =>
  Array.isArray(tags) && tags.some((tag) => tag.toLowerCase().includes(search));

/**
 * A hook that provides search value change handler.
 * @param searchStore {Object} The value store instance that supports value updates {@link SearchStore}.
 * @param searchThreshold {Number} An input value length to trigger the search value update.
 * Search is triggered starting from 3 symbols input length.
 * @returns {Function} input value change handler {@link SearchHandler}.
 */
export const useHandleSearch = (searchStore: SearchStore, searchThreshold: number = defaultSearchThreshold): SearchHandler => {
  const { setValue: setSearch } = searchStore;
  return useCallback<SearchHandler>(
    (value) => {
      setSearch(value.length > searchThreshold ? value.toLowerCase().trim() : '');
    },
    [searchThreshold, setSearch],
  );
};

/**
 * A hook that provides search value comparator function.
 * @param searchStore {Object} The value store instance that supports value updates {@link SearchStore}.
 * @returns {Function} a function to check if an item contains the search value ${@link SearchComparator}.
 */
export const useSearchComparator = <TModel extends SearchableModel>(searchStore: SearchStore): SearchComparator<TModel> => {
  const { value: search } = searchStore;
  return useCallback<SearchComparator<TModel>>(
    (item) => {
      if (search) {
        const { email, name, tags } = item;
        return matchSting(search, email) || matchSting(search, name) || matchTags(search, tags);
      }
      return item;
    },
    [search],
  );
};

/**
 * A hook that provides search value handlers.
 * @param searchStore {Object} The value store instance that supports value updates {@link SearchStore}.
 * @param searchThreshold {Number} An input value length to trigger the search value update.
 * Search is triggered starting from 3 symbols input length.
 *
 * @returns {Array} 3 element array: [
 *   the current search value,
 *   input value change handler {@link SearchHandler}, {@link useHandleSearch},
 *   a function to check if an item contains the search value {@link SearchComparator},  {@link useSearchComparator}
 * ]
 */
export const useSearch = <TModel extends SearchableModel>(
  searchStore: SearchStore,
  searchThreshold: number = defaultSearchThreshold,
): [string, SearchHandler, SearchComparator<TModel>] => {
  const { value: search } = searchStore;
  const handleSearch = useHandleSearch(searchStore, searchThreshold);
  const searchFilterComparator = useSearchComparator<TModel>(searchStore);
  return [search, handleSearch, searchFilterComparator];
};
