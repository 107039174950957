import type { JSX } from 'react';

import Button from 'antd/lib/button';
import classnames from 'classnames';

import type { CustomButtonProps } from 'common/components/buttons/buttons-types';
import TooltipWrapper from 'common/components/TooltipWrapper';

import styles from './CustomButton.module.less';

const CustomButton = ({
  children,
  className = '',
  dark = false,
  disabled = false,
  disabledTooltip,
  tooltip,
  ...restProps
}: CustomButtonProps): JSX.Element => {
  const buttonClassName = classnames({
    [className]: !!className,
    [styles.buttonDark!]: dark,
    [styles.buttonLight!]: !dark,
  });
  return (
    <TooltipWrapper disabled={disabled} disabledTitle={disabledTooltip} {...tooltip}>
      <Button className={buttonClassName} disabled={disabled} {...restProps}>
        {children}
      </Button>
    </TooltipWrapper>
  );
};

export default CustomButton;
