import { all } from 'redux-saga/effects';

import builderSagas from './builder';
import interactionSagas from './interactions';
import { projectSagas } from './project';
import uiSagas from './ui';

export function* lightningSagas(): Generator {
  try {
    yield all([...builderSagas, ...interactionSagas, projectSagas(), ...uiSagas]);
  } catch (e) {
    console.error('Lightning sagas error', e);
  }
}
