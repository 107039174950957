import { ObjectStoreQueryFilter, ObjectStoreQueryOperation, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { DEFAULT_PAGE, DEFAULT_PAGE_LIMIT } from '@import-io/js-sdk/constants/object-store-constants';
import type { ReportType } from '@import-io/types/report-types';

import { REPORTS_PAGE_URL } from 'common/routes/routes-constants';
import { getHistoryUrl, getSettingsUrl } from 'common/routes/routes-utils';

export const getReportHistoryUrl = (id: string): string => getHistoryUrl(`${REPORTS_PAGE_URL}/${id}`);
export const getReportSettingsUrl = (id: string): string => getSettingsUrl(`${REPORTS_PAGE_URL}/${id}`);
export const getReportUrl = (id: string, tab = ''): string => `${REPORTS_PAGE_URL}/${id}/${tab}`;

export const createReportsQuery = ({
  limit = DEFAULT_PAGE_LIMIT,
  page = DEFAULT_PAGE,
  types = null,
}: {
  limit?: number;
  page?: number;
  types?: ReportType[] | ReportType | null;
}) => {
  const query = new ObjectStoreQueryRequestBuilder()
    .setPageNumber(page)
    .setPageLimit(limit)
    .setSortDesc(true)
    .setShowArchived(false)
    .setShowMine(true);

  if (types) {
    query.addFilter('type', new ObjectStoreQueryFilter(ObjectStoreQueryOperation.IN, types));
  }

  return query.build();
};
