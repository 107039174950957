import type { UserSubscriptionData } from '@import-io/types';

import { queryClient } from 'common/query/query-constants';
import { selectCurrentUser } from 'features/user/auth/user-auth-query';
import type { SubscriptionFeatureFlags } from 'features/user/subscription/subscription-types';

import {
  DEFAULT_SUBSCRIPTION_FEATURE_FLAGS,
  GET_SUBSCRIPTION_QUERY_KEY,
  SUBSCRIPTION_FEATURE_FLAGS_QUERY_KEY,
} from './subscription-constants';

export const selectSubscriptionQueryData = (): UserSubscriptionData | null => {
  const { guid } = selectCurrentUser();
  return queryClient.getQueryData<UserSubscriptionData>([GET_SUBSCRIPTION_QUERY_KEY, guid]) ?? null;
};

export const invalidateSubscriptionQuery = (): Promise<void> =>
  queryClient.invalidateQueries({ queryKey: [GET_SUBSCRIPTION_QUERY_KEY] });

export const selectSubscriptionFeatureFlags = (): SubscriptionFeatureFlags => {
  const subscription = selectSubscriptionQueryData();
  return (
    queryClient.getQueryData<SubscriptionFeatureFlags>([SUBSCRIPTION_FEATURE_FLAGS_QUERY_KEY, subscription]) ??
    DEFAULT_SUBSCRIPTION_FEATURE_FLAGS
  );
};
