import type { AnyAction } from 'redux';

import type { RootState } from 'common/common-types';

import {
  AUTH_REVALIDATED,
  BEGIN_INITIAL_LOAD,
  CANCEL_INTERACTIVE_URL_CHANGE,
  CLEAR_ALL_ACTIONS,
  CLEAR_AUTH_INTERACTIONS,
  CLOSE_URL_DATA_FRAME,
  CONFIRM_INTERACTIVE_URL_CHANGE,
  DELETE_ACTION,
  DELETE_AUTH_ACTION,
  DISMISS_WARNING,
  EDITING_AUTH_STEPS_FINISH,
  EDITING_AUTH_STEPS_START,
  ENABLE_RECORDING,
  END_AUTH_COPY_LOAD,
  END_COPY_ACTIONS,
  EXTRACT_DATA_FINISHED,
  EXTRACT_DATA_INITIATED,
  INITIAL_LOAD_COMPLETE,
  INITIAL_LOAD_FAILED,
  MOCKING_ACTION,
  NAVIGATION_ALTERED,
  NAVIGATION_COMPLETE,
  NAVIGATION_START,
  NEW_ACTION_FAILED,
  NEW_ACTION_RECORDED,
  OPEN_URL_DATA_FRAME,
  PREVENT_RECORDING,
  RECEIVED_HTML,
  RECEIVED_VIRTUAL_BROWSER_ID,
  REFRESH_START,
  REFRESH_STOP,
  REPLAY_ACTIONS,
  REPLAY_AND_SUGGEST_FOR_INPUT,
  RESET_INTERACTION_STATE,
  REVALIDATING_AUTH,
  SAVING_ACTION_LIST,
  SELECT_INPUT,
  SET_AUTH_STATUS,
  SET_AUTH_URL,
  SET_AUTH_URL_AND_NAVIGATE,
  SET_CREDENTIALS_INPUT,
  SET_CURRENT_ACTION,
  SET_EXTRACTION_TYPE,
  SET_INITIAL_CURRENT_INPUT,
  SET_INTERACTION_PAGES,
  SET_POST_AUTH_URL,
  SET_RECORDING_AT_INDEX,
  SET_RECORDING_INTO_ACTION_ID,
  SET_REPLAY_MESSAGE,
  SET_SHOW_POPOVER_FOR_ACTION,
  SHOW_PROCESSING_PAGE_ERROR,
  START_AUTH_COPY_LOAD,
  START_COPY_ACTIONS,
  START_LOCK_INTERACTIONS,
  START_RECORDING,
  STOP_RECORDING,
  STOP_REPLAY,
  STOP_REPLAY_FOR_INPUT,
  SUGGESTING_DATA,
  TOGGLE_CODE_ACTION_EDITOR,
  TOGGLE_HOVER_MODE,
  TOGGLE_LOADING_ACTIONS,
  TOGGLE_PRIVATE_SESSION,
  UPDATE_AUTH_COPY_CREDENTIALS,
  UPDATE_BROWSER_STATUS,
  UPDATE_CURRENT_URL,
  UPDATE_INTERACTION_STATE,
  VALIDATE_AUTHENTICATION,
} from '../actions/interactions';
import { RESET_PROJECT } from '../actions/project';
import { SET_INITIAL_URL, SHOW_DATA_EXTRACTED } from '../actions/ui';

function getInitialState(): Partial<RootState['lightning']['interactions']> {
  return {
    editingCodeAction: null,
    actionList: [], // Main action list
    authInteractions: [], // Action used for authentication
    savedActions: [], // Most recent saved actions
    savedAuthActions: [], // Most recent saved auth actions
    invalidActions: [], // Actions that were recorded while record was turned off
    serializedActions: [], // Actions that have been serialized to save on runtime config
    serializedAuthActions: [], // AuthActions that have been serialized to save on runtime config
    inputs: [], // Inputs for training (currently these are all inputs used on an extractor attachment)
    variables: [], // Variables to be used with inputs
    playList: [], // Actions to be played
    playedActions: [], // Actions that have been played
    interactionPages: [], // Pages accumulated while playing back, happens with > 1 points of extraction
    browsingHistory: [], // cache of current browser history stack occurred during page interaction
    authUrl: '', // Initial url for auth extractor
    authStatus: 'undetected', // Various states of authentication, valid values are 'undetected', 'detected', 'validation-required', 'replaying-auth', and 'authenticated'
    currentAction: undefined, // Current action we are at in the list of actions
    html: null, // html of current page, should be similar to html in ui reducer
    playbackError: null, // indicator of an error during playback, sometimes errors can occur without an erroredOnAction
    erroredOnAction: null, // action that playback errored on
    playToAction: undefined, // action currently being used or last used for playing to
    recordingIntoActionId: null, // if present we will funnel all newly recorded actions into the action with this id
    credentialsInput: null, // credentials object, stored on private config of variables used during auth interaction
    currentInput: null, // current input being used
    initialCurrentInput: null, // initial input being used when user visits browse view
    currentHistoryIndex: 0, // index on browserHistory array that we are currently at in our navigation
    virtualBrowserId: '', // virtual browser id on chromium we are checking out, used for auth extractors
    navigationDirection: '', // direction we are navigating in, can be forward or back
    initialUrl: '', // starting url for project
    currentUrl: '', // current url of interaction
    replayMessage: '',
    // various flags
    isUrlDataFrameOpen: false, // Indicates if the websocket is open for getting data for one url
    isBrowserOpen: false, // Indicates if the websocket is open for interaction
    isReplaying: false, // We are replaying actions
    isExtracting: false, // Flag used when replaying in order to get html snapshots
    isSuggestingData: false,
    isNavigating: false,
    isRecording: true,
    isRefreshing: false,
    preventRecording: false,
    hasAlteredNavigation: false,
    replayingForInput: false,
    privateSession: false,
    isEditingAuthActions: false,
    isReValidating: false,
    isRecordingInitialLoad: false,
    isLoadingActions: true,
    isCopyingActions: false,
    isLoadingCopyActions: false,
    canReplaceAssetsForPage: true,
    hoverModeEnabled: true,
    recordingAtIndex: 0,
    authCopyData: {
      credentials: {},
      authInteractions: [],
    },
    isAuthNoPI: false,
    extractionType: 'basic',
    showProcessingPageError: false,
    interactiveUrlChange: false,
    suggestDataDuringExtraction: false,
    showPopoverOnActionId: null,
    lockInteractions: false,
  };
}

export function interactionsReducer(
  state = getInitialState(),
  action: AnyAction = { type: '' },
): Partial<RootState['lightning']['interactions']> {
  switch (action.type) {
    case SHOW_DATA_EXTRACTED:
      return {
        ...state,
        isExtracting: false,
      };
    case UPDATE_AUTH_COPY_CREDENTIALS:
      return {
        ...state,
        authCopyData: {
          ...(state.authCopyData || { authInteractions: [] }),
          credentials: {
            ...state.authCopyData?.credentials,
            [action.key]: action.value,
          },
        },
      };
    case START_AUTH_COPY_LOAD:
      return {
        ...state,
        isLoadingCopyActions: true,
        authCopyData: {
          credentials: {},
          authInteractions: [],
        },
      };
    case END_AUTH_COPY_LOAD:
      return {
        ...state,
        isLoadingCopyActions: false,
        authCopyData: {
          credentials: action.credentials || {},
          authInteractions: action.authInteractions || [],
        },
      };
    case START_COPY_ACTIONS:
      return {
        ...state,
        isCopyingActions: true,
      };
    case END_COPY_ACTIONS:
      return {
        ...state,
        isCopyingActions: false,
      };
    case NEW_ACTION_FAILED:
      return { ...state, actionList: action.actionList, invalidActions: action.invalidActions };
    case NEW_ACTION_RECORDED:
      return {
        ...state,
        actionList: action.actionList,
        currentAction: action.currentAction,
        currentVisibleAction: action.currentVisibleAction,
        invalidActions: action.invalidActions,
        authInteractions: action.authInteractions,
        recordingAtIndex: action.recordingAtIndex,
        isLoadingActions: false,
      };
    case DELETE_ACTION:
    case CLEAR_ALL_ACTIONS:
      return {
        ...state,
        actionList: action.actionList,
        currentAction: action.actionList[action.actionList.length - 1],
        inputs: action.inputs,
        variables: action.variables,
        currentInput: action.currentInput,
        invalidActions: [],
      };
    case DELETE_AUTH_ACTION:
      return {
        ...state,
        authInteractions: action.authInteractions,
        credentialsInput: action.credentialsInput,
        authStatus: 'validation-required',
        virtualBrowserId: '',
      };
    case SET_CURRENT_ACTION:
      return {
        ...state,
        currentAction: action.currentAction,
        currentVisibleAction: action.currentVisibleAction,
        playedActions: action.playedActions,
      };
    case RECEIVED_HTML:
      return {
        ...state,
        html: action.html,
        htmlTimestamp: action.timestamp,
      };
    case SET_INITIAL_URL:
      return {
        ...state,
        initialUrl: action.initialUrl,
        isNavigating: true,
        browsingHistory: [],
        currentHistoryIndex: 0,
        currentUrl: action.initialUrl,
        authInteractions: state.isEditingAuthActions ? [] : state.authInteractions,
        authUrl: state.isEditingAuthActions ? action.initialUrl : state.authUrl,
        privateSession: state.authStatus === 'started' ? false : state.privateSession,
        authStatus: state.authStatus === 'started' ? 'undetected' : state.authStatus,
        recordingIntoActionId: null,
      };
    case UPDATE_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.currentUrl,
        actionList: action.actionList,
        browsingHistory: action.browsingHistory,
        initialUrl: action.initialUrl,
        currentHistoryIndex: action.currentHistoryIndex,
        isNavigating: false,
        hasAlteredNavigation: action.hasAlteredNavigation,
        canReplaceAssetsForPage: action.canReplaceAssetsForPage,
        currentVisibleAction: action.currentVisibleAction,
      };
    case UPDATE_BROWSER_STATUS:
      return { ...state, isBrowserOpen: action.isBrowserOpen, navigationDirection: '' };
    case REPLAY_ACTIONS:
      return {
        ...state,
        isReplaying: true,
        replayMessage: action.replayMessage,
        playToAction: action.playToAction || null,
        playFromAction: action.playFromAction || null,
        playList: action.playList,
        currentAction: undefined,
        currentVisibleAction: null,
        playbackError: null,
        erroredOnAction: null,
        isLoadingActions: false,
      };
    case STOP_REPLAY:
      return {
        ...state,
        isReplaying: false,
        playbackError: action.playbackError,
        erroredOnAction: action.erroredOnAction,
        playedActions: [],
        playFromAction: action.playFromAction,
      };
    case EXTRACT_DATA_INITIATED:
      return {
        ...state,
        isExtracting: true,
        isRecording: false,
        preventRecording: true,
        suggestDataDuringExtraction: action.suggestDataDuringExtraction,
      };
    case EXTRACT_DATA_FINISHED:
      return {
        ...state,
        isExtracting: false,
        isSuggestingData: false,
        suggestDataDuringExtraction: false,
        interactionPages: [],
        html: null,
      };
    case SUGGESTING_DATA:
      return { ...state, isSuggestingData: true };
    case SAVING_ACTION_LIST:
      return {
        ...state,
        serializedActions: action.serializedActions,
        actionList: action.actionList,
        serializedAuthActions: action.serializedAuthActions,
        authInteractions: action.authInteractions,
        savedActions: action.actionList,
        savedAuthActions: action.authInteractions,
      };
    case START_RECORDING:
      return {
        ...state,
        isRecording: true,
        playbackError: null,
        playFromAction: undefined,
        playToAction: undefined,
        erroredOnAction: null,
        preventRecording: false,
        invalidActions: [],
      };
    case STOP_RECORDING:
      return { ...state, isRecording: false, preventRecording: true };
    case REFRESH_START:
      return { ...state, isRefreshing: true, invalidActions: [] };
    case REFRESH_STOP:
      return { ...state, isRefreshing: false };
    case NAVIGATION_START:
      return { ...state, isNavigating: true };
    case NAVIGATION_COMPLETE:
      return { ...state, isNavigating: false };
    case DISMISS_WARNING:
      return {
        ...state,
        invalidActions: [],
        playToAction: undefined,
        playFromAction: undefined,
        isRecording: action.isRecording,
        preventRecording: action.preventRecording,
      };
    case PREVENT_RECORDING:
      return {
        ...state,
        preventRecording: true,
      };
    case ENABLE_RECORDING:
      return {
        ...state,
        preventRecording: false,
        playFromAction: undefined,
        playToAction: undefined,
      };
    case UPDATE_INTERACTION_STATE:
      return {
        ...state,
        inputs: action.inputs,
        actionList: action.actionList,
        variables: action.variables,
        currentInput: action.currentInput,
        serializedActions: action.serializedActions,
        authInteractions: action.authInteractions,
        credentialsInput: action.credentialsInput,
      };
    case REPLAY_AND_SUGGEST_FOR_INPUT:
      return {
        ...state,
        replayingForInput: true,
        isExtracting: true,
      };
    case NAVIGATION_ALTERED:
      return {
        ...state,
        hasAlteredNavigation: true,
        navigationDirection: action.navigationDirection,
      };
    case SELECT_INPUT:
      return {
        ...state,
        currentInput: action.currentInput,
      };
    case STOP_REPLAY_FOR_INPUT:
      return {
        ...state,
        replayingForInput: false,
      };
    case BEGIN_INITIAL_LOAD:
      return {
        ...state,
        isRecordingInitialLoad: true,
      };
    case INITIAL_LOAD_FAILED:
    case INITIAL_LOAD_COMPLETE:
      return {
        ...state,
        isRecordingInitialLoad: false,
        isSuggestingData: false,
      };
    case MOCKING_ACTION:
      return {
        ...state,
        mockingActionId: action.mockingActionId,
      };
    case RESET_PROJECT:
    case RESET_INTERACTION_STATE:
      return {
        ...getInitialState(),
      };
    case RECEIVED_VIRTUAL_BROWSER_ID:
      return {
        ...state,
        virtualBrowserId: action.virtualBrowserId,
      };
    case TOGGLE_PRIVATE_SESSION:
      return {
        ...state,
        privateSession: action.privateSession,
      };
    case VALIDATE_AUTHENTICATION:
      return {
        ...state,
        authInteractions: action.authInteractions,
        actionList: action.actionList,
        isEditingAuthActions: false,
        authUrl: action.authUrl,
        credentialsInput: action.credentialsInput,
      };
    case EDITING_AUTH_STEPS_START:
      return {
        ...state,
        isEditingAuthActions: true,
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        virtualBrowserId:
          action.authStatus === 'validation-required' || action.authStatus === 'replaying-auth' ? '' : state.virtualBrowserId,
        authStatus: action.authStatus,
        privateSession: action.authStatus === 'authenticated' ? true : state.privateSession,
      };
    case EDITING_AUTH_STEPS_FINISH:
      return {
        ...state,
        isEditingAuthActions: false,
      };
    case CLEAR_AUTH_INTERACTIONS:
      return {
        ...state,
        authInteractions: [],
        credentialsInput: null,
        serializedAuthActions: [],
        authStatus: 'undetected',
        privateSession: false,
        virtualBrowserId: '',
        authUrl: '',
      };
    case SET_AUTH_URL:
      return {
        ...state,
        authUrl: action.authUrl,
      };
    case SET_AUTH_URL_AND_NAVIGATE:
      return {
        ...state,
        authUrl: action.authUrl,
        isNavigating: true,
        browsingHistory: [],
        currentHistoryIndex: 0,
        currentUrl: action.authUrl,
      };
    case SET_CREDENTIALS_INPUT:
      return {
        ...state,
        credentialsInput: action.credentialsInput,
      };
    case REVALIDATING_AUTH:
      return {
        ...state,
        isReValidating: true,
        privateSession: true,
        credentialsInput: action.credentialsInput,
      };
    case AUTH_REVALIDATED:
      return {
        ...state,
        isReValidating: false,
        playFromAction:
          !!state.playFromAction &&
          !!state.authInteractions?.length &&
          !state.authInteractions?.find((a) => a.actionId === state.playFromAction?.actionId)
            ? state.playFromAction
            : undefined,
        isEditingAuthActions: false,
        extractionType: state.extractionType === 'auth' ? 'basic' : state.extractionType,
      };
    case SET_INTERACTION_PAGES:
      return {
        ...state,
        interactionPages: action.interactionPages,
      };
    case SET_RECORDING_INTO_ACTION_ID:
      return {
        ...state,
        recordingIntoActionId: action.recordingIntoActionId,
        recordingAtIndex: 0,
        recordingIntoAuth: false,
      };
    case TOGGLE_LOADING_ACTIONS:
      return { ...state, isLoadingActions: action.isLoadingActions };
    case TOGGLE_HOVER_MODE:
      return { ...state, hoverModeEnabled: !state.hoverModeEnabled };
    case SET_RECORDING_AT_INDEX:
      return { ...state, recordingAtIndex: action.recordingAtIndex, recordingIntoAuth: action.recordingIntoAuth };
    case SET_REPLAY_MESSAGE:
      return { ...state, replayMessage: action.replayMessage };
    case TOGGLE_CODE_ACTION_EDITOR:
      return {
        ...state,
        editingCodeAction: action.editingCodeAction,
        // stop recording so that if user interacts with page we don't record any unwanted actions
        isRecording: !action.editingCodeAction,
        preventRecording: !!action.editingCodeAction,
        // also clear out invalid actions (actions recorded while using code action editor)
        invalidActions: [],
      };
    case SET_POST_AUTH_URL:
      return {
        ...state,
        isAuthNoPI: true,
        postAuthUrl: action.postAuthUrl,
      };
    case SET_EXTRACTION_TYPE:
      return {
        ...state,
        extractionType: action.extractionType,
      };
    case OPEN_URL_DATA_FRAME:
      return {
        ...state,
        isUrlDataFrameOpen: true,
      };

    case CLOSE_URL_DATA_FRAME:
      return {
        ...state,
        isUrlDataFrameOpen: false,
      };
    case SHOW_PROCESSING_PAGE_ERROR:
      return {
        ...state,
        showProcessingPageError: action.showProcessingPageError,
        isRecordingInitialLoad: action.isRecordingInitialLoad,
      };
    case CONFIRM_INTERACTIVE_URL_CHANGE:
      return {
        ...state,
        interactiveUrlChange: action.interactiveUrlChange,
      };
    case CANCEL_INTERACTIVE_URL_CHANGE:
      return {
        ...state,
        interactiveUrlChange: action.interactiveUrlChange,
      };
    case SET_INITIAL_CURRENT_INPUT:
      return {
        ...state,
        initialCurrentInput: action.initialCurrentInput,
      };
    case SET_SHOW_POPOVER_FOR_ACTION:
      return {
        ...state,
        showPopoverOnActionId: action.showPopoverOnActionId,
      };
    case START_LOCK_INTERACTIONS:
      return { ...state, lockInteractions: true };
    default:
      return state;
  }
}
