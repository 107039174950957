import type { JSX } from 'react';

import type { WithTitle } from 'common/common-types';
import ErrorPage from 'common/components/error/ErrorPage';

const PageNotFound = ({ title = 'Page not found' }: WithTitle): JSX.Element => (
  <div className="full-height full-width">
    <ErrorPage text={title} />
  </div>
);

export default PageNotFound;
