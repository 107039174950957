import { objectStoreApi } from '@import-io/js-sdk';
import { ReportType } from '@import-io/types/report-types';
import cloneDeep from 'lodash/cloneDeep';

import { selectExtractorListQueryData } from 'features/extractors/common/extractor-list-query';

import { META_COLUMNS, REPORT_FEATURES } from '../lib/dashConstants';

import { endReportSave, saveReportWithConfig, startReportSave } from './reports';

export const SET_ROWS = 'SET_ROWS';
export const SOFT_RESET = 'SOFT_RESET';
export const SET_COLUMNS = 'SET_COLUMNS';
export const REPORT_NOTIFY = 'REPORT_NOTIFY';
export const SET_EDIT_CELL = 'SET_EDIT_CELL';
export const SET_VALIDATION = 'SET_VALIDATION';
export const SET_MATRIX_MODE = 'SET_MATRIX_MODE';
export const SET_EDIT_COLUMN = 'SET_EDIT_COLUMN';
export const TRACK_MASTER_ROW = 'TRACK_MASTER_ROW';
export const LOAD_REPORT_CONFIG = 'LOAD_REPORT_CONFIG';
export const LOAD_CONFLICT_NOTIFY = 'LOAD_CONFLICT_NOTIFY';
export const CLEAR_REPORT_SETTINGS = 'CLEAR_REPORT_SETTINGS';
export const SET_AVAILABLE_EXTRACTORS = 'SET_AVAILABLE_EXTRACTORS';

let COLUMN_ID = 0;

const EXCLUDED_FIELD_TYPES = {
  IMAGE: true,
};

//---------------------------------------------------------------------
// CRAWL COMPARE FUNCTIONS
//---------------------------------------------------------------------

const nextColumnId = () => {
  COLUMN_ID++;
  return `column-${COLUMN_ID}`;
};

export const saveReportMatrix = () => {
  return async (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const selectedReport = cloneDeep(getState().dashboard.reports.reportCore.selectedReport);
    // Clone the config and remove any temp variables/maps created on it
    const innerConfig = cloneDeep(state.matrixConfig);

    for (const column of innerConfig.columns) {
      delete column.id;
      delete column.cellMap;
    }

    for (const row of innerConfig.rows) {
      delete row.fieldMap;
    }

    // De-Alias rows back to extractors
    innerConfig.extractors = innerConfig.rows;
    delete innerConfig.rows;

    // Cleanup internal flags
    delete innerConfig.isNew;

    // Set extractorId
    innerConfig.extractorId = state.masterId;

    dispatch(startReportSave());
    await dispatch(saveReportWithConfig(selectedReport, { config: innerConfig }));
    dispatch(endReportSave());
  };
};

export const softReset = () => {
  return (dispatch) => {
    dispatch({
      type: SOFT_RESET,
    });
  };
};

export const validateState = () => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;

    const validationState = {
      error: 0,
      warning: 0,
    };

    const expectedLength = state.matrixConfig.rows.length;
    for (const column of state.matrixConfig.columns) {
      const diff = expectedLength - column.fields.length;
      if (diff > 0) {
        if (column.primaryKey) {
          validationState.error += diff;
        } else if (state.masterId !== null && column.cellMap[state.masterId] === undefined) {
          validationState.error += 1;
          validationState.warning += diff - 1;
        } else {
          validationState.warning += diff;
        }
      }
    }

    dispatch({
      type: SET_VALIDATION,
      validationState: validationState,
    });
  };
};

export const moveColumn = (column, toIndex) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const columns = [...state.matrixConfig.columns];

    const columnIndex = columns.indexOf(column);
    if (columnIndex >= 0) {
      [columns[columnIndex], columns[toIndex]] = [columns[toIndex], columns[columnIndex]];
      dispatch(setColumns(columns));
    }
  };
};

export const clearCell = (row, column) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const newColumn = cloneDeep(column);
    const columns = [...state.matrixConfig.columns];

    // Find the field that needs to be cleared
    const fieldToClear = newColumn.cellMap[row.id];
    const fieldToClearIndex = newColumn.fields.indexOf(fieldToClear);

    // Clear the field from the new column
    if (fieldToClearIndex >= 0) {
      newColumn.fields.splice(fieldToClearIndex, 1);
    } else {
      return;
    }
    // Remove the field from the cell map
    delete newColumn.cellMap[row.id];

    // Replace the old column with the new column
    const columnIndex = columns.indexOf(column);
    if (columnIndex >= 0) {
      columns.splice(columnIndex, 1, newColumn);
      dispatch(setColumns(columns));
    }
  };
};

export const setCell = (fieldId, row, column, isMeta = false) => {
  return (dispatch, getState) => {
    if (!fieldId) {
      dispatch(clearCell(row, column));
      return;
    }

    const state = getState().dashboard.reports.reportSettings;
    const columns = [...state.matrixConfig.columns];

    const newColumn = { ...column };
    newColumn.fields = [...column.fields];
    let newCell;
    if (isMeta) {
      newCell = {
        _isMeta: true,
        name: fieldId,
        property: 'text',
        extractorId: row.id,
        displayName: META_COLUMNS.find((meta) => meta.id === fieldId).name,
      };
    } else {
      newCell = {
        property: 'text',
        columnId: fieldId,
        extractorId: row.id,
        name: row.fieldMap[fieldId].name,
      };
    }

    const existingIndex = newColumn.fields.findIndex((f) => f.extractorId === row.id);

    // Update the back-end
    if (existingIndex >= 0) {
      newColumn.fields.splice(existingIndex, 1, newCell);
    } else {
      newColumn.fields.push(newCell);
    }
    // Update the front-end
    newColumn.cellMap[row.id] = newCell;

    const columnIndex = columns.indexOf(column);
    /* istanbul ignore else */
    if (columnIndex >= 0) {
      columns.splice(columnIndex, 1, newColumn);
      dispatch(setColumns(columns));
      dispatch(setEditingCell());
      dispatch(validateState());
    }
  };
};

export const setEditingCell = (cell) => {
  return (dispatch) => {
    dispatch({
      cell: cell,
      type: SET_EDIT_CELL,
    });
  };
};

export const setColumnName = (column, name) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const columns = [...state.matrixConfig.columns];

    const newColumn = { ...column };
    newColumn[state.matrixParser.columnName] = name;

    const columnIndex = columns.indexOf(column);
    /* istanbul ignore else */
    if (columnIndex >= 0) {
      columns.splice(columnIndex, 1, newColumn);
      dispatch(setColumns(columns));
    }
  };
};

export const setEditColumn = (column) => {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_COLUMN,
      column: column,
    });
  };
};

export const setPrimary = (column) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const columns = [...state.matrixConfig.columns];

    for (const col of columns) {
      col.primaryKey = col.id === column.id ? !column.primaryKey : false;
    }

    dispatch(setColumns(columns));
  };
};

export const addColumn = (field) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const columns = [...state.matrixConfig.columns];

    // Make new column template
    const newColumn = {
      fields: [],
      cellMap: {},
      primaryKey: false,
      id: nextColumnId(),
      reportFieldName: field.name,
    };

    // Set the first column to be primary
    if (columns.length === 0) {
      newColumn.primaryKey = true;
    }

    // Apply some magic to load field data
    // This will update the back-end when saved.
    const fieldName = field.name.trim().toLowerCase();
    for (const rowData of state.matrixConfig.rows) {
      const row = state.rowMap[rowData.id];

      // Check for meta fields
      if (field._isMeta) {
        const newCell = {
          _isMeta: true,
          name: field.id,
          property: 'text',
          extractorId: row.id,
          displayName: field.name,
        };

        newColumn.fields.push(newCell);
        newColumn.cellMap[row.id] = newCell;
      } else {
        // Check for fields on the extractor
        const matchField = row.fields.find((f) => f.name.trim().toLowerCase() === fieldName);
        if (matchField) {
          const newCell = {
            property: 'text',
            extractorId: row.id,
            columnId: matchField.id,
            name: row.fieldMap[matchField.id].name,
          };

          newColumn.fields.push(newCell);
          newColumn.cellMap[row.id] = newCell;
        }
      }
    }

    columns.push(newColumn);
    dispatch(setColumns(columns));
  };
};

export const removeColumn = (column) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const columns = [...state.matrixConfig.columns];
    const columnIndex = columns.indexOf(column);

    /* istanbul ignore else */
    if (columnIndex >= 0) {
      columns.splice(columnIndex, 1);
      dispatch(setColumns(columns));
    }
  };
};

export const setColumns = (columns) => {
  return (dispatch) => {
    dispatch({
      type: SET_COLUMNS,
      columns: columns,
    });
    dispatch(validateState());
  };
};

export const setMaster = (row) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const rows = [...state.matrixConfig.rows];

    // Set the master key
    let masterId = null;
    for (const r of rows) {
      r.master = r.id === row.id ? !r.master : false;
      if (r.master) {
        masterId = r.id;
      }
    }

    // Move master to top of list
    const masterIndex = rows.findIndex((r) => r.master);
    if (masterIndex >= 0) {
      const masterRow = rows.splice(masterIndex, 1);
      rows.unshift(...masterRow);
    }

    dispatch({
      type: TRACK_MASTER_ROW,
      masterId: masterId,
    });

    dispatch(setRows(rows));

    dispatch({
      type: REPORT_NOTIFY,
      content: `${state.rowMap[row.id].name} is now the master extractor.`,
    });
  };
};

export const trackMasterId = () => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const masterRow = state.matrixConfig.rows.find((r) => r.master);
    dispatch({
      type: TRACK_MASTER_ROW,
      masterId: masterRow ? masterRow.id : null,
    });
  };
};

export const removeRow = (row) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const rows = [...state.matrixConfig.rows];
    const rowIndex = rows.findIndex((r) => r.id == row.id);
    /* istanbul ignore else */
    if (rowIndex >= 0) {
      const isMaster = rows[rowIndex].master;
      rows.splice(rowIndex, 1);
      dispatch(setRows(rows));
      if (rows.length === 0) {
        dispatch(setColumns([])); // If we have no rows, remove all the columns.
      } else {
        if (isMaster) {
          dispatch(setMaster(rows[0])); // Set the first row as the master extractor, when the master is removed.
        }
        dispatch(removeRowFromColumns(row));
      }
      dispatch(trackMasterId());
    }
  };
};

export const removeRowFromColumns = (row) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const columns = cloneDeep(state.matrixConfig.columns);

    for (const column of columns) {
      // Check field field to see if it references the remove extractor
      column.fields = column.fields || [];
      for (let i = column.fields.length - 1; i >= 0; i--) {
        const field = column.fields[i];
        // If it does then remove the field from the column and remove
        // the row reference from the cell map.
        if (field.extractorId === row.id) {
          column.fields.splice(i, 1);
          delete column.cellMap[row.id];
        }
      }
    }

    dispatch(setColumns(columns));
  };
};

export const addRow = (row) => {
  return (dispatch, getState) => {
    const state = getState().dashboard.reports.reportSettings;
    const rows = [...state.matrixConfig.rows];
    rows.push(row);
    dispatch(setRows(rows));

    // If this isn't the first row added, apply
    // some magic to fill in the cells for this row.
    if (rows.length > 1) {
      row = state.rowMap[row.id];
      const columns = cloneDeep(state.matrixConfig.columns);

      for (const column of columns) {
        const referenceCell = column.cellMap[rows[0].id];
        // The first row might not have a value in every column.
        /* istanbul ignore else */
        if (referenceCell) {
          const referenceName = referenceCell.name.trim().toLowerCase();
          const matchField = row.fields.find((f) => f.name.trim().toLowerCase() === referenceName);

          if (matchField) {
            const newCell = {
              property: 'text',
              extractorId: row.id,
              columnId: matchField.id,
              name: row.fieldMap[matchField.id].name,
            };

            column.fields.push(newCell);
            column.cellMap[row.id] = newCell;
          }
        }
      }

      dispatch(setColumns(columns));
    }
  };
};

export const setRows = (rows) => {
  return (dispatch) => {
    dispatch({
      type: SET_ROWS,
      rows: rows,
    });
    dispatch(validateState());
  };
};

export const clearReportSettings = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REPORT_SETTINGS,
    });
  };
};

//---------------------------------------------------------------------
// LOAD ENTRY
//---------------------------------------------------------------------

export const loadReportSettings = (report) => {
  return async (dispatch, getState) => {
    try {
      const config = await objectStoreApi.reportConfiguration.get(report.latestConfigId);

      if (config && getState().dashboard.reports.reportCore.selectedReportGuid === report.guid) {
        dispatch(setAvailableExtractors(cloneDeep(selectExtractorListQueryData()), report));
        dispatch(loadReportConfig(config.config));
      }
    } catch (ex) {
      console.error('Could not load report settings', ex);
    }
  };
};

//---------------------------------------------------------------------
// EXTRACTOR LOADING
//---------------------------------------------------------------------

const extractorsForCompare = (extractors) => {
  return (dispatch) => {
    const rowMap = {};

    // Alias guid to id and build row map
    for (const extractor of extractors) {
      extractor.id = extractor.guid;

      const fieldMap = {};
      extractor.fields = extractor.fields || [];
      for (let i = extractor.fields.length - 1; i >= 0; i--) {
        const field = extractor.fields[i];
        if (EXCLUDED_FIELD_TYPES[field.type]) {
          extractor.fields.splice(i, 1);
        } else {
          fieldMap[field.id] = field;
        }
      }

      extractor.fieldMap = fieldMap;
      rowMap[extractor.id] = extractor;
    }

    dispatch({
      type: SET_AVAILABLE_EXTRACTORS,
      extractors: extractors,
      rowMap: rowMap,
    });
  };
};

const extractorsForDiff = (extractors, report) => {
  return (dispatch) => {
    const diffExtractor = [];
    const extractorIndex = extractors.findIndex((e) => e.guid === report.extractorId);

    if (extractorIndex >= 0) {
      diffExtractor.push(extractors[extractorIndex]);
    }

    dispatch({
      type: SET_AVAILABLE_EXTRACTORS,
      extractors: diffExtractor,
      rowMap: {},
    });
  };
};

export const setAvailableExtractors = (extractors, report) => {
  return (dispatch) => {
    switch (report.type) {
      case ReportType.CRAWL_DIFF:
        dispatch(extractorsForDiff(extractors, report));
        break;
      case ReportType.CRAWL_REPORT:
        dispatch(extractorsForCompare(extractors));
        break;
      default:
        console.error(`Could not load extractors for report type ${report.type}`);
    }
  };
};

//---------------------------------------------------------------------
// CONFIG LOADING
//---------------------------------------------------------------------

export const loadReportConfig = (config, mode = null) => {
  return (dispatch) => {
    switch (config.type) {
      case ReportType.CRAWL_DIFF:
        dispatch(loadDiffReportConfig(config));
        break;
      case ReportType.CRAWL_REPORT:
        dispatch(loadCompareReportConfig(config, mode));
        break;
      default:
        console.error(`Unknown report type ${config.type}`);
    }
  };
};

export const loadDiffReportConfig = (config) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_REPORT_CONFIG,
      config: config,
      _configBack: cloneDeep(config),
    });
  };
};

export const loadCompareReportConfig = (config, mode = null) => {
  return (dispatch, getState) => {
    const conflicts = {
      rows: [],
      fields: [],
      rename: [],
      columns: [],
    };

    const { rowMap } = getState().dashboard.reports.reportSettings;
    // The row map in state that is initalized by availableExtractors
    // is a map of all rows available to the application. This will be
    // a map representative of selected rows.
    const loadConfig = cloneDeep(config);

    if (config) {
      // Alias extractors to rows
      loadConfig.rows = loadConfig.extractors || /* istanbul ignore next */ [];
      delete loadConfig.extractors;

      // Remove archived extractors
      for (let i = loadConfig.rows.length - 1; i >= 0; i--) {
        const extractor = loadConfig.rows[i];
        if (rowMap[extractor.id] === undefined) {
          conflicts.rows.push(extractor);
          loadConfig.rows.splice(i, 1);
        }
      }

      // Convert fields to maps
      // column.cellMap will update the front end
      // column.fields will update the back-end when saved
      loadConfig.columns = loadConfig.columns || /* istanbul ignore next */ [];
      for (let j = loadConfig.columns.length - 1; j >= 0; j--) {
        const column = loadConfig.columns[j];
        if (column) {
          const cellMap = {};
          column.fields = column.fields || [];

          for (let i = column.fields.length - 1; i >= 0; i--) {
            let extractorField = null;
            const field = column.fields[i];

            if (rowMap[field.extractorId] !== undefined && rowMap[field.extractorId].fieldMap[field.columnId] !== undefined) {
              extractorField = rowMap[field.extractorId].fieldMap[field.columnId];
            }

            // If the field is not related to a selected row, then remove it.
            // If the field was renamed, then replace it with the latest one from the extractor.
            // otherwise add it to a cell map.
            /* istanbul ignore if */
            if (!field._isMeta && extractorField === null) {
              if (rowMap[field.extractorId] !== undefined) {
                conflicts.fields.push(field);
              }
              column.fields.splice(i, 1);
            } else if (!field._isMeta && extractorField && extractorField.name !== field.name) {
              const renamedField = {
                columnId: field.columnId,
                extractorId: field.extractorId,
                name: extractorField.name,
                property: 'text',
              };

              conflicts.rename.push(field);
              column.fields.splice(i, 1, renamedField);
              cellMap[field.extractorId] = renamedField;
            } else {
              cellMap[field.extractorId] = field;
            }
          }

          column.cellMap = cellMap;
          column.id = nextColumnId();

          if (column.fields.length === 0) {
            loadConfig.columns.splice(j, 1);
            conflicts.columns.push(column);
          }
        }
      }
    }

    dispatch({
      type: SET_MATRIX_MODE,
      mode: mode,
    });

    dispatch({
      conflicts: conflicts,
      type: LOAD_CONFLICT_NOTIFY,
    });

    dispatch({
      type: LOAD_REPORT_CONFIG,
      config: loadConfig,
      _configBack: loadConfig,
      features: loadConfig ? REPORT_FEATURES[loadConfig.type] : {},
    });
    if (loadConfig) {
      dispatch(validateState());
      dispatch(trackMasterId());
    }
  };
};
