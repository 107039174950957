import { RuntimeConfigurationWrapper } from '@import-io/types';
import type { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import type { RootState } from 'common/common-types';

import {
  CREATE_PROJECT,
  EXTRACTOR_LOADED,
  FETCHING_RUNTIME_CONFIG,
  NAVIGATING_TO_URL,
  NEW_URL_ERROR,
  NEW_URL_LOADING,
  NEW_URL_NO_DATA,
  NEW_URL_SUCCESS,
  REFRESHING_HTML_COMPLETE,
  RESET_MANAGE_URLS_STATE,
  RESET_PROJECT,
  RUNTIME_CONFIG_RECEIVED,
  RUNTIME_CONFIG_UPDATE,
  SAVE_EXTRACTOR_FAILURE,
  SAVE_EXTRACTOR_STARTED,
  SAVE_EXTRACTOR_SUCCESS,
  SET_CREATE_DATA_REPORT,
  SET_CREDENTIALS_GUID,
  SET_EXISTING_PROJECT,
  SET_FINAL_PAGES_LIST,
  SET_HTML_EXTRACTION,
  SET_INTERACTION_PROJECT,
  SET_NEW_PROJECT,
  SET_NOTIFY_ME,
  SET_PROJECT_NAME,
  SET_PROXY_SETTINGS,
  SET_REPORT,
  SET_SCHEDULING_SETTINGS,
  SET_SCREEN_CAPTURE,
  START_REFRESHING_HTML,
  TOGGLE_CREATE_DATA_REPORT,
  TOGGLE_REPLACE_ASSETS,
} from '../actions/project';
import { CLEANUP_STATE, HIDE_MANAGE_URLS_MODAL, RESOURCE_RESPONSE_OBTAINED } from '../actions/ui';

/**
 * Returns the object used as the initial state.
 * This is the state object used everytime user enters results page.
 * @return {Object} Initial state object.
 */
function getInitialState(): Partial<RootState['lightning']['project']> {
  return {
    // Persisted properties:
    guid: null,
    temporaryId: null,
    name: '',
    fields: null,
    latestConfig: null,
    schemaId: null,
    _meta: null,
    screenCapture: true,
    // Non-persisted properties:
    pages: null,
    selectedPage: null,
    selectedField: null,
    data: null,
    existingProject: false,
    newUrlLoading: false,
    newUrlSuccessful: false,
    newUrlNoData: false,
    newUrlError: false,
    isSaving: false,
    hasSaveFailed: false,
    shouldSendDataAnalytics: false,
    isNewProject: false,
    notifyMe: true,
    diffConfig: null,
    isFetchingTraining: false,
    errorLoadingDiffConfig: false,
    finalPagesList: null,
    createDataReport: true,
    report: null,
    gettingDataForUrl: '',
    refreshingPageId: undefined,
    proxySettings: {},
    canReplaceAssetsForUrl: true,
    hasInteraction: false,
    isParentTriggered: false,
    addInteractiveInputsToOutput: true,
  };
}

/**
 * Main state reducer.
 */
export function projectReducer(
  state = getInitialState(),
  action: AnyAction = { type: '' },
): Partial<RootState['lightning']['project']> {
  switch (action.type) {
    case CREATE_PROJECT:
      return {
        ...state,
        guid: action.guid,
        temporaryId: uuidv4(),
        name: action.name,
        schemaId: action.schemaId,
        _meta: action._meta,
        existingProject: action.existingProject || state.existingProject,
      };

    case RUNTIME_CONFIG_UPDATE:
      return {
        ...state,
        runtimeConfiguration: action.runtimeConfiguration,
      };

    case SAVE_EXTRACTOR_STARTED:
      return {
        ...state,
        isSaving: true,
        hasSaveFailed: false,
      };

    case SAVE_EXTRACTOR_SUCCESS:
      return {
        ...state,
        isSaving: false,
        hasSaveFailed: false,
      };

    case SAVE_EXTRACTOR_FAILURE:
      return {
        ...state,
        isSaving: false,
        hasSaveFailed: true,
      };

    // User requested to revert the results state to the initial contidion.
    case CLEANUP_STATE:
      return getInitialState();

    case NEW_URL_LOADING:
      return {
        ...state,
        newUrlLoading: true,
        newUrlSuccessful: false,
        newUrlNoData: false,
        newUrlError: false,
      };

    case NEW_URL_SUCCESS:
      return {
        ...state,
        gettingDataForUrl: '',
        newUrlLoading: false,
        newUrlSuccessful: true,
        newUrlNoData: false,
        newUrlError: false,
      };

    case NEW_URL_NO_DATA:
      return {
        ...state,
        newUrlLoading: false,
        newUrlSuccessful: false,
        newUrlNoData: true,
        newUrlError: false,
      };
    case NEW_URL_ERROR:
      return {
        ...state,
        newUrlLoading: false,
        newUrlSuccessful: false,
        newUrlNoData: false,
        newUrlError: true,
      };

    case HIDE_MANAGE_URLS_MODAL:
      return {
        ...state,
        newUrlLoading: false,
        newUrlSuccessful: false,
        newUrlNoData: false,
        newUrlError: false,
      };

    case RESET_MANAGE_URLS_STATE:
      return {
        ...state,
        gettingDataForUrl: '',
        newUrlLoading: false,
        newUrlSuccessful: false,
        newUrlNoData: false,
        newUrlError: false,
      };

    case RESET_PROJECT:
      return getInitialState();

    case SET_NEW_PROJECT:
      return {
        ...state,
        isNewProject: true,
        hasInteraction: true,
      };

    case SET_PROJECT_NAME:
      return {
        ...state,
        name: action.name,
      };

    case SET_CREDENTIALS_GUID:
      return {
        ...state,
        credentialsGuid: action.credentialsGuid,
      };

    case SET_NOTIFY_ME:
      return {
        ...state,
        notifyMe: action.notifyMe,
      };

    case SET_SCREEN_CAPTURE: {
      const runtimeConfigurationWrapper = state.runtimeConfiguration
        ? new RuntimeConfigurationWrapper(state.runtimeConfiguration)
        : undefined;
      if (runtimeConfigurationWrapper) {
        runtimeConfigurationWrapper.screenCapture = {
          hasScreenCapture: action.screenCapture || false,
          screenCaptureType: action.screenCaptureType || runtimeConfigurationWrapper.screenCapture.screenCaptureType,
        };
      }
      return {
        ...state,
        screenCapture: action.screenCapture,
        runtimeConfiguration: runtimeConfigurationWrapper?.runtimeConfig,
      };
    }

    case SET_INTERACTION_PROJECT:
      return {
        ...state,
        hasInteraction: action.hasInteraction,
      };

    case SET_SCHEDULING_SETTINGS:
      return {
        ...state,
        notifyMe: action.notifyMe,
      };

    case FETCHING_RUNTIME_CONFIG:
      return {
        ...state,
        isFetchingTraining: true,
      };

    case RUNTIME_CONFIG_RECEIVED: {
      const runtimeConfigurationWrapper = new RuntimeConfigurationWrapper(action.runtimeConfiguration);
      return {
        ...state,
        isFetchingTraining: false,
        runtimeConfiguration: runtimeConfigurationWrapper.runtimeConfig,
        screenCapture: runtimeConfigurationWrapper.screenCapture.hasScreenCapture,
        screenCaptureType: runtimeConfigurationWrapper.screenCapture.screenCaptureType,
        htmlExtraction: runtimeConfigurationWrapper.shouldExtractHtml,
      };
    }

    case SET_FINAL_PAGES_LIST:
      return {
        ...state,
        finalPagesList: action.finalPagesList,
      };

    case TOGGLE_CREATE_DATA_REPORT:
      return {
        ...state,
        createDataReport: !state.createDataReport,
      };

    case SET_CREATE_DATA_REPORT:
      return {
        ...state,
        createDataReport: action.createDataReport,
      };

    case SET_REPORT:
      return {
        ...state,
        report: action.report,
      };

    case NAVIGATING_TO_URL:
      return {
        ...state,
        gettingDataForUrl: action.gettingDataForUrl,
        canReplaceAssetsForUrl: action.canReplaceAssetsForUrl,
      };

    case RESOURCE_RESPONSE_OBTAINED:
      return {
        ...state,
        gettingDataForUrl: '',
      };

    case START_REFRESHING_HTML:
      return {
        ...state,
        refreshingPageId: action.refreshingPageId,
      };

    case REFRESHING_HTML_COMPLETE:
      return {
        ...state,
        refreshingPageId: undefined,
      };

    case SET_PROXY_SETTINGS:
      return {
        ...state,
        proxySettings: action.proxySettings,
      };

    case SET_EXISTING_PROJECT:
      return {
        ...state,
        isNewProject: false,
        existingProject: true,
        guid: action.guid,
      };

    case TOGGLE_REPLACE_ASSETS:
      return {
        ...state,
        canReplaceAssetsForUrl: action.canReplaceAssetsForUrl,
      };

    case SET_HTML_EXTRACTION: {
      const runtimeConfigurationWrapper = state.runtimeConfiguration
        ? new RuntimeConfigurationWrapper(state.runtimeConfiguration)
        : undefined;
      if (runtimeConfigurationWrapper) {
        runtimeConfigurationWrapper.shouldExtractHtml = action?.htmlExtraction ?? false;
      }
      return {
        ...state,
        htmlExtraction: action.htmlExtraction,
        runtimeConfiguration: runtimeConfigurationWrapper?.runtimeConfig,
      };
    }

    case EXTRACTOR_LOADED:
      return {
        ...state,
        isParentTriggered: !!action.extractor.parentTriggered,
        addInteractiveInputsToOutput: !!action.extractor.addInteractiveInputsToOutput,
        parentExtractorGuid: action.extractor.parentExtractorGuid || '',
        parentReportGuid: action.extractor.parentReportGuid || '',
        name: action.extractor.name,
      };

    default:
      return state;
  }
}
