import { isEmptyString } from '@import-io/typeguards';
import { getGeoByDomain } from '@import-io/web-extractor';
import type { FormRule } from 'antd';

import { USA_COUNTRY_CODE } from 'common/constants/country-codes';
import { sanitizeUrl } from 'common/utils/url-utils';
import { ExtractionType } from 'features/extractors/forms/extractors-form-types';
import { alpha2To3Codes } from 'features/extractors/forms/new-extractor-form/new-extractor-form-constants';
import { AUTOMATIC_PROXY_SELECTION } from 'features/extractors/proxy/proxy-constants';

export const isAuth = (extractionType: ExtractionType): boolean => extractionType === ExtractionType.AUTH;

export const urlValidator = (_rule: FormRule, url: string) => {
  if (isEmptyString(url)) {
    return Promise.reject('Field is required');
  }
  const sanitizedUrl = sanitizeUrl(url) ?? '';
  return sanitizedUrl ? Promise.resolve() : Promise.reject('Please enter a valid URL and try again');
};

export const getIso3Country = (initialUrl: string, country: string, availableCountries: (string | undefined)[] = []): string => {
  if (country === AUTOMATIC_PROXY_SELECTION) {
    const initialUrlWithoutSlash = initialUrl.replace(/\/$/, '');
    const iso2Country = getGeoByDomain(initialUrlWithoutSlash) ?? '';
    return availableCountries.includes(iso2Country) ? alpha2To3Codes[iso2Country]! : USA_COUNTRY_CODE.alpha3;
  }
  return country;
};
