import type { PropsWithChildren } from 'react';
import { Component } from 'react';

import ErrorPage from 'common/components/error/ErrorPage';

class ErrorBoundary extends Component<PropsWithChildren<{}>, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error(error, info);
  }

  render() {
    return this.state.hasError ? <ErrorPage /> : this.props.children;
  }
}

export default ErrorBoundary;
