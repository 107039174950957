import { endpoints } from '@import-io/js-sdk';
import { HttpMethod } from '@import-io/js-sdk/types/http-request-details';
import { makeRequest } from '@import-io/js-sdk/utils/http-client';

import type { TrackedEvent } from 'common/events/events-types';

const endpoint = 'https://n8n.staging-owl.com/webhook/event';

/**
 * Track event with N8N and possibly some other trackers
 */
export const sendEvent = ({ type, data }: TrackedEvent): Promise<void> => {
  try {
    return makeRequest<void>({
      body: {
        ...data,
        env: endpoints.rootDomain,
        type: type,
      },
      method: HttpMethod.POST,
      url: endpoint,
    });
  } catch (e) {
    console.error(`Failed to deliver ${type} event`, e);
  }

  return Promise.resolve();
};
