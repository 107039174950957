export const COLORS = {
  IO_AQUA: '#66c2ce',
  IO_COBALT: '#009ee2',
  IO_RUSTY: '#84C2CC',
  IO_STEEL: '#366d93',
  IO_NAVY: '#001760',
  SUCCESS: '#7ED321',
  WARNING: '#F5A623',
  DANGER: '#E52054',
  DISABLED_BGCOLOR: '#f1f1f2',
  DISABLED_COLOR: 'rgba(202, 202, 202, 0.75)',
  HIGHLIGHT_BLUE: 'rgba(67,188,206,0.24)',
  MEDIUM_TEAL: '#A9E9EF',
  LIGHT_TEAL: '#E2F3F4',
  TEXT: '#3e2474',
  TEXT_MEDIUM: '#001760',
  TEXT_DARK: '#0D0147',
  ICON: '#9B9EBB',
  MEDIUM_GREY: '#D5D5DD',
  LIGHT_GREY: '#E4E4E8',
  LIGHTER_GREY: '#EEF1F5',
  DARK_GREY: '#B9B9C4',

  TRANSPARENT: 'rgba(0,0,0,0)',
  WHITE: 'rgba(255,255,255,1)',
  DARK_BLUE: '#0a0017',
  BLACK: '#000',
  MAGENTA: '#F50057',
  RECORD: '#DC143C',

  // New colors go below
  LAYOUT_BACKGROUND: '#E5E9EB',
  BACKGROUND1: '#100325',
  BACKGROUND2: '#1b0b3f',
  BACKGROUND3: '#2d1955',

  HIGHLIGHT_GREEN: '#36dba4',

  LINK: '#0e3564',
  LIGHTER_20P: 'rgba(255, 255, 255, 0.2)',
  BUTTON_PRIMARY: '#3e0e87',
  BUTTON_PRIMARY_BORDER: '#af80f5',
  CTA: '#3e0e87',
  ACTIVE_CTA: '#4c13a4',
};
Object.freeze(COLORS);

export function getColor(colorName) {
  return COLORS[colorName];
}

// eslint-disable-next-line import/no-default-export
export default COLORS;
